import { api } from "./UrlPadrao"

export const cadastraMotivoEstadia = async (motivoEstadia, token) => {
  return api.post("MotivoEstadia",
    motivoEstadia
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotivoEstadia = async (token) => {
  return api.get("MotivoEstadia",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getMotivoEstadiaPorId = async (id, token) => {
  return api.get("MotivoEstadia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteMotivoEstadiaPorId = async (id, token) => {
  return api.delete("MotivoEstadia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaMotivoEstadiaPorId = async (motivoEstadia, token) => {
  return api.put("MotivoEstadia",
    motivoEstadia,
  {
    headers: {
      'Authorization': token
    }
  })
}