import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3.js";
import InputLabel from "@mui/material/InputLabel";
import "./Estadia.css";
import CustomButton from "../../../components/CustomButton/CustomButton.js";
import CoresRedux from "../../../contexts/Cor.js";
import NomeTabelaRedux from "../../../contexts/NomeTabela.js";
import DadosUsuarioRedux from "../../../contexts/Usuario.js";
import Tabela from "../../../components/Tabela/Tabela.js";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela.js";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent.js";
import { getEstadia, cadastraEstadia, editaEstadiaPorId, getEstadiaPorId, deleteEstadiaPorId, cadastraEstadiaGoogleStorage, getEstadiaGoogleStoragePorId, deleteEstadiaStoragePorId } from "../../../services/ModuloGerenciador/Estadia.js";
import { getMotivoEstadia } from "../../../services/ModuloGerenciador/MotivoEstadia.js";
import { getStatusDiaria } from "../../../services/ModuloGerenciador/StatusDiaria.js";
import Text from "../../../components/Text/Text.js";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao.js";
import { getRegras } from "../../../services/ModuloGerenciador/Geral.js";
import RequestControllerRedux from "../../../contexts/RequestController.js";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2.js";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2.js";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro.js";
import CloseIcon from "../../../components/CloseIcon/CloseIcon.js";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2.js";
import { getEmbarqueSearch } from "../../../services/ModuloGerenciador/Search.js";
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import DadosContextoEmbarqueRedux from "../../../contexts/ContextoEmbarque.js";
import NewIcon from "../../../components/NewIcon/NewIcon.js";
import LoadingBar from "../../../components/LoadingBar/LoadingBar.js";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2.js";
import { getTipoVeiculo } from "../../../services/ModuloGerenciador/TipoVeiculo.js";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import DeleteIcon from '@mui/icons-material/Delete';
import { FormControl } from "@mui/material";
import Select from 'react-select';
import { valorMask } from "../../../regex/valorMask.js";

function Estadia(props) {

    const opcoes = [
        {
            'opcao': 'Diária de descarga',
            'valor': 1
        },
        {
            'opcao': 'Taxa de descarga',
            'valor': 2
        },
        {
            'opcao': 'Taxa devolução',
            'valor': 3
        }
    ];

    const opcoesVeiculo = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloVeiculo = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const labelVeiculo = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabelaEstadia, setDadosTabelaEstadia] = useState([]);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [telaCadastroEstadia, setTelaCadastroEstadia] = useState(false);

    const [cadastroEstadia, setCadastroEstadia] = useState(false);

    const [idEmbarque, setIdEmbarque] = useState(null);

    const [dadosEmb, setDadosEmb] = useState(null);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const [listaStatus, setListaStatus] = useState([]);

    const buscarStatusDiaria = async () => {
        setLoadingSelectStatusDiaria(true);
        await getStatusDiaria(dadosUsuarioRedux.token).then((result) => {
            setListaStatus(result.data);
            setLoadingSelectStatusDiaria(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [listaMotivo, setListaMotivo] = useState([]);

    const buscarMotivoEstadia = async () => {
        setLoadingSelectMotivoEstadia(true);
        await getMotivoEstadia(dadosUsuarioRedux.token).then((result) => {
            setListaMotivo(result.data);
            setLoadingSelectMotivoEstadia(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [listaTipoVeiculo, setListaTipoVeiculo] = useState([]);

    const buscarTipoVeiculo = async () => {
        setLoadingSelectTipoVeiculo(true);
        await getTipoVeiculo(dadosUsuarioRedux.token).then((result) => {
            setListaTipoVeiculo(result.data);
            setLoadingSelectTipoVeiculo(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoadingSelectTipoVeiculo(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarEstadia = async () => {
        setLoading(true);
        await getEstadia(dadosUsuarioRedux.token).then((result) => {
            setDadosTabelaEstadia(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const salvarEstadiaStorage = async (id) => {
        if (!arquivos || arquivos.length === 0) {
            setLoading(false);
            buscarEstadia();
            setDadosRequestControllerRedux("Fechar Modal Estadia");
            setTabelaRedux({});
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
            setValues({});
            setDadosEmb(null);
            setArquivos([]);
            setValuesSelect({});
            setTelaCadastro(false);
            setCadastroEstadia(false);
            return;
        }

        setLoading(true);
        try {
            for (const arquivo of arquivos) {
                const formData = new FormData();
                formData.append("formFile", arquivo.arquivo);

                const result = await cadastraEstadiaGoogleStorage(formData, id, dadosUsuarioRedux.token);

                if (result.status !== 200) {
                    throw new Error(result.data || "Erro ao cadastrar o arquivo");
                }
            }

            setDadosRequestControllerRedux("Fechar Modal Documentacao");
            setTextoSucesso("Todos os arquivos foram cadastrados com sucesso!");
            setOpenToastSucesso(true);
        } catch (error) {
            if (error.response && typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente. Caso o problema persista, entre em contato com o suporte.");
            }
            setOpenToastErro(true);
        } finally {
            setLoading(false);
            buscarEstadia();
            setDadosRequestControllerRedux("Fechar Modal Estadia");
            setTabelaRedux({});
            setTextoSucesso("Cadastrado Com Sucesso");
            setOpenToastSucesso(true);
            setValues({});
            setDadosEmb(null);
            setArquivos([]);
            setValuesSelect({});
            setTelaCadastro(false);
            setCadastroEstadia(false);
        }
        setLoading(false);
    };

    const cadastrar = async () => {
        setLoading(true);
        await cadastraEstadia(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                salvarEstadiaStorage(result.data.id);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const cadastrarEmbarque = async (idEmbarque) => {
        const valor = {
            embarqueId: idEmbarque,
        }
        setLoading(true);
        await cadastraEstadia(valor, dadosUsuarioRedux.token).then((result) => {
            setValues(result.data);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const editar = async () => {
        setLoading(true);
        await editaEstadiaPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                salvarEstadiaStorage(result.data.id);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarEstadiaStoragePorId = async () => {
        setLoading(true);
        await getEstadiaGoogleStoragePorId(tabelaRedux.row.id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result);
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarEstadiaPorId = async () => {
        setLoading(true);
        await getEstadiaPorId(tabelaRedux.row.id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result);
                    if (result.data.estadiaGoogleStorages) {
                        const arquivos = result.data.estadiaGoogleStorages.map((item) => ({
                            id: item.id,
                            url: item.googleStorage.documentoUrl,
                            name: item.googleStorage.documentoUrl.split('/').pop(), // Extrai o nome do arquivo do URL
                        }));
                        setArquivos(arquivos); // Atualiza o estado
                    }
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarEstadiaPorIdModal = async (id) => {
        setLoading(true);
        await getEstadiaPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarEstadiaPorId = async () => {
        setLoading(true);
        await deleteEstadiaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarEstadia();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);

    useEffect(() => {
        if (idEmbarque !== null && idEmbarque !== undefined) {
            setValues(current => ({ ...current, embarqueId: idEmbarque }));
            cadastrarEmbarque(idEmbarque);
        }
    }, [idEmbarque]);

    useEffect(() => {
        if (editando) {
            buscarEstadiaPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarEstadia();
        buscarStatusDiaria();
        buscarMotivoEstadia();
        buscarTipoVeiculo();
        if (props.idAtual) {
            buscarEstadiaPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Estadia') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Estadia') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const itensFiltro = [
        {
            'nomeColuna': 'Tipo Carroceria',
            'field': 'tipoCarroceria'
        },
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Data Viagem',
            'field': 'dataEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Viagem',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
            'field': 'veiculoMotorista',
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
        {
            'nomeColuna': 'Usuário Responsável',
            'field': 'usuarioResponsavel'
        },
        {
            'nomeColuna': 'Elegível BRF',
            'field': 'elegivelBrf'
        },
    ];

    const [dadosTabelaCopiaFiltro, setDadosTabelaCopiaFiltro] = useState([]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopiaFiltro);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const buscarSearch = async (ordenarPor) => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getEmbarqueSearch(dadosUsuarioRedux.token, pesquisaFormatada, valuesPesquisa.contexto, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal, ordenarPor).then((result) => {
            setDadosTabela(result.data.embarques);
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [loadingSelectStatusDiaria, setLoadingSelectStatusDiaria] = useState(false);

    const [openCadastroStatusDiaria, setOpenCadastroStatusDiaria] = useState(false);

    const handleCloseCadastroStatusDiaria = (value) => {
        if (idAtual) {
            setOpenCadastroStatusDiaria(false);
        } else {
            setLoadingSelectStatusDiaria(true);
            buscarStatusDiaria();
            setOpenCadastroStatusDiaria(false);
        }
    };

    const [loadingSelectMotivoEstadia, setLoadingSelectMotivoEstadia] = useState(false);

    const [openCadastroMotivoEstadia, setOpenCadastroMotivoEstadia] = useState(false);

    const handleCloseCadastroMotivoEstadia = (value) => {
        if (idAtual) {
            setOpenCadastroMotivoEstadia(false);
        } else {
            setLoadingSelectMotivoEstadia(true);
            buscarMotivoEstadia();
            setOpenCadastroMotivoEstadia(false);
        }
    };

    const [arquivos, setArquivos] = useState([]);

    const [loadingSelectTipoVeiculo, setLoadingSelectTipoVeiculo] = useState(false);

    const [openCadastroTipoVeiculo, setOpenCadastroTipoVeiculo] = useState(false);

    const handleCloseCadastroTipoVeiculo = (value) => {
        if (idAtual) {
            setOpenCadastroTipoVeiculo(false);
        } else {
            setLoadingSelectTipoVeiculo(true);
            buscarTipoVeiculo();
            setOpenCadastroTipoVeiculo(false);
        }
    };

    const [valuesSelect, setValuesSelect] = useState([]);

    const [disable, setDisable] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const clearStatusDiaria = () => {
        setValuesSelect(current => ({ ...current, statusDiariaId: '' }));
        setValues(current => ({ ...current, statusDiariaId: null }));
        setValidator(current => ({ ...current, statusDiariaId: "false" }));
    };

    const clearMotivoEstadia = () => {
        setValuesSelect(current => ({ ...current, motivoEstadiaId: '' }));
        setValues(current => ({ ...current, motivoEstadiaId: null }));
        setValidator(current => ({ ...current, motivoEstadiaId: "false" }));
    };

    const clearTipoVeiculo = () => {
        setValuesSelect(current => ({ ...current, tipoVeiculoId: '' }));
        setValues(current => ({ ...current, tipoVeiculoId: null }));
        setValidator(current => ({ ...current, tipoVeiculoId: "false" }));
    };

    const preencherDadosEditando = async (result) => {
        setDadosEmb(result.data.embarque);
        let tipoEstadia;
        if (result.data.tipoEstadia === 1) {
            tipoEstadia = "Diária de descarga";
        } else if (result.data.tipoEstadia === 2) {
            tipoEstadia = "Taxa de descarga"
        } else if (result.data.tipoEstadia === 3) {
            tipoEstadia = "Taxa devolução"
        }
        const tipoEstadiaSelecionado = opcoesVeiculo.find(option =>
            option.value === tipoEstadia
        );
        if (tipoEstadiaSelecionado) {
            setValuesSelect(current => ({ ...current, tipoEstadia: tipoEstadiaSelecionado }));
        }

        const statusSelecionado = listaStatus.find(option => option.id === result.data.statusDiariaId);
        if (statusSelecionado) {
            setValuesSelect(current => ({
                ...current,
                statusDiariaId: {
                    value: statusSelecionado.status,
                    label: statusSelecionado.status,
                    id: statusSelecionado.id
                }
            }));
        }
        const motivoSelecionado = listaMotivo.find(option => option.id === result.data.motivoEstadiaId);
        if (motivoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                motivoEstadiaId: {
                    value: motivoSelecionado.motivo,
                    label: motivoSelecionado.motivo,
                    id: motivoSelecionado.id
                }
            }));
        }
        const tipoVeiculoSelecionado = listaTipoVeiculo.find(option => option.id === result.data.tipoVeiculoId);
        if (tipoVeiculoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoVeiculoId: {
                    value: tipoVeiculoSelecionado.nome,
                    label: tipoVeiculoSelecionado.nome,
                    id: tipoVeiculoSelecionado.id
                }
            }));
        }
    };

    const deletarStorage = async (id) => {
        await deleteEstadiaStoragePorId(id, dadosUsuarioRedux.token).then((result) => {
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const removeImg = (item, index) => {
        // Se o item possui um id, chama o método necessário
        if (item.id) {
            deletarStorage(item.id); // Substitua pelo método correto
        }

        // Remove o item do estado 'arquivos' pelo índice
        setArquivos((prevArquivos) => prevArquivos.filter((_, i) => i !== index));
    };

    const colunas = [
        {
            'nomeColuna': 'Load',
            'field': 'load',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'Data/Hora Chegada',
            'field': 'dataHoraChegadaEmbarque',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'Data/Hora Saida',
            'field': 'dataHoraEmbarcado',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'Data/Hora Agendamento',
            'field': 'dataHoraEntregue',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'Placa',
            'field': 'veiculoEmbarque',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'Status Diária',
            'field': 'statusEmbarque',
            'objetoEmb': 'true',
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte',
            'objetoEmb': 'true',
        },
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Estadia" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Estadia" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Estadia</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="id"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.Estadia}
                                                setCadastroEstadia={setCadastroEstadia}
                                                setIdEmbarque={setIdEmbarque}
                                                dados={dadosTabelaEstadia} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Procurar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Estadia / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Estadia"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Estadia"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Estadia" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Estadia" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                buscarEstadia(); setValues({}); setDadosEmb(null); setArquivos([]); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); setCadastroEstadia(false); setValuesSelect({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            {cadastroEstadia ? (
                                                <></>
                                            ) : (
                                                <div className="filtro-embarque-v2">
                                                    <FiltroV2
                                                        classe='div-filtrar-embarque-v2'
                                                        classOption='select-options'
                                                        itensFiltro={itensFiltro}
                                                        valuesPesquisa={valuesPesquisa}
                                                        setValuesPesquisa={setValuesPesquisa}
                                                        buscarSearch={buscarSearch}
                                                        limpar={limpar}
                                                    />
                                                </div>
                                            )}
                                            {!cadastroEstadia ? (
                                                <TabelaV2
                                                    setTelaCadastro={setTelaCadastro}
                                                    setEditando={setEditando}
                                                    classeSelecionado='idEmbarque'
                                                    nomeClasse1="outer-wrapper"
                                                    nomeClasse2="table-wrapper-v2"
                                                    colunas={dadosTabelaRedux.Embarque}
                                                    dados={dadosTabela}
                                                    nome="embarque"
                                                    nomeTable="Embarque"
                                                    setCadastroEstadia={setCadastroEstadia}
                                                    setIdEmbarque={setIdEmbarque}
                                                    setDadosEmb={setDadosEmb}
                                                />
                                            ) : (
                                                <>
                                                    <div className="flex-column">
                                                        <div className="container-select-emb">
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb">
                                                                    <div class="outer-wrapper-diaria-veiculo">
                                                                        <div class="table-wrapper-diaria-veiculo" id="tableWrapper">
                                                                            <table className="tabela-diaria-veiculo">
                                                                                <thead>
                                                                                    <tr>
                                                                                        {colunas.map((coluna, index) => (
                                                                                            <th key={index} className="th-diaria-estadia">
                                                                                                {coluna.nomeColuna}
                                                                                            </th>
                                                                                        ))}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.load}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.dataHoraChegadaEmbarque
                                                                                                ? new Date(dadosEmb.dataHoraChegadaEmbarque).toLocaleDateString('pt-BR', {
                                                                                                    month: "numeric",
                                                                                                    day: "numeric",
                                                                                                    hour: 'numeric',
                                                                                                    minute: 'numeric',
                                                                                                })
                                                                                                : ''}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.dataHoraEmbarcado
                                                                                                ? new Date(dadosEmb.dataHoraEmbarcado).toLocaleDateString('pt-BR', {
                                                                                                    month: "numeric",
                                                                                                    day: "numeric",
                                                                                                    hour: 'numeric',
                                                                                                    minute: 'numeric',
                                                                                                })
                                                                                                : ''}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.dataHoraEntregue
                                                                                                ? new Date(dadosEmb.dataHoraEntregue).toLocaleDateString('pt-BR', {
                                                                                                    month: "numeric",
                                                                                                    day: "numeric",
                                                                                                    hour: 'numeric',
                                                                                                    minute: 'numeric',
                                                                                                })
                                                                                                : ''}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.veiculo?.caminhao?.placa}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.cliente}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.unidEmbarque}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.statusEmbarque}
                                                                                        </th>
                                                                                        <th className="th-diaria-estadia">
                                                                                            {dadosEmb?.cte}
                                                                                        </th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        {
                                                                            loadingSelectStatusDiaria ? (
                                                                                <LoadingBar />
                                                                            ) : (
                                                                                <div className="coluna-emb">
                                                                                    <div>
                                                                                        <p className="input-label-emb" htmlFor="descricao">Status Diária:</p>
                                                                                        <SelectEmbarqueV2
                                                                                            classeCss={250}
                                                                                            onChange={(e) => {
                                                                                                setValuesSelect(current => ({ ...current, statusDiariaId: e }));
                                                                                                setValues(current => ({ ...current, statusDiariaId: e.id }));
                                                                                                setValidator(current => ({ ...current, statusDiariaId: "true" }));
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setDisable(true);
                                                                                                setOpenCadastroStatusDiaria(true);
                                                                                            }}
                                                                                            clearSelection={clearStatusDiaria}
                                                                                            onInfoIconClick={setIdAtual}
                                                                                            id="id"
                                                                                            grande={true}
                                                                                            value={valuesSelect.statusDiariaId}
                                                                                            titulo="Grupo Cliente"
                                                                                            dadosSelect={listaStatus}
                                                                                            referenciaId="status"
                                                                                            referenciaItem="status" />
                                                                                    </div>
                                                                                    <NewIcon
                                                                                        onClick={(e) => {
                                                                                            setOpenCadastroStatusDiaria(true); setIdAtual(null); setDisable(false);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        {
                                                                            loadingSelectMotivoEstadia ? (
                                                                                <LoadingBar />
                                                                            ) : (
                                                                                <div className="coluna-emb">
                                                                                    <div>
                                                                                        <p className="input-label-emb" htmlFor="descricao">Motivo Estadia:</p>
                                                                                        <SelectEmbarqueV2
                                                                                            classeCss={250}
                                                                                            onChange={(e) => {
                                                                                                setValuesSelect(current => ({ ...current, motivoEstadiaId: e }));
                                                                                                setValues(current => ({ ...current, motivoEstadiaId: e.id }));
                                                                                                setValidator(current => ({ ...current, motivoEstadiaId: "true" }));
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setDisable(true);
                                                                                                setOpenCadastroMotivoEstadia(true);
                                                                                            }}
                                                                                            clearSelection={clearMotivoEstadia}
                                                                                            onInfoIconClick={setIdAtual}
                                                                                            id="id"
                                                                                            grande={true}
                                                                                            value={valuesSelect.motivoEstadiaId}
                                                                                            titulo="Grupo Cliente"
                                                                                            dadosSelect={listaMotivo}
                                                                                            referenciaId="motivo"
                                                                                            referenciaItem="motivo" />
                                                                                    </div>
                                                                                    <NewIcon
                                                                                        onClick={(e) => {
                                                                                            setOpenCadastroMotivoEstadia(true); setIdAtual(null); setDisable(false);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        {
                                                                            loadingSelectTipoVeiculo ? (
                                                                                <LoadingBar />
                                                                            ) : (
                                                                                <div className="coluna-emb">
                                                                                    <div>
                                                                                        <p className="input-label-emb" htmlFor="descricao">Tipo Veículo:</p>
                                                                                        <SelectEmbarqueV2
                                                                                            classeCss={250}
                                                                                            onChange={(e) => {
                                                                                                setValuesSelect(current => ({ ...current, tipoVeiculoId: e }));
                                                                                                setValues(current => ({ ...current, tipoVeiculoId: e.id }));
                                                                                                setValidator(current => ({ ...current, tipoVeiculoId: "true" }));
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                setDisable(true);
                                                                                                setOpenCadastroTipoVeiculo(true);
                                                                                            }}
                                                                                            clearSelection={clearTipoVeiculo}
                                                                                            onInfoIconClick={setIdAtual}
                                                                                            id="id"
                                                                                            grande={true}
                                                                                            value={valuesSelect.tipoVeiculoId}
                                                                                            titulo="Grupo Cliente"
                                                                                            dadosSelect={listaTipoVeiculo}
                                                                                            referenciaId="nome"
                                                                                            referenciaItem="nome" />
                                                                                    </div>
                                                                                    <NewIcon
                                                                                        onClick={(e) => {
                                                                                            setOpenCadastroTipoVeiculo(true); setIdAtual(null); setDisable(false);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Daste:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, daste: e.target.value }));
                                                                                setValidator(current => ({ ...current, daste: "true" }));
                                                                            }}
                                                                            value={values.daste}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, observacao: e.target.value }));
                                                                                setValidator(current => ({ ...current, observacao: "true" }));
                                                                            }}
                                                                            value={values.observacao}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">CTE:</p>
                                                                        <input
                                                                            type="text"
                                                                            maxLength="5"
                                                                            placeholder='00000'
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                let valor = e.target.value;
                                                                                valor = valor.replace(/\D/g, '');
                                                                                setValues(current => ({ ...current, cteComplementar: valor }));
                                                                            }}
                                                                            value={values.cteComplementar}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Tipo Estadia:</p>
                                                                        <FormControl sx={{ width: 265 }}>
                                                                            <Select
                                                                                value={valuesSelect.tipoEstadia}
                                                                                options={opcoesVeiculo}
                                                                                formatOptionLabel={labelVeiculo}
                                                                                styles={estiloVeiculo}
                                                                                placeholder="Selecione"
                                                                                noOptionsMessage={() => "Não há mais opções"}
                                                                                onChange={(selectedOption) => {
                                                                                    if (selectedOption) {
                                                                                        setValues((current) => ({
                                                                                            ...current,
                                                                                            tipoEstadia: selectedOption.valor,
                                                                                        }));
                                                                                        setValuesSelect(current => ({ ...current, tipoEstadia: selectedOption }));
                                                                                        setValidator(current => ({ ...current, tipoEstadia: "true" }));
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </FormControl>
                                                                    </div>
                                                                </div>
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Frete Empresa:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, freteEmpresa: e.target.value }));
                                                                                setValidator(current => ({ ...current, freteEmpresa: "true" }));
                                                                            }}
                                                                            value={valorMask(values.freteEmpresa)}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                                <div className="coluna-emb">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Frete Motorista:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, freteMotorista: e.target.value }));
                                                                                setValidator(current => ({ ...current, freteMotorista: "true" }));
                                                                            }}
                                                                            value={valorMask(values.freteMotorista)}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb" style={{ alignItems: 'flex-start', gap: 10 }}>
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Anexar Arquivos:</p>
                                                                        <input
                                                                            type="file"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            multiple
                                                                            onChange={(e) => {
                                                                                const arquivosSelecionados = Array.from(e.target.files);
                                                                                const arquivosComPreview = arquivosSelecionados.map((arquivo) => {
                                                                                    const url = URL.createObjectURL(arquivo);
                                                                                    return { arquivo, preview: url };
                                                                                });
                                                                                setArquivos((prevArquivos) => [...(prevArquivos || []), ...arquivosComPreview]);
                                                                                setValidator((current) => ({ ...current, arquivos: "true" }));
                                                                            }}
                                                                            disabled={props.desabilitar}
                                                                        />
                                                                    </div>
                                                                    {arquivos && arquivos.length > 0 && (
                                                                        <div className="lista-arquivos">
                                                                            <p className="input-label-emb">Arquivos Anexados:</p>
                                                                            <ul>
                                                                                {arquivos.map((item, index) => (
                                                                                    <li key={index} className="nome-arquivo">
                                                                                        {item.preview ? (
                                                                                            <img
                                                                                                src={item.preview}
                                                                                                alt={item.arquivo?.name || "Imagem do Google Storage"}
                                                                                                style={{ width: '100px', height: '100px', marginRight: '10px', objectFit: 'cover', borderRadius: '5px' }}
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                src={item?.url || "default-image-url.png"} // Substitua pela URL padrão, se necessário
                                                                                                alt="Imagem do Google Storage"
                                                                                                style={{ width: '100px', height: '100px', marginRight: '10px', objectFit: 'cover', borderRadius: '5px' }}
                                                                                            />
                                                                                        )}
                                                                                        {item && item.arquivo && item.arquivo.name ? (
                                                                                            <>
                                                                                                {item.arquivo.name.length > 10
                                                                                                    ? `${item.arquivo.name.substring(0, 10)}...`
                                                                                                    : item.arquivo.name}
                                                                                                <div className="grid-item-entrega">
                                                                                                    <DeleteIcon className="trash-v2" onClick={() => removeImg(item, index)}></DeleteIcon>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {item.name.length > 10
                                                                                                    ? `${item.name.substring(0, 10)}...`
                                                                                                    : item.name}
                                                                                                <div className="grid-item-entrega">
                                                                                                    <DeleteIcon className="trash-v2" onClick={() => removeImg(item, index)}></DeleteIcon>
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="botoes-embalagem">
                                                        {
                                                            editando ? (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cadastrar"
                                                                    classText="button-texto-cadastrar"
                                                                    icone="iconeSave"
                                                                    titulo="Salvar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); setArquivos([]); setValuesSelect({}); setDadosEmb(null); }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {
                                                                        !props.desabilitar ? (
                                                                            props.botaoCadastrar ? (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Salvar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        editar();
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Salvar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        editar();
                                                                                    }}
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            props.botaoVoltar ? (
                                                                <>
                                                                </>
                                                            ) : (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cancelar"
                                                                    classText="button-texto-cancelar"
                                                                    titulo="Cancelar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { buscarEstadia(); setValues({}); setDadosEmb(null); setArquivos([]); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); setCadastroEstadia(false); }}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            )}
                                            {
                                                !cadastroEstadia && dadosTabela.length === 0 && dadosContextoEmbarqueRedux != 'templateEmbarque' ? (
                                                    <p className="texto-mes">Nenhum embarque cadastrado neste mês</p>
                                                ) : (
                                                    <span></span>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarEstadiaPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroStatusDiaria="sim"
                                        open={openCadastroStatusDiaria}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroStatusDiaria}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroMotivoEstadia="sim"
                                        open={openCadastroMotivoEstadia}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroMotivoEstadia}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroTipoVeiculo="sim"
                                        open={openCadastroTipoVeiculo}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroTipoVeiculo}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Estadia;