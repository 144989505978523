import React, { useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import '../ModalLegenda/ModalLegenda.css';

export interface ModalLegendaProps {
    titulo: string;
    open: boolean;
    onClose: () => void;
}

function ModalLegenda(props: ModalLegendaProps) {
    const { onClose, open, titulo } = props;

    const legendasLinhas = [
        { valor: 'Possui CTE', cor: '#43ff64d9' },
        { valor: 'Não possui CTE', cor: '#e2e2e2' },
        { valor: 'Cancelado', cor: '#ff000080' },
    ];

    const legendasColunas = [
        { valor: 'Dta.h Cg. Emb.', descricao: 'Não On Time', cor: '#ff6e6e' },
        { valor: 'Dta.h Cg. Entr', descricao: 'Não On Time', cor: '#ff6e6e' },
        { valor: 'Dta.h Cg. Entr', descricao: 'Excedeu Diária Cliente', cor: '#6e78ff' },
        { valor: 'Previsão Embarque/Previsão Entrega', descricao: 'Tempo Faltante 4 horas.', cor: '#fffd70' },
        { valor: 'Previsão Embarque/Previsão Entrega', descricao: 'Tempo Faltante 3 horas.', cor: '#cac844' },
        { valor: 'Previsão Embarque/Previsão Entrega', descricao: 'Tempo Faltante 2 horas.', cor: '#ffa36e' },
        { valor: 'Previsão Embarque/Previsão Entrega', descricao: 'Tempo Faltante 1 hora.', cor: '#ff6e6e' },
        { valor: 'Placa Cavalo', descricao: 'Tem Api Sul.', cor: '#fffd70' },
    ];

    const handleClose = () => {
        onClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <div className="div-modal-legenda">
                    <DialogTitle id="modal-title">
                        {titulo}
                    </DialogTitle>
                    <DialogContent>
                        <div className="legendas">
                            <h3>Linhas:</h3>
                            <ul>
                                {legendasLinhas.map((item, index) => (
                                    <li key={index} className="legenda-item">
                                        <span
                                            className="legenda-cor"
                                            style={{ backgroundColor: item.cor }}
                                        ></span>
                                        {item.valor}
                                    </li>
                                ))}
                            </ul>
                            <h3>Colunas:</h3>
                            <ul>
                                {legendasColunas.map((item, index) => (
                                    <li key={index} className="legenda-item">
                                        <span
                                            className="legenda-cor"
                                            style={{ backgroundColor: item.cor }}
                                        ></span>
                                        {item.valor} - {item.descricao}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-ok"
                            titulo="Fechar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalLegenda;
