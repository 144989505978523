import { api } from "./UrlPadrao"

export const cadastraStatusDiaria = async (StatusDiaria, token) => {
  return api.post("StatusDiaria",
    StatusDiaria
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getStatusDiaria = async (token) => {
  return api.get("StatusDiaria",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getStatusDiariaPorId = async (id, token) => {
  return api.get("StatusDiaria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteStatusDiariaPorId = async (id, token) => {
  return api.delete("StatusDiaria/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaStatusDiariaPorId = async (StatusDiaria, token) => {
  return api.put("StatusDiaria",
    StatusDiaria,
  {
    headers: {
      'Authorization': token
    }
  })
}