import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3.js";
import InputLabel from "@mui/material/InputLabel";
import "./TipoContato.css";
import CustomButton from "../../../../components/CustomButton/CustomButton.js";
import CoresRedux from "../../../../contexts/Cor.js";
import NomeTabelaRedux from "../../../../contexts/NomeTabela.js";
import DadosUsuarioRedux from "../../../../contexts/Usuario.js";
import Tabela from "../../../../components/Tabela/Tabela.js";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../../contexts/Tabela.js";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent.js";
import { getTipoContato, cadastraTipoContato, editaTipoContatoPorId, getTipoContatoPorId, deleteTipoContatoPorId } from "../../../../services/ModuloGerenciador/TipoContato.js";
import Text from "../../../../components/Text/Text.js";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao.js";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral.js";
import RequestControllerRedux from "../../../../contexts/RequestController.js";
import { motion } from "framer-motion";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2.js";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2.js";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro.js";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon.js";
import { FormControl } from "@mui/material";
import Select from 'react-select';
import NewIcon from "../../../../components/NewIcon/NewIcon.js";
import LoadingBar from "../../../../components/LoadingBar/LoadingBar.js";
import SelectEmbarqueV2 from "../../../../components/SelectEmbarqueV2/SelectEmbarqueV2.js";
import { getProprietario } from "../../../../services/ModuloGerenciador/Proprietario.js";
import ModalCadastro from "../../../../components/ModalCadastro/ModalCadastro.tsx";
import { telefoneMask } from "../../../../regex/telefoneMask.js";
import { emailMask } from "../../../../regex/emailMask.js";
import { getMotorista } from "../../../../services/ModuloGerenciador/Motorista.js";

function TipoContato(props) {

    const opcoes = [
        {
            'opcao': 'Telefone',
            'valor': 1
        },
        {
            'opcao': 'Email',
            'valor': 2
        }
    ];

    const opcoesVeiculo = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const labelVeiculo = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const estiloVeiculo = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const preencherDadosEditando = async (result) => {
        const tipoContatoSelecionado = opcoes.find(opcao => opcao.valor === result.tipoContatoEnum);
        if (tipoContatoSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoContatoEnum: {
                    label: tipoContatoSelecionado.opcao,
                    opcao: tipoContatoSelecionado.opcao,
                    valor: tipoContatoSelecionado.valor,
                    value: tipoContatoSelecionado.opcao,
                }
            }));
        }
        const proprietarioSelecionado = listaProprietario.find(option => option.idProprietario === result.proprietarioId);
        if (proprietarioSelecionado) {
            setValuesSelect(current => ({
                ...current,
                proprietarioId: {
                    value: proprietarioSelecionado.nome,
                    label: proprietarioSelecionado.nome,
                    id: proprietarioSelecionado.idProprietario
                }
            }));
        }
        const motoristaSelecionado = listaMotorista.find(option => option.idMotorista === result.motoristaId);
        if (motoristaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                motoristaId: {
                    value: motoristaSelecionado.nome,
                    label: motoristaSelecionado.nome,
                    id: motoristaSelecionado.idMotorista
                }
            }));
        }
    };

    const { dadosCoresRedux } = useContext(CoresRedux);

    const [valuesSelect, setValuesSelect] = useState([]);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const buscarTipoContato = async () => {
        setLoading(true);
        await getTipoContato(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.contato !== "")) {
            setLoading(true);
            await cadastraTipoContato(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarTipoContato();
                    setDadosRequestControllerRedux("Fechar Modal TipoContato");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setValues({});
                    setValuesSelect({});
                    setTelaCadastro(false);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
                setValidator(current => ({ ...current, contato: "true" }));
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaTipoContatoPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarTipoContato();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setTelaCadastro(false);
                setEditando(false);
                setValues({});
                setValuesSelect({});
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarTipoContatoPorId = async () => {
        setLoading(true);
        await getTipoContatoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result.data);
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarTipoContatoPorIdModal = async (id) => {
        setLoading(true);
        await getTipoContatoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarTipoContatoPorId = async () => {
        setLoading(true);
        await deleteTipoContatoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarTipoContato();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, contato: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarTipoContatoPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarTipoContato();
        buscarProprietarios();
        buscarMotorista();
        if (props.idAtual) {
            buscarTipoContatoPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Uso Comum') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Uso Comum') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const clearMotorista = () => {
        setValuesSelect(current => ({ ...current, motoristaId: '' }));
        setValues(current => ({ ...current, motoristaId: null }));
        setValidator(current => ({ ...current, motoristaId: "false" }));
    };

    const clearProprietario = () => {
        setValuesSelect(current => ({ ...current, proprietarioId: '' }));
        setValues(current => ({ ...current, proprietarioId: null }));
        setValidator(current => ({ ...current, proprietarioId: "false" }));
    };

    const buscarProprietarios = async () => {
        await getProprietario(dadosUsuarioRedux.token).then((result) => {
            setListaProprietario(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarMotorista = async () => {
        await getMotorista(dadosUsuarioRedux.token).then((result) => {
            setListaMotorista(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [listaProprietario, setListaProprietario] = useState([]);

    const [listaMotorista, setListaMotorista] = useState([]);

    const [idAtual, setIdAtual] = useState();

    const [loadingSelectProprietario, setLoadingSelectProprietario] = useState(false);

    const [openCadastroProprietario, setOpenCadastroProprietario] = useState(false);

    const handleCloseCadastroProprietario = (value) => {
        if (idAtual) {
            setOpenCadastroProprietario(false);
        } else {
            setLoadingSelectProprietario(true);
            buscarProprietarios();
            setOpenCadastroProprietario(false);
        }
    };

    const [loadingSelectMotorista, setLoadingSelectMotorista] = useState(false);

    const [openCadastroMotorista, setOpenCadastroMotorista] = useState(false);

    const handleCloseCadastroMotorista = (value) => {
        if (idAtual) {
            setOpenCadastroMotorista(false);
        } else {
            setLoadingSelectMotorista(true);
            buscarMotorista();
            setOpenCadastroMotorista(false);
        }
    };

    const [disable, setDisable] = useState(false);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="TipoContato" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="TipoContato" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Tipo Contato</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="id"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.TipoContato}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Tipo Contato / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Tipo Contato"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Tipo Contato"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Tipo Contato" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Tipo Contato" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); setValuesSelect({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Tipo Contato:</p>
                                                                <FormControl sx={{ width: 265 }}>
                                                                    <Select
                                                                        value={valuesSelect.tipoContatoEnum}
                                                                        options={opcoesVeiculo}
                                                                        formatOptionLabel={labelVeiculo}
                                                                        styles={estiloVeiculo}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    tipoContatoEnum: selectedOption.valor,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, tipoContatoEnum: selectedOption }));
                                                                                setValidator(current => ({ ...current, tipoContatoEnum: "true" }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            {values.tipoContatoEnum === 1 && (
                                                                <div className="principal">
                                                                    <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                                                    <input
                                                                        type="text"
                                                                        className="input-emb"
                                                                        style={{ width: '270px' }}
                                                                        onChange={(e) => {
                                                                            const maskedValue = telefoneMask(e.target.value); // Aplica a máscara
                                                                            setValues((current) => ({ ...current, contato: maskedValue })); // Atualiza o estado com o valor formatado
                                                                            setValidator((current) => ({ ...current, contato: "true" })); // Mantém o validador
                                                                        }}
                                                                        placeholder=""
                                                                        value={values.contato}
                                                                        disabled={props.desabilitar} />
                                                                </div>
                                                            )}
                                                            {values.tipoContatoEnum === 2 && (
                                                                <div className="principal">
                                                                    <p className="input-label-emb" htmlFor="descricao">Email:</p>
                                                                    <input
                                                                        type="text"
                                                                        className="input-emb"
                                                                        style={{ width: '270px' }}
                                                                        onChange={(e) => {
                                                                            setValues(current => ({ ...current, contato: e.target.value }));
                                                                            setValidator(current => ({ ...current, contato: "true" }));
                                                                        }}
                                                                        placeholder=""
                                                                        value={values.contato}
                                                                        disabled={props.desabilitar} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        {!values.motoristaId && (
                                                            <div className="coluna-emb">
                                                                <div>
                                                                    {
                                                                        loadingSelectProprietario ? (
                                                                            <LoadingBar />
                                                                        ) : (
                                                                            <div className="coluna-emb">
                                                                                <div className="principal">
                                                                                    <p className="input-label-emb" htmlFor="descricao">Proprietário:</p>
                                                                                    <SelectEmbarqueV2
                                                                                        classeCss={230}
                                                                                        onChange={(e) => {
                                                                                            setValuesSelect(current => ({ ...current, proprietarioId: e }));
                                                                                            setValues(current => ({ ...current, proprietarioId: e.id }));
                                                                                            setValidator(current => ({ ...current, proprietarioId: "true" }));
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            setDisable(true);
                                                                                            setOpenCadastroProprietario(true);
                                                                                        }}
                                                                                        onInfoIconClick={setIdAtual}
                                                                                        id="idProprietario"
                                                                                        grande={true}
                                                                                        clearSelection={clearProprietario}
                                                                                        value={valuesSelect.proprietarioId}
                                                                                        titulo="Grupo Cliente"
                                                                                        dadosSelect={listaProprietario}
                                                                                        referenciaId="nome"
                                                                                        referenciaItem="nome" />
                                                                                </div>
                                                                                <NewIcon
                                                                                    onClick={(e) => {
                                                                                        setOpenCadastroProprietario(true); setIdAtual(null); setDisable(false);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="rota-div-2">
                                                        {!values.proprietarioId && (
                                                            <div className="coluna-emb">
                                                                <div>
                                                                    {
                                                                        loadingSelectMotorista ? (
                                                                            <LoadingBar />
                                                                        ) : (
                                                                            <div className="coluna-emb">
                                                                                <div className="principal">
                                                                                    <p className="input-label-emb" htmlFor="descricao">Motorista:</p>
                                                                                    <SelectEmbarqueV2
                                                                                        classeCss={260}
                                                                                        onChange={(e) => {
                                                                                            setValuesSelect(current => ({ ...current, motoristaId: e }));
                                                                                            setValues(current => ({ ...current, motoristaId: e.id }));
                                                                                            setValidator(current => ({ ...current, motoristaId: "true" }));
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            e.stopPropagation();
                                                                                            setDisable(true);
                                                                                            setOpenCadastroMotorista(true);
                                                                                        }}
                                                                                        clearSelection={clearMotorista}
                                                                                        onInfoIconClick={setIdAtual}
                                                                                        id="idMotorista"
                                                                                        grande={true}
                                                                                        value={valuesSelect.motoristaId}
                                                                                        titulo="Grupo Cliente"
                                                                                        dadosSelect={listaMotorista}
                                                                                        referenciaId="nome"
                                                                                        referenciaItem="nome" />
                                                                                </div>
                                                                                <NewIcon
                                                                                    onClick={(e) => {
                                                                                        setOpenCadastroMotorista(true); setIdAtual(null); setDisable(false);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '255px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nome: e.target.value }));
                                                                        setValidator(current => ({ ...current, nome: "true" }));
                                                                    }}
                                                                    value={values.nome}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, observacao: e.target.value }));
                                                                        setValidator(current => ({ ...current, observacao: "true" }));
                                                                    }}
                                                                    value={values.observacao}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-tipoContato">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.contato !== "")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.contato !== "")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); setValuesSelect({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarTipoContatoPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroProprietario="sim"
                                        open={openCadastroProprietario}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroProprietario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default TipoContato;