import { Button, CircularProgress, FormControl, Input, InputLabel } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { Card } from 'primereact/card';
import "./CadastroUsuario.css";
import { salvaSolicitacao } from "../../../../services/ModuloGerenciador/Solicitacao";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import Text from "../../../../components/Text/Text";
import { useNavigate } from "react-router-dom";
import RequestControllerRedux from "../../../../contexts/RequestController";
import { telefoneMask } from "../../../../regex/telefoneMask";
import TextLink from "../../../../components/TextLink/TextLink";
import { motion } from "framer-motion";
import { tenantData } from "../../../../components/EmpresasTenant";
import ReactSelect from "react-select";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import logoLogin from '../../../../images/logoLogin.png'
import ModalConfirmacao2 from "../../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";

function CadastroUsuario(props) {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const handleClose = (value) => {
        setOpen(false);
        navigate("/login")
    };

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const [values, setValues] = useState([]);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [tentouEnviar, setTentouEnviar] = useState(false);


    const [loading, setLoading] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [validator, setValidator] = useState([]);

    useEffect(() => {
        setValidator(current => ({ ...current, usuario: "false" }));
        setValidator(current => ({ ...current, email: "false" }));
        setValidator(current => ({ ...current, telefone: "false" }));
        setValidator(current => ({ ...current, nascimento: "false" }));
        setValidator(current => ({ ...current, tenant: "false" }));
    }, []);

    const enviar = async (event) => {
        if (event) event.preventDefault();
    
        setTentouEnviar(true); // Marca que o envio foi tentado
    
        const camposObrigatorios = [
            { nome: "Usuário", valor: values.usuario, chave: "usuario" },
            { nome: "Email", valor: values.email, chave: "email" },
            { nome: "Telefone", valor: values.telefone, chave: "telefone" },
            { nome: "Nascimento", valor: values.nascimento, chave: "nascimento" },
            { nome: "Empresa", valor: values.tenant, chave: "tenant" },
        ];
    
        const camposFaltando = [];
    
        camposObrigatorios.forEach((campo) => {
            if (!campo.valor || campo.valor.trim() === "") {
                camposFaltando.push(campo.nome);
                setValidator((current) => ({ ...current, [campo.chave]: "false" }));
            } else {
                setValidator((current) => ({ ...current, [campo.chave]: "true" }));
            }
        });
    
        if (camposFaltando.length > 0) {
            setTextoErro(`Preencha os campos: ${camposFaltando.join(", ")}`);
            setOpenToastErro(true);
            return;
        }
    
        if (values.senha !== values.confirmaSenha) {
            setTextoErro("As senhas devem ser iguais!");
            setOpenToastErro(true);
            return;
        }
    
        setLoading(true);
        try {
            const result = await salvaSolicitacao(values, values.tenant); // Espera a resposta da API
            if (result.status === 200) {
                setLoading(false);
                setOpen(true); // Mostra confirmação de sucesso
            }
        } catch (error) {
            setTextoErro(
                error.response?.data?.length > 500
                    ? "Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte."
                    : error.response?.data || "Erro ao enviar solicitação"
            );
            setOpenToastErro(true);
            setLoading(false);
        }
    };
    

    const labelFrota = (data) => (
        <div>
            {data.titulo}
        </div>
    );

    const opcoesFrota = tenantData.map((data) => ({
        value: data.tenant,
        label: data.titulo,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: tentouEnviar && validator.tenant === "false" ? '2px solid red' : '1px solid var(--Background-Input, #F2F2F2)',
            borderRadius: '7px',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
    
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '280px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
        setShowPassword(!showPassword);
    };

    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const handleClickShowPasswordConfirm = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
        setShowPasswordConfirm(!showPasswordConfirm);
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <img src={logoLogin} alt="logo" className='imageCad' />
            <div className="container">
                <form className="card-cadastro">
                    {!loading && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <div className="input">
                                <h1 className="titulo-cadastro">Cadastro</h1>
                                <p className="texto-informe">Será enviado uma solicitação de acesso sua aos administradores.</p>
                                <div className="rota-div-2">
                                    <div className="coluna-emb">
                                        <div className="principal">
                                            <p className="input-label-emb" htmlFor="descricao">Usuário:</p>
                                            <input
    type="text"
    className={`input-emb input-cad ${tentouEnviar && validator.usuario === "false" ? "input-invalido" : ""}`}
    style={{ width: '300px' }}
    onChange={(e) => {
        setValues(current => ({ ...current, usuario: e.target.value.replace(/ /g, '') }));
        setValidator(current => ({ ...current, usuario: "true" }));
    }}
    value={values.usuario || ""} />


                                        </div>
                                    </div>
                                    <div className="coluna-emb">
                                        <div className="principal">
                                            <p className="input-label-emb" htmlFor="descricao">Email:</p>
                                            <input
    type="text"
    className={`input-emb input-cad ${tentouEnviar && validator.email === "false" ? "input-invalido" : ""}`}
    style={{ width: '300px' }}
    id="email"
    onChange={(e) => {
        setValues(current => ({ ...current, email: e.target.value.replace(/ /g, '') }));
        setValidator(current => ({ ...current, email: "true" }));
    }}
    value={values.email || ""} />

                                        </div>
                                    </div>
                                </div>
                                <div className="rota-div-2">
                                    <div className="coluna-emb">
                                        <div className="principal">
                                            <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                            <input
    type="text"
    className={`input-emb input-cad ${tentouEnviar && validator.telefone === "false" ? "input-invalido" : ""}`}
    style={{ width: '300px' }}
    id="email"
    onChange={(e) => {
        setValues(current => ({ ...current, telefone: e.target.value.replace(/ /g, '') }));
        setValidator(current => ({ ...current, telefone: "true" }));
    }}
    value={telefoneMask(values.telefone) || ""} />

                                        </div>
                                    </div>
                                    <div className="coluna-emb">
                                        <div className="principal">
                                            <p className="input-label-emb" htmlFor="descricao">Nascimento:</p>
                                            <input
    type="date"
    className={`input-emb input-cad ${tentouEnviar && validator.nascimento === "false" ? "input-invalido" : ""}`}
    style={{ width: '300px' }}
    id="email"
    onChange={(e) => {
        setValues(current => ({ ...current, nascimento: e.target.value }));
        setValidator(current => ({ ...current, nascimento: "true" }));
    }}
    value={values.nascimento || ""} />


                                        </div>
                                    </div>
                                </div>
                                <div className="rota-div-2">
                                    <div className="coluna-emb">
                                        <div className="principal">
                                            <p className="input-label-emb" htmlFor="descricao">Senha:</p>
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <input
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    value={values.senha}
                                                    onChange={(e) => setValues(current => ({ ...current, senha: e.target.value }))}
                                                    className="input-emb input-cad"
                                                    style={{
                                                        paddingRight: '40px',
                                                        width: '300px',
                                                        height: '40px',
                                                        boxSizing: 'border-box'
                                                    }}
                                                />
                                                <div
                                                    onClick={handleClickShowPassword}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '10px',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="coluna-emb">
                                        <div className="principal">
                                            <p className="input-label-emb" htmlFor="descricao">Confirmar Senha:</p>
                                            <div style={{ position: 'relative', width: '100%' }}>
                                                <input
                                                    type={values.showConfirmPassword ? 'text' : 'password'}
                                                    value={values.confirmaSenha}
                                                    onChange={(e) => setValues(current => ({ ...current, confirmaSenha: e.target.value }))}
                                                    className="input-emb input-cad"
                                                    style={{
                                                        paddingRight: '40px',
                                                        width: '300px',
                                                        height: '40px',
                                                        boxSizing: 'border-box'
                                                    }}
                                                />
                                                <div
                                                    onClick={handleClickShowPasswordConfirm}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '10px',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rota-div-2">
                                    <div className="coluna-emb">
                                        <div className="principal">
                                            <p className="input-label-emb" htmlFor="descricao">Empresa:</p>
                                            <FormControl sx={{ width: 300 }}>
                                                <ReactSelect
                                                    value={valuesSelect.frota}
                                                    options={opcoesFrota}
                                                    formatOptionLabel={labelFrota}
                                                    styles={estiloFrota}
                                                    placeholder="Selecione"
                                                    noOptionsMessage={() => "Não há mais opções"}
                                                    onChange={(selectedOption) => {
                                                        if (selectedOption) {
                                                            setValues((current) => ({
                                                                ...current,
                                                                tenant: selectedOption.tenant,
                                                            }));
                                                            setValidator(current => ({ ...current, tenant: "true" }));
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="botao-senhas">
                            <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="ENVIAR"
                            cor="#0946B8"
                            valor={values}
                            onClick={(event) => {
                                event.preventDefault(); // Previne o comportamento padrão
                                enviar();               // Chama a função enviar
                            }}
                        />

                                <InputLabel className="titulo-select-cadastro" onClick={() => { navigate("/login"); }} htmlFor="status">
                                    <p className="decoration">Voltar para o login</p>
                                </InputLabel>
                            </div>
                        </motion.div>
                    )}
                    {loading && (
                        <div className="loading"><CircularProgress size={50} /></div>
                    )}
                </form>
            </div>
            <div>
                <ToastSucesso
                    texto={textoSucesso}
                    open={openToastSucesso}
                    onClose={handleCloseToastSucesso}
                />
                <ToastErro
                    texto={textoErro}
                    open={openToastErro}
                    onClose={handleCloseToastErro}
                />
                <ModalConfirmacao2
                    titulo="Solicitação enviada com sucesso!"
                    texto="Seu usuário só será liberado para acessar o sistema após a aprovação de um administrador"
                    tituloConfirmar="Sim"
                    tituloCancelar="Não"
                    textoBotao="Ok"
                    voltar={true}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                />
            </div>
        </motion.div>
    );
}

export default CadastroUsuario;