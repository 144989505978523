import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalCadastro/ModalCadastro.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButton from "../CustomButton/CustomButton";
import Rota from "../../pages/ModuloGerenciador/Rota/Rota";
import ClearIcon from '@mui/icons-material/Clear';
import Motorista from "../../pages/ModuloGerenciador/Motorista/Motorista";
import Proprietario from "../../pages/ModuloGerenciador/UsoComum/Proprietario/Proprietario";
import Cliente from "../../pages/ModuloGerenciador/Cliente/Cliente";
import Pedido from "../../pages/ModuloGerenciador/Pedido/Pedido";
import UnidadeEmbarque from "../../pages/ModuloGerenciador/UsoComum/UnidadeEmbarque/UnidadeEmbarque";
import Filial from "../../pages/ModuloGerenciador/UsoComum/Filial/Filial";
import EmpresaSubsidiaria from "../../pages/ModuloGerenciador/UsoComum/EmpresaSubsidiaria/EmpresaSubsidiaria";
import Embalagem from "../../pages/ModuloGerenciador/UsoComum/Embalagem/Embalagem";
import Mercadoria from "../../pages/ModuloGerenciador/UsoComum/Mercadoria/Mercadoria";
import Status from "../../pages/ModuloGerenciador/UsoComum/Status/Status";
import Motivo from "../../pages/ModuloGerenciador/UsoComum/Motivo/Motivo";
import MeioPagamento from "../../pages/ModuloGerenciador/UsoComum/MeioPagamento/MeioPagamento";
import Veiculo from "../../pages/ModuloGerenciador/Veiculo/Veiculo/Veiculo";
import Caminhao from "../../pages/ModuloGerenciador/Veiculo/Caminhao/Caminhao";
import Carreta from "../../pages/ModuloGerenciador/Veiculo/Carreta/Carreta";
import TipoCheckagem from "../../pages/ModuloChecklist/TipoCheckagem/TipoCheckagem";
import GrupoClientes from "../../pages/ModuloGerenciador/GrupoClientes/GrupoClientes";
import GrupoPrecificacao from "../../pages/GrupoPrecificacao/GrupoPrecificacao";
import ValorEmbarque from "../../pages/ValorEmbarque/ValorEmbarque";
import GrupoCarga from "../../pages/ModuloGerenciador/GrupoCarga/GrupoCarga";
import CloseIcon from "../CloseIcon/CloseIcon";
import MotivoOnTime from "../../pages/ModuloGerenciador/MotivoOnTime/MotivoOnTime";
import MotivoCotacao from "../../pages/ModuloGerenciador/UsoComum/MotivoCotacao/MotivoCotacao";
import SegmentoCliente from "../../pages/ModuloGerenciador/UsoComum/SegmentoCliente/SegmentoCliente";
import TipoOcorrencia from "../../pages/ModuloGerenciador/UsoComum/TipoOcorrencia/TipoOcorrencia";
import SubTipoOcorrencia from "../../pages/ModuloGerenciador/UsoComum/SubTipoOcorrencia/SubTipoOcorrencia";
import Ocorrencia from "../../pages/ModuloGerenciador/UsoComum/Ocorrencia/Ocorrencia";
import TipoCarroceria from "../../pages/ModuloGerenciador/UsoComum/TipoCarroceria/TipoCarroceria";
import TipoVeiculo from "../../pages/ModuloGerenciador/UsoComum/TipoVeiculo/TipoVeiculo";
import TipoRastreamento from "../../pages/ModuloRastreamento/TipoRastreamento/TipoRastreamento";
import StatusDiaria from "../../pages/ModuloGerenciador/UsoComum/StatusDiaria/StatusDiaria";
import MotivoEstadia from "../../pages/ModuloGerenciador/UsoComum/MotivoEstadia/MotivoEstadia";

export interface SimpleDialogProps {
    titulo?: string;
    tituloBotao?: string;
    tituloConfirmar?: string;
    texto?: string;
    cadastroRota?: string;
    cadastroMotorista?: string;
    cadastroProprietario?: string;
    cadastroCliente?: string;
    cadastroPedido?: string;
    cadastroUnidadeEmbarque?: string;
    cadastroFilial?: string;
    cadastroEmpresaSub?: string;
    cadastroVeiculo?: string;
    cadastroEmbalagem?: string;
    cadastroMercadoria?: string;
    cadastroStatus?: string;
    cadastroMotivo?: string;
    cadastroMeioPagamento?: string;
    cadastroCaminhao?: string;
    cadastroCarreta?: string;
    cadastroTipoCheck?: string;
    cadastroGrupoCliente?: string;
    cadastroGrupoPrecificacao?: string;
    cadastroValorEmbarque?: string;
    cadastroGrupoCarga?: string;
    cadastroMotivoOnTime?: string;
    cadastroMotivoCotacao?: string;
    cadastroSegmentoCliente?: string;
    cadastroOcorrencia?: string;
    cadastroTipoOcorrencia?: string;
    cadastroSubTipoOcorrencia?: string;
    cadastroCarroceria?: string;
    cadastroTipoVeiculo?: string;
    cadastroTipoRastreio?: string;
    cadastroMotivoEstadia?: string;
    cadastroStatusDiaria?: string;
    open: boolean;
    desabilitar?: boolean;
    idAtual?: number;
    onClose: () => void;
    onClick?: () => void;
}

function ModalCadastro(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        onClose();
    };


    return (
        <div>
            <Dialog
                open={open}
            >
                <div className='div-modal-usuario'>
                    <div className="titulo-modal-cadastro">
                        <DialogTitle id="alert-dialog-title">
                            {
                                !props.desabilitar ? (
                                    <h3>{props.titulo}</h3>
                                ) : (
                                    <></>
                                )
                            }
                        </DialogTitle>
                        <CloseIcon onClick={(e) => {
                            handleClose();
                        }} />
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                props.cadastroRota ? (
                                    <Rota navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroMotorista ? (
                                    <Motorista navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroProprietario ? (
                                    <Proprietario navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroCliente ? (
                                    <Cliente navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroPedido ? (
                                    <Pedido navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroUnidadeEmbarque ? (
                                    <UnidadeEmbarque navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroFilial ? (
                                    <Filial navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroEmpresaSub ? (
                                    <EmpresaSubsidiaria navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroVeiculo ? (
                                    <Veiculo navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroEmbalagem ? (
                                    <Embalagem navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroMercadoria ? (
                                    <Mercadoria navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroStatus ? (
                                    <Status navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroMotivo ? (
                                    <Motivo navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroMeioPagamento ? (
                                    <MeioPagamento navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroCaminhao ? (
                                    <Caminhao navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroCarreta ? (
                                    <Carreta navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroTipoCheck ? (
                                    <TipoCheckagem navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroGrupoCliente ? (
                                    <GrupoClientes navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroGrupoPrecificacao ? (
                                    <GrupoPrecificacao navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroValorEmbarque ? (
                                    <ValorEmbarque navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroGrupoCarga ? (
                                    <GrupoCarga navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroMotivoOnTime ? (
                                    <MotivoOnTime navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroMotivoCotacao ? (
                                    <MotivoCotacao navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroSegmentoCliente ? (
                                    <SegmentoCliente navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroTipoOcorrencia ? (
                                    <TipoOcorrencia navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroSubTipoOcorrencia ? (
                                    <SubTipoOcorrencia navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroOcorrencia ? (
                                    <Ocorrencia navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroCarroceria ? (
                                    <TipoCarroceria navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroTipoVeiculo ? (
                                    <TipoVeiculo navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroTipoRastreio ? (
                                    <TipoRastreamento navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroStatusDiaria ? (
                                    <StatusDiaria navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                            {
                                props.cadastroMotivoEstadia ? (
                                    <MotivoEstadia navbar='nao' botaoVoltar='nao' botaoCadastrar='nao' telaCadastro={true} idAtual={props.idAtual} desabilitar={props.desabilitar} />
                                ) : (
                                    <>
                                    </>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalCadastro;