import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./Documentacao.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getVeiculoDocumento, cadastraVeiculoDocumento, editaVeiculoDocumentoPorId, getVeiculoDocumentoPorId, deleteVeiculoDocumentoPorId, salvaDocsCarreta, cadastraCarretaDocumento } from "../../../services/ModuloGerenciador/VeiculoDocumento.js";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import { getVeiculo } from "../../../services/ModuloGerenciador/Veiculo.js";
import ModalVeiculo from "../../../components/ModalVeiculo/ModalVeiculo.tsx";
import { deleteDocs, deleteDocsCarreta, salvaDocs } from "../../../services/ModuloGerenciador/GoogleStorage.js";
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";
import { Height } from "@mui/icons-material";
import { height } from "@mui/system";

function Documentacao(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [openModalVeiculos, setOpenModalVeiculos] = useState(false);

    const handleCloseModalVeiculos = (value) => {
        setOpenModalVeiculos(false);
    };


    const navigate = useNavigate();

    const buscarDocumentacao = async () => {
        setLoading(true);
        await getVeiculoDocumento(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [veiculo, setVeiculo] = useState(null);
    const [carreta, setCarreta] = useState(null);
    const [carretaId, setCarretaId] = useState(null);
    const [carreta2, setCarreta2] = useState(null);
    const [carretaId2, setCarretaId2] = useState(null);

    const cadastrar = async () => {
        setLoading(true);
        await cadastraVeiculoDocumento(veiculo, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setVeiculo(result.data?.idVeiculoDocumento);
                setCarreta(result.data?.carretaDocumento?.id);
                setCarretaId(result.data?.veiculo?.carretaId);
                setCarreta2(result.data?.carretaDocumento2?.id);
                setCarretaId2(result.data?.veiculo?.carreta2Id);
                buscarDocumentacao();
                setDadosRequestControllerRedux("Fechar Modal Documentacao");
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setOpenModalVeiculos(false);
                setTelaCadastro(true);
                setValues(result.data);
                setTabelaRedux({ colunaSelecionada: result.data.idVeiculoDocumento });
                setPreviews({});
                setPreviewsCarreta({});
                setPreviewsCarreta2({});
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const editar = async () => {
        setLoading(true);
        await editaVeiculoDocumentoPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDocumentacao();
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const preencherData = (dados) => {
        // Faz uma cópia do objeto original para preservar os outros dados
        const valoresIniciais = { ...dados };

        // Itera sobre os documentos para transformar apenas as datas
        documentos.forEach((doc) => {
            const key = `dataValidade${doc.id}${doc.valor ? doc.valor : ''}`;
            if (dados[key]) {
                valoresIniciais[key] = new Date(dados[key]).toISOString().split("T")[0]; // Formata a data
            }
        });

        documentosCarreta.forEach((doc) => {
            const key = `dataValidade${doc.id}${doc.valor ? doc.valor : ''}`;
            const key2 = `dataValidade${doc.id}`;
            const dado = dados.carretaDocumento;
            if (dado !== null && dado[key2]) {
                valoresIniciais[key] = new Date(dado[key2]).toISOString().split("T")[0];
            }
        });

        documentosCarreta2.forEach((doc) => {
            const key = `dataValidade${doc.id}${doc.valor ? doc.valor : ''}`;
            const key2 = `dataValidade${doc.id}`;
            const dado = dados.carretaDocumento2;
            if (dado !== null && dado[key2]) {
                valoresIniciais[key] = new Date(dado[key2]).toISOString().split("T")[0];
            }
        });

        // Atualiza o estado com o objeto atualizado
        setValues(valoresIniciais);
    };

    const [openConfirmar, setOpenConfirmar] = useState(false);

    const handleCloseConfirmar = (value) => {
        setOpenConfirmar(false);
    };

    const [openConfirmar2, setOpenConfirmar2] = useState(false);

    const handleCloseConfirmar2 = (value) => {
        setOpenConfirmar2(false);
    };

    const buscarDocumentacaoPorId = async () => {
        setLoading(true);
        await getVeiculoDocumentoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setLoading(false);
                setValues(result.data);
                preencherData(result.data);
                setVeiculo(result.data.idVeiculoDocumento);
                setCarreta(result.data.carretaDocumento?.id);
                setCarretaId(result.data.veiculo.carretaId);
                setCarreta2(result.data.carretaDocumento2?.id);
                setCarretaId2(result.data.veiculo.carreta2Id);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarDocumentacaoPorIdParam = async (id) => {
        setLoadingDoc(id, true);
        await getVeiculoDocumentoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                setTimeout(() => {
                    setLoadingDoc(id, false);
                }, 100);
                setValues(result.data);
                preencherData(result.data);
                setVeiculo(result.data.idVeiculoDocumento);
                setCarreta(result.data.carretaDocumento?.id);
                setCarretaId(result.data.veiculo.carretaId);
                setCarreta2(result.data.carretaDocumento2?.id);
                setCarretaId2(result.data.veiculo.carreta2Id);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarDocumentacaoPorIdModal = async (id) => {
        setLoading(true);
        await getVeiculoDocumentoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarDocumentacaoPorId = async () => {
        setLoading(true);
        await deleteVeiculoDocumentoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDocumentacao();
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarDocumentacaoPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarDocumentacao();
        buscarVeiculos();
        if (props.idAtual) {
            buscarDocumentacaoPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(false);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Documentação') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Documentação') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const buscarVeiculos = async () => {
        await getVeiculo(dadosUsuarioRedux.token).then((result) => {
            setListaVeiculos(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    };

    const [listaVeiculos, setListaVeiculos] = useState([]);

    const [valuesDoc, setValuesDoc] = useState({
        antt: null,
        crlv: null,
        cnh: null,
        comprovanteResidencia: null,
        fotoVeiculo: null,
    });

    const [previews, setPreviews] = useState({
        antt: null,
        crlv: null,
        cnh: null,
        comprovanteResidencia: null,
        fotoVeiculo: null,
    });

    const [previewsCarreta, setPreviewsCarreta] = useState({
        antt: null,
        crlv: null,
        fotoVeiculo: null,
    });

    const [previewsCarreta2, setPreviewsCarreta2] = useState({
        antt: null,
        crlv: null,
        fotoVeiculo: null,
    });

    const handleFileChange = (e, tipoDocumento) => {
        const file = e.target.files[0];
        if (file) {
            setValues((current) => ({
                ...current,
                [tipoDocumento]: file,
            }));
            setPreviews((current) => ({
                ...current,
                [tipoDocumento]: URL.createObjectURL(file),
            }));
        }
    };

    const handleFileChangeCarreta = (e, tipoDocumento) => {
        const file = e.target.files[0];
        if (file) {
            setValues((current) => ({
                ...current,
                [`${tipoDocumento.id}_${tipoDocumento.valor}`]: file,
            }));
            setPreviewsCarreta((current) => ({
                ...current,
                [`${tipoDocumento.id}_${tipoDocumento.valor}`]: URL.createObjectURL(file),
            }));
        }
    };

    const handleFileChangeCarreta2 = (e, tipoDocumento) => {
        const file = e.target.files[0];
        if (file) {
            setValues((current) => ({
                ...current,
                [`${tipoDocumento.id}_${tipoDocumento.valor}`]: file,
            }));
            setPreviewsCarreta2((current) => ({
                ...current,
                [`${tipoDocumento.id}_${tipoDocumento.valor}`]: URL.createObjectURL(file),
            }));
        }
    };

    const baixar = async (url) => {
        // Verifica se a URL é válida
        // <button onClick={() => baixar(previews[doc.id] || values[doc.preview]?.documentoUrl)}>Baixar</button>
        if (!url) {
            console.error("URL inválida");
            return;
        }

        // Cria um elemento <a> para forçar o download
        const link = document.createElement("a");
        link.href = url;
        link.download = url.split('/').pop() || "arquivo"; // Nome do arquivo, baseado na URL
        link.target = "_blank"; // Garante que o download ocorra em nova aba, se necessário

        // Adiciona ao DOM e clica no elemento para iniciar o download
        document.body.appendChild(link);
        link.click();

        // Remove o elemento do DOM após o clique
        document.body.removeChild(link);
    };

    const handleSave = async (tipoDocumento) => {
        const dataValidade = values[`dataValidade${tipoDocumento.id}${tipoDocumento.valor ? tipoDocumento.valor : ''}`];
        const documento = values[`${tipoDocumento.id}`];
        if (!dataValidade && documento === undefined) {
            setTextoErro("Por favor, insira uma data de validade e uma arquivo válido.");
            setOpenToastErro(true);
            return;
        }

        if (documento === undefined && values[`${tipoDocumento.preview}`] === null) {
            setTextoErro("Por favor, insira um arquivo válido.");
            setOpenToastErro(true);
            return;
        }

        if (!dataValidade) {
            setTextoErro("Por favor, insira uma data de validade válida.");
            setOpenToastErro(true);
            return;
        }

        let formData;
        if (documento !== undefined) {
            formData = new FormData();
            formData.append("file", documento);
        }
        await salvaDocs(tipoDocumento.id, formData, veiculo, dataValidade, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDocumentacaoPorIdParam(tipoDocumento.loading);
                setDadosRequestControllerRedux("Fechar Modal Documentacao");
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setOpenModalVeiculos(false);
                // setValues({});
                // setPreviews({});
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const handleSaveCarreta = async (tipoDocumento) => {
        if (carreta !== null && carreta !== undefined) {
            salvarDocCarreta(tipoDocumento, carreta);
        } else {
            const objeto = {
                usuario: values.usuario,
                carretaId: carretaId
            }
            await cadastraCarretaDocumento(objeto, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    setCarreta(result.data.id);
                    salvarDocCarreta(tipoDocumento, result.data.id);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    };

    const handleSaveCarreta2 = async (tipoDocumento) => {
        if (carreta2 !== null && carreta2 !== undefined) {
            salvarDocCarreta(tipoDocumento, carreta2);
        } else {
            const objeto = {
                usuario: values.usuario,
                carretaId: carretaId2
            }
            await cadastraCarretaDocumento(objeto, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    setCarreta2(result.data.id);
                    salvarDocCarreta(tipoDocumento, result.data.id);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    };

    const salvarDocCarreta = async (tipoDocumento, docId) => {
        const formData = new FormData();
        formData.append("file", values[`${tipoDocumento.id}_${tipoDocumento.valor}`]);
        const dataValidade = values[`dataValidade${tipoDocumento.id}${tipoDocumento.valor}`];
        const documento = values[`${tipoDocumento.id}_${tipoDocumento.valor}`];
        if (!dataValidade || documento === undefined) {
            setTextoErro("Por favor, insira uma data de validade e uma arquivo válido.");
            setOpenToastErro(true);
            return;
        }
        await salvaDocsCarreta(tipoDocumento.id, formData, docId, dataValidade, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDocumentacaoPorIdParam(tipoDocumento.loading);
                setDadosRequestControllerRedux("Fechar Modal Documentacao");
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setOpenModalVeiculos(false);
                // setValues({});
                // setPreviews({});
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const [tipoDocAtual, setTipoDocAtual] = useState(null);
    const [docValor, setDocValor] = useState(null);
    const [docIdAtual, setDocIdAtual] = useState(null);
    const [veiculoDocIdAtual, setVeiculoDocIdAtual] = useState(null);
    const [veiculoDocCarretaIdAtual, setVeiculoDocCarretaIdAtual] = useState(null);

    const deletarAnexo = async () => {
        await deleteDocs(tipoDocAtual, docIdAtual, veiculoDocIdAtual, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDocumentacaoPorIdParam(docValor);
                setDadosRequestControllerRedux("Fechar Modal Documentacao");
                setTextoSucesso("Deletado Com Sucesso");
                setOpenToastSucesso(true);
                setOpenModalVeiculos(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const deletarAnexoCarreta = async () => {
        await deleteDocsCarreta(tipoDocAtual, docIdAtual, veiculoDocCarretaIdAtual, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarDocumentacaoPorId();
                setDadosRequestControllerRedux("Fechar Modal Documentacao");
                setTextoSucesso("Deletado Com Sucesso");
                setOpenToastSucesso(true);
                setOpenModalVeiculos(false);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const [loadingDocs, setLoadingDocs] = useState({});

    const setLoadingDoc = (id, isLoading) => {
        setLoadingDocs((prevState) => ({
            ...prevState,
            [id]: isLoading,
        }));
    };

    const documentos = [
        { id: "Antt", label: "ANTT", preview: 'antt', loading: "antt" },
        { id: "Crlv", label: "CRLV", preview: 'crlv', loading: "crlv" },
        { id: "CnhMotorista", label: "CNH", preview: 'cnhMotorista', loading: "cnhMotorista" },
        { id: "ComprovanteResidencia", label: "Comprovante Residência", preview: 'comprovanteResidencia', loading: "comprovanteResidencia" },
        { id: "FotoVeiculo", label: "Foto do Veículo", preview: 'fotoVeiculo', loading: "fotoVeiculo" },
    ];

    const documentosCarreta = [
        { id: "Antt", label: "ANTT", preview: 'antt', valor: "anttCarreta", loading: 'anttCarreta' },
        { id: "Crlv", label: "CRLV", preview: 'crlv', valor: "crlvCarreta", loading: 'crlvCarreta' },
        { id: "FotoVeiculo", label: "Foto do Veículo", preview: 'fotoVeiculo', valor: "fotoVeiculoCarreta", loading: 'fotoVeiculo' },
    ];

    const documentosCarreta2 = [
        { id: "Antt", label: "ANTT", preview: 'antt', valor: "anttCarreta2", loading: 'anttCarreta2' },
        { id: "Crlv", label: "CRLV", preview: 'crlv', valor: "crlvCarreta2", loading: 'crlvCarreta2' },
        { id: "FotoVeiculo", label: "Foto do Veículo", preview: 'fotoVeiculo', valor: "fotoVeiculoCarreta2", loading: 'fotoVeiculo' },
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Documentacao" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Documentacao" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Documentação</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idVeiculoDocumento"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.Documentacao}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => {
                                                            setOpenModalVeiculos(true);
                                                        }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Documentação / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Documentação"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Documentação"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex" style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                                {
                                                    !props.botaoVoltar ? (
                                                        <>
                                                            <div className="flex-emb">
                                                                {
                                                                    editando ? (
                                                                        <HeaderCadastro texto="Detalhes de Documentação" />
                                                                    ) : (
                                                                        <HeaderCadastro texto="Cadastro de Documentação" />
                                                                    )
                                                                }
                                                                <CloseIcon onClick={() => {
                                                                    setValues({});
                                                                    setVeiculo({});
                                                                    setPreviews({});
                                                                    setCarreta({});
                                                                    setCarretaId({});
                                                                    setPreviewsCarreta({});
                                                                    setCarreta2({});
                                                                    setCarretaId2({});
                                                                    setPreviewsCarreta2({});
                                                                    setTelaCadastro(false);
                                                                    setEditando(false);
                                                                    setTabelaRedux({});
                                                                }} />
                                                            </div>
                                                            <div>
                                                                <p className="indicador-emb">Placa Veículo: <b>{values?.veiculo?.caminhao?.placa}</b></p>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="documentos-container">
                                                {documentos.map((doc) => (
                                                    <div key={doc.id} className="card-documento">
                                                        <p className="documento-label">{doc.label}</p>
                                                        {loadingDocs[doc.loading] ? (
                                                            <div className="loading-indicator">
                                                                <LoadingComponent />
                                                            </div>
                                                        ) : (
                                                            <div className="preview-container">
                                                                {previews[doc.id] || values[doc.preview]?.documentoUrl ? (
                                                                    <div className="preview-container">
                                                                        {values[doc.id]?.type?.includes("image") ||
                                                                            values[doc.preview]?.documentoUrl?.match(/\.(jpeg|jpg|png|gif|webp)$/i) ? (
                                                                            <img
                                                                                src={previews[doc.id] || values[doc.preview]?.documentoUrl}
                                                                                alt={`${doc.label} Preview`}
                                                                                className="preview-image"
                                                                                onClick={() =>
                                                                                    window.open(previews[doc.id] || values[doc.preview]?.documentoUrl, "_blank")
                                                                                }
                                                                            />
                                                                        ) : values[doc.preview]?.documentoUrl?.match(/\.pdf$/i) ||
                                                                            values[doc.id]?.type?.includes("pdf") ? (
                                                                            <iframe
                                                                                src={previews[doc.id] || values[doc.preview]?.documentoUrl}
                                                                                className="preview-pdf"
                                                                                title={`${doc.label} Preview`}
                                                                                onClick={() =>
                                                                                    window.open(previews[doc.id] || values[doc.preview]?.documentoUrl, "_blank")
                                                                                }
                                                                            ></iframe>
                                                                        ) : (
                                                                            <div className="preview-generic">
                                                                                <p>Arquivo não visualizável diretamente.</p>
                                                                            </div>
                                                                        )}
                                                                        <div style={{ gap: 10, display: "flex" }}>
                                                                            <button
                                                                                onClick={() =>
                                                                                    window.open(previews[doc.id] || values[doc.preview]?.documentoUrl, "_blank")
                                                                                }
                                                                            >
                                                                                Visualizar
                                                                            </button>
                                                                            {values[doc.preview] !== null && (
                                                                                <button
                                                                                    style={{ backgroundColor: '#ff0000' }}
                                                                                    onClick={() => {
                                                                                        if (values[doc.preview] !== null) {
                                                                                            setOpenConfirmar(true);
                                                                                            setTipoDocAtual(doc.id);
                                                                                            setDocValor(doc.loading);
                                                                                            setDocIdAtual(values[doc.preview].idGoogleStorage);
                                                                                            setVeiculoDocIdAtual(values.idVeiculoDocumento);
                                                                                        } else {
                                                                                            setOpenToastErro(true);
                                                                                            setTextoErro("Não é possivel deletar este dococumento");
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    Deletar
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                                <div className="upload-container">
                                                                    <input
                                                                        type="file"
                                                                        className="input-file"
                                                                        onChange={(e) => handleFileChange(e, doc.id)}
                                                                    />
                                                                    <input
                                                                        type="date"
                                                                        className="input-date"
                                                                        value={values[`dataValidade${doc.id}${doc.valor ? doc.valor : ''}`] || ""}
                                                                        onChange={(e) => {
                                                                            setValues((current) => ({
                                                                                ...current,
                                                                                [`dataValidade${doc.id}${doc.valor ? doc.valor : ''}`]: e.target.value,
                                                                            }));
                                                                        }}
                                                                        placeholder="Data de Validade"
                                                                    />
                                                                    <button onClick={() => handleSave(doc)}>Cadastrar</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            {values?.veiculo?.carreta && (
                                                <p className="indicador-emb" style={{ textAlign: 'center' }}>Placa Carreta: <b>{values?.veiculo?.carreta?.placa}</b></p>
                                            )}
                                            {values?.veiculo?.carreta && (
                                                <div className="documentos-container">
                                                    {documentosCarreta.map((doc) => (
                                                        <div key={doc.id} className="card-documento">
                                                            <p className="documento-label">{doc.label}</p>
                                                            {loadingDocs[doc.loading] ? (
                                                                <div className="loading-indicator">
                                                                    <LoadingComponent />
                                                                </div>
                                                            ) : (
                                                                <div className="preview-container">
                                                                    {previewsCarreta[`${doc.id}_${doc.valor}`] || values?.carretaDocumento?.[doc.preview]?.documentoUrl ? (
                                                                        <div className="preview-container">
                                                                            {values[`${doc.id}_${doc.valor}`]?.type?.includes("image") ||
                                                                                values?.carretaDocumento?.[doc.preview]?.documentoUrl?.match(/\.(jpeg|jpg|png|gif|webp)$/i) ? (
                                                                                <img
                                                                                    src={previewsCarreta[`${doc.id}_${doc.valor}`] || values?.carretaDocumento?.[doc.preview]?.documentoUrl}
                                                                                    alt={`${doc.label} Preview`}
                                                                                    className="preview-image"
                                                                                    onClick={() =>
                                                                                        window.open(previewsCarreta[`${doc.id}_${doc.valor}`] || values?.carretaDocumento?.[doc.preview]?.documentoUrl, "_blank")
                                                                                    }
                                                                                />
                                                                            ) : values?.carretaDocumento?.[doc.preview]?.documentoUrl?.match(/\.pdf$/i) ||
                                                                                values[`${doc.id}_${doc.valor}`]?.type?.includes("pdf") ? (
                                                                                <iframe
                                                                                    src={previewsCarreta[`${doc.id}_${doc.valor}`] || values?.carretaDocumento?.[doc.preview]?.documentoUrl}
                                                                                    className="preview-pdf"
                                                                                    title={`${doc.label} Preview`}
                                                                                    onClick={() =>
                                                                                        window.open(previewsCarreta[`${doc.id}_${doc.valor}`] || values?.carretaDocumento?.[doc.preview]?.documentoUrl, "_blank")
                                                                                    }
                                                                                ></iframe>
                                                                            ) : (
                                                                                <div className="preview-generic">
                                                                                    <p>Arquivo não visualizável diretamente.</p>
                                                                                </div>
                                                                            )}
                                                                            <div style={{ gap: 10, display: "flex" }}>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        window.open(previewsCarreta[`${doc.id}_${doc.valor}`] || values?.carretaDocumento?.[doc.preview]?.documentoUrl, "_blank")
                                                                                    }
                                                                                >
                                                                                    Visualizar
                                                                                </button>
                                                                                {values.carretaDocumento !== null && values.carretaDocumento[doc.preview] !== null && (
                                                                                    <button
                                                                                        style={{ backgroundColor: '#ff0000' }}
                                                                                        onClick={() => {
                                                                                            if (values.carretaDocumento[doc.preview] !== null) {
                                                                                                setOpenConfirmar2(true);
                                                                                                setTipoDocAtual(doc.id);
                                                                                                setDocValor(doc.loading);
                                                                                                setDocIdAtual(values.carretaDocumento[doc.preview].idGoogleStorage);
                                                                                                setVeiculoDocCarretaIdAtual(values.carretaDocumento.id);
                                                                                            } else {
                                                                                                setOpenToastErro(true);
                                                                                                setTextoErro("Não é possivel deletar este dococumento");
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Deletar
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    <div className="upload-container">
                                                                        <input
                                                                            type="file"
                                                                            className="input-file"
                                                                            onChange={(e) => handleFileChangeCarreta(e, doc)}
                                                                        />
                                                                        <input
                                                                            type="date"
                                                                            className="input-date"
                                                                            value={values[`dataValidade${doc.id}${doc.valor}`] || ""}
                                                                            onChange={(e) => {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    [`dataValidade${doc.id}${doc.valor}`]: e.target.value,
                                                                                }));
                                                                            }}
                                                                            placeholder="Data de Validade"
                                                                        />
                                                                        <button onClick={() => handleSaveCarreta(doc)}>Cadastrar</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {values?.veiculo?.carreta2 && (
                                                < p className="indicador-emb" style={{ textAlign: 'center' }}>Placa Carreta 2: <b>{values?.veiculo?.carreta2?.placa}</b></p>
                                            )}
                                            {values?.veiculo?.carreta2 && (
                                                <div className="documentos-container">
                                                    {documentosCarreta2.map((doc) => (
                                                        <div key={doc.id} className="card-documento">
                                                            <p className="documento-label">{doc.label}</p>
                                                            {loadingDocs[doc.loading] ? (
                                                                <div className="loading-indicator">
                                                                    <LoadingComponent />
                                                                </div>
                                                            ) : (
                                                                <div className="preview-container">
                                                                    {previewsCarreta2[`${doc.id}_${doc.valor}`] || values?.carretaDocumento2?.[doc.preview]?.documentoUrl ? (
                                                                        <div className="preview-container">
                                                                            {values[`${doc.id}_${doc.valor}`]?.type?.includes("image") ||
                                                                                values?.carretaDocumento2?.[doc.preview]?.documentoUrl?.match(/\.(jpeg|jpg|png|gif|webp)$/i) ? (
                                                                                <img
                                                                                    src={previewsCarreta2[`${doc.id}_${doc.valor}`] || values?.carretaDocumento2?.[doc.preview]?.documentoUrl}
                                                                                    alt={`${doc.label} Preview`}
                                                                                    className="preview-image"
                                                                                    onClick={() =>
                                                                                        window.open(previewsCarreta2[`${doc.id}_${doc.valor}`] || values?.carretaDocumento2?.[doc.preview]?.documentoUrl, "_blank")
                                                                                    }
                                                                                />
                                                                            ) : values?.carretaDocumento2?.[doc.preview]?.documentoUrl?.match(/\.pdf$/i) ||
                                                                                values[`${doc.id}_${doc.valor}`]?.type?.includes("pdf") ? (
                                                                                <iframe
                                                                                    src={previewsCarreta2[`${doc.id}_${doc.valor}`] || values?.carretaDocumento2?.[doc.preview]?.documentoUrl}
                                                                                    className="preview-pdf"
                                                                                    title={`${doc.label} Preview`}
                                                                                    onClick={() =>
                                                                                        window.open(previewsCarreta2[`${doc.id}_${doc.valor}`] || values?.carretaDocumento2?.[doc.preview]?.documentoUrl, "_blank")
                                                                                    }
                                                                                ></iframe>
                                                                            ) : (
                                                                                <div className="preview-generic">
                                                                                    <p>Arquivo não visualizável diretamente.</p>
                                                                                </div>
                                                                            )}
                                                                            <div style={{ gap: 10, display: "flex" }}>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        window.open(previewsCarreta2[`${doc.id}_${doc.valor}`] || values?.carretaDocumento2?.[doc.preview]?.documentoUrl, "_blank")
                                                                                    }
                                                                                >
                                                                                    Visualizar
                                                                                </button>

                                                                                {values.carretaDocumento2 !== null && values.carretaDocumento2[doc.preview] !== null && (
                                                                                    <button
                                                                                        style={{ backgroundColor: '#ff0000' }}
                                                                                        onClick={() => {
                                                                                            if (values.carretaDocumento2[doc.preview] !== null) {
                                                                                                setOpenConfirmar2(true);
                                                                                                setTipoDocAtual(doc.id);
                                                                                                setDocValor(doc.loading);
                                                                                                setDocIdAtual(values.carretaDocumento2[doc.preview].idGoogleStorage);
                                                                                                setVeiculoDocCarretaIdAtual(values.carretaDocumento2.id);
                                                                                            } else {
                                                                                                setOpenToastErro(true);
                                                                                                setTextoErro("Não é possivel deletar este dococumento");
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Deletar
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                    <div className="upload-container">
                                                                        <input
                                                                            type="file"
                                                                            className="input-file"
                                                                            onChange={(e) => handleFileChangeCarreta2(e, doc)}
                                                                        />
                                                                        <input
                                                                            type="date"
                                                                            className="input-date"
                                                                            value={values[`dataValidade${doc.id}${doc.valor}`] || ""}
                                                                            onChange={(e) => {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    [`dataValidade${doc.id}${doc.valor}`]: e.target.value,
                                                                                }));
                                                                            }}
                                                                            placeholder="Data de Validade"
                                                                        />
                                                                        <button onClick={() => handleSaveCarreta2(doc)}>Cadastrar</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {/* 
                                            <div className="botoes-documentacao">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Cadastrar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "") && (validator.nome === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Cadastrar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "") && (validator.nome === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setTelaCadastro(false);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div> */}
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarDocumentacaoPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalVeiculo
                                        titulo="Selecione um Veículo"
                                        tituloConfirmar="OK"
                                        open={openModalVeiculos}
                                        onClick={cadastrar}
                                        onClose={handleCloseModalVeiculos}
                                        setVeiculo={setVeiculo}
                                        listaVeiculos={listaVeiculos}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deletar"
                                        texto="Deseja deletar este documento?"
                                        textoBotao="Sim"
                                        open={openConfirmar}
                                        onClose={handleCloseConfirmar}
                                        onClick={deletarAnexo}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deletar"
                                        texto="Deseja deletar este documento?"
                                        textoBotao="Sim"
                                        open={openConfirmar2}
                                        onClose={handleCloseConfirmar2}
                                        onClick={deletarAnexoCarreta}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div >
    );
}

export default Documentacao;