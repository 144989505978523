import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import "./Rota.css";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import { cadastraRota, deleteRotaPorId, editaRotaPorId, getRota, getRotaPorId } from "../../../services/ModuloGerenciador/Rota";
import { RotaModel } from "../../../models/RotaModel"
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras, getRotaDependencias } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { FormControl } from "@mui/material";
import ReactSelect from 'react-select';
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import ClearIcon from '@mui/icons-material/Clear';

function Rota(props) {
    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState(new RotaModel({}));

    const [valuesAbreviacao, setValuesAbreviacao] = useState({});

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [listaCidades, setListaCidades] = useState([]);

    const [listaCidadesCompleta, setListaCidadesCompleta] = useState([]);

    const [valuesFiltro, setValuesFiltro] = useState({});

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const buscarDependencias = async () => {
        setLoading(true);
        await getRotaDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaCidades(result.data.cidadeEstados);
            setListaCidadesCompleta(result.data.cidadeEstados);
            setDadosTabela(result.data.rotas);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarRota = async () => {
        setLoading(true);
        await getRota(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setLoading(false);
        })
    }

    const cadastrar = async () => {
        if (values.cidadeDestinoId !== null && values.cidadeOrigemId !== null) {
            setLoading(true);
            await cadastraRota(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarRota();
                    setDadosRequestControllerRedux("Fechar Modal Rota");
                    setTabelaRedux({});
                    setValuesAbreviacao({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setValues({});
                    setValuesSelect({});
                    setValuesAbreviacao({});
                    setTelaCadastro(false);
                }
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setOpenToastErro(true);
            setTextoErro("Há campos sem preencher");
        }
    }

    const editar = async () => {
        if (values.cidadeDestinoId !== null && values.cidadeOrigemId !== null) {
            setLoading(true);
            await editaRotaPorId(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarRota();
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setTelaCadastro(false);
                    setEditando(false);
                    setValues({});
                    setValuesSelect({});
                    setValuesAbreviacao({});
                }
            }).catch((error) => {
                if (error.response.status != 200) {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setOpenToastErro(true);
            setTextoErro("Há campos sem preencher");
        }
    }

    const buscarRotaPorId = async () => {
        setLoading(true);
        await getRotaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    const nome = result.data.nome;
                    const nomeSplit1 = nome.split('X')[0];
                    const nomeSplit2 = nome.split('X')[1];
                    const { cidadeOrigem, estadoOrigem, cidadeDestino, estadoDestino } = result.data;
                    const setSelectValue = (cidade, estado, field) => {
                        const selectedOption = opcoesCidadeEstado.find(option => option.cidade === cidade && option.estado === estado);
                        if (selectedOption) {
                            setValuesSelect(current => ({ ...current, [field]: selectedOption }));
                        }
                    };
                    setSelectValue(cidadeOrigem, estadoOrigem, 'cidadeOrigem');
                    setSelectValue(cidadeDestino, estadoDestino, 'cidadeDestino');
                    setValues(result.data);
                    setValuesAbreviacao(current => ({ ...current, abreviacao1: nomeSplit1 }));
                    setValuesAbreviacao(current => ({ ...current, abreviacao2: nomeSplit2 }));
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarRotaPorIdModal = async (id) => {
        setLoading(true);
        await getRotaPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                const nome = result.data.nome;
                const nomeSplit1 = nome.split('X')[0];
                const nomeSplit2 = nome.split('X')[1];
                const { cidadeOrigem, estadoOrigem, cidadeDestino, estadoDestino } = result.data;
                const setSelectValue = (cidade, estado, field) => {
                    const selectedOption = opcoesCidadeEstado.find(option => option.cidade === cidade && option.estado === estado);
                    if (selectedOption) {
                        setValuesSelect(current => ({ ...current, [field]: selectedOption }));
                    }
                };
                setSelectValue(cidadeOrigem, estadoOrigem, 'cidadeOrigem');
                setSelectValue(cidadeDestino, estadoDestino, 'cidadeDestino');
                setValues(result.data);
                setValuesAbreviacao(current => ({ ...current, abreviacao1: nomeSplit1 }));
                setValuesAbreviacao(current => ({ ...current, abreviacao2: nomeSplit2 }));
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarRotaPorId = async () => {
        setLoading(true);
        await deleteRotaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarRota();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarRotaPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarDependencias();
    }, []);

    useEffect(() => {
        if (props.idAtual) {
            buscarRotaPorIdModal(props.idAtual);
        }
    }, [listaCidades]);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Rota') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Rota') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const labelCidadeEstadoOrigem = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.cidade} - {data.estado}
            {values.cidadeOrigem === data.cidade &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearCidadeEstadoOrigem();
                    }} />
            }
        </div>
    );

    const labelCidadeEstadoDestino = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.cidade} - {data.estado}
            {values.cidadeDestino === data.cidade &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearCidadeEstadoDestino();
                    }} />
            }
        </div>
    );

    const clearCidadeEstadoOrigem = () => {
        if (valuesAbreviacao.abreviacao1 && valuesAbreviacao.abreviacao2) {
            setValues(current => ({ ...current, nome: '' }));
        }
        setValues((current) => ({
            ...current,
            cidadeOrigemId: null,
            cidadeOrigem: '',
            estadoOrigem: '',
            nome: '',
        }));
        setValuesAbreviacao(current => ({
            ...current, abreviacao1: ''
        }));
        setValuesSelect(current => ({ ...current, cidadeOrigem: '' }));
    };

    const clearCidadeEstadoDestino = () => {
        if (valuesAbreviacao.abreviacao1 && valuesAbreviacao.abreviacao2) {
            setValues(current => ({ ...current, nome: '' }));
        }
        setValues((current) => ({
            ...current,
            cidadeDestinoId: null,
            cidadeDestino: '',
            estadoDestino: '',
            nome: '',
        }));
        setValuesAbreviacao(current => ({
            ...current, abreviacao2: ''
        }));
        setValuesSelect(current => ({ ...current, cidadeDestino: '' }));
    };


    const estiloCidadeEstado = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const opcoesCidadeEstado = listaCidades.map((data) => ({
        value: data.idCidadeEstado,
        label: `${data.cidade} - ${data.estado}`,
        ...data
    }));

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Rotas" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Rotas" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Rotas</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idRota"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Rota}
                                                nome="rota" dados={dadosTabela} />
                                            <div className="container-botoes-rota">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Rota / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Rota"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Rota"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Rota" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Rota" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setValues({});
                                                                setValuesSelect({});
                                                                setValuesAbreviacao({});
                                                                setTelaCadastro(false);
                                                                setEditando(false);
                                                                setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Cidade/Estado Origem:</p>
                                                                <FormControl sx={{ width: 250 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.cidadeOrigem}
                                                                        isDisabled={props.desabilitar}
                                                                        options={opcoesCidadeEstado}
                                                                        formatOptionLabel={labelCidadeEstadoOrigem}
                                                                        styles={estiloCidadeEstado}
                                                                        placeholder=""
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (valuesAbreviacao.abreviacao1 && valuesAbreviacao.abreviacao2) {
                                                                                setValues(current => ({ ...current, nome: `${valuesAbreviacao.abreviacao1} X ${valuesAbreviacao.abreviacao2}` }));
                                                                            }
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    cidadeOrigemId: selectedOption.idCidadeEstado,
                                                                                    cidadeOrigem: selectedOption.cidade,
                                                                                    estadoOrigem: selectedOption.estado,
                                                                                    nome: '',
                                                                                }));
                                                                                setValuesAbreviacao(current => ({
                                                                                    ...current, abreviacao1: `${selectedOption.abreviacao} - ${selectedOption.estado}`
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, cidadeOrigem: selectedOption }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Cidade/Estado Destino:</p>
                                                                <FormControl sx={{ width: 250 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.cidadeDestino}
                                                                        isDisabled={props.desabilitar}
                                                                        options={opcoesCidadeEstado}
                                                                        formatOptionLabel={labelCidadeEstadoDestino}
                                                                        styles={estiloCidadeEstado}
                                                                        placeholder=""
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                if (valuesAbreviacao.abreviacao1 && valuesAbreviacao.abreviacao2) {
                                                                                    setValues(current => ({ ...current, nome: `${valuesAbreviacao.abreviacao1} X ${valuesAbreviacao.abreviacao2}` }));
                                                                                }
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    cidadeDestinoId: selectedOption.idCidadeEstado,
                                                                                    cidadeDestino: selectedOption.cidade,
                                                                                    estadoDestino: selectedOption.estado,
                                                                                    nome: '',
                                                                                }));
                                                                                setValuesAbreviacao(current => ({
                                                                                    ...current, abreviacao2: `${selectedOption.abreviacao} - ${selectedOption.estado}`
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, cidadeDestino: selectedOption }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="principal-rota">
                                                {/* <InputLabel style={{ marginTop: '40px' }} htmlFor="status"><Text texto="Filtrar" /></InputLabel> */}
                                                {/* <div className="div-filtrar-cidades">
                                                        <input
                                                            placeholder="Cidade"
                                                            type="text"
                                                            className="input-text-filtrar"
                                                            onChange={(e) => {
                                                                setValuesFiltro(current => ({ ...current, filtroOrigem: e.target.value }));
                                                            }}
                                                            value={valuesFiltro.filtroOrigem}
                                                            disabled={props.desabilitar} />
                                                        <input
                                                            placeholder="UF"
                                                            maxLength={2}
                                                            type="text"
                                                            className="input-text-filtrar-estado"
                                                            onChange={(e) => {
                                                                setValuesFiltro(current => ({ ...current, filtroOrigemEstado: e.target.value }));
                                                            }}
                                                            value={valuesFiltro.filtroOrigemEstado}
                                                            disabled={props.desabilitar} />
                                                        <FilterAltIcon className="icon-filtro"
                                                            disabled={props.desabilitar} onClick={(e) => {
                                                                if (valuesFiltro.filtroOrigem && valuesFiltro.filtroOrigemEstado) {
                                                                    setListaCidades(listaCidades.filter((data) => { return data.cidade.toLowerCase().includes(valuesFiltro.filtroOrigem.toLowerCase()) && data.estado.toLowerCase().includes(valuesFiltro.filtroOrigemEstado.toLowerCase()) }));
                                                                } else if (valuesFiltro.filtroOrigem && !valuesFiltro.filtroOrigemEstado) {
                                                                    setListaCidades(listaCidades.filter((data) => { return data.cidade.toLowerCase().includes(valuesFiltro.filtroOrigem.toLowerCase()) }));
                                                                } else if (valuesFiltro.filtroOrigemEstado && !valuesFiltro.filtroOrigem) {
                                                                    setListaCidades(listaCidades.filter((data) => { return data.estado.toLowerCase().includes(valuesFiltro.filtroOrigemEstado.toLowerCase()) }));
                                                                } else {
                                                                    setListaCidades(listaCidadesCompleta);
                                                                }
                                                            }} />
                                                        <ClearIcon className="icon-filtro"
                                                            disabled={props.desabilitar}
                                                            onClick={(e) => {
                                                                setValuesFiltro(current => ({ ...current, filtroOrigem: '' }));
                                                                setValuesFiltro(current => ({ ...current, filtroOrigemEstado: '' }));
                                                                setListaCidades(listaCidadesCompleta);
                                                            }}
                                                        />
                                                    </div> */}
                                            </div>
                                            <div className="botoes-rota">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => {
                                                                editar();
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                cadastrar();
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                cadastrar();
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setValuesAbreviacao({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarRotaPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Rota;