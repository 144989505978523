import React, { useContext, useState, useEffect, useRef } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3.js";
import "./LocalizadorVeiculos.css";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent.js";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao.js";
import { motion } from "framer-motion";
import RastrearRedux from "../../../contexts/Rastreio.js";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario.js";
import DadosUsuarioRedux from "../../../contexts/Usuario.js";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useLoadScript } from "@react-google-maps/api";
import caminhaoPin from '../../../images/caminhao_pin.png';
import caminhaoPinCarregando from '../../../images/cam-greem-removebg-preview.png';
import { useNavigate } from 'react-router-dom';
import CustomButton from "../../../components/CustomButton/CustomButton.js";
import CoresRedux from "../../../contexts/Cor.js";
import { getLocalizadorVeiculos } from "../../../services/ModuloGerenciador/Search.js";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2.js";
import { getRegras } from "../../../services/ModuloGerenciador/Geral.js";
import ClearIcon from '@mui/icons-material/Clear';
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import DadosContextoEmbarqueRedux from "../../../contexts/ContextoEmbarque.js";
import TabelaRedux from "../../../contexts/Tabela.js";

function LocalizadorVeiculos(props) {

    const navigate = useNavigate();

    const voltar = () => {
        navigate(-1);
    };

    const itensFiltro = [
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2',
            'filtro': 'true'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
           'field': 'veiculoMotorista',
        },
        {
            'nomeColuna': 'Motorista2',
            'field': 'motorista2'
        },
    ];

    const { dadosRastrearRedux, setDadosRastrearRedux } = useContext(RastrearRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const [loading, setLoading] = useState(true);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [openToastErro, setOpenToastErro] = useState(false);

    const [dadosRastreio, setDadosRastreio] = useState([]);

    const [dadosEventos, setDadosEventos] = useState([]);

    const [lat, setLat] = useState(null);

    const [lng, setLng] = useState(null);

    const [marker, setMarker] = useState(false);

    // Estados adicionais para armazenar o veículo selecionado
    const [veiculoSelecionado, setVeiculoSelecionado] = useState(null);

    const [localizadoresVeiculosDescarregando, setLocalizadoresVeiculosDescarregando] = useState([]);

    const [localizadoresVeiculosCarregando, setLocalizadoresVeiculosCarregando] = useState([]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCN4pBitoJiDhjeEFW3AnGncUhiV1JE-Z4",
    });

    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const [polygon, setPolygon] = useState({ paths: [] });

    const mapRef = useRef(null);

    const handleMapLoaded = (map) => {
        setTimeout(() => {
            mapRef.current = map;
            setIsMapLoaded(true);
        }, 100);
    };

    const [drawnPolygon, setDrawnPolygon] = useState(null);

    useEffect(() => {
        if (isMapLoaded && polygon.paths.length > 0) {
            setTimeout(() => {
                if (drawnPolygon) {
                    drawnPolygon.setMap(null);
                }

                const newPolygon = new window.google.maps.Polygon({
                    paths: polygon.paths,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                });

                newPolygon.setMap(mapRef.current);
                setDrawnPolygon(newPolygon);
            }, 200);
        }
    }, [polygon, isMapLoaded]);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [textoErro, setTextoErro] = useState("");

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const buscarVeiculosInicial = async () => {
        setTimeout(() => {
            setValuesPesquisa(current => ({ ...current, embarqueInicio: formatarData2(new Date()) }));
            setValuesPesquisa(current => ({ ...current, embarqueFinal: formatarData2(new Date()) }));
        }, 100);
        setLoading(true);
        await getLocalizadorVeiculos(dadosUsuarioRedux.token, 3, 'todos', formatarData2(new Date()), formatarData2(new Date())).then((result) => {
            setVeiculoSelecionado(null);
            setLocalizadoresVeiculosDescarregando(result.data.descarregando);
            setLocalizadoresVeiculosCarregando(result.data.carregando);
        }).catch((error) => {
            if (error.response.data.length > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const buscarVeiculosSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getLocalizadorVeiculos(dadosUsuarioRedux.token, valuesPesquisa.tipo, valuesPesquisa.contexto, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal, pesquisaFormatada).then((result) => {
            setLocalizadoresVeiculosDescarregando(result.data.descarregando);
            setLocalizadoresVeiculosCarregando(result.data.carregando);
            setVeiculoSelecionado(null);
        }).catch((error) => {
            console.error(error);

            if (error.response.data.length > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    useEffect(() => {
        buscarVeiculosInicial();
    }, []);

    useEffect(() => {
        if (valuesPesquisa.tipo !== null && valuesPesquisa.tipo !== undefined) {
            buscarVeiculosSearch();
        }
    }, [valuesPesquisa.tipo]);

    useEffect(() => {
        if (lat != null && lng != null && dadosEventos.length > 0) {
            setLoading(false);
        }
    }, [lat, lng]);

    const formatarData3 = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${dia}-${mes}-${ano}`;
    };

    const formatarData2 = (dataString) => {
        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // getMonth() retorna mês de 0 a 11
        const ano = data.getFullYear();
        return `${ano}-${mes}-${dia}`;
    };

    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dataReferencia = new Date("2000-01-01T00:00:00");

        if (dataFormatada.getTime() === dataReferencia.getTime()) {
            return "Ainda não informado";
        }

        return dataFormatada.toLocaleDateString('pt-BR', {
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    // Novo estado para o centro do mapa
    const [mapCenter, setMapCenter] = useState({ lat: -13.352436164089657, lng: -49.59815951547697 });

    // Função para lidar com o clique em um Marker
    const handleMarkerClick = (veiculo) => {
        setVeiculoSelecionado(veiculo);
        setMapCenter({ lat: veiculo.latitude, lng: veiculo.longitude });
    };

    const { dadosContextoEmbarqueRedux, setDadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const navegarEdicaoEmbarque = (veiculo) => {
        setDadosContextoEmbarqueRedux(veiculo.embarque.contexto);
        navigate(`/embarque?id=${veiculo.embarque.idEmbarque}`);
    };

    function RastreioCard({ veiculoSelecionado, onClose }) {
        return (
            <div className="rastreio-card">
                <div className="card-header-veiculos">
                    <h2>Dados dos Veículos</h2>
                    <ClearIcon className="close-button" onClick={onClose} />
                </div>
                <div className="card-header-veiculos">
                    <h4>Clique no card para ser direcionado para a Viagem!</h4>
                </div>
                <div style={{
                    overflow: 'auto',
                    gap: '12px',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {veiculoSelecionado.localizacao.map((veiculo, index) => (
                        <div key={index} className="veiculo-detalhes" onClick={() => { navegarEdicaoEmbarque(veiculo); }}>
                            <p><strong>Veículo: <strong>{index + 1}</strong></strong></p>
                            <p><strong>Motorista:</strong> {veiculo.motorista}</p>
                            <p><strong>Placa Veículo:</strong> {veiculo.placaVeiculo}</p>
                            <p><strong>Previsão Chegada:</strong> {formatarData(veiculo.previsaoChegada)}</p>
                            <p><strong>Proprietário:</strong> {veiculo.proprietario}</p>
                            <p><strong>Saída:</strong> {formatarData(veiculo.saida)}</p>
                        </div>
                    ))}
                </div>
            </div >
        );
    }

    const limpar = () => {
        buscarVeiculosInicial();
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
            let regras = [];
            result.data.forEach((dado) => {
                regras.push(dado.regras.regra)
                if (dado.regras.regra === 'Localizador Veículos') {
                    setSemAutorizacao(false);
                }
            });
            dadosUsuarioRedux.regras = regras;
            setDadosUsuarioRedux(dadosUsuarioRedux);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }, []);

    function EventosCard({ dadosEventos }) {
        if (dadosEventos.length > 0) {
            return (
                <div className="eventos-card">
                    <h2>Eventos</h2>
                    {dadosEventos.map((dado, index) => (
                        <p key={index}><strong>-</strong> {dado.evento.nome}</p>
                    ))}
                </div>
            );
        }
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Localizador Veículos" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {props.navbar || <NavbarV3 selecionado="Localizador Veículos" />}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        <div className="principal-mapa">
                            {loadError && <div>Erro ao carregar o mapa</div>}
                            {!isLoaded ? (
                                <LoadingComponent />
                            ) : (
                                <>
                                    <div className="voltar-card-veiculos">
                                        <FiltroV2
                                            classe='div-filtrar-embarque-v2'
                                            classOption='select-options-cliente'
                                            itensFiltro={itensFiltro}
                                            valuesPesquisa={valuesPesquisa}
                                            setValuesPesquisa={setValuesPesquisa}
                                            buscarSearch={buscarVeiculosSearch}
                                            limpar={limpar}
                                        />
                                        <CustomButton
                                            classCss="custom-button-voltar"
                                            titulo="Voltar"
                                            cor={dadosCoresRedux.botaoPadrao}
                                            corTexto={dadosCoresRedux.textoBotaoPadrao}
                                            onClick={() => { voltar(); setDadosRastrearRedux({}); }}
                                        />
                                    </div>
                                    <div className="legenda-card-veiculos">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <img style={{ width: '30px' }} src={caminhaoPinCarregando} />
                                            <span>Carregando</span>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                            <img style={{ width: '30px' }} src={caminhaoPin} />
                                            <span>Descarregando</span>
                                        </div>

                                    </div>
                                    {
                                        dadosRastrearRedux.endereco ? (
                                            <>
                                                {
                                                    !loading ? (
                                                        <EventosCard dadosEventos={dadosEventos} />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </>
                                        ) : (
                                            veiculoSelecionado && <RastreioCard veiculoSelecionado={veiculoSelecionado} onClose={() => setVeiculoSelecionado(null)} />
                                        )
                                    }
                                    <GoogleMap
                                        mapContainerStyle={{ width: "100%", height: "96vh" }}
                                        center={mapCenter}
                                        zoom={5}
                                        onLoad={handleMapLoaded}
                                    >
                                        {isMapLoaded && localizadoresVeiculosDescarregando !== null && localizadoresVeiculosDescarregando.length > 0 && localizadoresVeiculosDescarregando.map((localizador, index) => (
                                            <Marker
                                                key={index}
                                                position={{ lat: localizador.latitude + 0.0020, lng: localizador.longitude + 0.0020 }}
                                                icon={{
                                                    url: caminhaoPin,
                                                    scaledSize: new window.google.maps.Size(50, 50),
                                                    labelOrigin: new window.google.maps.Point(25, 1)
                                                }}
                                                label={{
                                                    text: `${localizador.numeroVeiculos}`,
                                                    color: 'black',
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => handleMarkerClick(localizador)}
                                            />
                                        ))}
                                        {isMapLoaded && localizadoresVeiculosCarregando !== null && localizadoresVeiculosCarregando.length > 0 && localizadoresVeiculosCarregando.map((localizador, index) => (
                                            <Marker
                                                key={index}
                                                position={{ lat: localizador.latitude, lng: localizador.longitude }}
                                                icon={{
                                                    url: caminhaoPinCarregando,
                                                    scaledSize: new window.google.maps.Size(50, 50),
                                                    labelOrigin: new window.google.maps.Point(25, 1)
                                                }}
                                                label={{
                                                    text: `${localizador.numeroVeiculos}`,
                                                    color: 'black',
                                                    fontSize: '18px',
                                                    fontWeight: 'bold',
                                                }}
                                                onClick={() => handleMarkerClick(localizador)}
                                            />
                                        ))}
                                    </GoogleMap>
                                </>
                            )}
                        </div>
                        <div>
                            <ToastErro
                                texto={textoErro}
                                open={openToastErro}
                                onClose={handleCloseToastErro}
                            />
                        </div>
                    </motion.div>
                </div>
            )}
        </motion.div>
    );
}

export default LocalizadorVeiculos;
