import React, { useContext, useState, useEffect, useRef } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./Cliente.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { getCliente, cadastraClienteComEndereco, editaClientePorId, getClientePorId, deleteClientePorId } from "../../../services/ModuloGerenciador/Cliente";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { getUserClientes, verificaDadosUsuarioRedux, vincularUsuario } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { ChromePicker } from "react-color";
import { getClienteSearch } from "../../../services/ModuloGerenciador/Search";
import { cnpjMask } from "../../../regex/cnpjMask";
import { cpfMask } from "../../../regex/cpfMask";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import { GoogleMap, Marker, useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import { gerarUsuarioMotorista } from "../../../services/ModuloGerenciador/Motorista";
import ModalUsuarioMotorista from "../../../components/ModalUsuarioMotorista/ModalUsuarioMotorista.tsx";
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";
import ModalVincular from "../../../components/ModalVincular/ModalVincular.tsx";
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import { useLocation } from "react-router-dom";
import FiltrosRedux from "../../../contexts/Filtros.js";

function Cliente(props) {

    const libraries = ["places"];
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCN4pBitoJiDhjeEFW3AnGncUhiV1JE-Z4",
        libraries
    });

    const [searchBox, setSearchBox] = useState(null);

    const [zoom, setZoom] = useState(6);

    const handlePlacesChanged = () => {
        const places = searchBox.getPlaces();
        if (places && places.length === 1) {
            const location = places[0].geometry.location;
            setLat(location.lat());
            setLng(location.lng());
            fetchAddressFromCoords(location.lat(), location.lng());
            setMarker({ lat: location.lat(), lng: location.lng() });
            setZoom(14);
        }
    };

    const [isMapLoaded, setIsMapLoaded] = useState(false);

    const [lat, setLat] = useState(-23.550520);

    const [lng, setLng] = useState(-46.633308);

    const [polygon, setPolygon] = useState({ paths: [] });

    const mapRef = useRef(null);

    const handleMapLoaded = (map) => {
        mapRef.current = map;
        setIsMapLoaded(true);
    };

    const [marker, setMarker] = useState(null);

    const handleMapClickPoint = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLat(lat);
        setLng(lng);
        setMarker({ lat, lng });
        fetchAddressFromCoords(lat, lng);
    };

    const handleMapClickPoint2 = (lat, lng) => {
        setLat(lat);
        setLng(lng);
        setMarker({ lat, lng });
        fetchAddressFromCoords(lat, lng);
    };

    const [showFirstPointMarker, setShowFirstPointMarker] = useState(false);

    const handleMapClickPoligon = (event) => {
        const newPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        setLat(event.latLng.lat());
        setLng(event.latLng.lng());
        const novoPonto = { latitude: event.latLng.lat(), longitude: event.latLng.lng() };

        setValues(current => {
            // Atualiza showFirstPointMarker com base no número de pontos no polígono
            setShowFirstPointMarker(current.polygon?.length === 0);

            return {
                ...current,
                polygon: Array.isArray(current.polygon) ? [...current.polygon, novoPonto] : [novoPonto]
            };
        });

        setDisableInputs(false);
        setPolygon(prevPolygon => ({
            ...prevPolygon,
            paths: [...prevPolygon.paths, newPoint]
        }));
    };

    const [drawnPolygon, setDrawnPolygon] = useState(null);

    useEffect(() => {
        if (isMapLoaded && polygon.paths.length > 0) {
            setTimeout(() => {
                if (drawnPolygon) {
                    drawnPolygon.setMap(null);
                }

                const newPolygon = new window.google.maps.Polygon({
                    paths: polygon.paths,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                });

                newPolygon.setMap(mapRef.current);
                setDrawnPolygon(newPolygon);
            }, 200);
        }
    }, [polygon, isMapLoaded]);

    const fetchAddressFromCoords = async (lat, lng) => {
        const newPoint = { latitude: lat, longitude: lng };
        let coordenadas = [];
        coordenadas.push(newPoint);
        setValues(current => ({ ...current, polygon: coordenadas }));
        setDisableInputs(false);
        const apiKey = "AIzaSyCN4pBitoJiDhjeEFW3AnGncUhiV1JE-Z4";
        const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

        try {
            const response = await fetch(apiUrl);
            const data = await response.json();

            if (data.results && data.results.length > 0) {
                const addressComponents = data.results[0].address_components;
                const route = addressComponents.find(component => component.types.includes("route"));
                const street_number = addressComponents.find(component => component.types.includes("street_number"));
                const administrative_area_level_2 = addressComponents.find(component => component.types.includes("administrative_area_level_2"));
                const postal_code = addressComponents.find(component => component.types.includes("postal_code"));
                const sublocality_level_1 = addressComponents.find(component => component.types.includes("sublocality_level_1"));
                if (route) {
                    const rua = route.long_name;
                    const numero = street_number.long_name;
                    const cidade = administrative_area_level_2.long_name;
                    const cep = postal_code.long_name;
                    const bairro = sublocality_level_1.long_name;
                    setValues(current => ({ ...current, rua: rua }));
                    setValues(current => ({ ...current, numero: numero.replace(/[^\d]+/g, "") }));
                    setValues(current => ({ ...current, cidade: cidade }));
                    setValues(current => ({ ...current, cep: cep.replace("-", "") }));
                    setValues(current => ({ ...current, bairro: bairro }));
                } else {
                    console.error("Rua não encontrada nas coordenadas fornecidas.");
                }
            } else {
                console.error("Não foi possível obter o endereço das coordenadas fornecidas.");
            }
        } catch (error) {
            console.error("Erro ao buscar endereço:", error);
        }
    };

    const [disableInputs, setDisableInputs] = useState(true);

    const [disableCnpj, setDisableCnpj] = useState(false);

    const itensFiltro = [
        {
            'nomeColuna': 'Nome Fantasia',
            'field': 'nomeFantasia'
        },
        {
            'nomeColuna': 'Razão Social',
            'field': 'razaoSocial'
        },
        {
            'nomeColuna': 'CNPJ',
            'field': 'cnpj'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getClienteSearch(dadosUsuarioRedux.token, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [dadosUsuarioMotorista, setDadosUsuarioMotorista] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [isMapa, setIsMapa] = useState(true);

    const [tipoGeometria, setTipoGeometria] = useState(false);

    const removePolygon = () => {
        if (drawnPolygon) {
            drawnPolygon.setMap(null);
            setDrawnPolygon(null);
        }
    };

    const handleTipoGeometria = () => {
        setMarker(null);
        setDrawnPolygon(null);
        setPolygon({ paths: [] });
        removePolygon();
        setValues(current => ({ ...current, latitude: null }));
        setValues(current => ({ ...current, longitude: null }));
        setValues(current => ({ ...current, polygon: null }));
        setTipoGeometria(!tipoGeometria);
    }

    const limparGeoms = () => {
        setMarker(null);
        setDrawnPolygon(null);
        setPolygon({ paths: [] });
        removePolygon();
    }

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [openUsuarioMotorista, setOpenUsuarioMotorista] = useState(false);

    const handleCloseUsuarioMotorista = (value) => {
        setOpenUsuarioMotorista(false);
        setOpenConfirmar(false);
        buscarEmbarcador();
    };

    const abrirConfirmar = (value) => {
        setOpenConfirmar(true);
    };

    const [openConfirmar, setOpenConfirmar] = useState(false);

    const handleCloseConfirmar = (value) => {
        setOpenConfirmar(false);
    };

    const clickGerar = () => {
        gerarUsuario();
    };

    const [openGerar, setOpenGerar] = useState(false);

    const handleCloseGerar = (value) => {
        setOpenGerar(false);
    };

    const [listaUsuarios, setListaUsuario] = useState([]);

    const [usuarioId, setUsuarioId] = useState();

    const [nomeUsuario, setNomeUsuario] = useState();

    const buscarEmbarcador = async () => {
        setLoading(true);
        await getCliente(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarUsuariosClientes = async () => {
        await getUserClientes(dadosUsuarioRedux.token).then((result) => {
            setListaUsuario(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const gerarUsuario = async () => {
        const usuarioVinculo = {
            "clienteId": tabelaRedux.colunaSelecionada,
            "usuarioClientesId": usuarioId
        }
        await vincularUsuario(usuarioVinculo, dadosUsuarioRedux.token).then((result) => {
            setOpenGerar(false);
            buscarEmbarcador();
            setTextoSucesso("Vinculado Com Sucesso");
            setOpenToastSucesso(true);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const cadastrar = async () => {
        const algumPreenchido = values.rua || values.numero || values.cidade || values.cep || values.bairro;
        const todosPreenchidos = values.rua && values.numero && values.cidade && values.cep && values.bairro;
        if ((values.cnpj !== "" || values.razaoSocial !== "" || values.nomeFantasia !== "" || values.apelido !== "") &&
            (validator.validatorCnpj === "true" && validator.validatorNomeFantasia === "true" && validator.validatorRazaoSocial === "true" && validator.validatorApelido === "true") &&
            (!algumPreenchido || todosPreenchidos)) {
            setLoading(true);
            await cadastraClienteComEndereco(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarEmbarcador();
                    setDadosRequestControllerRedux("Fechar Modal Cliente");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    limparGeoms();
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else if (algumPreenchido && !todosPreenchidos) {
            setTextoErro('Endereço é Obrigatório!');
            setOpenToastErro(true);
            setLoading(false);
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaClientePorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (valuesPesquisa.pesquisa === undefined) {
                    buscarEmbarcador();
                } else {
                    buscarSearch();
                }
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarEmbarcadorPorId = async () => {
        setLoading(true);
        await getClientePorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    const minutos = parseFloat(result.data.tempoDiariaMinutos);
                    const horas = isNaN(minutos) ? '' : (minutos / 60).toFixed(0); // Divide minutos por 60 e mantém 2 casas decimais
                    setValues(current => ({ ...current, tempoDiariaMinutos: minutos }));
                    setValues(current => ({ ...current, tempoDiariaMinutosV: horas }));
                    setValues(current => ({ ...current, idCliente: result.data.idCliente }));
                    setValues(current => ({ ...current, nomeFantasia: result.data.nomeFantasia }));
                    setValues(current => ({ ...current, razaoSocial: result.data.razaoSocial }));
                    setValues(current => ({ ...current, cnpj: result.data.cnpj }));
                    setValues(current => ({ ...current, corCliente: result.data.corCliente }));
                    setValues(current => ({ ...current, apelido: result.data.apelido }));
                    setValues(current => ({ ...current, diFreteFiscal: result.data.diFreteFiscal }));
                    setValues(current => ({ ...current, diPedagio: result.data.diPedagio }));
                    setValues(current => ({ ...current, diClassificacao: result.data.diClassificacao }));
                    if (result.data.endereco != null) {
                        setValues(current => ({ ...current, rua: result.data.endereco.rua }));
                        setValues(current => ({ ...current, numero: result.data.endereco.numero }));
                        setValues(current => ({ ...current, cidade: result.data.endereco.cidade }));
                        setValues(current => ({ ...current, cep: result.data.endereco.cep }));
                        setValues(current => ({ ...current, bairro: result.data.endereco.bairro }));
                        setValues(current => ({ ...current, complemento: result.data.endereco.complemento }));
                        // setValues(current => ({ ...current, latitude: result.data.endereco.latitude }));
                        // setValues(current => ({ ...current, longitude: result.data.endereco.longitude }));
                        if (result.data.endereco.polygon.length <= 1) {
                            setTimeout(() => {
                                setLat(result.data.endereco.polygon[0].latitude);
                                setLng(result.data.endereco.polygon[0].longitude);
                                setMarker({ lat: result.data.endereco.polygon[0].latitude, lng: result.data.endereco.polygon[0].longitude });
                                const coordenadasTransformadas2 = result.data.endereco.polygon.map(coordenada => ({
                                    latitude: coordenada.latitude,
                                    longitude: coordenada.longitude
                                }));
                                setValues(current => ({ ...current, polygon: coordenadasTransformadas2 }));
                                setZoom(14);
                            }, 200);
                        } else {
                            const coordenadasTransformadas = result.data.endereco.polygon.map(coordenada => ({
                                lat: coordenada.latitude,
                                lng: coordenada.longitude
                            }));
                            const coordenadasTransformadas2 = result.data.endereco.polygon.map(coordenada => ({
                                latitude: coordenada.latitude,
                                longitude: coordenada.longitude
                            }));
                            setValues(current => ({ ...current, polygon: coordenadasTransformadas2 }));
                            setPolygon({ paths: coordenadasTransformadas });
                            setLat(coordenadasTransformadas[0].lat);
                            setLng(coordenadasTransformadas[0].lng);
                            setZoom(11);
                        }
                    } else {
                        setLat(-23.550520);
                        setLng(-46.633308);
                    }
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarEmbarcadorPorIdModal = async (id) => {
        setLoading(true);
        await getClientePorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarEmbarcadorPorId = async () => {
        setLoading(true);
        await deleteClientePorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarEmbarcador();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, validatorCnpj: "false" }));
        setValidator(current => ({ ...current, validatorApelido: "false" }));
        setValidator(current => ({ ...current, validatorNomeFantasia: "false" }));
        setValidator(current => ({ ...current, validatorRazaoSocial: "false" }));
        if (editando) {
            setValidator(current => ({ ...current, validatorCnpj: "true" }));
            setValidator(current => ({ ...current, validatorNomeFantasia: "true" }));
            setValidator(current => ({ ...current, validatorRazaoSocial: "true" }));
            setValidator(current => ({ ...current, validatorApelido: "true" }));
        }
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarEmbarcadorPorId();
            setIsMapa(true);
            setDisableCnpj(true);
        } else {
            setDisableCnpj(false);
        }
    }, [editando]);

    useEffect(() => {
        buscarEmbarcador();
        buscarUsuariosClientes();
        if (props.idAtual) {
            buscarEmbarcadorPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        if (telaCadastro === true && editando === false) {
            setValues(current => ({ ...current, diFreteFiscal: true }));
        }
    }, [telaCadastro]);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoGerarLogin, setSemAutorizacaoGerarLogin] = useState(true);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
            let regras = [];
            result.data.forEach((dado) => {
                regras.push(dado.regras.regra)
                if (dado.regras.regra === 'Clientes') {
                    setSemAutorizacao(false);
                }
                if (dado.regras.regra === 'Gerar Login') {
                    setSemAutorizacaoGerarLogin(false);
                }
            });
            dadosUsuarioRedux.regras = regras;
            setDadosUsuarioRedux(dadosUsuarioRedux);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }, []);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Clientes" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Clientes" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Clientes</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idCliente"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Cliente}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {semAutorizacaoGerarLogin ? (
                                                    <></>
                                                ) : (
                                                    <div>
                                                        <CustomButtonV2
                                                            classCss="custom-button-vincular"
                                                            classText="button-texto-user"
                                                            titulo="Vincular Usuário"
                                                            cor="#FFFFFF"
                                                            valor={values}
                                                            onClick={() => {
                                                                if (tabelaRedux.colunaSelecionada) {
                                                                    setOpenGerar(true);
                                                                } else {
                                                                    setTextoErro("Selecione Uma Coluna");
                                                                    setOpenToastErro(true);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Clientes / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Clientes"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Clientes"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Cliente" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Cliente" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false);
                                                                setDisableInputs(true); setTabelaRedux({}); setIsMapa(false);
                                                                limparGeoms();
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Nome Fantasia:</p>
                                                                <input
                                                                    type="text"
                                                                    id="nomeFantasia"
                                                                    className="input-emb"
                                                                    style={{ width: '210px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nomeFantasia: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorNomeFantasia: "true" }));
                                                                    }}
                                                                    placeholder="Ex: BRF"
                                                                    value={values.nomeFantasia}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Razão Social:</p>
                                                                <input
                                                                    type="text"
                                                                    id="razaoSocial"
                                                                    className="input-emb"
                                                                    style={{ width: '210px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, razaoSocial: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorRazaoSocial: "true" }));
                                                                    }}
                                                                    placeholder="Ex: BRF S.A"
                                                                    value={values.razaoSocial}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">CPF/CNPJ:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '210px' }}
                                                                    onChange={(e) => {
                                                                        let valorMascara = e.target.value;
                                                                        if (e.target.value.length <= 14) {
                                                                            valorMascara = cpfMask(e.target.value);
                                                                        } else if (e.target.value.length > 13) {
                                                                            valorMascara = cnpjMask(e.target.value);
                                                                        }
                                                                        setValues(current => ({ ...current, cnpj: valorMascara }));
                                                                        setValidator(current => ({ ...current, validatorCnpj: "true" }));
                                                                    }}
                                                                    placeholder="Ex: 00.000.000/0000-00"
                                                                    value={values.cnpj}
                                                                    disabled={props.desabilitar || disableCnpj}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Apelido:</p>
                                                                <input
                                                                    type="text"
                                                                    id="apelido"
                                                                    className="input-emb"
                                                                    style={{ width: '210px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, apelido: e.target.value }));
                                                                        setValidator(current => ({ ...current, validatorApelido: "true" }));
                                                                    }}
                                                                    placeholder="Ex: BRF"
                                                                    maxLength={12}
                                                                    value={values.apelido}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Tempo Diária:</p>
                                                                <input
                                                                    type="number"
                                                                    id="apelido"
                                                                    className="input-emb"
                                                                    style={{ width: '210px' }}
                                                                    onChange={(e) => {
                                                                        const horas = parseFloat(e.target.value);
                                                                        const minutos = isNaN(horas) ? '' : Math.floor(horas * 60);
                                                                        setValues(current => ({ ...current, tempoDiariaMinutos: minutos }));
                                                                        setValues(current => ({ ...current, tempoDiariaMinutosV: horas }));
                                                                    }}
                                                                    placeholder="Ex: 24 horas"
                                                                    maxLength={5}
                                                                    value={values.tempoDiariaMinutosV}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="principal-cliente">
                                                <div className="box2">
                                                    <div className="flex">
                                                        <h4>Base de cálculo para o ICMS</h4>
                                                    </div>
                                                    <div className="flex">
                                                        <div className="grid-item">
                                                            <input
                                                                type="checkbox"
                                                                className="check-periodo"
                                                                checked={values.diFreteFiscal}
                                                                onChange={(e) => {
                                                                    setValues(current => ({ ...current, diFreteFiscal: e.target.checked }));
                                                                }}
                                                            ></input>
                                                            <p className="texto-periodo">Frete Fiscal</p>
                                                        </div>
                                                        <div className="grid-item">
                                                            <input
                                                                type="checkbox"
                                                                className="check-periodo"
                                                                checked={values.diPedagio}
                                                                onChange={(e) => {
                                                                    setValues(current => ({ ...current, diPedagio: e.target.checked }));
                                                                }}
                                                            ></input>
                                                            <p className="texto-periodo">Pedágio</p>
                                                        </div>
                                                        <div className="grid-item">
                                                            <input
                                                                type="checkbox"
                                                                className="check-periodo"
                                                                checked={values.diClassificacao}
                                                                onChange={(e) => {
                                                                    setValues(current => ({ ...current, diClassificacao: e.target.checked }));
                                                                }}
                                                            ></input>
                                                            <p className="texto-periodo">Classificação/Descarga</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex-row">
                                                        <CustomButtonV2
                                                            classCss="custom-button-mapa"
                                                            classText="button-texto-user"
                                                            titulo="Selecionar Endereço no Mapa"
                                                            cor="#FFFFFF"
                                                            onClick={() => {
                                                                setIsMapa(!isMapa);
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        isMapa ? (
                                                            <div className="principal-mapa-v2">
                                                                {loadError && <div>Erro ao carregar o mapa</div>}
                                                                {!isLoaded ? (
                                                                    <LoadingComponent />
                                                                ) : (
                                                                    <>
                                                                        <StandaloneSearchBox
                                                                            onLoad={ref => setSearchBox(ref)}
                                                                            onPlacesChanged={handlePlacesChanged}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Selecione um endereço"
                                                                                className="search-box"
                                                                            />
                                                                        </StandaloneSearchBox>
                                                                        <div className="geometria-card">
                                                                            <CustomButtonV2
                                                                                classCss="custom-button-geometria"
                                                                                classText="button-texto-user"
                                                                                titulo={tipoGeometria ? "Ponto" : "Polígono"}
                                                                                cor="#FFFFFF"
                                                                                onClick={() => {
                                                                                    handleTipoGeometria();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <GoogleMap
                                                                            mapContainerStyle={{ width: "100%", height: "56vh" }}
                                                                            zoom={zoom}
                                                                            center={{ lat: lat, lng: lng }}
                                                                            onLoad={handleMapLoaded}
                                                                            onClick={tipoGeometria ? handleMapClickPoligon : handleMapClickPoint}
                                                                        >
                                                                            {/* {marker && <Marker position={{ lat: marker.lat, lng: marker.lng }} />} */}
                                                                            {values.polygon?.length === 1 && (
                                                                                <Marker
                                                                                    position={{ lat: values.polygon[0].latitude, lng: values.polygon[0].longitude }}
                                                                                />
                                                                            )}
                                                                        </GoogleMap>
                                                                    </>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Rua:</p>
                                                                <input
                                                                    type="text"
                                                                    id="rua"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, rua: e.target.value }));
                                                                    }}
                                                                    value={values.rua}
                                                                    disabled={props.desabilitar || disableInputs}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Número:</p>
                                                                <input
                                                                    type="number"
                                                                    id="numero"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, numero: e.target.value }));
                                                                    }}
                                                                    value={values.numero}
                                                                    disabled={props.desabilitar || disableInputs}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="Bairro">Bairro:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, bairro: e.target.value }));
                                                                    }}
                                                                    value={values.bairro}
                                                                    disabled={props.desabilitar || disableInputs}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">CEP:</p>
                                                                <input
                                                                    maxLength="8"
                                                                    type="text"
                                                                    id="cep"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        let valor = e.target.value;
                                                                        valor = valor.replace(/\D/g, '');
                                                                        setValues(current => ({ ...current, cep: valor }));
                                                                    }}
                                                                    value={values.cep}
                                                                    disabled={props.desabilitar || disableInputs}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Cidade:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, cidade: e.target.value }));
                                                                    }}
                                                                    value={values.cidade}
                                                                    disabled={props.desabilitar || disableInputs}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Complemento:</p>
                                                                <input
                                                                    type="text"
                                                                    id="complemento"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, complemento: e.target.value }));
                                                                    }}
                                                                    value={values.complemento}
                                                                    disabled={props.desabilitar || disableInputs}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div>
                                                            <InputLabel htmlFor="cnpj">Cor Cliente</InputLabel>
                                                            <ChromePicker
                                                                className="picker"
                                                                color={values.corCliente ? values.corCliente : '#000000'}
                                                                onChange={updatedColor => setValues(current => ({ ...current, corCliente: updatedColor?.hex }))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-cliente">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => {
                                                                const todosPreenchidos = values.rua && values.numero && values.cidade && values.cep && values.bairro;
                                                                if ((values.cnpj !== "" && values.nomeFantasia !== "" && values.razaoSocial !== "" && values.apelido !== "" &&
                                                                    values.rua !== "" && values.numero !== "" && values.cidade !== "" && values.cep !== "" && values.bairro !== "")
                                                                    && (validator.validatorCnpj === "true" && validator.validatorNomeFantasia === "true"
                                                                        && validator.validatorRazaoSocial === "true" && validator.validatorApelido === "true")) {
                                                                    editar();
                                                                    setTelaCadastro(false);
                                                                    setEditando(false);
                                                                    setValues({});
                                                                    setDisableInputs(true);
                                                                } else if (!todosPreenchidos) {
                                                                    setTextoErro('Endereço é Obrigatório!');
                                                                    setOpenToastErro(true);
                                                                } else {
                                                                    setTextoErro('Há campos sem preencher!');
                                                                    setOpenToastErro(true);
                                                                }
                                                            }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                const algumPreenchido = values.rua || values.numero || values.cidade || values.cep || values.bairro;
                                                                                const todosPreenchidos = values.rua && values.numero && values.cidade && values.cep && values.bairro;
                                                                                if ((values.cnpj !== "" && values.nomeFantasia !== "" && values.razaoSocial !== "" && values.apelido !== "")
                                                                                    && (validator.validatorCnpj === "true" && validator.validatorNomeFantasia === "true"
                                                                                        && validator.validatorRazaoSocial === "true" && validator.validatorApelido === "true")
                                                                                    && todosPreenchidos) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setDisableInputs(true);
                                                                                } else if (!todosPreenchidos) {
                                                                                    setTextoErro('Endereço é Obrigatório!');
                                                                                    setOpenToastErro(true);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                const algumPreenchido = values.rua || values.numero || values.cidade || values.cep || values.bairro;
                                                                                const todosPreenchidos = values.rua && values.numero && values.cidade && values.cep && values.bairro;
                                                                                if ((values.cnpj !== "" && values.nomeFantasia !== "" && values.razaoSocial !== "" && values.apelido !== "")
                                                                                    && (validator.validatorCnpj === "true" && validator.validatorNomeFantasia === "true"
                                                                                        && validator.validatorRazaoSocial === "true" && validator.validatorApelido === "true")
                                                                                    && todosPreenchidos) {
                                                                                    cadastrar();
                                                                                    setTelaCadastro(false);
                                                                                    setValues({});
                                                                                    setDisableInputs(true);
                                                                                } else if (!todosPreenchidos) {
                                                                                    setTextoErro('Endereço é Obrigatório!');
                                                                                    setOpenToastErro(true);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false);
                                                                setDisableInputs(true); setTabelaRedux({}); setIsMapa(false); limparGeoms();
                                                            }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarEmbarcadorPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalUsuarioMotorista
                                        titulo="Login Motorista"
                                        tituloConfirmar="OK"
                                        open={openUsuarioMotorista}
                                        onClose={abrirConfirmar}
                                        dados={dadosUsuarioMotorista}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deseja Fechar? "
                                        texto="Ao fechar esse modal não será mais possivel visalizar a senha."
                                        textoBotao="Sim"
                                        open={openConfirmar}
                                        onClose={handleCloseConfirmar}
                                        onClick={handleCloseUsuarioMotorista}
                                    />
                                    <ModalVincular
                                        titulo="Vincular Usuário"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={openGerar}
                                        onClose={handleCloseGerar}
                                        onClick={clickGerar}
                                        listaUsuarios={listaUsuarios}
                                        setUsuarioId={setUsuarioId}
                                        setNomeUsuario={setNomeUsuario}
                                        referenciaId="nome"
                                        referenciaItem="nome"
                                        id="id"
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Cliente;