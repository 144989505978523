import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalVeiculo/ModalVeiculo.css';
import CoresRedux from "../../contexts/Cor.js";
import TabelaRedux from "../../contexts/Tabela.js";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2.js";
import { valorMask } from "../../regex/valorMask.js";
import NewIcon from "../NewIcon/NewIcon.js";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2.js";
import RequestControllerRedux from "../../contexts/RequestController.js";
import ModalCadastro from "../ModalCadastro/ModalCadastro.tsx";
import LoadingBar from "../LoadingBar/LoadingBar.js";
import ToastErro from "../Toast/ToastErro/ToastErro.tsx";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaVeiculos: any[];
    onClose: () => void;
    onClick: () => void;
    setVeiculo: (e) => void;
}

function ModalVeiculo(props: SimpleDialogProps) {

    const { setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const [values, setValues] = useState<any>([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const handleClose = () => {
        onClose();
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = () => {
        setOpenToastErro(false);
    };

    const [textoErro, setTextoErro] = useState("");

    const handleClick = () => {
        if (valuesSelect.veiculoId) {
            onClick();
        } else {
            setTextoErro("Selecione um veículo para prosseguir.");
            setOpenToastErro(true);
        }
    };

    const clearStatus = () => {
        setValuesSelect(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, statusId: null }));
    };

    const [idAtual, setIdAtual] = useState(0);

    const [disable, setDisable] = useState(false);

    const [openCadastroStatus, setOpenCadastroStatus] = useState(false);

    const [loadingSelectStatus, setLoadingSelectStatus] = useState(false);

    useEffect(() => {
        setLoadingSelectStatus(true);
        setTimeout(() => {
            setLoadingSelectStatus(false);
        }, 1000);
    }, [props.listaVeiculos]);

    const handleCloseCadastroStatus = () => {
        if (idAtual) {
            setOpenCadastroStatus(false);
            setDadosRequestControllerRedux("Close Status");
        } else {
            setDadosRequestControllerRedux("Close Status");
            setOpenCadastroStatus(false);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-usuario'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <DialogContent className="content-veiculo">
                        <DialogContentText id="alert-dialog-description">
                            <div className="usuario-data-row">
                                {
                                    loadingSelectStatus ? (
                                        <div className="coluna-modalmotivo">
                                            <LoadingBar />
                                        </div>
                                    ) : (
                                        <div className="coluna-modalmotivo">
                                            <div>
                                                <p className="input-label-emb">Veículo:</p>
                                                <SelectEmbarqueV2
                                                    classeCss={180}
                                                    onChange={(e) => {
                                                        setValuesSelect(current => ({ ...current, veiculoId: e }));
                                                        props.setVeiculo(current => ({ ...current, veiculoId: e.id }));
                                                    }}
                                                    pequeno={true}
                                                    id="idVeiculo"
                                                    value={valuesSelect.veiculoId}
                                                    titulo="Veículo"
                                                    dadosSelect={props.listaVeiculos}
                                                    referenciaId="caminhao"
                                                    referenciaItem="caminhao"
                                                    referenciaItem2="placa"
                                                    info={false} />
                                            </div>
                                            <NewIcon
                                                onClick={(e) => {
                                                    setOpenCadastroStatus(true); setIdAtual(0); setDisable(false);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="Cancelar"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                    </DialogActions>
                </div>
            </Dialog>
            <div>
                <ModalCadastro
                    titulo="Cadastro"
                    tituloConfirmar="OK"
                    cadastroVeiculo="sim"
                    open={openCadastroStatus}
                    idAtual={idAtual}
                    desabilitar={disable}
                    onClose={handleCloseCadastroStatus}
                />
                <ToastErro
                    texto={textoErro}
                    open={openToastErro}
                    onClose={handleCloseToastErro}
                    onClick={handleCloseToastErro}
                />
            </div>
        </div>
    );
}

export default ModalVeiculo;