import React from 'react';
import "./LoadingComponent.css";
import loading from '../../images/loading.gif'
import { motion } from "framer-motion";

function LoadingComponent(props) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className='loading-component'
        >
            <img src={loading} alt="Carregando" style={{ maxWidth: "15%", maxHeight: "20%" }}></img>
            <b className='carregando-texto' style={{ marginTop: '12px' }}>Carregando...</b>
        </motion.div>
    )
}

export default LoadingComponent;