import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';
import * as HiIcons from 'react-icons/hi';
import * as MdIcons from 'react-icons/md';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs'
import * as BiIcons from 'react-icons/bi'
import * as TbIcons from 'react-icons/tb'
import iconePedidos from '../../images/iconePedidos.png'
import iconeRastreamento from '../../images/iconeRastreamento.png'
import iconeVeiculos from '../../images/iconeVeiculos.png'
import iconePessoas from '../../images/iconePessoas.png'
import iconeComum from '../../images/iconeComum.png'
import iconeDash from '../../images/iconeDash.png'
import iconePreco from '../../images/iconePreco.png'
import iconeCotacao from '../../images/iconeCotacao.png'
import iconeOnTime from '../../images/iconeOnTime.png'
import iconeFaturamento from '../../images/iconeFaturamento.png'
import iconeAlerta from '../../images/iconeAlerta.png'
import iconeRota from '../../images/iconeRota.png'
import iconeCheck from '../../images/iconeCheck.png'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TodayIcon from '@mui/icons-material/Today';
import '../NavbarV3/NavbarV3.css'

export const SidebarDataUsoComum = [
  {
    titulo: 'Cidade',
    rota: '/cidade-estado',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 11
  },
  {
    titulo: 'Embalagem',
    rota: '/embalagem',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 12
  },
  {
    titulo: 'Mercadoria',
    rota: '/mercadoria',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 12
  },
  {
    titulo: 'Empresas Subsidiárias',
    rota: '/empresa-sub',
    icone: <RiIcons.RiBuilding3Line />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 13
  },
  {
    titulo: 'Filial',
    rota: '/filial',
    icone: <BiIcons.BiBuilding />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 14
  },
  {
    titulo: 'Meio Pagamento',
    rota: '/meio-pagamento',
    icone: <MdIcons.MdPayment />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 15
  },
  {
    titulo: 'Status',
    rota: '/status',
    icone: <HiIcons.HiOutlineStatusOnline />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 16
  },
  {
    titulo: 'Motivo',
    rota: '/motivo',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Taxa ICMS',
    rota: '/taxa-icms',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Motivo On Time',
    rota: '/motivo-ontime',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Tipo Veículo',
    rota: '/tipo-veiculo',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Tipo Carroceria',
    rota: '/tipo-carroceria',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Motivo Cotação',
    rota: '/motivo-cotacao',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Segmento Cliente',
    rota: '/segmento-cliente',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Ocorrência',
    rota: '/ocorrencia',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Sub Tipo Ocorrência',
    rota: '/tipo-ocorrencia',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Tipo Documento',
    rota: '/tipo-documento',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Tipo Contato',
    rota: '/tipo-contato',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Status Diária',
    rota: '/diaria-status',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Motivo Estadia',
    rota: '/motivo-estadia',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  }
];

export const SidebarDataV3 = [
  {
    titulo: 'Pedido',
    icone: <img src={iconePedidos} />,
    nomeClasse: 'nav-text',
    pedido: 'pedidoBotao',
    index: 1
  },
  {
    titulo: 'Pedidos',
    rota: '/pedido',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    pedido: 'pedido',
    index: 11
  },
  {
    titulo: 'Gerenciamento de Pedido',
    rota: '/gerenciamento-pedido',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    pedido: 'pedido',
    pendentes: true,
    index: 12
  },
  {
    titulo: 'Rotas',
    rota: '/rota',
    icone: <img src={iconeRota} />,
    nomeClasse: 'nav-text',
    index: 3
  },
  {
    titulo: 'Gestão de Veículos',
    nomeClasse: 'nav-text',
    icone: <img src={iconeVeiculos} />,
    veiculos: 'veiculosBotao',
    index: 10
  },
  {
    titulo: 'Conjuntos',
    rota: '/conjuntos',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    veiculos: 'veiculos',
    index: 11
  },
  {
    titulo: 'Agenda',
    rota: '/diaria-veiculos',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    veiculos: 'veiculos',
    index: 11
  },
  {
    titulo: 'Caminhões',
    rota: '/caminhoes',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    veiculos: 'veiculos',
    index: 11
  },
  {
    titulo: 'Carretas',
    rota: '/carretas',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    veiculos: 'veiculos',
    index: 11
  },
  {
    titulo: 'Grupo Cargas',
    rota: '/grupo-cargas',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    veiculos: 'veiculos',
    index: 11
  },
  {
    titulo: 'Vincular Grupo Cargas',
    rota: '/grupo-cargas-vinculo',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    veiculos: 'veiculos',
    index: 11
  },
  {
    titulo: 'Checklist',
    nomeClasse: 'nav-text',
    icone: <img src={iconeCheck} />,
    gerenciamentoFrota: 'gerenciamentoFrotaBotao',
    index: 10
  },
  {
    titulo: 'Check List Frota',
    rota: '/frota',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    gerenciamentoFrota: 'gerenciamentoFrota',
    index: 11
  },
  {
    titulo: 'Veículos Vencidos',
    rota: '/veiculos-vencidos',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    gerenciamentoFrota: 'gerenciamentoFrota',
    index: 11
  },
  {
    titulo: 'Configurações',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    gerenciamentoFrota: 'gerenciamentoFrota',
    index: 11
  },
  {
    titulo: 'Adicionar Checklist Veículo',
    rota: '/adicionar-check-veiculo',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum-2',
    diversos: 'diversos',
    index: 11
  },
  {
    titulo: 'Tipo de Checkagem',
    icone: <FaIcons.FaCity />,
    rota: '/tipo-checkagem',
    nomeClasse: 'nav-text-comum-2',
    diversos: 'diversos',
    index: 11
  },
  {
    titulo: 'Checkagem',
    icone: <FaIcons.FaCity />,
    rota: '/checkagem',
    nomeClasse: 'nav-text-comum-2',
    diversos: 'diversos',
    index: 11
  },
  {
    titulo: 'Gestão de Pessoas',
    nomeClasse: 'nav-text',
    icone: <img src={iconePessoas} />,
    pessoas: 'pessoasBotao',
    index: 10
  },
  {
    titulo: 'Proprietário',
    rota: '/proprietario',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    pessoas: 'pessoas',
    index: 18
  },
  {
    titulo: 'Gestão de Veiculos',
    rota: '/motorista',
    icone: <GiIcons.GiSteeringWheel />,
    pessoas: 'pessoas',
    nomeClasse: 'nav-text-comum',
    index: 7
  },
  {
    titulo: 'Clientes',
    rota: '/cliente',
    icone: <RiIcons.RiEditBoxFill />,
    pessoas: 'pessoas',
    nomeClasse: 'nav-text-comum',
    index: 6
  },
  {
    titulo: 'Grupo de Clientes',
    rota: '/grupo-clientes',
    icone: <RiIcons.RiEditBoxFill />,
    pessoas: 'pessoas',
    nomeClasse: 'nav-text-comum',
    index: 6
  },
  {
    titulo: 'Vincular Clientes',
    rota: '/vincular-clientes',
    icone: <RiIcons.RiEditBoxFill />,
    pessoas: 'pessoas',
    nomeClasse: 'nav-text-comum',
    index: 6
  },
  {
    titulo: 'Rastreamento',
    nomeClasse: 'nav-text',
    icone: <img src={iconeRastreamento} />,
    rastreamento: 'rastreamentoBotao',
    index: 10
  },
  {
    titulo: 'Cadastro Rastreio',
    rota: '/rastreamento',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    rastreamento: 'rastreamento',
    index: 11
  },
  {
    titulo: 'Tipo Rastreio',
    rota: '/tipo-rastreamento',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    rastreamento: 'rastreamento',
    index: 11
  },
  {
    titulo: 'Uso Comum',
    nomeClasse: 'nav-text',
    icone: <img src={iconeComum} />,
    usoComum: 'usoComumBotao',
    index: 10
  },
  {
    titulo: 'Cidade',
    rota: '/cidade-estado',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 11
  },
  {
    titulo: 'Embalagem',
    rota: '/embalagem',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 12
  },
  {
    titulo: 'Mercadoria',
    rota: '/mercadoria',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 12
  },
  {
    titulo: 'Empresas Subsidiárias',
    rota: '/empresa-sub',
    icone: <RiIcons.RiBuilding3Line />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 13
  },
  {
    titulo: 'Filial',
    rota: '/filial',
    icone: <BiIcons.BiBuilding />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 14
  },
  {
    titulo: 'Meio Pagamento',
    rota: '/meio-pagamento',
    icone: <MdIcons.MdPayment />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 15
  },
  {
    titulo: 'Status',
    rota: '/status',
    icone: <HiIcons.HiOutlineStatusOnline />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 16
  },
  // {
  //   titulo: 'Unidade Embarque',
  //   rota: '/unidade-embarque',
  //   icone: <TbIcons.TbTruckLoading />,
  //   nomeClasse: 'nav-text-comum',
  //   usoComum: 'usoComum',
  //   index: 17
  // },
  {
    titulo: 'Motivo',
    rota: '/motivo',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Taxa ICMS',
    rota: '/taxa-icms',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Motivo On Time',
    rota: '/motivo-ontime',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Tipo Veículo',
    rota: '/tipo-veiculo',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Tipo Carroceria',
    rota: '/tipo-carroceria',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Motivo Cotação',
    rota: '/motivo-cotacao',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Segmento Cliente',
    rota: '/segmento-cliente',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Ocorrência',
    rota: '/ocorrencia',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  {
    titulo: 'Sub Tipo Ocorrência',
    rota: '/tipo-ocorrencia',
    icone: <TbIcons.TbTruckLoading />,
    nomeClasse: 'nav-text-comum',
    usoComum: 'usoComum',
    index: 18
  },
  // {
  //   titulo: 'Sub Tipo Ocorrência',
  //   rota: '/sub-tipo-ocorrencia',
  //   icone: <TbIcons.TbTruckLoading />,
  //   nomeClasse: 'nav-text-comum',
  //   usoComum: 'usoComum',
  //   index: 18
  // },
  {
    titulo: 'Dashboards',
    nomeClasse: 'nav-text',
    icone: <img src={iconeDash} />,
    dashboard: 'dashboardBotao',
    index: 10
  },
  {
    titulo: 'Dashboard',
    rota: '/dashboard',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    dashboard: 'dashboard',
    index: 11
  },
  {
    titulo: 'Dashboard Cockpit',
    rota: '/dashboard-cockpit',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    dashboard: 'dashboard',
    index: 12
  },
  {
    titulo: 'Painel',
    rota: '/painel',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    dashboard: 'dashboard',
    index: 13
  },
  {
    titulo: 'Responsáveis',
    rota: '/responsaveis',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    dashboard: 'dashboard',
    index: 13
  },
  {
    titulo: 'Ocorrências',
    rota: '/dashboard-ocorrencias',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    dashboard: 'dashboard',
    index: 13
  },
  {
    titulo: 'Estatísticas Terceiros',
    rota: '/relatorio-proprietarios',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    dashboard: 'dashboard',
    index: 13
  },
  {
    titulo: 'Precificações',
    nomeClasse: 'nav-text',
    icone: <img src={iconePreco} />,
    precificacao: 'precificacaoBotao',
    index: 10
  },
  {
    titulo: 'Precificação',
    rota: '/precificacao',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    precificacao: 'precificacao',
    index: 11
  },
  {
    titulo: 'Grupo Precificação',
    rota: '/grupo-precificacao',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    precificacao: 'precificacao',
    index: 12
  },
  {
    titulo: 'Cotação',
    nomeClasse: 'nav-text',
    icone: <img src={iconeCotacao} />,
    cotacao: 'cotacaoBotao',
    index: 10
  },
  {
    titulo: 'Cotações',
    rota: '/cotacao',
    icone: <FaIcons.FaCity />,
    nomeClasse: 'nav-text-comum',
    cotacao: 'cotacao',
    index: 11
  },
  {
    titulo: 'Dashboard Cotações',
    rota: '/dashboard-cotacao',
    icone: <BsIcons.BsBoxSeam />,
    nomeClasse: 'nav-text-comum',
    cotacao: 'cotacao',
    index: 12
  },
  {
    titulo: 'On Time',
    icone: <img src={iconeOnTime} />,
    nomeClasse: 'nav-text',
    ontime: 'ontimeBotao',
    index: 6
  },
  {
    titulo: 'Todos',
    rota: '/ontime',
    nomeClasse: 'nav-text-comum',
    ontime: 'ontime',
    index: 6
  },
  {
    titulo: 'Embarque',
    rota: '/ontime-embarque',
    nomeClasse: 'nav-text-comum',
    ontime: 'ontime',
    index: 6
  },
  {
    titulo: 'Entrega',
    rota: '/ontime-entrega',
    nomeClasse: 'nav-text-comum',
    ontime: 'ontime',
    index: 6
  },
  {
    titulo: 'Diária',
    icone: <TodayIcon className="diariaBotao"/>,
    nomeClasse: 'nav-text',
    diaria: 'diariaBotao',
    index: 6
  },
  {
    titulo: 'Diárias',
    rota: '/diaria',
    nomeClasse: 'nav-text-comum',
    diaria: 'diaria',
    index: 6
  },
  {
    titulo: 'Configuração Empresa',
    rota: '/configuracao-empresa',
    nomeClasse: 'nav-text-comum',
    diaria: 'diaria',
    index: 6
  },
  {
    titulo: 'Faturamento',
    rota: '/faturamento',
    icone: <img src={iconeFaturamento} />,
    nomeClasse: 'nav-text',
    index: 6
  },
  {
    titulo: 'Alertas',
    rota: '/alertas',
    icone: <img src={iconeAlerta} />,
    nomeClasse: 'nav-text',
    index: 6
  },
  {
    titulo: 'Sorteio',
    rota: '/sorteio',
    icone: <EmojiEventsIcon className="diariaBotao"/>,
    nomeClasse: 'nav-text',
    index: 6
  },
  // {
  //   titulo: 'Feedback',
  //   rota: '/feedback',
  //   icone: <MdIcons.MdFeedback />,
  //   nomeClasse: 'nav-text',
  //   index: 8
  // },
];
