import { api } from "../ModuloGerenciador/UrlPadrao"

export const getGoogleStoragePorId = async (id, token) => {
  return api.get("GoogleStorage/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const salvaDocs = async (tipoDocumento, doc, id, dataValidade, token) => {
  if (tipoDocumento === 'CnhMotorista') {
    tipoDocumento = 'Cnh';
  }
  return api.post("GoogleStorage/" + tipoDocumento + "/" + id + "/" + dataValidade,
    doc
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteDocs = async (tipoDocumento, id, veiculoDocumentoId, token) => {
  if (tipoDocumento === 'CnhMotorista') {
    tipoDocumento = 'Cnh';
  }
  return api.delete("GoogleStorage/delete/" + tipoDocumento + "/" + id + "/" + veiculoDocumentoId,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteDocsCarreta = async (tipoDocumento, id, carretaDocumentoId, token) => {
  if (tipoDocumento === 'CnhMotorista') {
    tipoDocumento = 'Cnh';
  }
  return api.delete("CarretaDocumento/delete/" + tipoDocumento + "/" + id + "/" + carretaDocumentoId,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const salvaAntt = async (antt, token) => {
  return api.post("GoogleStorage/antt",
    antt
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const salvaComprovanteResidencia = async (comprovanteResidencia, token) => {
  return api.post("GoogleStorage/comprovante_residencia",
    comprovanteResidencia
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const salvaFotoVeiculo = async (fotoVeiculo, token) => {
  return api.post("GoogleStorage/foto_veiculo",
    fotoVeiculo
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const salvaCnh = async (cnh, token) => {
  return api.post("GoogleStorage/cnh",
    cnh
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const salvaCrlv = async (crlv, token) => {
  return api.post("GoogleStorage/crlv",
    crlv
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteGoogleStorage = async (token) => {
  return api.delete("delete",
  {
    headers: {
      'Authorization': token
    }
  })
}