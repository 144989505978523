import { api } from "../ModuloGerenciador/UrlPadrao"

export const getPedidoSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Pedido?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getClienteSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Cliente?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCidadeSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/CidadeEstado?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getVeiculoSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Veiculo?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCaminhaoSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Caminhao?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getCarretaSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Carreta?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getMotoristaSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Motorista?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getProprietarioSearch = async (token, pesquisa) => {
    return api.get("SearchPredicate/search/Proprietario?" + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getEmbarqueSearch = async (token, pesquisa, contexto, dataInicio, dataFim, ordenarPor, cancelado) => {
    return api.get("SearchPredicate/search/Embarque/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + ordenarPor + '/' + cancelado + '?' + pesquisa,
        {
            headers: {
                'Authorization': token,
                'contexto': contexto
            }
        })
}

export const getEmbarqueMesoRegiaSearch = async (token, pesquisa, contexto, dataInicio, dataFim, ordenarPor, cancelado) => {
    return api.get("SearchPredicate/search/EmbarquePorMesoregioes/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + ordenarPor + '/' + cancelado + '?' + pesquisa,
        {
            headers: {
                'Authorization': token,
                'contexto': contexto
            }
        })
}

export const getOcorrenciaSearch = async (token, pesquisa, dataInicio, dataFim, ordenarPor) => {
    return api.get("OcorrenciaEmbarque/ocorrenciasEmbarque/" + dataInicio + '/' + dataFim + '/' + ordenarPor + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getEmbarqueDiariaSearch = async (token, pesquisa, contexto, dataInicio, dataFim, ordenarPor) => {
    return api.get("SearchPredicate/search/EmbarqueDiaria/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + ordenarPor + '?' + pesquisa,
        {
            headers: {
                'Authorization': token,
                'contexto': contexto
            }
        })
}


export const getLocalizadorVeiculos = async (token, tipo, contexto, dataInicio, dataFim, pesquisa) => {
    return api.get("SearchPredicate/search/LocalizadorVeiculos/" + tipo + '/' + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token,
                'contexto': contexto
            }
        })
}

export const getDashEmbarque1Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina1/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashEmbarque2Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina2/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashEmbarque3Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina3/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashEmbarque4Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina4/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashEmbarque5Search = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/pagina7/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getOnTimeSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/OnTime/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getOnTimeEmbarqueSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/OnTime/Embarque/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getOnTimeEntregaSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/OnTime/Entrega/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashCockpitSearch = async (token, pesquisa, contexto, dataInicio, dataFim, grupoClientes) => {
    return api.get("SearchPredicate/cockpit/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + grupoClientes + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getFaturamento = async (token, pesquisa, contexto, dataInicio, dataFim, ordenarPor) => {
    return api.get("SearchPredicate/relatorioValores/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + ordenarPor + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDashResponsaveisSearch = async (token, pesquisa, contexto, dataInicio, dataFim) => {
    return api.get("SearchPredicate/Responsaveis/" + contexto + '/' + dataInicio + '/' + dataFim + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getDiariaVeiculoSearch = async (token, contexto, dataInicio, dataFim, dataInicioDisponivel, dataFimDisponivel, elegivelBrf, pesquisa) => {
    return api.get("diariaVeiculo/" + contexto + '/' + dataInicio + '/' + dataFim + '/' + dataInicioDisponivel + '/' + dataFimDisponivel + '/' + elegivelBrf + '?' + pesquisa,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const getEmbarquesDisponiveis = async (token, contexto, dataInicio, cidadeId, raioKm) => {
    return api.get("diariaVeiculo/buscaEmbarquesDisponivel/" + contexto + '/' + dataInicio + '/' + cidadeId + "/" + raioKm,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const cadastraStatusManual = async (status, token) => {
    return api.post("diariaVeiculo/inserirStatusManual",
        status
        , {
            headers: {
                'Authorization': token
            }
        })
}

export const deleteStatusManual = async (id, token) => {
    return api.delete("diariaVeiculo/removerStatusManual/" + id,
        {
            headers: {
                'Authorization': token
            }
        })
}

export const ocultarVeiculo = async (id, token) => {
    return api.post("diariaVeiculo/ocultarVeiculo/" + id,
        null
        , {
            headers: {
                'Authorization': token
            }
        })
}