import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalStatusManual/ModalStatusManual.css';
import CoresRedux from "../../contexts/Cor";
import TabelaRedux from "../../contexts/Tabela";
import CustomButton from "../CustomButton/CustomButton";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";
import SelectEmbarqueV2 from "../SelectEmbarqueV2/SelectEmbarqueV2";
import { cadastraStatusManual } from "../../services/ModuloGerenciador/Search";
import DadosUsuarioRedux from "../../contexts/Usuario";
import { FormControl } from "@mui/material";
import ReactSelect from 'react-select';
import ClearIcon from '@mui/icons-material/Clear';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    dataAlteracao: string;
    open: boolean;
    listaVeiculos: any[];
    listaCidade: any[];
    listaStatus: any[];
    onClose: () => void;
    onClick: () => void;
}

function ModalStatusManual(props: SimpleDialogProps) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { tabelaRedux } = useContext(TabelaRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const handleClick = () => {
        enviarStatusManual();
    };

    const enviarStatusManual = async () => {
        if (values.cidadeId && values.veiculoId && values.data && values.status) {
            await cadastraStatusManual(values, dadosUsuarioRedux.token).then(() => {
                handleClose();
            });
        }
    }

    const data = tabelaRedux.dataAlteracao;
    const formatDate = (data) => {
        return new Date(data).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' });
    }

    const [values, setValues] = useState<any>([]);

    const [valuesSelect, setValuesSelect] = useState<any>([]);

    const clearStatus = () => {
        setValuesSelect(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, status: '' }));
        setValues(current => ({ ...current, statusId: null }));
    };

    const opcoesCidadeEstado = props.listaCidade.map((data) => ({
        value: data.idCidadeEstado,
        label: `${data.cidade} - ${data.estado}`,
        ...data
    }));

    const labelCidadeEstado = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.cidade} - {data.estado}
        </div>
    );

    const clearCidade = () => {
        setValues((current) => ({
            ...current,
            cidadeEstadoId: null,
        }));
        setValuesSelect(current => ({ ...current, cidadeEstadoId: '' }));
    };

    const estiloCidadeEstado = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: '180px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    useEffect(() => {
        setValues(current => ({ ...current, status: 'disp em' }));
        setValues(current => ({ ...current, disponivel: false }));
    }, [])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <div className='div-modal-status-manual'>
                    <DialogTitle id="alert-dialog-title">
                        {props.titulo}
                    </DialogTitle>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '20px', marginBottom: '10px' }}>
                        <div>
                            <p className="input-label-emb">Veículo:</p>
                            <SelectEmbarqueV2
                                classeCss={180}
                                onChange={(e) => {
                                    setValuesSelect(current => ({ ...current, veiculoId: e }));
                                    setValues(current => ({ ...current, veiculoId: e.id }));
                                }}
                                pequeno={true}
                                id="idVeiculo"
                                value={valuesSelect.veiculoId}
                                titulo="Veículo"
                                dadosSelect={props.listaVeiculos}
                                referenciaId="caminhao"
                                referenciaItem="caminhao"
                                referenciaItem2="placa"
                                info={false} />
                        </div>
                        <div>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={values.disponivel}
                                    onChange={(e) => {
                                        if (e.target.checked === true) {
                                            setValues(current => ({ ...current, disponivel: e.target.checked }));
                                            setValues(current => ({ ...current, status: 'disp em' }));
                                        }
                                        else {
                                            setValues(current => ({ ...current, status: values.cidade }));
                                            setValues(current => ({ ...current, disponivel: e.target.checked }));
                                        }
                                    }}
                                />} label={'Disponível?'} />
                        </div>
                        <div>
                            <p className="input-label-emb">Cidade:</p>
                            <FormControl sx={{ width: 180 }}>
                                <ReactSelect
                                    value={valuesSelect.cidadeEstadoId}
                                    options={opcoesCidadeEstado}
                                    formatOptionLabel={labelCidadeEstado}
                                    styles={estiloCidadeEstado}
                                    placeholder="Selecione"
                                    noOptionsMessage={() => "Não há mais opções"}
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            if (values.disponivel === false) {
                                                setValues(current => ({ ...current, status: selectedOption.label }));
                                            }
                                            setValues((current) => ({
                                                ...current,
                                                cidade: selectedOption.label,
                                            }));
                                            setValues((current) => ({
                                                ...current,
                                                cidadeId: selectedOption.idCidadeEstado,
                                            }));
                                            setValuesSelect(current => ({ ...current, cidadeEstadoId: selectedOption }));
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <p className="input-label-emb">Data:</p>
                            <input
                                type="datetime-local"
                                className="input-emb"
                                style={{ width: '180px' }}
                                onChange={(e) => {
                                    setValues(current => ({ ...current, data: e.target.value }));
                                }}
                                value={values.data} />
                        </div>
                    </div>
                    <DialogActions>
                        <CustomButtonV2
                            classCss="custom-button-cancelar"
                            classText="button-texto-cancelar"
                            titulo="CANCELAR"
                            cor="#0946B8"
                            onClick={handleClose}
                        />
                        <CustomButtonV2
                            classCss="custom-button-cadastrar"
                            classText="button-texto-cadastrar"
                            titulo="OK"
                            cor="#0946B8"
                            onClick={handleClick}
                        />
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ModalStatusManual;