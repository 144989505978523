import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./Checkagem.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getCheckagem, cadastraCheckagem, editaCheckagemPorId, getCheckagemPorId, deleteCheckagemPorId } from "../../../services/ModuloChecklist/Checkagem";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { Box, FormControl, LinearProgress, MenuItem, Select } from "@mui/material";
import SelectEmbarque from "../../../components/SelectEmbarque/SelectEmbarque";
import TextLink from "../../../components/TextLink/TextLink";
import { getTipoCheckagem } from "../../../services/ModuloChecklist/TipoCheckagem";
import { getGeralCheck } from "../../../services/ModuloChecklist/Geral";
import { valorMask } from "../../../regex/valorMask";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import ReactSelect from 'react-select';
import NewIcon from "../../../components/NewIcon/NewIcon";
import LoadingBar from "../../../components/LoadingBar/LoadingBar";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2";

function Checkagem(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [listaTipoCheck, setListaTipoCheckagem] = useState([]);

    const [tipoManutencao, setTipoNotificacao] = useState(['Preventiva', 'Corretiva']);

    const [disable, setDisable] = useState(false);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [loadingSelectTipoCheck, setLoadingSelectTipoCheck] = useState(false);

    const [openCadastroTipoCheck, setOpenCadastroTipoCheck] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const handleCloseCadastroTipoCheck = (value) => {
        if (idAtual) {
            setOpenCadastroTipoCheck(false);
        } else {
            setLoadingSelectTipoCheck(true);
            buscarCheckagemSemLoading();
            setOpenCadastroTipoCheck(false);
        }
    };

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const buscarCheckagem = async () => {
        setLoading(true);
        await getGeralCheck(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data.checkListTemplete);
            setListaTipoCheckagem(result.data.tipoCheck);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCheckagemSemLoading = async () => {
        await getGeralCheck(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data.checkListTemplete);
            setListaTipoCheckagem(result.data.tipoCheck);
            setTabelaRedux({});
            setLoadingSelectTipoCheck(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const cadastrar = async () => {
        if ((values.tipoManutencao !== "") && (validator.tipoManutencao === "true") &&
            (values.dados !== "") && (validator.dados === "true") &&
            (values.tipoCheck !== "") && (validator.tipoCheck === "true") &&
            (values.km !== "") && (validator.km === "true") &&
            (values.local !== "") && (validator.local === "true") &&
            (values.dataCheckagem !== "") && (validator.dataCheckagem === "true")) {
            setLoading(true);
            await cadastraCheckagem(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarCheckagem();
                    setDadosRequestControllerRedux("Fechar Modal Checkagem");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaCheckagemPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCheckagem();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const preencherDatas = async (result) => {
        var todayEm = new Date(result.dataCheckagem);
        var ddEm = String(todayEm.getDate()).padStart(2, '0');
        var mmEm = String(todayEm.getMonth() + 1).padStart(2, '0');
        var yyyyEm = todayEm.getFullYear();
        todayEm = yyyyEm + '-' + mmEm + '-' + ddEm;
        setValues(current => ({ ...current, dataCheckagem: todayEm }));
    };

    const preencherDadosEditando = async (result) => {
        let tipoManutencao;
        tipoManutencao = result.data.tipoManutencao;
        if (tipoManutencao) {
            setValuesSelect(current => ({
                ...current,
                tipoManutencao: {
                    value: tipoManutencao,
                    opcao: tipoManutencao,
                    label: tipoManutencao
                },
            }));
            setValues(current => ({ ...current, tipoManutencao: tipoManutencao }));
        }
        const tipoCheckSelecionado = listaTipoCheck.find(option => option.idTipoCheck === result.data.tipoCheckId);
        if (tipoCheckSelecionado) {
            setValuesSelect(current => ({
                ...current,
                tipoCheckId: {
                    value: tipoCheckSelecionado.nome,
                    label: tipoCheckSelecionado.nome,
                    id: tipoCheckSelecionado.idTipoCheck
                }
            }));
        }
    };

    const buscarCheckagemPorId = async () => {
        setLoading(true);
        await getCheckagemPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(result.data);
                    await preencherDatas(result.data);
                    await preencherDadosEditando(result);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarCheckagemPorIdModal = async (id) => {
        setLoading(true);
        await getCheckagemPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
                await preencherDatas(result.data);
                await preencherDadosEditando(result);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarCheckagemPorId = async () => {
        setLoading(true);
        await deleteCheckagemPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCheckagem();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, tipoManutencao: "false" }));
        setValidator(current => ({ ...current, dados: "false" }));
        setValidator(current => ({ ...current, tipoCheck: "false" }));
        setValidator(current => ({ ...current, km: "false" }));
        setValidator(current => ({ ...current, local: "false" }));
        setValidator(current => ({ ...current, dataCheckagem: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarCheckagemPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarCheckagem();
        if (props.idAtual) {
            buscarCheckagemPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        var todayEm = new Date();
        var ddEm = String(todayEm.getDate()).padStart(2, '0');
        var mmEm = String(todayEm.getMonth() + 1).padStart(2, '0');
        var yyyyEm = todayEm.getFullYear();
        todayEm = yyyyEm + '-' + mmEm + '-' + ddEm;
        setValues(current => ({ ...current, dataCheckagem: todayEm }));
        setValidator(current => ({ ...current, dataCheckagem: "true" }));
    }, [telaCadastro]);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Gerenciamento de Frota Configurações') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Gerenciamento de Frota Configurações') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const opcoesTipoManutencao = [
        {
            'opcao': 'Preventiva',
        },
        {
            'opcao': 'Corretiva',
        },
    ];

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = opcoesTipoManutencao.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const clearTipoCheckagem = () => {
        setValuesSelect(current => ({ ...current, tipoCheckId: '' }));
        setValues(current => ({ ...current, tipoCheckId: null }));
        setValidator(current => ({ ...current, tipoCheck: "false" }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Checkagem" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Checkagem" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Checkagem</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idCheck"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.Checkagem}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Checkagem / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Checkagem"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Checkagem"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Checkagem" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Checkagem" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Tipo de Manutenção:</p>
                                                                <FormControl sx={{ width: 250 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.tipoManutencao}
                                                                        options={opcoesFrota}
                                                                        formatOptionLabel={labelFrota}
                                                                        styles={estiloFrota}
                                                                        placeholder=""
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption.value === 'Preventiva') {
                                                                                setValuesSelect(current => ({ ...current, tipoManutencao: selectedOption }));
                                                                                setValues(current => ({ ...current, tipoManutencao: 'Preventiva' }));
                                                                                setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                            } else {
                                                                                setValues(current => ({ ...current, tipoManutencao: 'Corretiva' }));
                                                                                setValuesSelect(current => ({ ...current, tipoManutencao: selectedOption }));
                                                                                setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {/* <Select
                                                                    className='input-emb'
                                                                    variant="standard"
                                                                    id="tipoManutencao"
                                                                    style={{ width: 250 }}
                                                                    value={values.tipoManutencao}
                                                                    disabled={props.desabilitar}
                                                                >
                                                                    {tipoManutencao.map((data) => (
                                                                        <MenuItem
                                                                            key={data}
                                                                            value={data}
                                                                            onClick={() => {
                                                                                if (data === 'Preventiva') {
                                                                                    setValues(current => ({ ...current, tipoManutencao: 'Preventiva' }));
                                                                                    setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                                } else {
                                                                                    setValues(current => ({ ...current, tipoManutencao: 'Corretiva' }));
                                                                                    setValidator(current => ({ ...current, tipoManutencao: "true" }));
                                                                                }
                                                                            }}
                                                                        >
                                                                            {data}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select> */}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Local:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, local: e.target.value }));
                                                                        setValidator(current => ({ ...current, local: "true" }));
                                                                    }}
                                                                    placeholder="Ex: Toledo"
                                                                    value={values.local}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {
                                                                    loadingSelectTipoCheck ? (
                                                                        <LoadingBar />
                                                                    ) : (
                                                                        <div className="coluna-emb">
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Tipo Checkagem:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={210}
                                                                                    onChange={(e) => {
                                                                                        setValuesSelect(current => ({ ...current, tipoCheckId: e }));
                                                                                        setValues(current => ({ ...current, tipoCheckId: e.id }));
                                                                                        setValidator(current => ({ ...current, tipoCheck: "true" }));
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroTipoCheck(true);
                                                                                    }}
                                                                                    clearSelection={clearTipoCheckagem}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="idTipoCheck"
                                                                                    grande={true}
                                                                                    value={valuesSelect.tipoCheckId}
                                                                                    titulo="TipoCheck"
                                                                                    dadosSelect={listaTipoCheck}
                                                                                    referenciaId="nome"
                                                                                    referenciaItem="nome" />
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroTipoCheck(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">KM:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, km: e.target.value }));
                                                                        setValidator(current => ({ ...current, km: "true" }));
                                                                    }}
                                                                    placeholder="Ex: 100000"
                                                                    value={valorMask(values.km)}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Data Checkagem:</p>
                                                                <input
                                                                    type="date"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, dataCheckagem: e.target.value }));
                                                                        setValidator(current => ({ ...current, dataCheckagem: "true" }));
                                                                    }}
                                                                    value={values.dataCheckagem}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Observação:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, observacao: e.target.value }));
                                                                        setValidator(current => ({ ...current, observacao: "true" }));
                                                                    }}
                                                                    value={values.observacao}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Dados:</p>
                                                                <textarea
                                                                    type="text"
                                                                    className="input-emb-avisos"
                                                                    style={{ width: '100vh' }}
                                                                    rows="10"
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, dados: e.target.value }));
                                                                        setValidator(current => ({ ...current, dados: "true" }));
                                                                    }}
                                                                    value={values.dados}
                                                                    disabled={props.desabilitar}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-checkagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({}); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.tipoManutencao !== "") && (validator.tipoManutencao === "true") &&
                                                                                    (values.dados !== "") && (validator.dados === "true") &&
                                                                                    (values.tipoCheck !== "") && (validator.tipoCheck === "true") &&
                                                                                    (values.km !== "") && (validator.km === "true") &&
                                                                                    (values.local !== "") && (validator.local === "true") &&
                                                                                    (values.dataCheckagem !== "") && (validator.dataCheckagem === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.tipoManutencao !== "") && (validator.tipoManutencao === "true") &&
                                                                                    (values.dados !== "") && (validator.dados === "true") &&
                                                                                    (values.tipoCheck !== "") && (validator.tipoCheck === "true") &&
                                                                                    (values.km !== "") && (validator.km === "true") &&
                                                                                    (values.local !== "") && (validator.local === "true") &&
                                                                                    (values.dataCheckagem !== "") && (validator.dataCheckagem === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarCheckagemPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroTipoCheck="sim"
                                        open={openCadastroTipoCheck}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroTipoCheck}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Checkagem;