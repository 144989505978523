import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./Motorista.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { getUserClientes, verificaDadosUsuarioRedux, vincularUsuario } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getMotorista, cadastraMotorista, editaMotoristaPorId, getMotoristaPorId, deleteMotoristaPorId, gerarUsuarioMotorista } from "../../../services/ModuloGerenciador/Motorista";
import { VerificaExistenciaMotorista } from "../../../services/ModuloGerenciador/VerificaExistencia";
import { MotoristaModel } from "../../../models/MotoristaModels";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import ReactSelect from 'react-select';
import { FormControl } from "@mui/material";
import { telefoneMask } from "../../../regex/telefoneMask";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2";
import { getMotoristaSearch } from "../../../services/ModuloGerenciador/Search";
import ModalUsuarioMotorista from "../../../components/ModalUsuarioMotorista/ModalUsuarioMotorista.tsx";
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";
import ModalVincular from "../../../components/ModalVincular/ModalVincular.tsx";
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import { useLocation } from "react-router-dom";
import FiltrosRedux from "../../../contexts/Filtros.js";
import TipoObservacao from "../../ModuloGerenciador/UsoComum/TipoObservacao/TipoObservacao.js"
import TipoContatoProp from "../UsoComum/TipoContatoProp/TipoContatoProp.js";

function Motorista(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [dadosUsuarioMotorista, setDadosUsuarioMotorista] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState(new MotoristaModel({}));

    const [valuesUsuario, setValuesUsuario] = useState([]);

    const [validator, setValidator] = useState([]);

    const [valuesSelect, setValuesSelect] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [motoristaValido, setMotoristaValido] = useState(true);

    const [frota, setFrota] = useState(['Sim', 'Não']);

    const [verificaMotivo, setVerificaMotivo] = useState(true);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [openUsuarioMotorista, setOpenUsuarioMotorista] = useState(false);

    const handleCloseUsuarioMotorista = (value) => {
        setOpenUsuarioMotorista(false);
        setOpenConfirmar(false);
        buscarMotorista();
    };

    const abrirConfirmar = (value) => {
        setOpenConfirmar(true);
    };

    const [openConfirmar, setOpenConfirmar] = useState(false);

    const handleCloseConfirmar = (value) => {
        setOpenConfirmar(false);
    };

    const clickGerar = () => {
        gerarUsuario();
    };

    const [listaUsuarios, setListaUsuario] = useState([]);

    const [usuarioId, setUsuarioId] = useState();

    const [nomeUsuario, setNomeUsuario] = useState();

    const [openGerar, setOpenGerar] = useState(false);

    const handleCloseGerar = (value) => {
        setOpenGerar(false);
    };

    const preencherDadosEditando = async (result) => {
        let tipoDocumento;
        if (result.tipoDocumento === 'CPF') {
            tipoDocumento = "CPF";
        } else if (result.tipoDocumento === 'CNH') {
            tipoDocumento = "CNH"
        }
        const tipoDocumentoSelecionado = opcoesFrota.find(option =>
            option.value === tipoDocumento
        );
        if (tipoDocumentoSelecionado) {
            setValuesSelect(current => ({ ...current, tipoDocumento: tipoDocumentoSelecionado }));
        }
    };

    const buscarMotorista = async () => {
        setLoading(true);
        await getMotorista(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const verificadorExistenciaMotorista = async (motorista) => {
        if (motorista !== null && motorista !== '') {
            await VerificaExistenciaMotorista(dadosUsuarioRedux.token, motorista).then((result) => {
                if (result.data === true) {
                    setTextoSucesso("Motorista ainda não cadastrado.");
                    setOpenToastSucesso(true);
                    setMotoristaValido(true);
                } else {
                    setTextoErro("Motorista já cadastrado com esse nome.");
                    setOpenToastErro(true);
                    setMotoristaValido(false);
                }
            }).catch(() => {
                setTextoErro("Motorista já cadastrado com esse nome.");
                setOpenToastErro(true);
                setMotoristaValido(false);
            });
        }
    }

    const buscarUsuariosClientes = async () => {
        await getUserClientes(dadosUsuarioRedux.token).then((result) => {
            setListaUsuario(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const gerarUsuario = async () => {
        const usuarioVinculo = {
            "motoristaId": tabelaRedux.colunaSelecionada,
            "usuarioClientesId": usuarioId
        }
        await vincularUsuario(usuarioVinculo, dadosUsuarioRedux.token).then((result) => {
            setOpenGerar(false);
            buscarMotorista();
            setTextoSucesso("Vinculado Com Sucesso");
            setOpenToastSucesso(true);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const cadastrar = async () => {
        if ((values.nome !== "" || values.telefone !== "") && (validator.nome === "true" && validator.telefone === "true")) {
            setLoading(true);
            await cadastraMotorista(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    setValues({});
                    setValuesSelect({});
                    setTelaCadastro(false);
                    if (valuesPesquisa.pesquisa === undefined) {
                        buscarMotorista();
                    } else {
                        buscarSearch();
                    }
                    setDadosRequestControllerRedux("Fechar Modal Motorista");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaMotoristaPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (valuesPesquisa.pesquisa === undefined) {
                    buscarMotorista();
                } else {
                    buscarSearch();
                }
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const [motoristaId, setMotoristaId] = useState(0);

    const buscarMotoristaPorId = async () => {
        setLoading(true);
        await getMotoristaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result.data);
                    setValues(result.data);
                    setMotoristaId(result.data.idMotorista);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarMotoristaPorIdModal = async (id) => {
        setLoading(true);
        await getMotoristaPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                await preencherDadosEditando(result.data);
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarMotoristaPorId = async () => {
        setLoading(true);
        await deleteMotoristaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarMotorista();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        setValidator(current => ({ ...current, telefone: "false" }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [telaCadastro]);

    useEffect(() => {
        if (editando) {
            buscarMotoristaPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarMotorista();
        buscarUsuariosClientes();
    }, []);

    useEffect(() => {
        buscarMotorista();
        if (props.idAtual) {
            buscarMotoristaPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoGerarLogin, setSemAutorizacaoGerarLogin] = useState(true);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
            let regras = [];
            result.data.forEach((dado) => {
                regras.push(dado.regras.regra)
                if (dado.regras.regra === 'Motorista') {
                    setSemAutorizacao(false);
                }
                if (dado.regras.regra === 'Gerar Login') {
                    setSemAutorizacaoGerarLogin(false);
                }
            });
            dadosUsuarioRedux.regras = regras;
            setDadosUsuarioRedux(dadosUsuarioRedux);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }, []);

    const opcoes = [
        {
            'opcao': 'CPF',
        },
        {
            'opcao': 'CNH',
        },
    ];

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const itensFiltro = [
        {
            'nomeColuna': 'Nome Motorista',
            'field': 'nome'
        },
        {
            'nomeColuna': 'Número Documento',
            'field': 'numeroDocumento'
        },
        {
            'nomeColuna': 'Tipo Documento',
            'field': 'tipoDocumento'
        },
        {
            'nomeColuna': 'Telefone',
            'field': 'telefone'
        },
        {
            'nomeColuna': 'Pessoa Emergência',
            'field': 'nomeEmergencia'
        },
        {
            'nomeColuna': 'Telefone Emergência',
            'field': 'telefoneEmergencia'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        }
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getMotoristaSearch(dadosUsuarioRedux.token, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Motoristas" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Motoristas" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Motoristas</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idMotorista"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Motorista}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {semAutorizacaoGerarLogin ? (
                                                    <></>
                                                ) : (
                                                    <div>
                                                        <CustomButtonV2
                                                            classCss="custom-button-vincular"
                                                            classText="button-texto-user"
                                                            titulo="Vincular Usuário"
                                                            cor="#FFFFFF"
                                                            valor={values}
                                                            onClick={() => {
                                                                if (tabelaRedux.colunaSelecionada) {
                                                                    setOpenGerar(true);
                                                                } else {
                                                                    setTextoErro("Selecione Uma Coluna");
                                                                    setOpenToastErro(true);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Motorista / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Motorista"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Motorista"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Motorista" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Motorista" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                <input
                                                                    type="text"
                                                                    className={motoristaValido ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nome: e.target.value }));
                                                                        setValidator(current => ({ ...current, nome: "true" }));
                                                                    }}
                                                                    placeholder="Luiz Carlos"
                                                                    maxLength={24}
                                                                    onBlur={(e) => { verificadorExistenciaMotorista(e.target.value) }}
                                                                    value={values.nome}
                                                                    disabled={props.desabilitar} />
                                                                {!motoristaValido && <div className="error-text">Motorista já existe</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, telefone: e.target.value }));
                                                                        setValidator(current => ({ ...current, telefone: "true" }));
                                                                    }}
                                                                    placeholder="(99)99999-9999"
                                                                    value={telefoneMask(values.telefone)}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Número Documento:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => setValues(current => ({ ...current, numeroDocumento: e.target.value }))}
                                                                    placeholder="999-999-999-99"
                                                                    value={values.numeroDocumento}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Tipo Documento:</p>
                                                                <FormControl sx={{ width: 270 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.tipoDocumento}
                                                                        options={opcoesFrota}
                                                                        formatOptionLabel={labelFrota}
                                                                        styles={estiloFrota}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    tipoDocumento: selectedOption.opcao,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, tipoDocumento: selectedOption }));
                                                                                setValidator((current) => ({
                                                                                    ...current,
                                                                                    tipoDocumento: "true",
                                                                                }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Contato de Emergência:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => setValues(current => ({ ...current, nomeEmergencia: e.target.value }))}
                                                                    placeholder="Irmão"
                                                                    value={values.nomeEmergencia}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Telefone de Emergência:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '270px' }}
                                                                    onChange={(e) => setValues(current => ({ ...current, telefoneEmergencia: e.target.value }))}
                                                                    placeholder="(99)99999-9999"
                                                                    value={telefoneMask(values.telefoneEmergencia)}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="flex">
                                                                <input
                                                                    className="checkcheck"
                                                                    type="checkbox"
                                                                    checked={values.frota}
                                                                    disabled={props.desabilitar}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, frota: e.target.checked }));
                                                                    }}
                                                                ></input>
                                                                <InputLabel htmlFor="status"><Text texto="Frota" /></InputLabel>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="flex">
                                                                <input
                                                                    type="checkbox"
                                                                    className="checkcheck"
                                                                    checked={values.bloqueado}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked === true) {
                                                                            setVerificaMotivo(false);
                                                                        }
                                                                        else {
                                                                            setVerificaMotivo(true);
                                                                        }
                                                                        setValues(current => ({ ...current, bloqueado: e.target.checked }));
                                                                    }}
                                                                ></input>
                                                                <InputLabel htmlFor="status"><Text texto="Bloqueado" /></InputLabel>
                                                            </div>
                                                        </div>
                                                        {
                                                            values.bloqueado ? (
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Motivo:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '370px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, motivo: e.target.value }));
                                                                            }}
                                                                            placeholder=""
                                                                            value={values.motivo}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-motora">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); setValuesSelect({}); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.telefone !== "")
                                                                                    && (validator.nome === "true" && validator.telefone === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.telefone !== "")
                                                                                    && (validator.nome === "true" && validator.telefone === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                            {editando && (
                                                <>
                                                    <div style={{
                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                        borderRadius: "8px",
                                                        padding: "16px",
                                                        backgroundColor: "#fff",
                                                        margin: "16px",
                                                    }}>
                                                        <TipoObservacao
                                                            navbar='nao'
                                                            botaoCadastrar='nao'
                                                            telaCadastro={false}
                                                            idAtual={props.idAtual}
                                                            desabilitar={props.desabilitar}
                                                            motoristaId={motoristaId}
                                                        />
                                                    </div>
                                                    <div style={{
                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                        borderRadius: "8px",
                                                        padding: "16px",
                                                        backgroundColor: "#fff",
                                                        margin: "16px",
                                                    }}>
                                                        <TipoContatoProp
                                                            navbar='nao'
                                                            botaoVoltar='nao'
                                                            botaoCadastrar='nao'
                                                            telaCadastro={false}
                                                            idAtual={props.idAtual}
                                                            desabilitar={props.desabilitar}
                                                            motoristaId={motoristaId}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarMotoristaPorId}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Deseja Fechar? "
                                        texto="Ao fechar esse modal não será mais possivel visalizar a senha."
                                        textoBotao="Sim"
                                        open={openConfirmar}
                                        onClose={handleCloseConfirmar}
                                        onClick={handleCloseUsuarioMotorista}
                                    />
                                    <ModalVincular
                                        titulo="Vincular Usuário"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={openGerar}
                                        onClose={handleCloseGerar}
                                        onClick={clickGerar}
                                        listaUsuarios={listaUsuarios}
                                        setUsuarioId={setUsuarioId}
                                        setNomeUsuario={setNomeUsuario}
                                        referenciaId="nome"
                                        referenciaItem="nome"
                                        id="id"
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalUsuarioMotorista
                                        titulo="Login Motorista"
                                        tituloConfirmar="OK"
                                        open={openUsuarioMotorista}
                                        onClose={abrirConfirmar}
                                        dados={dadosUsuarioMotorista}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div >
            )
            }
        </motion.div >
    );
}

export default Motorista;