import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3.js";
import InputLabel from "@mui/material/InputLabel";
import "./ConfiguracaoEmpresa.css";
import CustomButton from "../../../components/CustomButton/CustomButton.js";
import CoresRedux from "../../../contexts/Cor.js";
import NomeTabelaRedux from "../../../contexts/NomeTabela.js";
import DadosUsuarioRedux from "../../../contexts/Usuario.js";
import Tabela from "../../../components/Tabela/Tabela.js";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela.js";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario.js";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent.js";
import { getConfiguracaoEmpresa, cadastraConfiguracaoEmpresa, deleteConfiguracaoEmpresaPorId, getConfiguracaoEmpresaPorId, editaConfiguracaoEmpresaPorId } from "../../../services/ModuloGerenciador/ConfiguracaoEmpresa.js";
import Text from "../../../components/Text/Text.js";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao.js";
import { getGrupoCargaDependencias, getRegras } from "../../../services/ModuloGerenciador/Geral.js";
import RequestControllerRedux from "../../../contexts/RequestController.js";
import { motion } from "framer-motion";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2.js";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2.js";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro.js";
import CloseIcon from "../../../components/CloseIcon/CloseIcon.js";
import { Checkbox, FormControl, FormControlLabel, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ModalConfirmacao2 from "../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2.js";
import LoadingBar from "../../../components/LoadingBar/LoadingBar.js";
import NewIcon from "../../../components/NewIcon/NewIcon.js";
import { getProprietario } from "../../../services/ModuloGerenciador/Proprietario.js";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import { telefoneMask } from "../../../regex/telefoneMask.js";
import { cpfCnpj } from "../../../regex/cpfCnpj.js";
import { getGrupoClientes } from "../../../services/ModuloGerenciador/GrupoClientes.js";
import Select from 'react-select';

function ConfiguracaoEmpresa(props) {

    const opcoes = [
        {
            'opcao': 'Texto',
            'valor': 1
        },
        {
            'opcao': 'Numérico',
            'valor': 2
        },
        {
            'opcao': 'Verdadeiro ou Falso',
            'valor': 3
        }
    ];

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '180px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px',
        }),
    }

    const acoesData = [
        {
            titulo: 'Ver Colunas',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastro(true);
                setEditando(true);
                setVerColunas(true);
            },
        },
        {
            titulo: 'Gerar Cupons',
            onClick: () => {
                setOpcoesOpen(false);
                setOpenCupons(true);
            },
        },
        {
            titulo: 'Sortear',
            onClick: () => {
                setOpcoesOpen(false);
                setOpenConfiguracaoEmpresa(true);
            },
        },
        {
            titulo: 'Ver Cupons',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastro(true);
                setVerCupons(true);
            },
        },
        {
            titulo: 'Cadastrar Cupom',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastroCupom(true);
                setTelaCadastro(true);
                setValues(current => ({ ...current, configuracaoEmpresaId: tabelaRedux.colunaSelecionada }));
            },
        },
        {
            titulo: 'Ver Vencedores',
            onClick: () => {
                setOpcoesOpen(false);
                setTelaCadastro(true);
                setVerVencedores(true);
            },
        },
    ];

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [telaCadastroCupom, setTelaCadastroCupom] = useState(false);

    const [editando, setEditando] = useState(false);

    const [verColunas, setVerColunas] = useState(false);

    const [verCupons, setVerCupons] = useState(false);

    const [verVencedores, setVerVencedores] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [cupons, setCupons] = useState([]);

    const [vencedores, setVencedores] = useState([]);

    const [colunas, setColunas] = useState([]);

    const adicionarColunas = () => {
        setColunas([
            ...colunas,
            {
                nomeColuna: "",
                tipoColuna: 0,
            },
        ]);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...colunas];
        list[index][name] = value;
        setColunas(list);
    };

    const handleInput2Change = (selectedOption, index) => {
        const list = [...colunas];
        list[index].tipoColuna = selectedOption;
        setColunas(list);
    };

    useEffect(() => {
        if (!telaCadastroCupom) {
            setValues(prevValues => ({
                ...prevValues,
                colunas,
            }));
        } else {
            setValues(current => ({ ...current, embarqueId: 0 }));
        }
    }, [colunas]);

    const removePremio = (indexToRemove) => {
        const newList = colunas.filter((_, index) => index !== indexToRemove);
        setColunas(newList);
    };

    const [openConfiguracaoEmpresa, setOpenConfiguracaoEmpresa] = useState(false);

    const handleCloseConfiguracaoEmpresa = (value) => {
        setOpenConfiguracaoEmpresa(false);
    };

    const [openCupons, setOpenCupons] = useState(false);

    const handleCloseCupons = (value) => {
        setOpenCupons(false);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const [idAtual, setIdAtual] = useState();

    const [loadingSelectGrupoCliente, setLoadingSelectGrupoCliente] = useState(false);

    const [openCadastroGrupoCliente, setOpenCadastroGrupoCliente] = useState(false);

    const handleCloseCadastroGrupoCliente = (value) => {
        if (idAtual) {
            setOpenCadastroGrupoCliente(false);
        } else {
            setLoadingSelectGrupoCliente(true);
            buscarGrupoClientes();
            setOpenCadastroGrupoCliente(false);
        }
    };

    const [loadingSelectProprietario, setLoadingSelectProprietario] = useState(false);

    const [openCadastroProprietario, setOpenCadastroProprietario] = useState(false);

    const handleCloseCadastroProprietario = (value) => {
        if (idAtual) {
            setOpenCadastroProprietario(false);
        } else {
            setLoadingSelectProprietario(true);
            buscarProprietarios();
            setOpenCadastroProprietario(false);
        }
    };

    const navigate = useNavigate();

    const [listaGrupoCliente, setListaGrupoCliente] = useState([]);

    const [listaProprietario, setListaProprietario] = useState([]);

    const buscarConfiguracaoEmpresa = async () => {
        setLoading(true);
        await getConfiguracaoEmpresa(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarGrupoClientes = async () => {
        await getGrupoClientes(dadosUsuarioRedux.token).then((result) => {
            setListaGrupoCliente(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarProprietarios = async () => {
        await getProprietario(dadosUsuarioRedux.token).then((result) => {
            setListaProprietario(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        const dadosParaEnvio = colunas.map(coluna => ({
            ...coluna,
            nomeColuna: coluna.nomeColuna ? coluna.nomeColuna : null,
            tipoColuna: coluna.tipoColuna ? coluna.tipoColuna.valor : null // Extrai apenas o valor
        }));
        const objeto = {
            grupoClienteId: values.grupoClienteId,
            tempoDiariaMinutos: values.tempoDiariaMinutos,
            diariaEmbarque: values.diariaEmbarque,
            diariaEntrega: values.diariaEntrega,
            somaDiarias: values.somaDiarias,
            colunas: dadosParaEnvio,
        }

        if ((values.tempoDiariaMinutos !== "") && (validator.tempoDiariaMinutos === "true")) {
            setLoading(true);
            await cadastraConfiguracaoEmpresa(objeto, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarConfiguracaoEmpresa();
                    setDadosRequestControllerRedux("Fechar Modal ConfiguracaoEmpresa");
                    setTabelaRedux({});
                    setValues({});
                    setTelaCadastro(false);
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setColunas([]);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaConfiguracaoEmpresaPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarConfiguracaoEmpresa();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
                setColunas([]);
                setValuesSelect({}); setTelaCadastro(false); setTelaCadastroCupom(false); setEditando(false); setVerColunas(false); setValues({});
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const preencherDadosEditando = async (result) => {
        const grupoClienteSelecionado = listaGrupoCliente.find(option => option.idGrupoCliente === result.data.grupoClienteId);
        if (grupoClienteSelecionado) {
            setValuesSelect(current => ({
                ...current,
                grupoClienteId: {
                    value: grupoClienteSelecionado.nome,
                    label: grupoClienteSelecionado.nome,
                    id: grupoClienteSelecionado.idGrupoCliente
                }
            }));
        }

        const colunasEditadas = result.data.colunas.map(coluna => {
            const tipoColunaEncontrado = opcoes.find(opcao => opcao.valor === coluna.tipoColuna);

            return {
                nomeColuna: coluna.nomeColuna || "",
                tipoColuna: tipoColunaEncontrado.valor || null,
            };
        });

        setColunas(colunasEditadas);
    };

    const buscarConfiguracaoEmpresaPorId = async () => {
        setLoading(true);
        await getConfiguracaoEmpresaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    preencherDadosEditando(result);
                    setValues(result.data);
                    // setColunas(result.data.colunas);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarConfiguracaoEmpresaPorIdModal = async (id) => {
        setLoading(true);
        await getConfiguracaoEmpresaPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarConfiguracaoEmpresaPorId = async () => {
        setLoading(true);
        await deleteConfiguracaoEmpresaPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarConfiguracaoEmpresa();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);


    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        if (editando) {
            buscarConfiguracaoEmpresaPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarConfiguracaoEmpresa();
        buscarGrupoClientes();
        buscarProprietarios();
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Configuração Diária') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Configuração Diária') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const [valuesSelect, setValuesSelect] = useState([]);
    const [disable, setDisable] = useState(false);
    const [opcoesOpen, setOpcoesOpen] = useState(false);
    const [selecioneOpen, setSelecioneOpen] = useState(false);
    useEffect(() => {
        if (selecioneOpen === true) {
            setTimeout(() => {
                setSelecioneOpen(false);
            }, 1000);
        }
    }, [selecioneOpen]);


    const clearGrupoCliente = () => {
        setValuesSelect(current => ({ ...current, grupoClienteId: '' }));
        setValues(current => ({ ...current, grupoClienteId: null }));
        setValidator(current => ({ ...current, grupoClienteId: "false" }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Configuração Empresa" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Configuração Empresa" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Configuração Empresa</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="id"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.ConfiguracaoEmpresa}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div style={{ display: 'flex' }}>
                                                    {opcoesOpen && (
                                                        <div className="config-dropdown-configuracaoEmpresa2">
                                                            {acoesData.map((acao, index) => {
                                                                return (
                                                                    <p key={index} onClick={acao.onClick}>
                                                                        {acao.titulo}
                                                                    </p>
                                                                );

                                                            })}
                                                        </div>
                                                    )}
                                                    {/* {selecioneOpen &&
                                                        <div className="config-dropdown-configuracaoEmpresa">
                                                            <p>Selecione Uma Coluna</p>
                                                        </div>
                                                    }
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Ações"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setSelecioneOpen(false);
                                                                setVerVencedores(false);
                                                                setVerCupons(false);
                                                                setOpcoesOpen(!opcoesOpen);                                                           
                                                            } else {
                                                                setSelecioneOpen(!selecioneOpen);
                                                            }
                                                        }}
                                                    /> */}
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    {/* <CustomButtonV2
                                                        classCss="custom-button-colunas"
                                                        classText="button-texto-editar"
                                                        titulo="Ver Colunas"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                                setVerColunas(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Gerar Cupons"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenCupons(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Sortear"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpenConfiguracaoEmpresa(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    /> */}

                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        !telaCadastroCupom ? (
                                            <div>
                                                {
                                                    !props.desabilitar ? (
                                                        <div className="indicadores">
                                                            <p className="indicador-emb">{"Dados de Configuração Empresa "}</p>
                                                            {!verColunas && !verCupons && !verVencedores && (
                                                                <p className="indicador-emb">{"/ Cadastro de Configuração Empresa"}</p>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <div className="flex">
                                                    {
                                                        !props.botaoVoltar ? (
                                                            verColunas ? (
                                                                <div className="flex-emb">
                                                                    <HeaderCadastro texto="Colunas" />
                                                                    <CloseIcon onClick={() => {
                                                                        setValues({});
                                                                        setValuesSelect({});
                                                                        setTelaCadastro(false);
                                                                        setTelaCadastroCupom(false);
                                                                        setEditando(false);
                                                                        setVerColunas(false);
                                                                        setTabelaRedux({});
                                                                        setColunas([]);
                                                                    }} />
                                                                </div>
                                                            ) : (
                                                                verCupons ? (
                                                                    <>
                                                                        <div className="flex-emb">
                                                                            <HeaderCadastro texto="Cupons" />
                                                                            <CloseIcon onClick={() => {
                                                                                setValues({});
                                                                                setValuesSelect({});
                                                                                setTelaCadastro(false);
                                                                                setTelaCadastroCupom(false);
                                                                                setEditando(false);
                                                                                setVerCupons(false);
                                                                                setTabelaRedux({});
                                                                                setCupons([]);
                                                                            }} />
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    verVencedores ? (
                                                                        <>
                                                                            <div className="flex-emb">
                                                                                <HeaderCadastro texto="Vencedores" />
                                                                                <CloseIcon onClick={() => {
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                    setTelaCadastroCupom(false);
                                                                                    setEditando(false);
                                                                                    setVencedores(false);
                                                                                    setTabelaRedux({});
                                                                                    setCupons([]);
                                                                                }} />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="flex-emb">
                                                                                {
                                                                                    editando ? (
                                                                                        <HeaderCadastro texto="Detalhes de Configuração Empresa" />
                                                                                    ) : (
                                                                                        <HeaderCadastro texto="Cadastro de Configuração Empresa" />
                                                                                    )
                                                                                }
                                                                                <CloseIcon onClick={() => {
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                    setTelaCadastro(false);
                                                                                    setTelaCadastroCupom(false);
                                                                                    setEditando(false);
                                                                                    setVerColunas(false);
                                                                                    setTabelaRedux({});
                                                                                    setColunas([]);
                                                                                }} />
                                                                            </div>
                                                                        </>
                                                                    )
                                                                )
                                                            )
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                                <div className="flex-column">
                                                    {!verColunas && !verCupons && !verVencedores && (
                                                        <div className="container-select-emb">
                                                            <div className="rota-div-2">
                                                                <div className="coluna-configuracaoEmpresa">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Tempo Diária em Minutos:</p>
                                                                        <input
                                                                            type="number"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, tempoDiariaMinutos: e.target.value }));
                                                                                setValidator(current => ({ ...current, tempoDiariaMinutos: "true" }));
                                                                            }}
                                                                            value={values.tempoDiariaMinutos}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            {
                                                                                loadingSelectGrupoCliente ? (
                                                                                    <LoadingBar />
                                                                                ) : (
                                                                                    <div className="coluna-emb">
                                                                                        <div>
                                                                                            <p className="input-label-emb" htmlFor="descricao">Grupo Cliente:</p>
                                                                                            <SelectEmbarqueV2
                                                                                                classeCss={230}
                                                                                                onChange={(e) => {
                                                                                                    setValuesSelect(current => ({ ...current, grupoClienteId: e }));
                                                                                                    setValues(current => ({ ...current, grupoClienteId: e.id }));
                                                                                                    setValidator(current => ({ ...current, grupoClienteId: "true" }));
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    setDisable(true);
                                                                                                    setOpenCadastroGrupoCliente(true);
                                                                                                }}
                                                                                                clearSelection={clearGrupoCliente}
                                                                                                onInfoIconClick={setIdAtual}
                                                                                                id="idGrupoCliente"
                                                                                                grande={true}
                                                                                                value={valuesSelect.grupoClienteId}
                                                                                                titulo="Grupo Cliente"
                                                                                                dadosSelect={listaGrupoCliente}
                                                                                                referenciaId="nome"
                                                                                                referenciaItem="nome" />
                                                                                        </div>
                                                                                        <NewIcon
                                                                                            onClick={(e) => {
                                                                                                setOpenCadastroGrupoCliente(true); setIdAtual(null); setDisable(false);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-configuracaoEmpresa">
                                                                    <div className="principal">
                                                                        <FormControlLabel control={
                                                                            <Checkbox
                                                                                checked={values.diariaEmbarque}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked === true) {
                                                                                        setValues(current => ({ ...current, diariaEmbarque: true }));
                                                                                    }
                                                                                    else {
                                                                                        setValues(current => ({ ...current, diariaEmbarque: false }));
                                                                                    }
                                                                                }}
                                                                            />} label={'Diária Viagem'} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <FormControlLabel control={
                                                                            <Checkbox
                                                                                checked={values.diariaEntrega}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked === true) {
                                                                                        setValues(current => ({ ...current, diariaEntrega: true }));
                                                                                    }
                                                                                    else {
                                                                                        setValues(current => ({ ...current, diariaEntrega: false }));
                                                                                    }
                                                                                }}
                                                                            />} label={'Diária Entrega'} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <FormControlLabel control={
                                                                            <Checkbox
                                                                                checked={values.somaDiarias}
                                                                                onChange={(e) => {
                                                                                    if (e.target.checked === true) {
                                                                                        setValues(current => ({ ...current, somaDiarias: true }));
                                                                                    }
                                                                                    else {
                                                                                        setValues(current => ({ ...current, somaDiarias: false }));
                                                                                    }
                                                                                }}
                                                                            />} label={'Soma Diárias'} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="container-cupom">
                                                        {cupons && cupons.map((cupom, index) => (
                                                            <div className="cupom" key={index}>
                                                                <h5>Cupom {index + 1}</h5>
                                                                <p>CPF/CNPJ: {cupom.cpfcnpj}</p>
                                                                <p>Nome: {cupom.nome}</p>
                                                                <p>Telefone: {cupom.telefone}</p>
                                                            </div>
                                                        ))}
                                                        {vencedores && vencedores.map((vencedor, index) => (
                                                            <div className="cupom" key={index}>
                                                                <h5>Coluna {vencedor.descricao}</h5>
                                                                <p>CPF/CNPJ: {vencedor.cupom.cpfcnpj}</p>
                                                                <p>Nome: {vencedor.cupom.nome}</p>
                                                                <p>Telefone: {vencedor.cupom.telefone}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {colunas && colunas.map((premio, index) => {
                                                        return (
                                                            <div style={{ marginTop: '20px' }} key={index}>
                                                                <h5>Coluna {index + 1}</h5>
                                                                <div className="coluna-1-emb" style={{ justifyContent: 'center', flexDirection: 'row' }}>
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            <p className="input-label-emb" htmlFor="nomeColuna">Nome Coluna:</p>
                                                                            <input
                                                                                name="nomeColuna"
                                                                                type="text"
                                                                                className="input-emb"
                                                                                style={{ width: '222px' }}
                                                                                onChange={(e) => handleInputChange(e, index)}
                                                                                value={premio.nomeColuna}
                                                                                disabled={verColunas} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            {/* <p className="input-label-emb" htmlFor="descricao">Tipo Coluna:</p>
                                                                            <input
                                                                                name="tipoColuna"
                                                                                type="text"
                                                                                className="input-emb"
                                                                                style={{ width: '222px' }}
                                                                                onChange={(e) => handleInputChange(e, index)}
                                                                                value={premio.tipoColuna}
                                                                                disabled={verColunas} /> */}
                                                                            <p style={{ textAlign: 'center' }} className="input-label-emb" htmlFor="descricao">Tipo Coluna:</p>
                                                                            <FormControl sx={{ width: '222px' }}>
                                                                                <Select
                                                                                    value={colunas[index].tipoColuna || null}
                                                                                    options={opcoesFrota}
                                                                                    formatOptionLabel={labelFrota}
                                                                                    styles={estiloFrota}
                                                                                    placeholder=""
                                                                                    noOptionsMessage={() => "Não há mais opções"}
                                                                                    onChange={(selectedOption) => {
                                                                                        if (selectedOption) {
                                                                                            handleInput2Change(selectedOption, index);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </FormControl>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {!verColunas && (<div className="grid-item-premio" style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
                                                                    <Tooltip title="Remover Coluna">
                                                                        <DeleteIcon className="trash-v2" onClick={() => removePremio(index)}></DeleteIcon>
                                                                    </Tooltip>
                                                                </div>)}
                                                            </div>
                                                        );
                                                    })}
                                                    {!verColunas && !verCupons && !verVencedores && (
                                                        <div className="container-select-emb">
                                                            <div className="coluna-3-emb" style={{ alignItems: 'center', flexDirection: 'column' }}>
                                                                <CustomButtonV2
                                                                    classCss="custom-button-entrega"
                                                                    classText="button-texto-entrega"
                                                                    titulo="Adicionar Coluna"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { adicionarColunas(); }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {!verColunas && !verCupons && !verVencedores && (
                                                    <div className="botoes-configuracaoEmpresa">
                                                        {
                                                            editando ? (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cadastrar"
                                                                    classText="button-texto-cadastrar"
                                                                    icone="iconeSave"
                                                                    titulo="Salvar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { editar(); }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {
                                                                        !props.desabilitar ? (
                                                                            props.botaoCadastrar ? (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Salvar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.tempoDiariaMinutos !== "") && (validator.tempoDiariaMinutos === "true")) {
                                                                                            if (colunas.length === 0) {
                                                                                                setTextoErro('Pelo menos uma coluna é obrigatório!');
                                                                                                setOpenToastErro(true);
                                                                                            } else {
                                                                                                cadastrar();
                                                                                            }
                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Salvar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.tempoDiariaMinutos !== "") && (validator.tempoDiariaMinutos === "true")) {
                                                                                            if (colunas.length === 0) {
                                                                                                setTextoErro('Pelo menos uma coluna é obrigatório!');
                                                                                                setOpenToastErro(true);
                                                                                            } else {
                                                                                                cadastrar();
                                                                                            }
                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            props.botaoVoltar ? (
                                                                <>
                                                                </>
                                                            ) : (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cancelar"
                                                                    classText="button-texto-cancelar"
                                                                    titulo="Cancelar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { setValues({}); setValuesSelect({}); setColunas([]); setTelaCadastro(false); setTelaCadastroCupom(false); setEditando(false); setVerColunas(false); setTabelaRedux({}); }}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div>
                                                {
                                                    !props.desabilitar ? (
                                                        <div className="indicadores">
                                                            <p className="indicador-emb">{"Dados de ConfiguracaoEmpresa "}</p>
                                                            {!verColunas && (
                                                                <p className="indicador-emb">{"/ Cadastro de Cupom"}</p>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <div className="flex">
                                                    {
                                                        !props.botaoVoltar ? (
                                                            verColunas ? (
                                                                <div className="flex-emb">
                                                                    <HeaderCadastro texto="Colunas" />
                                                                    <CloseIcon onClick={() => {
                                                                        setValues({});
                                                                        setValuesSelect({});
                                                                        setTelaCadastro(false);
                                                                        setTelaCadastroCupom(false);
                                                                        setEditando(false);
                                                                        setVerColunas(false);
                                                                        setTabelaRedux({});
                                                                        setColunas([]);
                                                                    }} />
                                                                </div>
                                                            ) : (
                                                                <div className="flex-emb">
                                                                    {
                                                                        editando ? (
                                                                            <HeaderCadastro texto="Detalhes de Cupom" />
                                                                        ) : (
                                                                            <HeaderCadastro texto="Cadastro de Cupom" />
                                                                        )
                                                                    }
                                                                    <CloseIcon onClick={() => {
                                                                        setValues({});
                                                                        setValuesSelect({});
                                                                        setTelaCadastro(false);
                                                                        setTelaCadastroCupom(false);
                                                                        setEditando(false);
                                                                        setVerColunas(false);
                                                                        setTabelaRedux({});
                                                                        setColunas([]);
                                                                    }} />
                                                                </div>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </div>
                                                <div className="flex-column">
                                                    {!verColunas && (
                                                        <div className="container-select-emb">
                                                            <div className="rota-div-2">
                                                                <div className="coluna-configuracaoEmpresa">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, nome: e.target.value }));
                                                                                setValidator(current => ({ ...current, nome: "true" }));
                                                                            }}
                                                                            value={values.tempoDiariaMinutos}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, telefone: e.target.value }));
                                                                                setValidator(current => ({ ...current, telefone: "true" }));
                                                                            }}
                                                                            value={telefoneMask(values.telefone)}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-configuracaoEmpresa">
                                                                    <div className="principal">
                                                                        <p className="input-label-emb" htmlFor="descricao">CPF/CNPJ:</p>
                                                                        <input
                                                                            type="text"
                                                                            className="input-emb"
                                                                            style={{ width: '270px' }}
                                                                            onChange={(e) => {
                                                                                setValues(current => ({ ...current, cpfcnpj: e.target.value }));
                                                                                setValidator(current => ({ ...current, cpfcnpj: "true" }));
                                                                            }}
                                                                            value={cpfCnpj(values.cpfcnpj)}
                                                                            disabled={props.desabilitar} />
                                                                    </div>
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            {
                                                                                loadingSelectGrupoCliente ? (
                                                                                    <LoadingBar />
                                                                                ) : (
                                                                                    <div className="coluna-emb">
                                                                                        <div>
                                                                                            <p className="input-label-emb" htmlFor="descricao">GrupoCliente:</p>
                                                                                            <SelectEmbarqueV2
                                                                                                classeCss={230}
                                                                                                onChange={(e) => {
                                                                                                    setValuesSelect(current => ({ ...current, motgrupoClienteIdoristaId: e }));
                                                                                                    setValues(current => ({ ...current, grupoClienteId: e.id }));
                                                                                                    setValidator(current => ({ ...current, grupoClienteId: "true" }));
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    setDisable(true);
                                                                                                    setOpenCadastroGrupoCliente(true);
                                                                                                }}
                                                                                                clearSelection={clearGrupoCliente}
                                                                                                onInfoIconClick={setIdAtual}
                                                                                                id="idGrupoCliente"
                                                                                                grande={true}
                                                                                                value={valuesSelect.grupoClienteId}
                                                                                                titulo="Grupo Cliente"
                                                                                                dadosSelect={listaGrupoCliente}
                                                                                                referenciaId="nome"
                                                                                                referenciaItem="nome" />
                                                                                        </div>
                                                                                        <NewIcon
                                                                                            onClick={(e) => {
                                                                                                setOpenCadastroGrupoCliente(true); setIdAtual(null); setDisable(false);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-configuracaoEmpresa">
                                                                    <div className="coluna-emb">
                                                                        <div>
                                                                            {
                                                                                loadingSelectProprietario ? (
                                                                                    <LoadingBar />
                                                                                ) : (
                                                                                    <div className="coluna-emb">
                                                                                        <div>
                                                                                            <p className="input-label-emb" htmlFor="descricao">Proprietário:</p>
                                                                                            <SelectEmbarqueV2
                                                                                                classeCss={230}
                                                                                                onChange={(e) => {
                                                                                                    setValuesSelect(current => ({ ...current, proprietarioId: e }));
                                                                                                    setValues(current => ({ ...current, proprietarioId: e.id }));
                                                                                                    setValidator(current => ({ ...current, proprietarioId: "true" }));
                                                                                                }}
                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    setDisable(true);
                                                                                                    setOpenCadastroProprietario(true);
                                                                                                }}
                                                                                                onInfoIconClick={setIdAtual}
                                                                                                id="idProprietario"
                                                                                                grande={true}
                                                                                                value={valuesSelect.proprietarioId}
                                                                                                titulo="Grupo Cliente"
                                                                                                dadosSelect={listaProprietario}
                                                                                                referenciaId="nome"
                                                                                                referenciaItem="nome" />
                                                                                        </div>
                                                                                        <NewIcon
                                                                                            onClick={(e) => {
                                                                                                setOpenCadastroProprietario(true); setIdAtual(null); setDisable(false);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                {!verColunas && (
                                                    <div className="botoes-configuracaoEmpresa">
                                                        {
                                                            editando ? (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cadastrar"
                                                                    classText="button-texto-cadastrar"
                                                                    icone="iconeSave"
                                                                    titulo="Salvar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { editar(); }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {
                                                                        !props.desabilitar ? (
                                                                            props.botaoCadastrar ? (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Salvar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.tempoDiariaMinutos !== "") && (validator.tempoDiariaMinutos === "true")) {

                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <CustomButtonV2
                                                                                    classCss="custom-button-cadastrar"
                                                                                    classText="button-texto-cadastrar"
                                                                                    icone="iconeSave"
                                                                                    titulo="Salvar"
                                                                                    cor="#0946B8"
                                                                                    valor={values}
                                                                                    onClick={() => {
                                                                                        if ((values.tempoDiariaMinutos !== "") && (validator.tempoDiariaMinutos === "true")) {

                                                                                        } else {
                                                                                            setTextoErro('Há campos sem preencher!');
                                                                                            setOpenToastErro(true);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            props.botaoVoltar ? (
                                                                <>
                                                                </>
                                                            ) : (
                                                                <CustomButtonV2
                                                                    classCss="custom-button-cancelar"
                                                                    classText="button-texto-cancelar"
                                                                    titulo="Cancelar"
                                                                    cor="#0946B8"
                                                                    valor={values}
                                                                    onClick={() => { setValues({}); setValuesSelect({}); setColunas([]); setTelaCadastro(false); setTelaCadastroCupom(false); setEditando(false); setVerColunas(false); setTabelaRedux({}); }}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarConfiguracaoEmpresaPorId}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroGrupoCliente="sim"
                                        open={openCadastroGrupoCliente}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroGrupoCliente}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default ConfiguracaoEmpresa;