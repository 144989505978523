import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import "./Caminhao.css";
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import { cadastraCaminhao, deleteCaminhaoPorId, editaCaminhaoPorId, getCaminhao, getCaminhaoPorId, transformaMercosulCaminhao } from "../../../../services/ModuloGerenciador/Caminhao";
import { VerificaExistenciaCaminhao } from "../../../../services/ModuloGerenciador/VerificaExistencia";
import { CaminhaoModel } from "../../../../models/Caminhao"
import { useLocation, useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";
import RequestControllerRedux from "../../../../contexts/RequestController";
import { FormControl } from "@mui/material";
import Select from 'react-select';
import { valorMask } from "../../../../regex/valorMask";
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon";
import { getCaminhaoSearch } from "../../../../services/ModuloGerenciador/Search";
import FiltroV2 from "../../../../components/FiltroV2/FiltroV2";
import MultiplosFiltrosRedux from "../../../../contexts/MultiplosFiltros.js";
import FiltrosRedux from "../../../../contexts/Filtros.js";
import ModalConfirmacao2 from "../../../../components/ModalConfirmacao2/ModalConfirmacao2.tsx";

function Caminhao(props) {

    const itensFiltro = [
        {
            'nomeColuna': 'Placa',
            'field': 'placa'
        },
        {
            'nomeColuna': 'Ano',
            'field': 'ano'
        },
        {
            'nomeColuna': 'Renavam',
            'field': 'renavam'
        },
        {
            'nomeColuna': 'Marca',
            'field': 'marca'
        },
        {
            'nomeColuna': 'Modelo',
            'field': 'modelo'
        },
        {
            'nomeColuna': 'KM',
            'field': 'km'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const [dadosTabelaCopia, setDadosTabelaCopia] = useState([]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setDadosTabela(dadosTabelaCopia);
        setTimeout(() => {
            setLoading(false);
        }, 100);
    };

    const buscarSearch = async () => {
        setLoading(true);
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getCaminhaoSearch(dadosUsuarioRedux.token, pesquisaFormatada).then((result) => {
            setDadosTabela(result.data);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState(new CaminhaoModel({}));

    const [valuesSelect, setValuesSelect] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [placaValida, setPlacaValida] = useState(true);

    const [openMercosul, setOpenMercosul] = useState(false);
    const handleCloseMercosul = (value) => {
        setOpenMercosul(false);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const buscarCaminhao = async () => {
        setLoading(true);
        await getCaminhao(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setDadosTabelaCopia(result.data);
            setTabelaRedux({});
            setLoading(false);
        })
    }

    const verificadorExistenciaCaminhao = async (placaCaminhao) => {
        if (placaCaminhao !== null && placaCaminhao !== '') {
            await VerificaExistenciaCaminhao(dadosUsuarioRedux.token, placaCaminhao).then((result) => {
                if (result.data === true) {
                    setTextoSucesso("Placa do caminhão não possui nenhum vínculo.");
                    setOpenToastSucesso(true);
                    setPlacaValida(true);
                } else {
                    setTextoErro("Placa do caminhão já possui um vínculo ativo, não será possível cadastrar novamente com ela.");
                    setOpenToastErro(true);
                    setPlacaValida(false);
                }
            }).catch(() => {
                setTextoErro("Placa do caminhão já possui um vínculo ativo, não será possível cadastrar novamente com ela.");
                setOpenToastErro(true);
                setPlacaValida(false);
            });
        }
    }

    const cadastrar = async () => {
        if (values.frota === 'Sim') values.frota = true;
        if (values.frota === 'Não') values.frota = false;
        if ((values.placa !== "") && (validator.placa === "true")) {
            setLoading(true);
            await cadastraCaminhao(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    setValues({});
                    setTelaCadastro(false);
                    if (valuesPesquisa.pesquisa === undefined) {
                        buscarCaminhao();
                    } else {
                        buscarSearch();
                    }
                    setDadosRequestControllerRedux("Fechar Modal Caminhao");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                setValues(values);
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        if (values.frota === 'Sim') values.frota = true;
        if (values.frota === 'Não') values.frota = false;
        setLoading(true);
        await editaCaminhaoPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (valuesPesquisa.pesquisa === undefined) {
                    buscarCaminhao();
                } else {
                    buscarSearch();
                }
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCaminhaoPorId = async () => {
        setLoading(true);
        await getCaminhaoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    preencherDadosEditando(result.data);
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const transformaMercosul = async () => {
        setLoading(true);
        await transformaMercosulCaminhao(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCaminhao();
                setTextoSucesso("Placa alterada Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCaminhaoPorIdModal = async (id) => {
        setLoading(true);
        await getCaminhaoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const deletarCaminhaoPorId = async () => {
        setLoading(true);
        await deleteCaminhaoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarCaminhao();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        if (values.placa === "") {
            setValidator(current => ({ ...current, placa: "false" }));
        }
    }, [loading]);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
    }, [telaCadastro]);

    useEffect(() => {
        if (editando) {
            buscarCaminhaoPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarCaminhao();
        if (props.idAtual) {
            buscarCaminhaoPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Veiculo') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Veiculo') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    });

    const labelFrota = (data) => (
        <div>
            {data.opcao}
        </div>
    );

    const opcoesFrota = dadosTabelaRedux.Opcoes.map((data) => ({
        value: data.opcao,
        label: data.opcao,
        ...data
    }));

    const estiloFrota = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    }

    const preencherDadosEditando = async (result) => {
        let frota;
        if (result.frota === true) {
            frota = "Sim";
        } else {
            frota = "Não"
        }
        const frotaSelecionado = opcoesFrota.find(option =>
            option.value === frota
        );
        if (frotaSelecionado) {
            setValuesSelect(current => ({ ...current, frota: frotaSelecionado }));
        }
    };

    useEffect(() => {
        if (telaCadastro === true && editando === false) {
            setValues((current) => ({
                ...current,
                frota: 'Não',
            }));
            let frota;
            frota = "Não"
            const frotaSelecionado = opcoesFrota.find(option =>
                option.value === frota
            );
            if (frotaSelecionado) {
                setValuesSelect(current => ({ ...current, frota: frotaSelecionado }));
            }
        }
    }, [telaCadastro]);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Caminhões" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Caminhões" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Caminhões</p>
                                                </div>
                                            </div>
                                            <div className="filtro-cliente-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-embarque-v2'
                                                    classOption='select-options-cliente'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idCaminhao"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Caminhao}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-mercosul"
                                                        classText="button-texto-user"
                                                        titulo="Gerar Placa Mercosul"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenMercosul(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Caminhao / "}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Caminhao"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Caminhao"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Caminhão" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Caminhão" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Placa:</p>
                                                                <input
                                                                    type="text"
                                                                    className={placaValida ? "input-emb" : "input-emb-erro"}
                                                                    style={{ width: '265px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, placa: e.target.value.toUpperCase() }));
                                                                        setValidator(current => ({ ...current, placa: "true" }));
                                                                    }}
                                                                    placeholder="AAA-1A11"
                                                                    maxLength={7}
                                                                    onBlur={(e) => { verificadorExistenciaCaminhao(e.target.value) }}
                                                                    value={values.placa}
                                                                    disabled={props.desabilitar || editando} />
                                                                {!placaValida && <div className="error-text">Placa já cadastrada</div>}
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Ano:</p>
                                                                <input
                                                                    type="text"
                                                                    placeholder="2020"
                                                                    maxLength={4}
                                                                    className="input-emb"
                                                                    style={{ width: '200px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, ano: valorMask(e.target.value) }));
                                                                    }}
                                                                    value={values.ano}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Renavam:</p>
                                                                <input
                                                                    type="text"
                                                                    placeholder="00000000000"
                                                                    maxLength={11}
                                                                    className="input-emb"
                                                                    style={{ width: '265px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, renavam: valorMask(e.target.value) }));
                                                                    }}
                                                                    value={values.renavam}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Modelo:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    placeholder="FH 440"
                                                                    maxLength={24}
                                                                    style={{ width: '265px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, modelo: e.target.value.toUpperCase() }));
                                                                    }}
                                                                    value={values.modelo}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Marca:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    placeholder="VOLVO"
                                                                    maxLength={12}
                                                                    style={{ width: '265px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, marca: e.target.value.toUpperCase() }));
                                                                    }}
                                                                    value={values.marca}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Eixos:</p>
                                                                <input
                                                                    type="number"
                                                                    className="input-emb"
                                                                    placeholder="3"
                                                                    min="1"
                                                                    max="5"
                                                                    style={{ width: '200px' }}
                                                                    onChange={(e) => {
                                                                        const valor = Math.max(1, Math.min(5, Number(e.target.value)));
                                                                        setValues(current => ({ ...current, numeroEixos: valor }));
                                                                    }}
                                                                    value={values.numeroEixos}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Frota:</p>
                                                                <FormControl sx={{ width: 260 }}>
                                                                    <Select
                                                                        value={valuesSelect.frota}
                                                                        options={opcoesFrota}
                                                                        formatOptionLabel={labelFrota}
                                                                        styles={estiloFrota}
                                                                        placeholder=""
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    frota: selectedOption.opcao,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, frota: selectedOption }));
                                                                                setValidator((current) => ({
                                                                                    ...current,
                                                                                    validatorFrota: "true",
                                                                                }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">KM:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    placeholder="0,00"
                                                                    style={{ width: '265px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, km: e.target.value }));
                                                                    }}
                                                                    value={valorMask(values.km)}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-caminhao">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.placa !== "")
                                                                                    && (validator.placa === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Campo placa obrigatório!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.placa !== "")
                                                                                    && (validator.placa === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Campo placa obrigatório!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarCaminhaoPorId}
                                    />
                                    <ModalConfirmacao2
                                        titulo="Mercosul"
                                        texto="Deseja Converter essa Placa para o modelo Mercosul?"
                                        tituloConfirmar="Sim"
                                        tituloCancelar="Não"
                                        textoBotao="Gerar"
                                        open={openMercosul}
                                        onClose={handleCloseMercosul}
                                        onClick={transformaMercosul}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Caminhao;