import NavbarV3 from "../../../../components/NavbarV3/NavbarV3";
import React, { useContext, useState, useEffect } from "react";
import DadosUsuarioRedux from "../../../../contexts/Usuario";
import CoresRedux from "../../../../contexts/Cor";
import NomeTabelaRedux from "../../../../contexts/NomeTabela";
import { useNavigate } from 'react-router-dom';
import TabelaRedux from "../../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../../services/ModuloGerenciador/Usuario";
import { cadastraFilial, getFilial, getFilialPorId, deleteFilialPorId, editaFilialPorId } from "../../../../services/ModuloGerenciador/Filial";
import ModalConfirmacao from "../../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../../components/ModalUsuario/ModalUsuario.tsx";
import LoadingComponent from "../../../../components/LoadingComponent/LoadingComponent";
import Tabela from "../../../../components/Tabela/Tabela";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { getCidadeEstadoComSigla, getCidadeEstadoPorId } from "../../../../services/ModuloGerenciador/CidadeEstado";
import { telefoneMask } from '../../../../regex/telefoneMask'
import SemAutorizacao from "../../../../components/SemAutorizacao/SemAutorizacao";
import "./Filial.css";
import Text from "../../../../components/Text/Text";
import { getFilialDependencias, getRegras } from "../../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../../contexts/RequestController";
import { motion } from "framer-motion";
import { FormControl } from "@mui/material";
import ReactSelect from 'react-select';
import TabelaV2 from "../../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../../components/CloseIcon/CloseIcon";
import ClearIcon from '@mui/icons-material/Clear';

function Filial(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(false);

    const [values, setValues] = useState({});

    const [validator, setValidator] = useState([]);

    const [listaCidades, setListaCidades] = useState([]);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const navigate = useNavigate();

    const [openUsuario, setOpenUsuario] = useState(false);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const buscarDependencias = async () => {
        setLoading(true);
        await getFilialDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaCidades(result.data.cidadeEstados);
            setDadosTabela(result.data.filials);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCidadeEstado = async () => {
        await getCidadeEstadoComSigla(dadosUsuarioRedux.token).then((result) => {
            setListaCidades(result.data);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarFilial = async () => {
        setLoading(true);
        await getFilial(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setLoading(false);
        })
    }

    const preencherDadosEditando = async (result) => {
        const cidadeSelecionado = listaCidades.find(option =>
            option.idCidadeEstado === result.data.cidadeEstadoId
        );
        if (cidadeSelecionado) {
            setValuesSelect(current => ({ ...current, cidadeEstadoId: cidadeSelecionado }));
        }
    };

    const buscarFilialPorID = async () => {
        await getFilialPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result);
                    setValues(result.data);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            }
        });
    }

    const buscarFilialPorIDModal = async (id) => {
        await getFilialPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                await preencherDadosEditando(result);
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
            }
        });
    }

    const cadastrar = async () => {
        if ((values.nome !== "" || values.telefone !== "" || values.cidadeEstadoId !== ""
            || values.responsavel !== "" || values.email !== "" || values.complementoLocalizacao !== "") &&
            (validator.nome === "true" && validator.telefone === "true" && validator.cidadeEstadoId === "true"
                && validator.responsavel === "true" && validator.telefone === "true" && validator.cidadeEstadoId === "true")) {
            setLoading(true);
            await cadastraFilial(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarFilial();
                    setDadosRequestControllerRedux("Fechar Modal Filial");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        if ((values.nome !== "" && values.telefone !== "" && values.cidadeEstadoId !== ""
            && values.responsavel !== "" && values.email !== "" && values.complementoLocalizacao !== "")) {
            setLoading(true);
            await editaFilialPorId(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarFilial();
                    setTabelaRedux({});
                    setTextoSucesso("Editado Com Sucesso");
                    setOpenToastSucesso(true);
                    setTelaCadastro(false);
                    setValues({});
                    setValuesSelect({});
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const deletarFilial = async () => {
        setLoading(true)
        await deleteFilialPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarFilial();
                setTabelaRedux({});
                setTextoSucesso("Deletado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarCidadeEstadoPorId = async () => {
        await getCidadeEstadoPorId(values.cidadeEstadoId).then((result) => {
            if (result.status === 200) {
                if (editando) {
                    setValues(current => ({ ...current, cidadeOBJ: result.data }))
                }
            }
        })
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, nome: "false" }));
        setValidator(current => ({ ...current, telefone: "false" }));
        setValidator(current => ({ ...current, cidadeEstadoId: "false" }));
        setValidator(current => ({ ...current, responsavel: "false" }));
        setValidator(current => ({ ...current, email: "false" }));
        setValidator(current => ({ ...current, complementoLocalizacao: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarFilialPorID();
        }
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, [editando]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, [telaCadastro]);

    useEffect(() => {
        buscarDependencias();
    }, []);

    useEffect(() => {
        if (props.idAtual) {
            buscarFilialPorIDModal(props.idAtual);
        }
    }, [listaCidades]);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Uso Comum') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Uso Comum') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    const [valuesSelect, setValuesSelect] = useState([]);

    const labelCidadeEstado = (data) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {data.cidade} - {data.estado}
            {valuesSelect.cidadeEstadoId === data &&
                <ClearIcon className="info-icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        clearCidade();
                    }} />
            }
        </div>
    );

    const clearCidade = () => {
        setValues((current) => ({
            ...current,
            cidadeEstadoId: null,
        }));
        setValuesSelect(current => ({ ...current, cidadeEstadoId: '' }));
        setValidator((current) => ({
            ...current,
            cidadeEstadoId: "false",
        }));
    };

    const estiloCidadeEstado = {
        control: (provided) => ({
            ...provided,
            minHeight: '30px',
            border: 'none',
            borderRadius: '7px',
            border: '1px solid var(--Background-Input, #F2F2F2)',
            background: 'var(--Background-Input, #F2F2F2)',
            '&:hover': {
                background: '#F0F6FF',
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'var(--Placeholder-Color, #888)',
            fontSize: '12px',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'max-content',
            minWidth: props.classeCss ? props.classeCss : '250px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '13px', // ajuste o tamanho da fonte conforme desejado
        }),
    };

    const opcoesCidadeEstado = listaCidades.map((data) => ({
        value: data.idCidadeEstado,
        label: `${data.cidade} - ${data.estado}`,
        ...data
    }));

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Filial" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Filial" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Filial</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idFilial"
                                                nomeClasse1="outer-wrapper-rota"
                                                nomeClasse2="table-wrapper-v2"
                                                colunas={dadosTabelaRedux.Filial}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Cadastro de Filial /"}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Filial"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Filial"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Filial" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Filial" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setTabelaRedux({}); setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false);
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                <input
                                                                    type="text"
                                                                    id="nome"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nome: e.target.value }));
                                                                        setValidator(current => ({ ...current, nome: "true" }));
                                                                    }}
                                                                    placeholder="Ex: STS"
                                                                    value={values.nome}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Telefone:</p>
                                                                <input
                                                                    type="text"
                                                                    id="telefone"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, telefone: e.target.value }));
                                                                        setValidator(current => ({ ...current, telefone: "true" }));
                                                                    }}
                                                                    placeholder="Ex: (99)99999-9999"
                                                                    value={telefoneMask(values.telefone)}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Cidade/UF:</p>
                                                                <FormControl sx={{ width: 250 }}>
                                                                    <ReactSelect
                                                                        value={valuesSelect.cidadeEstadoId}
                                                                        isDisabled={props.desabilitar}
                                                                        options={opcoesCidadeEstado}
                                                                        formatOptionLabel={labelCidadeEstado}
                                                                        styles={estiloCidadeEstado}
                                                                        placeholder="Selecione"
                                                                        noOptionsMessage={() => "Não há mais opções"}
                                                                        onChange={(selectedOption) => {
                                                                            if (selectedOption) {
                                                                                setValues((current) => ({
                                                                                    ...current,
                                                                                    cidadeEstadoId: selectedOption.idCidadeEstado,
                                                                                }));
                                                                                setValuesSelect(current => ({ ...current, cidadeEstadoId: selectedOption }));
                                                                                setValidator((current) => ({
                                                                                    ...current,
                                                                                    cidadeEstadoId: "true",
                                                                                }));
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Responsável:</p>
                                                                <input
                                                                    type="text"
                                                                    id="responsavel"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, responsavel: e.target.value }))
                                                                        setValidator(current => ({ ...current, responsavel: "true" }));
                                                                    }}
                                                                    placeholder="Ex: Jõao"
                                                                    value={values.responsavel}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">E-mail:</p>
                                                                <input
                                                                    type="email"
                                                                    id="email"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, email: e.target.value }))
                                                                        setValidator(current => ({ ...current, email: "true" }));
                                                                    }}
                                                                    placeholder="Ex: joao@gmail.com"
                                                                    value={values.email}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="coluna-emb">
                                                            <div className="principal">
                                                                <p className="input-label-emb" htmlFor="descricao">Complemento Localização:</p>
                                                                <input
                                                                    type="text"
                                                                    id="loc"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, complementoLocalizacao: e.target.value }))
                                                                        setValidator(current => ({ ...current, complementoLocalizacao: "true" }));
                                                                    }}
                                                                    placeholder="Ex: Toledo"
                                                                    value={values.complementoLocalizacao}
                                                                    disabled={props.desabilitar}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.telefone !== "" && values.email !== ""
                                                                                    && values.responsavel !== "" && values.cidadeEstadoId !== "" && values.complementoLocalizacao !== "")
                                                                                    && (validator.nome === "true" && validator.telefone === "true"
                                                                                        && validator.email === "true" && validator.responsavel === "true"
                                                                                        && validator.cidadeEstadoId === "true" && validator.complementoLocalizacao === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "" && values.telefone !== "" && values.email !== ""
                                                                                    && values.responsavel !== "" && values.cidadeEstadoId !== "" && values.complementoLocalizacao !== "")
                                                                                    && (validator.nome === "true" && validator.telefone === "true"
                                                                                        && validator.email === "true" && validator.responsavel === "true"
                                                                                        && validator.cidadeEstadoId === "true" && validator.complementoLocalizacao === "true")) {
                                                                                    cadastrar();
                                                                                    setTelaCadastro(false);
                                                                                    setValues({});
                                                                                    setValuesSelect({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarFilial}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Filial;