import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./GrupoCargaVinculo.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getGrupoCargaVinculo, cadastraGrupoCargaVinculo, editaGrupoCargaVinculoPorId, getGrupoCargaVinculoPorId, deleteGrupoCargaVinculoPorId } from "../../../services/ModuloGerenciador/GrupoCargaVinculo";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getGrupoCargaDependencias, getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { Box, LinearProgress } from "@mui/material";
import SelectEmbarque from "../../../components/SelectEmbarque/SelectEmbarque";
import TextLink from "../../../components/TextLink/TextLink";
import ModalCadastro from "../../../components/ModalCadastro/ModalCadastro.tsx";
import { getGrupoClientes } from "../../../services/ModuloGerenciador/GrupoClientes";
import { getCliente } from "../../../services/ModuloGerenciador/Cliente";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import LoadingBar from "../../../components/LoadingBar/LoadingBar";
import SelectEmbarqueV2 from "../../../components/SelectEmbarqueV2/SelectEmbarqueV2";
import NewIcon from "../../../components/NewIcon/NewIcon";
import { getMotorista } from "../../../services/ModuloGerenciador/Motorista.js";

function GrupoCargaVinculo(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [listaGrupos, setListaGrupos] = useState([]);

    const [disable, setDisable] = useState(false);

    const [idAtual, setIdAtual] = useState();

    const [listaGrupoCarga, setListaGrupoCarga] = useState([]);

    const [listaMotorista, setListaMotorista] = useState([]);

    const [listaProprietario, setListaProprietario] = useState([]);

    const [loadingSelectGrupoCarga, setLoadingSelectGrupoCarga] = useState(false);

    const [openCadastroGrupoCarga, setOpenCadastroGrupoCarga] = useState(false);

    const handleCloseCadastroGrupoCarga = (value) => {
        if (idAtual) {
            setOpenCadastroGrupoCarga(false);
        } else {
            setLoadingSelectGrupoCarga(true);
            buscarGrupoCargaVinculoDependencias();
            setOpenCadastroGrupoCarga(false);
        }
    };

    const [loadingSelectMotorista, setLoadingSelectMotorista] = useState(false);

    const [openCadastroMotorista, setOpenCadastroMotorista] = useState(false);

    const handleCloseCadastroMotorista = (value) => {
        if (idAtual) {
            setOpenCadastroMotorista(false);
        } else {
            setLoadingSelectMotorista(true);
            buscarGrupoCargaVinculoDependencias();
            setOpenCadastroMotorista(false);
        }
    };

    const [loadingSelectProprietario, setLoadingSelectProprietario] = useState(false);

    const [openCadastroProprietario, setOpenCadastroProprietario] = useState(false);

    const handleCloseCadastroProprietario = (value) => {
        if (idAtual) {
            setOpenCadastroProprietario(false);
        } else {
            setLoadingSelectProprietario(true);
            buscarGrupoCargaVinculoDependencias();
            setOpenCadastroProprietario(false);
        }
    };

    const [valuesSelect, setValuesSelect] = useState([]);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const preencherDadosEditando = async (result) => {
        const grupoCargaSelecionado = listaGrupoCarga.find(option => option.id === result.data.grupoCargaId);
        if (grupoCargaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                grupoCargaId: {
                    value: grupoCargaSelecionado.nomeGrupo,
                    label: grupoCargaSelecionado.nomeGrupo,
                    id: grupoCargaSelecionado.id
                }
            }));
        }
        const motoristaSelecionado = listaMotorista.find(option => option.idMotorista === result.data.motoristaId);
        if (motoristaSelecionado) {
            setValuesSelect(current => ({
                ...current,
                motoristaId: {
                    value: motoristaSelecionado.nome,
                    label: motoristaSelecionado.nome,
                    id: motoristaSelecionado.idMotorista
                }
            }));
        }
        const proprietarioSelecionado = listaProprietario.find(option => option.idProprietario === result.data.proprietarioId);
        if (proprietarioSelecionado) {
            setValuesSelect(current => ({
                ...current,
                proprietarioId: {
                    value: proprietarioSelecionado.nome,
                    label: proprietarioSelecionado.nome,
                    id: proprietarioSelecionado.idProprietario
                }
            }));
        }
    };

    const buscarGrupoCargaVinculo = async () => {
        setLoading(true);
        await getGrupoCargaVinculo(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarMotorista = async () => {
        await getMotorista(dadosUsuarioRedux.token).then((result) => {
            setListaMotorista(result.data);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const buscarGrupoCargaVinculoDependencias = async () => {
        await getGrupoCargaDependencias(dadosUsuarioRedux.token).then((result) => {
            setListaGrupoCarga(result.data.grupoCarga);
            setListaProprietario(result.data.proprietarios);
            setTabelaRedux({});
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.grupoCargaId !== "") && (validator.grupoCargaId === "true")
            && (values.motoristaId !== "") && (validator.motoristaId === "true")
            && (values.proprietarioId !== "") && (validator.proprietarioId === "true")) {
            setLoading(true);
            await cadastraGrupoCargaVinculo(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    setTelaCadastro(false);
                    setValues({});
                    setValuesSelect({});
                    buscarGrupoCargaVinculo();
                    buscarGrupoCargaVinculoDependencias();
                    setDadosRequestControllerRedux("Fechar Modal GrupoCargaVinculo");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        if ((values.grupoCargaId !== "") && (validator.grupoCargaId === "true")
            && (values.motoristaId !== "") && (validator.motoristaId === "true")
            && (values.proprietarioId !== "") && (validator.proprietarioId === "true")) {
            setLoading(true);
            await editaGrupoCargaVinculoPorId(values, dadosUsuarioRedux.token).then(async (result) => {
                if (result.status === 200) {
                    buscarGrupoCargaVinculo();
                    buscarGrupoCargaVinculoDependencias();
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                    setTelaCadastro(false);
                    setEditando(false);
                    setValues({});
                    setValuesSelect({});
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                    setOpenToastErro(true);
                    setLoading(false);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                    setOpenToastErro(true);
                    setLoading(false);
                }
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const buscarGrupoCargaVinculoPorId = async () => {
        setLoading(true);
        await getGrupoCargaVinculoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await preencherDadosEditando(result);
                    setValues(result.data);
                    setValues(current => ({ ...current, idCliente: result.data.clienteId }));
                    setValues(current => ({ ...current, idGrupoCliente: result.data.grupoClienteId }));
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarGrupoCargaVinculoPorIdModal = async (id) => {
        setLoading(true);
        await getGrupoCargaVinculoPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                setValues(result.data);
                await preencherDadosEditando(result);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarGrupoCargaVinculoPorId = async () => {
        setLoading(true);
        await deleteGrupoCargaVinculoPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarGrupoCargaVinculo();
                buscarGrupoCargaVinculoDependencias();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValidator(current => ({ ...current, nome: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarGrupoCargaVinculoPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarGrupoCargaVinculo();
        buscarGrupoCargaVinculoDependencias();
        buscarMotorista();
        if (props.idAtual) {
            buscarGrupoCargaVinculoPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Vincular Grupo Cargas') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Vincular Grupo Cargas') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    const clearGrupoCarga = () => {
        setValuesSelect(current => ({ ...current, grupoCargaId: '' }));
        setValues(current => ({ ...current, grupoCargaId: null }));
        setValidator(current => ({ ...current, grupoCargaId: "false" }));
    };

    const clearMotorista = () => {
        setValuesSelect(current => ({ ...current, motoristaId: '' }));
        setValues(current => ({ ...current, motoristaId: null }));
        setValidator(current => ({ ...current, motoristaId: "false" }));
    };

    const clearProprietario = () => {
        setValuesSelect(current => ({ ...current, proprietarioId: '' }));
        setValues(current => ({ ...current, proprietarioId: null }));
        setValidator(current => ({ ...current, proprietarioId: "false" }));
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Vincular Grupo Cargas" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Vincular Grupo Cargas" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Vincular Grupo Cargas</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="id"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.GrupoCargaVinculo}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Vincular Grupo Cargas >"}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Vincular Grupo Cargas"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Vincular Grupo Cargas"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            <HeaderCadastro texto="Vincular Grupo Cargas" />
                                                            <CloseIcon onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {
                                                                    loadingSelectGrupoCarga ? (
                                                                        <LoadingBar />
                                                                    ) : (
                                                                        <div className="coluna-emb">
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Grupo Carga:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={270}
                                                                                    onChange={(e) => {
                                                                                        setValuesSelect(current => ({ ...current, grupoCargaId: e }));
                                                                                        setValues(current => ({ ...current, grupoCargaId: e.id }));
                                                                                        setValidator(current => ({ ...current, grupoCargaId: "true" }));
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroGrupoCarga(true);
                                                                                    }}
                                                                                    clearSelection={clearGrupoCarga}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="id"
                                                                                    grande={true}
                                                                                    value={valuesSelect.grupoCargaId}
                                                                                    titulo="Grupo Cliente"
                                                                                    dadosSelect={listaGrupoCarga}
                                                                                    referenciaId="nomeGrupo"
                                                                                    referenciaItem="nomeGrupo" />
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroGrupoCarga(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {
                                                                    loadingSelectMotorista ? (
                                                                        <LoadingBar />
                                                                    ) : (
                                                                        <div className="coluna-emb">
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Motorista:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={270}
                                                                                    onChange={(e) => {
                                                                                        setValuesSelect(current => ({ ...current, motoristaId: e }));
                                                                                        setValues(current => ({ ...current, motoristaId: e.id }));
                                                                                        setValidator(current => ({ ...current, motoristaId: "true" }));
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroMotorista(true);
                                                                                    }}
                                                                                    clearSelection={clearMotorista}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="idMotorista"
                                                                                    grande={true}
                                                                                    value={valuesSelect.motoristaId}
                                                                                    titulo="Grupo Cliente"
                                                                                    dadosSelect={listaMotorista}
                                                                                    referenciaId="nome"
                                                                                    referenciaItem="nome" />
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroMotorista(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                {
                                                                    loadingSelectProprietario ? (
                                                                        <LoadingBar />
                                                                    ) : (
                                                                        <div className="coluna-emb">
                                                                            <div>
                                                                                <p className="input-label-emb" htmlFor="descricao">Proprietário:</p>
                                                                                <SelectEmbarqueV2
                                                                                    classeCss={270}
                                                                                    onChange={(e) => {
                                                                                        setValuesSelect(current => ({ ...current, proprietarioId: e }));
                                                                                        setValues(current => ({ ...current, proprietarioId: e.id }));
                                                                                        setValidator(current => ({ ...current, proprietarioId: "true" }));
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setDisable(true);
                                                                                        setOpenCadastroProprietario(true);
                                                                                    }}
                                                                                    clearSelection={clearProprietario}
                                                                                    onInfoIconClick={setIdAtual}
                                                                                    id="idProprietario"
                                                                                    grande={true}
                                                                                    value={valuesSelect.proprietarioId}
                                                                                    titulo="Grupo Cliente"
                                                                                    dadosSelect={listaProprietario}
                                                                                    referenciaId="nome"
                                                                                    referenciaItem="nome" />
                                                                            </div>
                                                                            <NewIcon
                                                                                onClick={(e) => {
                                                                                    setOpenCadastroProprietario(true); setIdAtual(null); setDisable(false);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.grupoCargaId !== "") && (validator.grupoCargaId === "true")
                                                                                    && (values.motoristaId !== "") && (validator.motoristaId === "true")
                                                                                    && (values.proprietarioId !== "") && (validator.proprietarioId === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.grupoCargaId !== "") && (validator.grupoCargaId === "true")
                                                                                    && (values.motoristaId !== "") && (validator.motoristaId === "true")
                                                                                    && (values.proprietarioId !== "") && (validator.proprietarioId === "true")) {
                                                                                    cadastrar();
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesSelect({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarGrupoCargaVinculoPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroGrupoCarga="sim"
                                        open={openCadastroGrupoCarga}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroGrupoCarga}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroMotorista="sim"
                                        open={openCadastroMotorista}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroMotorista}
                                    />
                                    <ModalCadastro
                                        titulo="Cadastro"
                                        tituloConfirmar="OK"
                                        cadastroProprietario="sim"
                                        open={openCadastroProprietario}
                                        idAtual={idAtual}
                                        desabilitar={disable}
                                        onClose={handleCloseCadastroProprietario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default GrupoCargaVinculo;