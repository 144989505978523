import { api } from "./UrlPadrao"

export const cadastraTipoDocumento = async (tipoDocumento, token) => {
  return api.post("TipoDocumento",
    tipoDocumento
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoDocumento = async (token) => {
  return api.get("TipoDocumento",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoDocumentoPorId = async (id, token) => {
  return api.get("TipoDocumento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTipoDocumentoPorId = async (id, token) => {
  return api.delete("TipoDocumento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTipoDocumentoPorId = async (tipoDocumento, token) => {
  return api.put("TipoDocumento",
    tipoDocumento,
  {
    headers: {
      'Authorization': token
    }
  })
}