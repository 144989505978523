import React, { useContext, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon2 from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import "./FiltroV2.css";
import FiltrosRedux from "../../contexts/Filtros";
import FilialCidade from "../../contexts/CidadesFilial";
import { WindowSharp } from "@mui/icons-material";
import { Box, LinearProgress } from "@mui/material";
import iconeStroke from "../../images/iconeStroke.png";
import iconeFiltro from "../../images/iconeFiltro.png";
import iconePesquisa from "../../images/iconePesquisa.png";
import iconeCidadeFiltro from "../../images/iconeCidadeFiltro.png";
import iconeMenu from "../../images/iconeMenu.png";
import DadosContextoEmbarqueRedux from "../../contexts/ContextoEmbarque";
import ModalMultiplosFiltros from "../ModalMultiplosFiltros/ModalMultiplosFiltros.tsx";
import iconeAnterior from "../../images/iconeAnterior.png";
import iconeProximo from "../../images/iconeProximo.png";
import MultiplosFiltrosRedux from "../../contexts/MultiplosFiltros.js";

function FiltroV2({
  classe,
  itensFiltro,
  valuesPesquisa,
  setValuesPesquisa,
  buscarSearch,
  limpar,
  setCompleto,
  setPeriodoCompleto,
  periodoCompleto,
  classOption,
  listaGrupos,
  mesAnterior,
  mesSeguinte,
  setEstadia,
  setFiltroEstadia,
  estadia,
}) {
  const { dadosContextoEmbarqueRedux } = useContext(DadosContextoEmbarqueRedux);

  const { multiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

  const [openFiltrar, setOpenFiltrar] = useState(false);
  const [openFiltrar2, setOpenFiltrar2] = useState(false);
  const [valorSelecionado, setValorSelecionado] = useState(valuesPesquisa.item);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState(null);
  const [filtroSelecionado, setFiltroSelecionado] = useState(null);

  const onClickFiltrar2 = () => {
    setOpenFiltrar2(!openFiltrar2);
    setOpenCidade(false);
  };

  const onClickFiltrar = () => {
    setOpenFiltrar(!openFiltrar);
    setOpenCidade(false);
  };

  const onClickOpcaoFiltrar = (value, option, nome) => {
    setValorSelecionado(value);
    setOpcaoSelecionada(option);
    setFiltroSelecionado(nome);
    setValuesPesquisa((current) => ({ ...current, item: value }));
    setFiltrosRedux((current) => ({ ...current, filtroNome: nome }));
    setFiltrosRedux((current) => ({ ...current, item: value }));
    setOpenFiltrar(false);
  };

  const [openCidade, setOpenCidade] = useState(false);
  const [valorSelecionadoCidade, setValorSelecionadoCidade] = useState(
    valuesPesquisa.item
  );
  const [opcaoSelecionadaCidade, setOpcaoSelecionadaCidade] = useState(null);
  const [cidadeFiltro, setCidadeFiltro] = useState(null);

  const onClickCidade = () => {
    setOpenCidade(!openCidade);
    setOpenFiltrar(false);
  };

  const onClickOpcaoCidade = (value, option, cidade, boolean) => {
    setValorSelecionadoCidade(value);
    setOpcaoSelecionadaCidade(option);
    setCidadeFiltro(cidade);
    setValuesPesquisa((current) => ({ ...current, contexto: value }));
    setFiltrosRedux((current) => ({ ...current, filial: cidade }));
    setFiltrosRedux((current) => ({ ...current, contexto: value }));
    setOpenCidade(false);
  };

  const [openTipo, setOpenTipo] = useState(false);
  const [valorSelecionadoTipo, setValorSelecionadoTipo] = useState(
    valuesPesquisa.item
  );
  const [opcaoSelecionadaTipo, setOpcaoSelecionadaTipo] = useState(null);
  const [tipoFiltro, setTipoFiltro] = useState(null);

  const onClickTipo = () => {
    setOpenTipo(!openTipo);
    setOpenFiltrar(false);
  };

  const onClickOpcaoTipo = (value, option, tipo, boolean) => {
    setValorSelecionadoTipo(value);
    setOpcaoSelecionadaTipo(option);
    setTipoFiltro(tipo);
    setValuesPesquisa((current) => ({ ...current, tipo: value }));
    setFiltrosRedux((current) => ({ ...current, tipo: tipo }));
    setFiltrosRedux((current) => ({ ...current, tipo: value }));
    setOpenTipo(false);
  };

  const [openElegivelBrf, setOpenElegivelBrf] = useState(false);
  const [valorSelecionadoElegivelBrf, setValorSelecionadoElegivelBrf] =
    useState(valuesPesquisa.item);
  const [opcaoSelecionadaElegivelBrf, setOpcaoSelecionadaElegivelBrf] =
    useState(null);
  const [elegivelBrfFiltro, setElegivelBrfFiltro] = useState(null);

  const onClickElegivelBrf = () => {
    setOpenElegivelBrf(!openElegivelBrf);
    setOpenFiltrar(false);
  };

  const onClickOpcaoElegivelBrf = (value, option, tipo, boolean) => {
    setValorSelecionadoElegivelBrf(value);
    setOpcaoSelecionadaElegivelBrf(option);
    setElegivelBrfFiltro(tipo);
    setValuesPesquisa((current) => ({ ...current, elegivelBrf: value }));
    setFiltrosRedux((current) => ({ ...current, elegivelBrfTipo: tipo }));
    setFiltrosRedux((current) => ({ ...current, elegivelBrf: value }));
    setOpenElegivelBrf(false);
  };

  const [openGrupoCliente, setOpenGrupoCliente] = useState(false);
  const [valorSelecionadoGrupoCliente, setValorSelecionadoGrupoCliente] =
    useState(valuesPesquisa.item);
  const [opcaoSelecionadaGrupoCliente, setOpcaoSelecionadaGrupoCliente] =
    useState(null);
  const [grupoClienteFiltro, setGrupoClienteFiltro] = useState(null);

  const onClickGrupoCliente = () => {
    setOpenGrupoCliente(!openGrupoCliente);
    setOpenFiltrar(false);
  };

  const onClickOpcaoGrupoCliente = (value, option, cidade, boolean) => {
    setValorSelecionadoGrupoCliente(value);
    setOpcaoSelecionadaGrupoCliente(option);
    setGrupoClienteFiltro(cidade);
    setValuesPesquisa((current) => ({ ...current, grupoCliente: value }));
    setFiltrosRedux((current) => ({ ...current, grupoClienteNome: cidade }));
    setFiltrosRedux((current) => ({ ...current, grupoCliente: value }));
    setOpenGrupoCliente(false);
  };

  const [openOpcionais, setOpenOpcionais] = useState(false);

  const onClickOpcionais = () => {
    setOpenOpcionais(!openOpcionais);
  };

  const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

  const { dadosFilialRedux } = useContext(FilialCidade);

  const opcoesTipo = [
    {
      opcao: "Ambos",
      valor: 1,
    },
    {
      opcao: "Carregando",
      valor: 2,
    },
    {
      opcao: "Descarregando",
      valor: 3,
    },
  ];

  const opcoesBrf = [
    {
      opcao: "Todos",
      valor: 5,
    },
    {
      opcao: "Elegível Padrão BRF",
      valor: 1,
    },
    {
      opcao: "Elegível BRF Spot",
      valor: 2,
    },
    {
      opcao: "Inelegível Padrão BRF",
      valor: 3,
    },
    {
      opcao: "Elegível BRF OTM",
      valor: 4,
    },
  ];

  const converterData = (data) => {
    if (typeof data !== "string" || !data.includes("-")) {
      console.error("Formato inválido ou data não fornecida.");
      return null;
    }

    const parts = data.split("-");
    if (parts.length !== 3) {
      console.error("Data incompleta ou formato incorreto.");
      return null;
    }

    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  const [valuesSelect, setValuesSelect] = useState([]);

  const [inputDisable, setInputDisable] = useState(true);

  const [loadingInput, setLoadingInput] = useState(false);

  const [openMultiplosFiltros, setOpenMultiplosFiltros] = useState(false);

  const handleCloseMultiplosFiltros = (value) => {
    setOpenMultiplosFiltros(false);
  };

  useEffect(() => {
    setLoadingInput(true);
    if (
      valuesPesquisa.item === "previsaoEmbarque" ||
      valuesPesquisa.item === "dataEmbarque" ||
      valuesPesquisa.item === "dataEntrega" ||
      valuesPesquisa.item === "previsaoEntrega"
    ) {
      var date = new Date();
      var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1)
        .toLocaleDateString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");
      setValuesPesquisa((current) => ({ ...current, pesquisa: primeiroDia }));
      setInputDisable(false);
      setLoadingInput(false);
    } else {
      setValuesPesquisa((current) => ({ ...current, pesquisa: "" }));
      setLoadingInput(false);
    }
  }, [valuesPesquisa.item]);

  useEffect(() => {
    setValuesPesquisa((current) => ({
      ...current,
      embarqueInicio: "01-01-2020",
    }));
    setValuesPesquisa((current) => ({
      ...current,
      embarqueFinal: "01-01-2050",
    }));
    setValuesPesquisa((current) => ({ ...current, tipo: 3 }));
    onClickOpcaoTipo(3, 3, "Descarregando", "false");
    setValuesPesquisa((current) => ({ ...current, contexto: "todos" }));
    onClickOpcaoCidade("todos", "todos", "Todos", "false");
    setValuesPesquisa((current) => ({ ...current, elegivelBrf: 5 }));
    onClickOpcaoElegivelBrf(5, 5, "Todos", "false");
    if (filtrosRedux.contexto && filtrosRedux.filial) {
      onClickOpcaoCidade(
        filtrosRedux.contexto,
        filtrosRedux.contexto,
        filtrosRedux.filial,
        "false"
      );
    }
    if (filtrosRedux.elegivelBrf && filtrosRedux.elegivelBrfTipo) {
      onClickOpcaoElegivelBrf(
        filtrosRedux.elegivelBrf,
        filtrosRedux.elegivelBrf,
        filtrosRedux.elegivelBrfTipo,
        "false"
      );
    }
    if (window.location.href.endsWith("dashboard-cockpit")) {
      onClickOpcaoGrupoCliente(1, 1, listaGrupos[0]?.nome, "false");
      if (filtrosRedux.grupoCliente) {
        onClickOpcaoGrupoCliente(
          filtrosRedux.grupoCliente,
          filtrosRedux.grupoCliente,
          filtrosRedux.grupoClienteNome,
          "false"
        );
      }
    }
    if (filtrosRedux.item && filtrosRedux.filtroNome) {
      onClickOpcaoFiltrar(
        filtrosRedux.item,
        filtrosRedux.item,
        filtrosRedux.filtroNome
      );
    }
    if (filtrosRedux.embarqueFinal != "01-01-2050") {
      setValuesSelect((current) => ({
        ...current,
        embarqueFinal: filtrosRedux.embarqueFinal,
      }));
    }
    if (filtrosRedux.embarqueInicio != "01-01-2020") {
      setValuesSelect((current) => ({
        ...current,
        embarqueInicio: filtrosRedux.embarqueInicio,
      }));
    }
    if (window.location.href.endsWith("diaria-veiculos")) {
      const date = new Date();
      var primeiroDiaDisp = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 2
      )
        .toLocaleDateString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");
      let data1Disp = converterData(primeiroDiaDisp);
      var primeiroDia = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 8
      )
        .toLocaleDateString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");
      let data1 = converterData(primeiroDia);
      var ultimoDia = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 8
      )
        .toLocaleDateString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");
      let data2 = converterData(ultimoDia);
      if (filtrosRedux.embarqueFinal != "01-01-2050") {
        if (filtrosRedux.embarqueFinal === undefined) {
          setValuesSelect((current) => ({ ...current, embarqueFinal: data2 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueFinal: data2,
          }));
        } else {
          const data1 = converterData(filtrosRedux.embarqueFinal);
          setValuesSelect((current) => ({ ...current, embarqueFinal: data1 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueFinal: data1,
          }));
        }
      } else {
        setValuesSelect((current) => ({ ...current, embarqueFinal: data2 }));
        setValuesPesquisa((current) => ({ ...current, embarqueFinal: data2 }));
      }
      if (filtrosRedux.embarqueFinalDisp != "01-01-2050") {
        if (filtrosRedux.embarqueFinalDisp === undefined) {
          setValuesSelect((current) => ({
            ...current,
            embarqueFinalDisp: data2,
          }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueFinalDisp: data2,
          }));
        } else {
          const data2 = converterData(filtrosRedux.embarqueFinalDisp);
          setValuesSelect((current) => ({
            ...current,
            embarqueFinalDisp: data2,
          }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueFinalDisp: data2,
          }));
        }
      } else {
        setValuesSelect((current) => ({
          ...current,
          embarqueFinalDisp: data2,
        }));
        setValuesPesquisa((current) => ({
          ...current,
          embarqueFinalDisp: data2,
        }));
      }
      if (filtrosRedux.embarqueInicio != "01-01-2020") {
        if (filtrosRedux.embarqueInicio === undefined) {
          setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueInicio: data1,
          }));
        } else {
          const data1 = converterData(filtrosRedux.embarqueInicio);
          setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueInicio: data1,
          }));
        }
      } else {
        setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
        setValuesPesquisa((current) => ({ ...current, embarqueInicio: data1 }));
      }
      if (filtrosRedux.embarqueInicioDisp != "01-01-2020") {
        if (filtrosRedux.embarqueInicioDisp === undefined) {
          setValuesSelect((current) => ({
            ...current,
            embarqueInicioDisp: data1Disp,
          }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueInicioDisp: data1Disp,
          }));
        } else {
          const data2 = converterData(filtrosRedux.embarqueInicioDisp);
          setValuesSelect((current) => ({
            ...current,
            embarqueInicioDisp: data2,
          }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueInicioDisp: data2,
          }));
        }
      } else {
        setValuesSelect((current) => ({
          ...current,
          embarqueInicioDisp: data1Disp,
        }));
        setValuesPesquisa((current) => ({
          ...current,
          embarqueInicioDisp: data1Disp,
        }));
      }
    }
    if (
      window.location.href.endsWith("dashboard") ||
      window.location.href.endsWith("faturamento") ||
      window.location.href.endsWith("estadia") ||
      window.location.href.endsWith("ontime") ||
      window.location.href.endsWith("ontime-embarque") ||
      window.location.href.endsWith("ontime-entrega") ||
      window.location.search.includes("id=") ||
      (window.location.href.endsWith("embarque") &&
        dadosContextoEmbarqueRedux != "templateEmbarque") ||
      window.location.href.endsWith("dashboard-cockpit") ||
      window.location.href.endsWith("cliente") ||
      window.location.href.endsWith("pedido") ||
      window.location.href.endsWith("motorista") ||
      window.location.href.endsWith("conjuntos") ||
      window.location.href.endsWith("responsaveis") ||
      window.location.href.endsWith("rastreamento") ||
      window.location.href.endsWith("dashboard-cotacao") ||
      window.location.href.endsWith("caminhoes") ||
      window.location.href.endsWith("carretas") ||
      window.location.href.endsWith("proprietario") ||
      window.location.href.endsWith("dashboard-ocorrencias") ||
      window.location.href.endsWith("diaria-veiculos") ||
      window.location.href.endsWith("mesoregiao")
    ) {
      setValuesPesquisa((current) => ({ ...current, item: filtrosRedux.item }));
      setValuesPesquisa((current) => ({
        ...current,
        pesquisa: filtrosRedux.pesquisa,
      }));
      if (filtrosRedux.embarqueFinal != "01-01-2050") {
        if (filtrosRedux.embarqueFinal === undefined) {
          var date = new Date();
          var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            .toLocaleDateString("pt-BR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
            .replace(/\//g, "-");
          const data2 = converterData(ultimoDia);
          setValuesSelect((current) => ({ ...current, embarqueFinal: data2 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueFinal: data2,
          }));
        } else {
          const data1 = converterData(filtrosRedux.embarqueFinal);
          setValuesSelect((current) => ({ ...current, embarqueFinal: data1 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueFinal: data1,
          }));
        }
      } else {
        var date = new Date();
        var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0)
          .toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .replace(/\//g, "-");
        const data2 = converterData(ultimoDia);
        setValuesSelect((current) => ({ ...current, embarqueFinal: data2 }));
        setValuesPesquisa((current) => ({ ...current, embarqueFinal: data2 }));
      }
      if (filtrosRedux.embarqueInicio != "01-01-2020") {
        if (filtrosRedux.embarqueInicio === undefined) {
          var date = new Date();
          var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1)
            .toLocaleDateString("pt-BR", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
            .replace(/\//g, "-");
          const data1 = converterData(primeiroDia);
          setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueInicio: data1,
          }));
        } else {
          const data1 = converterData(filtrosRedux.embarqueInicio);
          setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
          setValuesPesquisa((current) => ({
            ...current,
            embarqueInicio: data1,
          }));
        }
      } else {
        var date = new Date();
        var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1)
          .toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .replace(/\//g, "-");
        const data1 = converterData(primeiroDia);
        setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
        setValuesPesquisa((current) => ({ ...current, embarqueInicio: data1 }));
      }
    }
    if (
      (window.location.href.endsWith("faturamento") ||
        window.location.href.endsWith("estadia") ||
        window.location.href.endsWith("ontime") ||
        window.location.href.endsWith("ontime-embarque") ||
        window.location.href.endsWith("ontime-entrega") ||
        window.location.search.includes("id=") ||
        window.location.search.includes("mesoregiao") ||
        (window.location.href.endsWith("embarque") &&
          dadosContextoEmbarqueRedux != "templateEmbarque")) &&
      !filtrosRedux.embarqueInicio &&
      !filtrosRedux.embarqueFinal
    ) {
      var date = new Date();
      var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1)
        .toLocaleDateString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");
      var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0)
        .toLocaleDateString("pt-BR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
        .replace(/\//g, "-");
      const data1 = converterData(primeiroDia);
      const data2 = converterData(ultimoDia);
      setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
      setValuesSelect((current) => ({ ...current, embarqueFinal: data2 }));
      setValuesPesquisa((current) => ({ ...current, embarqueInicio: data1 }));
      setValuesPesquisa((current) => ({ ...current, embarqueFinal: data2 }));
    } else if (filtrosRedux.embarqueInicio && filtrosRedux.embarqueFinal) {
      const data1 = converterData(filtrosRedux.embarqueInicio);
      const data2 = converterData(filtrosRedux.embarqueFinal);
      setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
      setValuesSelect((current) => ({ ...current, embarqueFinal: data2 }));
      setValuesPesquisa((current) => ({ ...current, embarqueInicio: data1 }));
      setValuesPesquisa((current) => ({ ...current, embarqueFinal: data2 }));
    }
    if (window.location.href.endsWith("localizador-veiculos")) {
      var date = new Date();
      const data1 = formatarData(date);
      setValuesSelect((current) => ({ ...current, embarqueInicio: data1 }));
      setValuesSelect((current) => ({ ...current, embarqueFinal: data1 }));
      setValuesPesquisa((current) => ({ ...current, embarqueInicio: data1 }));
      setValuesPesquisa((current) => ({ ...current, embarqueFinal: data1 }));
    }
  }, []);

  const formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = data.getDate().toString().padStart(2, "0");
    const mes = (data.getMonth() + 1).toString().padStart(2, "0"); // getMonth() retorna mês de 0 a 11
    const ano = data.getFullYear();
    return `${ano}-${mes}-${dia}`;
  };

  useEffect(() => {
    if (window.location.href.endsWith("faturamento")) {
      setFiltrosRedux((current) => ({ ...current, menu: "faturamento" }));
    }
    if (
      window.location.href.endsWith("dashboard") ||
      window.location.href.endsWith("dashboard-segundo") ||
      window.location.href.endsWith("dashboard-terceiro")
    ) {
      setFiltrosRedux((current) => ({ ...current, menu: "dashboard" }));
    }
    if (
      window.location.href.endsWith("embarque") ||
      window.location.href.endsWith("mesoregiao") ||
      (window.location.search.includes("id=") &&
        dadosContextoEmbarqueRedux != "templateEmbarque")
    ) {
      setFiltrosRedux((current) => ({ ...current, menu: "embarque" }));
    }
    // if (!window.location.href.endsWith("embarque")) {
    //     var date = new Date();
    //     var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
    //     var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString('pt-BR', { year: "numeric", month: "numeric", day: "numeric" }).replace(/\//g, '-');
    //     const data1 = converterData(primeiroDia);
    //     const data2 = converterData(ultimoDia);
    //     setFiltrosRedux(current => ({ ...current, embarqueInicio: data1 }));
    //     setFiltrosRedux(current => ({ ...current, embarqueFinal: data2 }));
    // }
  }, []);

  return (
    <>
      <div className={classe}>
        {window.location.href.endsWith("embarque") &&
        window.location.href.endsWith("mesoregiao") &&
        dadosContextoEmbarqueRedux != "templateEmbarque" &&
        window.location.search.includes("id=") ? (
          <img
            className="botoes-mes"
            src={iconeAnterior}
            onClick={() => {
              mesAnterior();
            }}
          />
        ) : (
          <></>
        )}
        {window.location.href.endsWith("diaria-veiculos") && (
          <>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              className="div-datas-v2"
            >
              <p>Selecione o Tipo:</p>
              <div className="card-cidade-filial" onClick={onClickElegivelBrf}>
                {openElegivelBrf && (
                  <div className="select-options2">
                    <div className="option2">
                      <b>Tipo</b>
                    </div>
                    {opcoesBrf.map((item) => {
                      return (
                        <div
                          className={
                            item.opcao === opcaoSelecionadaElegivelBrf
                              ? "option selected"
                              : "option"
                          }
                          key={item.opcao}
                          onClick={() => {
                            onClickOpcaoElegivelBrf(
                              item.valor,
                              item.valor,
                              item.opcao,
                              "true"
                            );
                          }}
                        >
                          {item.opcao}
                        </div>
                      );
                    })}
                  </div>
                )}
                <b>{elegivelBrfFiltro}</b>
              </div>
            </div>
          </>
        )}
        {window.location.href.endsWith("localizador-veiculos") && (
          <>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              className="div-datas-v2"
            >
              <p>Selecione o Tipo:</p>
              <div className="card-cidade-filial" onClick={onClickTipo}>
                {openTipo && (
                  <div className="select-options2">
                    <div className="option2">
                      <b>Tipo</b>
                    </div>
                    {opcoesTipo.map((item) => {
                      return (
                        <div
                          className={
                            item.opcao === opcaoSelecionadaTipo
                              ? "option selected"
                              : "option"
                          }
                          key={item.opcao}
                          onClick={() => {
                            onClickOpcaoTipo(
                              item.valor,
                              item.valor,
                              item.opcao,
                              "true"
                            );
                          }}
                        >
                          {item.opcao}
                        </div>
                      );
                    })}
                  </div>
                )}
                <b>{tipoFiltro}</b>
              </div>
            </div>
          </>
        )}
        {((window.location.href.endsWith("embarque") &&
          dadosContextoEmbarqueRedux != "templateEmbarque") ||
          window.location.href.endsWith("localizador-veiculos") ||
          window.location.href.endsWith("mesoregiao") ||
          window.location.href.endsWith("diaria-veiculos") ||
          window.location.href.endsWith("dashboard") ||
          window.location.href.endsWith("dashboard-segundo") ||
          window.location.href.endsWith("dashboard-terceiro") ||
          window.location.href.endsWith("dashboard-cockpit") ||
          window.location.href.endsWith("faturamento") ||
          window.location.href.endsWith("estadia") ||
          window.location.href.endsWith("responsaveis") ||
          window.location.href.endsWith("dashboard-ocorrencias") ||
          window.location.href.endsWith("ontime") ||
          window.location.href.endsWith("ontime-embarque") ||
          window.location.href.endsWith("ontime-entrega") ||
          window.location.href.endsWith("dashboard-cotacao") ||
          window.location.search.includes("id=")) && (
          <>
            <div className="div-datas-v2">
              <p className="texto-filtro" htmlFor="embarque">
                Início
              </p>
            </div>
          </>
        )}
        {((window.location.href.endsWith("embarque") &&
          dadosContextoEmbarqueRedux != "templateEmbarque") ||
          window.location.href.endsWith("localizador-veiculos") ||
          window.location.href.endsWith("mesoregiao") ||
          window.location.href.endsWith("diaria-veiculos") ||
          window.location.href.endsWith("dashboard") ||
          window.location.href.endsWith("dashboard-segundo") ||
          window.location.href.endsWith("dashboard-terceiro") ||
          window.location.href.endsWith("dashboard-cockpit") ||
          window.location.href.endsWith("faturamento") ||
          window.location.href.endsWith("estadia") ||
          window.location.href.endsWith("responsaveis") ||
          window.location.href.endsWith("dashboard-ocorrencias") ||
          window.location.href.endsWith("ontime") ||
          window.location.href.endsWith("ontime-embarque") ||
          window.location.href.endsWith("ontime-entrega") ||
          window.location.href.endsWith("dashboard-cotacao") ||
          window.location.search.includes("id=")) && (
          <>
            <div className="div-datas-v2-margem">
              <input
                type={"date"}
                className="input-filtro-date"
                value={valuesSelect.embarqueInicio}
                style={{ width: 120 }}
                onChange={(e) => {
                  const formattedDate = converterData(e.target.value);
                  setValuesPesquisa((current) => ({
                    ...current,
                    embarqueInicio: e.target.value,
                  }));
                  setFiltrosRedux((current) => ({
                    ...current,
                    embarqueInicio: formattedDate,
                  }));
                  setValuesSelect((current) => ({
                    ...current,
                    embarqueInicio: e.target.value,
                  }));
                  setInputDisable(false);
                }}
                id="embarque"
              ></input>
            </div>
            <img src={iconeStroke} />
            <div className="div-datas-v2-margem-2">
              <input
                type={"date"}
                className="input-filtro-date"
                value={valuesSelect.embarqueFinal}
                style={{ width: 120 }}
                onChange={(e) => {
                  const formattedDate = converterData(e.target.value);
                  setValuesPesquisa((current) => ({
                    ...current,
                    embarqueFinal: e.target.value,
                  }));
                  setFiltrosRedux((current) => ({
                    ...current,
                    embarqueFinal: formattedDate,
                  }));
                  setValuesSelect((current) => ({
                    ...current,
                    embarqueFinal: e.target.value,
                  }));
                  setInputDisable(false);
                }}
                id="embarquef"
              ></input>
            </div>
          </>
        )}
        {((window.location.href.endsWith("embarque") &&
          dadosContextoEmbarqueRedux != "templateEmbarque") ||
          window.location.href.endsWith("localizador-veiculos") ||
          window.location.href.endsWith("mesoregiao") ||
          window.location.href.endsWith("diaria-veiculos") ||
          window.location.href.endsWith("dashboard") ||
          window.location.href.endsWith("dashboard-segundo") ||
          window.location.href.endsWith("dashboard-terceiro") ||
          window.location.href.endsWith("dashboard-cockpit") ||
          window.location.href.endsWith("faturamento") ||
          window.location.href.endsWith("estadia") ||
          window.location.href.endsWith("dashboard-ocorrencias") ||
          window.location.href.endsWith("responsaveis") ||
          window.location.href.endsWith("ontime") ||
          window.location.href.endsWith("ontime-embarque") ||
          window.location.href.endsWith("ontime-entrega") ||
          window.location.href.endsWith("dashboard-cotacao") ||
          window.location.search.includes("id=")) && (
          <>
            <div className="div-datas-v2">
              <p className="texto-filtro" htmlFor="embarque">
                Fim
              </p>
            </div>
          </>
        )}
        {window.location.href.endsWith("diaria-veiculos") && (
          <>
            <div className="div-datas-v2">
              <p className="texto-filtro" htmlFor="embarque">
                Início Disp
              </p>
            </div>
          </>
        )}
        {window.location.href.endsWith("diaria-veiculos") && (
          <>
            <div className="div-datas-v2-margem">
              <input
                type={"date"}
                className="input-filtro-date"
                value={valuesSelect.embarqueInicioDisp}
                style={{ width: 120 }}
                onChange={(e) => {
                  const formattedDate = converterData(e.target.value);
                  setValuesPesquisa((current) => ({
                    ...current,
                    embarqueInicioDisp: e.target.value,
                  }));
                  setFiltrosRedux((current) => ({
                    ...current,
                    embarqueInicioDisp: formattedDate,
                  }));
                  setValuesSelect((current) => ({
                    ...current,
                    embarqueInicioDisp: e.target.value,
                  }));
                  setInputDisable(false);
                }}
                id="embarque"
              ></input>
            </div>
            <img src={iconeStroke} />
            <div className="div-datas-v2-margem-2">
              <input
                type={"date"}
                className="input-filtro-date"
                value={valuesSelect.embarqueFinalDisp}
                style={{ width: 120 }}
                onChange={(e) => {
                  const formattedDate = converterData(e.target.value);
                  setValuesPesquisa((current) => ({
                    ...current,
                    embarqueFinalDisp: e.target.value,
                  }));
                  setFiltrosRedux((current) => ({
                    ...current,
                    embarqueFinalDisp: formattedDate,
                  }));
                  setValuesSelect((current) => ({
                    ...current,
                    embarqueFinalDisp: e.target.value,
                  }));
                  setInputDisable(false);
                }}
                id="embarquef"
              ></input>
            </div>
          </>
        )}
        {window.location.href.endsWith("diaria-veiculos") && (
          <>
            <div className="div-datas-v2">
              <p className="texto-filtro" htmlFor="embarque">
                Fim Disp
              </p>
            </div>
          </>
        )}
        {window.location.href.endsWith("embarque") &&
        window.location.href.endsWith("mesoregiao") &&
        dadosContextoEmbarqueRedux != "templateEmbarque" &&
        window.location.search.includes("id=") ? (
          <img
            className="botoes-mes"
            src={iconeProximo}
            onClick={() => {
              mesSeguinte();
            }}
          />
        ) : (
          <></>
        )}
        {!window.location.href.endsWith("dashboard-cotacao") && (
          <>
            <div className="div-datas-v2">
              <div className="card-cidade-filial" onClick={onClickFiltrar}>
                <img className="pointer-filtro" src={iconeFiltro} />
                {openFiltrar && (
                  <div className="select-options">
                    <div className="option2">
                      <b>Filtrar</b>
                    </div>
                    {itensFiltro.map((item) => (
                      <div
                        className={
                          item.field === opcaoSelecionada
                            ? "option selected"
                            : "option"
                        }
                        key={item.field}
                        onClick={() => {
                          onClickOpcaoFiltrar(
                            item.field,
                            item.field,
                            item.nomeColuna
                          );
                        }}
                      >
                        {item.nomeColuna}
                      </div>
                    ))}
                  </div>
                )}
                <b>Filtrar Por: </b>
                <b>{filtroSelecionado}</b>
              </div>
            </div>
            <div className="div-datas-v2">
              {loadingInput ? (
                <>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                  </Box>
                </>
              ) : (
                <>
                  <div style={{ position: "relative", width: 400 }}>
                    <input
                      type={"text"}
                      className="input-filtro-data-v2"
                      value={valuesPesquisa.pesquisa}
                      placeholder="Pesquisar"
                      style={{ width: 400 }}
                      onChange={(e) => {
                        setValuesPesquisa((current) => ({
                          ...current,
                          pesquisa: e.target.value,
                        }));
                        setFiltrosRedux((current) => ({
                          ...current,
                          pesquisa: e.target.value,
                        }));
                        setInputDisable(false);
                      }}
                      id="embarque"
                    ></input>
                    {
                      // inputDisable ? (
                      //     <Tooltip title={<p>Digite para filtrar</p>}>
                      //         <img src={iconePesquisa} style={{
                      //             position: 'absolute',
                      //             left: 365,
                      //             top: 5,
                      //         }} />
                      //     </Tooltip>
                      //) : (
                      <img
                        src={iconePesquisa}
                        style={{
                          position: "absolute",
                          left: 365,
                          top: 5,
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          buscarSearch();
                        }}
                      />
                      //)
                    }
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {/* <div className="div-datas-v2">
                <p htmlFor="embarque">Filtrar Por:</p>
                <select
                    className='input-filtro-data'
                    value={valuesPesquisa.item}
                    style={{ width: 150 }}
                    onChange={(e) => {
                        setValuesPesquisa(current => ({ ...current, item: e.target.value }));
                        setFiltrosRedux(current => ({ ...current, item: e.target.value }));
                    }}
                    id='embarque'>
                    <option disabled={valuesPesquisa.item} value="">Selecione</option>
                    {itensFiltro.map((item) => (
                        <option value={item.field}>
                            {item.nomeColuna}
                        </option>
                    ))}
                </select>
            </div> */}
        {(window.location.href.endsWith("dashboard") ||
          window.location.href.endsWith("dashboard-segundo") ||
          window.location.href.endsWith("dashboard-terceiro") ||
          window.location.href.endsWith("faturamento") ||
          window.location.href.endsWith("estadia") ||
          window.location.href.endsWith("ontime") ||
          window.location.href.endsWith("ontime-embarque") ||
          window.location.href.endsWith("ontime-entrega") ||
          window.location.href.endsWith("responsaveis") ||
          window.location.href.endsWith("relatorio-proprietarios") ||
          window.location.href.endsWith("localizador-veiculos") ||
          window.location.href.endsWith("diaria-veiculos")) && (
          <>
            <div className="div-datas-v2">
              <div className="card-cidade-filial" onClick={onClickCidade}>
                <img className="pointer" src={iconeCidadeFiltro} />
                {openCidade && (
                  <div className="select-options2">
                    <div className="option2">
                      <b>Filial</b>
                    </div>
                    {dadosFilialRedux.map((item) => {
                      if (item.nomeFilial != "Modelo Viagens") {
                        return (
                          <div
                            className={
                              item.contexto === opcaoSelecionadaCidade
                                ? "option selected"
                                : "option"
                            }
                            key={item.nomeFilial}
                            onClick={() => {
                              onClickOpcaoCidade(
                                item.contexto,
                                item.contexto,
                                item.nomeFilial,
                                "true"
                              );
                            }}
                          >
                            {item.nomeFilial}
                          </div>
                        );
                      }
                    })}
                  </div>
                )}
                <b>{cidadeFiltro}</b>
              </div>
            </div>
          </>
        )}
        {window.location.href.endsWith("dashboard-cockpit") && (
          <>
            <div className="div-datas-v2">
              <div className="card-grupo-cliente" onClick={onClickGrupoCliente}>
                {/* <img className="pointer" src={iconeGrupoClienteFiltro} /> */}
                {openGrupoCliente && (
                  <div className="select-options3">
                    <div className="option3">
                      <b>Grupo Cliente</b>
                    </div>
                    {listaGrupos.map((item) => {
                      return (
                        <div
                          className={
                            item.idGrupoCliente === opcaoSelecionadaGrupoCliente
                              ? "option selected"
                              : "option"
                          }
                          key={item.nome}
                          onClick={() => {
                            onClickOpcaoGrupoCliente(
                              item.idGrupoCliente,
                              item.idGrupoCliente,
                              item.nome,
                              "true"
                            );
                          }}
                        >
                          {item.nome}
                        </div>
                      );
                    })}
                  </div>
                )}
                <b>Grupo: </b>
                <b>{grupoClienteFiltro}</b>
              </div>
            </div>
          </>
        )}
        {window.location.href.endsWith("dashboard-cotacao") && (
          <>
            <Tooltip title={<p>Buscar</p>}>
              <SearchIcon
                className="icon-filtro"
                style={{ width: "35px", height: "35px" }}
                onClick={(e) => {
                  buscarSearch();
                }}
              />
            </Tooltip>
          </>
        )}
        <Tooltip title={<p>Limpar Filtro</p>}>
          <ClearIcon
            className="icon-filtro"
            style={{ width: "35px", height: "35px" }}
            onClick={(e) => {
              limpar();
              setValuesPesquisa([]);
              setFiltrosRedux([]);
            }}
          />
        </Tooltip>
        {window.location.href.endsWith("embarque") &&
          window.location.search.includes("id=") &&
          dadosContextoEmbarqueRedux != "templateEmbarque" && (
            <>
              <div className="div-datas-v2">
                <div className="card-filtro" onClick={onClickOpcionais}>
                  {openOpcionais && (
                    <div className="select-options4">
                      <div className="option4">
                        <input
                          type="checkbox"
                          className="check-periodo"
                          checked={periodoCompleto}
                          onChange={(e) => {
                            setCompleto(e.target.checked);
                            setPeriodoCompleto(e.target.checked);
                          }}
                        ></input>
                        <p className="texto-periodo">Período Completo</p>
                      </div>
                      <div className="option4">
                        <input
                          type="checkbox"
                          className="check-periodo"
                          checked={estadia}
                          onChange={(e) => {
                            setEstadia(e.target.checked);
                            setFiltroEstadia(e.target.checked);
                          }}
                        ></input>
                        <p className="texto-periodo">Estadia</p>
                      </div>
                    </div>
                  )}
                  <b>Opcionais</b>
                </div>
              </div>
            </>
          )}
        {dadosContextoEmbarqueRedux != "templateEmbarque" &&
          !window.location.href.endsWith("dashboard-cotacao") &&
          !window.location.href.endsWith("localizador-veiculos") && (
            <div className="div-datas-v2">
              <div
                className="card-filtro"
                onClick={(e) => {
                  setOpenMultiplosFiltros(true);
                }}
              >
                <b>Múltiplos Filtros</b>
              </div>
            </div>
          )}
        {multiplosFiltrosRedux && multiplosFiltrosRedux.length && (
          <div
            className="div-datas-v2"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 10,
            }}
          >
            {multiplosFiltrosRedux.map((filtro) => {
              return (
                <div className="card-filtro-multiple">
                  <b>
                    {filtro.coluna}: {filtro.valor}
                  </b>
                </div>
              );
            })}
          </div>
        )}
        <div className="div-datas-v2"></div>
        {/* <div className="div-datas-v2">
                <Tooltip title={<p>Filtrar</p>}>
                    <FilterAltIcon2 className="icon-filtro"
                        onClick={(e) => {
                            buscarSearch();
                            // setFiltrosRedux(valuesSelect);
                        }} />
                </Tooltip>
            </div>
             */}
      </div>
      <div>
        <ModalMultiplosFiltros
          titulo="Filtrar"
          tituloConfirmar="OK"
          open={openMultiplosFiltros}
          onClick={buscarSearch}
          onClose={handleCloseMultiplosFiltros}
          classOption={classOption}
          itensFiltro={itensFiltro}
          onClickFiltrar={onClickFiltrar2}
          openFiltrar={openFiltrar2}
          opcaoSelecionada={opcaoSelecionada}
          onClickOpcaoFiltrar={onClickOpcaoFiltrar}
          filtroSelecionado={filtroSelecionado}
          loadingInput={loadingInput}
          setValuesPesquisa={setValuesPesquisa}
          setFiltrosRedux={setFiltrosRedux}
          setInputDisable={setInputDisable}
          buscarSearch={buscarSearch}
          valuesPesquisa={valuesPesquisa}
        />
      </div>
    </>
  );
}

export default FiltroV2;
