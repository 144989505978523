import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import "./Faturamento.css";
import CoresRedux from "../../../contexts/Cor";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import { motion } from "framer-motion";
import { editaMeta, getMetaContexto, getRelatorio } from "../../../services/ModuloGerenciador/Relatorio";
import TabelaRedux from "../../../contexts/Tabela";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import Tabela from "../../../components/Tabela/Tabela";
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon2 from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import FilialCidade from "../../../contexts/CidadesFilial";
import { getFaturamento } from "../../../services/ModuloGerenciador/Search";
import Filtro from "../../../components/Filtro/Filtro";
import FiltroV2 from "../../../components/FiltroV2/FiltroV2";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import iconeFaturamentos from "../../../images/iconeFaturamentos.png";
import iconeTelaCheia from "../../../images/iconeTelaCheia.png";
import Charts from "../../../components/Charts/Charts.tsx";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import { Button, Dialog, DialogTitle } from "@mui/material";
import MultiplosFiltrosRedux from "../../../contexts/MultiplosFiltros.js";
import { useLocation } from "react-router-dom";
import FiltrosRedux from "../../../contexts/Filtros.js";
import { CSVLink } from "react-csv";
import { IconButton } from "@mui/material";
import iconeDownload from "../../../images/iconeDownload.png";

function Faturamento() {

    const headersFaturamento = {
        'Faturamento': [
            {
                'nomeColuna': 'Adiantamento',
                'field': 'adiantamento'
            },
            {
                'nomeColuna': 'Adicional Empresa',
                'field': 'adicionalEmpresa'
            },
            {
                'nomeColuna': 'Adicional Motorista',
                'field': 'adicionalMotorista'
            },
            {
                'nomeColuna': 'Classificação',
                'field': 'classificacao'
            },
            {
                'nomeColuna': 'Frete Fiscal',
                'field': 'freteFiscal'
            },
            {
                'nomeColuna': 'Frete Motorista',
                'field': 'freteMotorista'
            },
            {
                'nomeColuna': 'ICMS',
                'field': 'icms'
            },
            {
                'nomeColuna': 'KM',
                'field': 'km'
            },
            {
                'nomeColuna': 'Margem',
                'field': 'margem'
            },
            {
                'nomeColuna': 'Pedágio',
                'field': 'pedagio'
            },
            {
                'nomeColuna': 'Peso',
                'field': 'peso'
            },
            {
                'nomeColuna': 'Saldo',
                'field': 'saldo'
            }
        ],
    };

    const [values, setValues] = useState([]);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosFilialRedux } = useContext(FilialCidade);

    const [openFaturamentos, setOpenFaturamentos] = useState(true);

    const [openProjecao, setOpenProjecao] = useState(false);

    const [openTelaCheia, setOpenTelaCheia] = useState(true);

    const [loading, setLoading] = useState(true);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [dadosTabela, setDadosTabela] = useState([]);

    const [dadosRelatorio, setDadosRelatorio] = useState([]);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const { multiplosFiltrosRedux, setMultiplosFiltrosRedux } = useContext(MultiplosFiltrosRedux);

    const [dadosGrafico, setDadosGrafico] = useState([]);

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const onClickOpenFaturamentos = () => {
        setOpenFaturamentos(!openFaturamentos);
    }

    const onClickOpenProjecao = () => {
        setOpenProjecao(!openProjecao);
    }

    const onClickTelaCheia = () => {
        setOpenFaturamentos(false);
        setOpenTelaCheia(!openTelaCheia);
    }

    const [openMetas, setOpenMetas] = useState(false);

    const handleCloseMetas = (value) => {
        setOpenMetas(false);
    };

    const cadastrarMeta = (value) => {
        editarMeta();
    };

    const handleOpenMetas = (value) => {
        setOpenMetas(true);
    };

    const buscarDependenciasData = async (contexto, dataInicio, dataFim) => {
        setLoading(true);
        let dados = [];
        await getRelatorio(dadosUsuarioRedux.token, contexto, dataInicio, dataFim).then((result) => {
            setDadosTabela(result.data.embarques);
            setDadosGrafico(result.data.projecaoFaturamento);
            dados.push(result.data.paginaRelatorioValores);
            setDadosRelatorio(dados[0]);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const buscarMeta = async () => {
        await getMetaContexto(dadosUsuarioRedux.token, valuesPesquisa.contexto).then((result) => {
            setValues(result.data);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };


    const editarMeta = async () => {
        await editaMeta(dadosUsuarioRedux.token, values).then((result) => {
            setOpenMetas(false);
            buscarMeta();
            buscarSearch();
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    const buscarDependenciasDataInicial = async (contexto, dataInicio, dataFim) => {
        setLoading(true);
        let dados = [];
        await getRelatorio(dadosUsuarioRedux.token, contexto, dataInicio, dataFim).then((result) => {
            setDadosTabela(result.data.embarques);
            dados.push(result.data.paginaRelatorioValores);
            setDadosRelatorio(dados[0]);
            setTabelaRedux({});
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    };

    useEffect(() => {
        setValorInicial();
    }, []);

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
    }, [loading]);

    const filtrarData = () => {
        buscarDependenciasData(valuesPesquisa.contexto, valuesPesquisa.primeiroDia, valuesPesquisa.ultimoDia);
    };

    const limparFiltro = () => {
        setValorInicial();
    };

    const setValorInicial = () => {
        const date = new Date();
        const contexto = 'todos';
        const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
        const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        var diaPrimeiro = String(primeiroDia?.getDate()).padStart(2, '0');
        var diaUltimo = String(ultimoDia?.getDate()).padStart(2, '0');
        var mesPriemiro = String(date?.getMonth() + 1).padStart(2, '0');
        var anoPrimeiro = date?.getFullYear();
        var dateInicio = anoPrimeiro + '-' + mesPriemiro + '-' + diaPrimeiro;
        var dateFinal = anoPrimeiro + '-' + mesPriemiro + '-' + diaUltimo;
        setValuesPesquisa(current => ({ ...current, contexto: contexto }));
        setValuesPesquisa(current => ({ ...current, embarqueInicio: dateInicio }));
        setValuesPesquisa(current => ({ ...current, embarqueFinal: dateFinal }));
        // buscarDependenciasDataInicial(contexto, dateInicio, dateFinal);
    };

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Faturamento') {
                    setSemAutorizacao(false);
                }
                if (element === 'Cadastrar Meta') {
                    setSemAutorizacaoMetas(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra == 'Faturamento') {
                        setSemAutorizacao(false);
                    }
                    if (dado.regras.regra == 'Cadastrar Meta') {
                        setSemAutorizacaoMetas(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            }).catch((error) => {
                if (error.response.data.lenght > 200) {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                } else {
                    setTextoErro(error.response.data);
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        }
    });

    const itensFiltro = [
        {
            'nomeColuna': 'Tipo Carroceria',
            'field': 'tipoCarroceria'
        },
        {
            'nomeColuna': 'Descrição',
            'field': 'descricao'
        },
        {
            'nomeColuna': 'Empresa',
            'field': 'empresa'
        },
        {
            'nomeColuna': 'Filial',
            'field': 'filial'
        },
        {
            'nomeColuna': 'Unidade de Embarque',
            'field': 'unidEmbarque'
        },
        {
            'nomeColuna': 'Cidade Origem',
            'field': 'cidadeOrigem'
        },
        {
            'nomeColuna': 'Estado Origem',
            'field': 'estadoOrigem'
        },
        {
            'nomeColuna': 'Cidade Destino',
            'field': 'cidadeDestino'
        },
        {
            'nomeColuna': 'Estado Destino',
            'field': 'estadoDestino'
        },
        {
            'nomeColuna': 'Pedido',
            'field': 'pedido'
        },
        {
            'nomeColuna': 'CTE',
            'field': 'cte'
        },
        {
            'nomeColuna': 'Previsão Viagem',
            'field': 'previsaoEmbarque'
        },
        {
            'nomeColuna': 'Data Viagem',
            'field': 'dataEmbarque'
        },
        {
            'nomeColuna': 'Previsão Entrega',
            'field': 'previsaoEntrega'
        },
        {
            'nomeColuna': 'Data Entrega',
            'field': 'dataEntrega'
        },
        {
            'nomeColuna': 'Data Hora Chegada Viagem',
            'field': 'dataHoraChegadaEmbarque'
        },
        {
            'nomeColuna': 'Data Hora Chegada Entrega',
            'field': 'dataHoraChegadaEntrega'
        },
        {
            'nomeColuna': 'Data Hora Entregue',
            'field': 'dataHoraEntregue'
        },
        {
            'nomeColuna': 'Data Hora Embarcado',
            'field': 'dataHoraEmbarcado'
        },
        {
            'nomeColuna': 'Aceite',
            'field': 'aceite'
        },
        {
            'nomeColuna': 'Status',
            'field': 'status'
        },
        {
            'nomeColuna': 'Placa Cavalo',
            'field': 'placaCavalo'
        },
        {
            'nomeColuna': 'Placa Carreta',
            'field': 'placaCarreta'
        },
        {
            'nomeColuna': 'Placa Segunda Carreta',
            'field': 'placaCarreta2'
        },
        {
            'nomeColuna': 'Proprietario',
            'field': 'proprietario'
        },
        {
            'nomeColuna': 'Motorista',
           'field': 'veiculoMotorista',
        },
        {
            'nomeColuna': 'ICMS',
            'field': 'icms'
        },
        {
            'nomeColuna': 'Frete Motorista',
            'field': 'freteMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Frete Fiscal',
            'field': 'freteFiscal',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Empresa',
            'field': 'adicionalEmpresa',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Adicional Motorista',
            'field': 'adicionalMotorista',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Pedagio',
            'field': 'pedagio',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Classificação',
            'field': 'classificacao',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Margem',
            'field': 'margem'
        },
        {
            'nomeColuna': 'Adiantamento',
            'field': 'adiantamento',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Saldo',
            'field': 'saldo',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Meio Pagamento',
            'field': 'meioPagamento'
        },
        {
            'nomeColuna': 'Meio Pagamento Dois',
            'field': 'meioPagamento2'
        },
        {
            'nomeColuna': 'Km',
            'field': 'km',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Cliente',
            'field': 'cliente'
        },
        {
            'nomeColuna': 'Embarcador',
            'field': 'embarcador'
        },
        {
            'nomeColuna': 'Destinatario',
            'field': 'destinatario'
        },
        {
            'nomeColuna': 'LOAD',
            'field': 'load'
        },
        {
            'nomeColuna': 'Mercadoria',
            'field': 'mercadoria'
        },
        {
            'nomeColuna': 'Peso',
            'field': 'peso',
            'tipo': 'number'
        },
        {
            'nomeColuna': 'Embalagem',
            'field': 'embalagem'
        },
        {
            'nomeColuna': 'Observação',
            'field': 'observacao'
        },
        {
            'nomeColuna': 'Frota',
            'field': 'frota'
        },
        {
            'nomeColuna': 'Cancelado',
            'field': 'cancelado'
        },
        {
            'nomeColuna': 'Motivo',
            'field': 'motivo'
        },
        {
            'nomeColuna': 'Usuário Responsável',
            'field': 'usuarioResponsavel'
        },
        {
            'nomeColuna': 'Elegível BRF',
            'field': 'elegivelBrf'
        },
    ];

    const [valuesPesquisa, setValuesPesquisa] = useState([]);

    const limpar = () => {
        setLoading(true);
        setValuesPesquisa([]);
        setValorInicial();
        buscarSearch();
        buscarMeta();
        setTimeout(() => {
            setLoading(false);
        }, 0);
    }

    const buscarOrdenacao = (ordenarPor) => {
        buscarSearch(ordenarPor)
    }

    const buscarSearch = async (ordenarPor) => {
        setLoading(true);
        let dados = [];
        let pesquisaFormatada = `${valuesPesquisa.item}=${valuesPesquisa.pesquisa}`;
        if (multiplosFiltrosRedux && multiplosFiltrosRedux.length > 0) {
            for (let filtro of multiplosFiltrosRedux) {
                pesquisaFormatada += `&${filtro.field}=${filtro.valor}`;
            }
        }
        await getFaturamento(dadosUsuarioRedux.token, pesquisaFormatada, valuesPesquisa.contexto, valuesPesquisa.embarqueInicio, valuesPesquisa.embarqueFinal, ordenarPor).then((result) => {
            setDadosTabela(result.data.embarques);
            setDadosGrafico(result.data.projecaoFaturamento);
            dados.push(result.data.paginaRelatorioValores);
            setDadosRelatorio(dados[0]);
            setLoading(false);
        }).catch((error) => {
            if (error.response.data.lenght > 200) {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setTextoErro(error.response.data);
            }
            setOpenToastErro(true);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (valuesPesquisa.contexto != undefined) {
            buscarSearch();
            buscarMeta();
        }
    }, [valuesPesquisa.contexto])

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    const [semAutorizacaoMetas, setSemAutorizacaoMetas] = useState(true);

    const { filtrosRedux, setFiltrosRedux } = useContext(FiltrosRedux);

    const location = useLocation();

    useEffect(() => {
        setValuesPesquisa(current => ({ ...current, item: undefined }));
        setValuesPesquisa(current => ({ ...current, pesquisa: undefined }));
        setFiltrosRedux(current => ({ ...current, item: undefined }));
        setFiltrosRedux(current => ({ ...current, pesquisa: undefined }));
    }, [location.pathname]);

    const headers = [
        { label: "ID", key: "idEmbarque" },
        { label: "Empresa", key: "empresa" },
        { label: "Filial", key: "filial" },
        { label: "Unidade Embarque", key: "unidEmbarque" },
        { label: "Cidade Origem", key: "cidadeOrigem" },
        { label: "Estado Origem", key: "estadoOrigem" },
        { label: "Cidade Destino", key: "cidadeDestino" },
        { label: "Estado Destino", key: "estadoDestino" },
        { label: "Pedido", key: "pedido" },
        { label: "Previsão Viagem", key: "previsaoEmbarque" },
        { label: "Data Viagem", key: "dataEmbarque" },
        { label: "Previsão Entrega", key: "previsaoEntrega" },
        { label: "Data Entrega", key: "dataEntrega" },
        { label: "Aceite", key: "aceite" },
        { label: "Status", key: "status" },
        { label: "Placa Cavalo", key: "placaCavalo" },
        { label: "Placa Carreta", key: "placaCarreta" },
        { label: "Placa Carreta 2", key: "placaCarreta2" },
        { label: "Proprietário", key: "proprietario" },
        { label: "Motorista", key: "motorista" },
        { label: "Frete Fiscal", key: "freteFiscal" },
        { label: "ICMS", key: "icms" },
        { label: "Frete Motorista", key: "freteMotorista" },
        { label: "Adicional Empresa", key: "adicionalEmpresa" },
        { label: "Adicional Motorista", key: "adicionalMotorista" },
        { label: "Pedagio", key: "pedagio" },
        { label: "Classificacao", key: "classificacao" },
        { label: "Adiantamento", key: "adiantamento" },
        { label: "Saldo", key: "saldo" },
        { label: "Meio Pagamento", key: "meioPagamento" },
        { label: "Meio Pagamento 2", key: "meioPagamento2" },
        { label: "KM", key: "km" },
        { label: "Cliente", key: "cliente" },
        { label: "Embarcador", key: "embarcador" },
        { label: "Destinatário", key: "destinatario" },
        { label: "Load", key: "load" },
        { label: "Mercadoria", key: "mercadoria" },
        { label: "Peso", key: "peso" },
        { label: "Embalagem", key: "embalagem" },
        { label: "Observação", key: "observacao" },
        { label: "Frota", key: "frota" },
        { label: "Cancelado", key: "cancelado" },
        { label: "Motivo", key: "motivo" },
    ];

    const headersFaturamentoCSV = [
        { label: "Adiantamento", key: "adiantamento" },
        { label: "Adicional Empresa", key: "adicionalEmpresa" },
        { label: "Adicional Motorista", key: "adicionalMotorista" },
        { label: "Classificação", key: "classificacao" },
        { label: "Frete Fiscal", key: "freteFiscal" },
        { label: "Frete Motorista", key: "freteMotorista" },
        { label: "ICMS", key: "icms" },
        { label: "KM", key: "km" },
        { label: "Margem", key: "margem" },
        { label: "Pedágio", key: "pedagio" },
        { label: "Peso", key: "peso" },
        { label: "Saldo", key: "saldo" }
    ];

    const chavesFormatar = ["previsaoEmbarque", "dataEmbarque", "previsaoEntrega", "dataEntrega"];
    const listaFormatada = dadosTabela.map((obj) => {
        const novoObj = {};
        Object.keys(obj).forEach(key => {
            if (chavesFormatar.includes(key) && obj[key]) {
                novoObj[key] = obj[key].replace(/T00:00:00/, "");
            } else {
                novoObj[key] = obj[key];
            }
        });
        return novoObj;
    });

    const dadosRelatorioCSV = [];
    const formatarNumero = (numero) => {
        return numero.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };
    const dadosFormatados = {};

    for (const key in dadosRelatorio) {
        if (dadosRelatorio.hasOwnProperty(key)) {
            dadosFormatados[key] = formatarNumero(dadosRelatorio[key]);
        }
    }
    dadosRelatorioCSV.push(dadosFormatados);

    const handleDownload = () => {
        document.getElementById('csv-download-1').click();
        document.getElementById('csv-download-2').click();
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <><NavbarV3 selecionado="Faturamento" /><SemAutorizacao></SemAutorizacao></>
            ) : (
                <div>
                    {
                        openTelaCheia ? (
                            <NavbarV3 selecionado="Faturamento" />
                        ) : (
                            <></>
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                <div className="faturamentos">
                                    <div className="header-faturamentos">
                                        <div className="flex2">
                                            <div className="flex3">
                                                <p className="indicador-principal-faturamento-v2">Faturamento</p>
                                                {
                                                    !openTelaCheia ? (
                                                        <p className="texto-tela-cheia">Modo Tela Cheia Ativo</p>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex3">
                                                {
                                                    !openTelaCheia ? (
                                                        <img onClick={onClickTelaCheia} className="imagens-faturamentos" src={iconeTelaCheia} />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <>
                                                    <CSVLink
                                                        id="csv-download-1" className="csv-botao" data={listaFormatada} headers={headers} filename={`Dados Viagens`}
                                                    />
                                                    <CSVLink
                                                        id="csv-download-2" className="csv-botao" data={dadosRelatorioCSV} headers={headersFaturamentoCSV} filename={`Dados Faturamento`}
                                                    />
                                                    <Tooltip title={<p>Baixar dados em formato .csv</p>}>
                                                        <IconButton className="butao-csv" onClick={handleDownload}>
                                                            <img src={iconeDownload} alt="Download Icon" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        openTelaCheia ? (
                                            <div className="filtro-embarque-v2">
                                                <FiltroV2
                                                    classe='div-filtrar-faturamento-v2'
                                                    classOption='select-options'
                                                    itensFiltro={itensFiltro}
                                                    valuesPesquisa={valuesPesquisa}
                                                    setValuesPesquisa={setValuesPesquisa}
                                                    buscarSearch={buscarSearch}
                                                    limpar={limpar}
                                                />
                                                <div className="fat-botoes">
                                                    <CustomButtonV2
                                                        classCss="custom-button-faturamento"
                                                        classText="button-texto-faturamento"
                                                        titulo={openProjecao ? "Ocultar Gráfico" : "Exibir Gráfico"}
                                                        cor="#FFFFFF"
                                                        onClick={onClickOpenProjecao}
                                                    />
                                                    {
                                                        semAutorizacaoMetas ? (
                                                            <></>
                                                        ) : (
                                                            <CustomButtonV2
                                                                classCss="custom-button-faturamento"
                                                                classText="button-texto-faturamento"
                                                                titulo="Cadastrar Meta"
                                                                cor="#FFFFFF"
                                                                onClick={handleOpenMetas}
                                                            />
                                                        )
                                                    }
                                                    <img onClick={onClickOpenFaturamentos} className="imagens-faturamentos" src={iconeFaturamentos} />
                                                    <img onClick={onClickTelaCheia} className="imagens-faturamentos" src={iconeTelaCheia} />
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    {
                                        openFaturamentos ? (
                                            <div className="container-faturamento-card">
                                                {headersFaturamento.Faturamento.map((header) => (
                                                    <div className="card-faturamento" key={header.field}>
                                                        <div className="card-header">
                                                            {header.nomeColuna}
                                                        </div>
                                                        <div className="card-body">
                                                            {dadosRelatorio[header.field].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="container-faturamento-card2"></div>
                                        )
                                    }
                                    {
                                        openProjecao ? (
                                            <div className="principal-projecao">
                                                <div class="item-projecao">
                                                    <Charts data={dadosGrafico} type={"line"} group={true} height="auto" width={570}></Charts>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="container-faturamento-card2"></div>
                                        )
                                    }
                                    <TabelaV2 classeSelecionado="idEmbarque" nomeClasse1="outer-wrapper" nomeClasse2="table-wrapper-v2"
                                        colunas={dadosTabelaRedux.Embarque} dados={dadosTabela} nome="embarque" nomeTable="Embarque" buscarOrdenacao={buscarOrdenacao}/>
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                    <Dialog className="corpoModalMetas" open={openMetas} onClose={handleCloseMetas}>
                                        <DialogTitle>Meta</DialogTitle>
                                        <input
                                            type="number"
                                            id="apelido"
                                            className="input-text"
                                            onChange={(e) => {
                                                setValues(current => ({ ...current, meta: e.target.value }));
                                            }}
                                            value={values.meta}
                                        />
                                        <div className="botaoConcluir">
                                            <Button
                                                sx={{ width: 180 }}
                                                variant="contained"
                                                onClick={() => { cadastrarMeta() }}>
                                                Cadastrar
                                            </Button>
                                        </div>
                                    </Dialog>
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div>
            )
            }
        </motion.div>
    );
}

export default Faturamento;