import React, { useContext, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../ModalConfigTabela/ModalConfigTabela.css';
import CoresRedux from "../../contexts/Cor";
import CustomButton from "../CustomButton/CustomButton";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenusRedux from "../../contexts/Menus";
import DadosUsuarioRedux from "../../contexts/Usuario";
import { editaUsuarioTabelaPorId } from "../../services/ModuloGerenciador/UsuarioTabela";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import RequestControllerRedux from "../../contexts/RequestController";
import NomeTabelaRedux from "../../contexts/NomeTabela";
import CustomButtonV2 from "../CustomButton copy/CustomButtonV2";

export interface SimpleDialogProps {
    titulo: string;
    tituloBotao: string;
    tituloConfirmar: string;
    texto: string;
    checks: [];
    dataAlteracao: string;
    open: boolean;
    nome: string;
    onClose: () => void;
    onClick: () => void;
}

function ModalConfigTabela(props: SimpleDialogProps) {
    const { dadosTabelaRedux, setDadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { menusRedux, setDadosMenusRedux } = useContext(MenusRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { onClose, open, onClick } = props;

    const handleClose = () => {
        onClose();
    };

    const [loading, setLoading] = useState(false);

    const [unidEmbarqueValue, setUnidEmbarqueValue] = useState(dadosTabelaRedux.Embarque[1]);

    const [empresaValue, setEmpresaValue] = useState(dadosTabelaRedux.Embarque[2]);

    const [filialValue, setFilialValue] = useState(dadosTabelaRedux.Embarque[3]);

    const [rotaEmbarqueValue, setRotaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[4]);

    const [pedidoValue, setPedidoValue] = useState(dadosTabelaRedux.Embarque[5]);

    const [cteValue, setCteValue] = useState(dadosTabelaRedux.Embarque[6]);

    const [previsaoEmbarqueValue, setPrevisaoEmbarqueValue] = useState(dadosTabelaRedux.Embarque[7]);

    const [previsaoEntregaValue, setPrevisaoEntregaValue] = useState(dadosTabelaRedux.Embarque[8]);

    const [dataHoraChegadaEmbarqueValue, setDataHoraChegadaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[9]);

    const [dataHoraEmbarcadoValue, setDataHoraEmbarcadoValue] = useState(dadosTabelaRedux.Embarque[10]);

    const [dataHoraChegadaEntregaValue, setDataHoraChegadaEntregaValue] = useState(dadosTabelaRedux.Embarque[11]);

    const [dataHoraEntregueValue, setDataHoraEntregueValue] = useState(dadosTabelaRedux.Embarque[12]);

    const [aceiteValue, setAceiteValue] = useState(dadosTabelaRedux.Embarque[13]);

    const [statusValue, setStatusValue] = useState(dadosTabelaRedux.Embarque[14]);

    const [placaCavaloValue, setPlacaCavaloValue] = useState(dadosTabelaRedux.Embarque[15]);

    const [placaCarretaValue, setPlacaCarretaValue] = useState(dadosTabelaRedux.Embarque[16]);

    const [placaCarreta2Value, setPlacaCarreta2Value] = useState(dadosTabelaRedux.Embarque[17]);

    const [proprietarioValue, setProprietarioValue] = useState(dadosTabelaRedux.Embarque[18]);

    const [motoristaValue, setMotoristaValue] = useState(dadosTabelaRedux.Embarque[19]);

    const [freteFiscalValue, setFreteFiscalValue] = useState(dadosTabelaRedux.Embarque[20]);

    const [icmsValue, setIcmsValue] = useState(dadosTabelaRedux.Embarque[21]);

    const [freteMotoristaValue, setFreteMotoristaValue] = useState(dadosTabelaRedux.Embarque[22]);

    const [adicionalEmpresa, setAdicionalValue] = useState(dadosTabelaRedux.Embarque[23]);

    const [adicionalMotoristaValue, setAdicionalMotoristaValue] = useState(dadosTabelaRedux.Embarque[24]);

    const [numeroEixosEmbarqueValue, setNumeroEixosEmbarqueValue] = useState(dadosTabelaRedux.Embarque[25]);

    const [pedagioValue, setPedagioValue] = useState(dadosTabelaRedux.Embarque[26]);

    const [classificacaoValue, setClassificacaoValue] = useState(dadosTabelaRedux.Embarque[27]);

    const [margemValue, setMargemValue] = useState(dadosTabelaRedux.Embarque[28]);

    const [adiantamentoValue, setAdiamentoValue] = useState(dadosTabelaRedux.Embarque[29]);

    const [saldoValue, setSaldoValue] = useState(dadosTabelaRedux.Embarque[30]);

    const [meioPagamentoValue, setMeioPagamentoValue] = useState(dadosTabelaRedux.Embarque[31]);

    const [meioPagamento2Value, setMeioPagamento2Value] = useState(dadosTabelaRedux.Embarque[32]);

    const [kmValue, setKmValue] = useState(dadosTabelaRedux.Embarque[33]);

    const [clienteValue, setClienteValue] = useState(dadosTabelaRedux.Embarque[34]);

    const [embarcadorEmbValue, setEmbarcadorEmbValue] = useState(dadosTabelaRedux.Embarque[35]);

    const [destinatarioEmbValue, setDestinatarioEmbValue] = useState(dadosTabelaRedux.Embarque[36]);

    const [loadValue, setLoadValue] = useState(dadosTabelaRedux.Embarque[37]);

    const [mercadoriaValue, setMercadoriaValue] = useState(dadosTabelaRedux.Embarque[38]);

    const [pesoValue, setPesoValue] = useState(dadosTabelaRedux.Embarque[39]);

    const [embalagemEmbValue, setEmbalagemEmbValue] = useState(dadosTabelaRedux.Embarque[40]);

    const [observacaoValue, setObservacaoValue] = useState(dadosTabelaRedux.Embarque[41]);

    const [frotaValue, setFrotaValue] = useState(dadosTabelaRedux.Embarque[42]);

    const [canceladoValue, setCanceladoValue] = useState(dadosTabelaRedux.Embarque[43]);

    const [motivoEmbarqueValue, setMotivoEmbarqueValue] = useState(dadosTabelaRedux.Embarque[44]);

    const [usuarioResponsavelValue, setUsuarioResponsaveleValue] = useState(dadosTabelaRedux.Embarque[45]);

    const [nEntregasValue, setNEntregasValue] = useState(dadosTabelaRedux.Embarque?.[46]);

    const [comprovanteValue, setComprovanteValue] = useState(dadosTabelaRedux.Embarque?.[47]);

    const [checkinValue, setCheckinValue] = useState(dadosTabelaRedux.Embarque?.[48]);

    const [quebraTabelaEmbarque, setQuebraTabelaEmbarqueValue] = useState(dadosTabelaRedux.Embarque[49]);

    const [configDataHora, setConfigDataHora] = useState(dadosTabelaRedux.Embarque[50]);

    const [pedidoPValue, setPedidoPValue] = useState(dadosTabelaRedux.Pedido[0]);

    const [tipoRotaValue, setTipoRotaValue] = useState(dadosTabelaRedux.Pedido[1]);

    const [freteFiscalPValue, setFreteFiscalPValue] = useState(dadosTabelaRedux.Pedido[2]);

    const [icmsPValue, setIcmsPValue] = useState(dadosTabelaRedux.Pedido[3]);

    const [pedagioPValue, setPedagioPValue] = useState(dadosTabelaRedux.Pedido[4]);

    const [classificacaoPValue, setClassificacaoPValue] = useState(dadosTabelaRedux.Pedido[5]);

    const [kmPValue, setKmPValue] = useState(dadosTabelaRedux.Pedido[6]);

    const [clientePValue, setClientePValue] = useState(dadosTabelaRedux.Pedido[7]);

    const [embarcadorPValue, setEmbarcadorPValue] = useState(dadosTabelaRedux.Pedido[8]);

    const [destinatarioPValue, setDestinatarioPValue] = useState(dadosTabelaRedux.Pedido[9]);

    const [freteMotoristaPValue, setFreteMotoristaPValue] = useState(dadosTabelaRedux.Pedido[10]);

    const [observacaoPValue, setObservacaoPValue] = useState(dadosTabelaRedux.Pedido[11]);

    const [permiteAlterarFreteFiscalValue, setPermiteAlterarFreteFiscalValue] = useState(dadosTabelaRedux.Pedido[12]);

    const [valorEmbarquePedidoValue, setValorEmbarquePedidoValue] = useState(dadosTabelaRedux.Pedido[13]);

    const [icmsEmbutidoValue, setIcmsEmbutidoValue] = useState(dadosTabelaRedux.Pedido[14]);

    const [icmsEmbarcadorValue, setIcmsEmbarcadorValue] = useState(dadosTabelaRedux.Pedido[15]);

    const [freteNormalvalue, setFreteNormalValue] = useState(dadosTabelaRedux.Pedido[16]);

    const [quebraTabelaPedido, setQuebraTabelaPedidoValue] = useState(dadosTabelaRedux.Pedido[17]);

    const [pedidoPVisivel, setPedidoPVisivel] = useState(menusRedux.Pedido[0]);

    const handleOnChangePedidoPVisivel = () => {
        setPedidoPVisivel({
            nomeColuna: 'N Pedido',
            field: 'pedido',
            visivel: !pedidoPVisivel?.visivel
        })
    };

    const [tipoRotaVisivel, setTipoRotaVisivel] = useState(menusRedux.Pedido[1]);

    const handleOnChangeTipoRotaVisivel = () => {
        setTipoRotaVisivel({
            nomeColuna: 'Rota',
            field: 'tipoRota',
            visivel: !tipoRotaVisivel?.visivel
        })
    };

    const [freteFiscalPVisivel, setFreteFiscalPVisivel] = useState(menusRedux.Pedido[2]);

    const handleOnChangeFreteFiscalPVisivel = () => {
        setFreteFiscalPVisivel({
            nomeColuna: 'Frete Fiscal',
            field: 'freteFiscal',
            visivel: !freteFiscalPVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [icmsPVisivel, setIcmsPVisivel] = useState(menusRedux.Pedido[3]);

    const handleOnChangeIcmsPVisivel = () => {
        setIcmsPVisivel({
            nomeColuna: 'ICMS',
            field: 'icms',
            visivel: !icmsPVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [pedagioPVisivel, setPedagioPVisivel] = useState(menusRedux.Pedido[4]);

    const handleOnChangePedagioPVisivel = () => {
        setPedagioPVisivel({
            nomeColuna: 'Pedagio',
            field: 'pedagio',
            visivel: !pedagioPVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [classificacaoPVisivel, setClassificacaoPVisivel] = useState(menusRedux.Pedido[5]);

    const handleOnChangeClassificacaoPVisivel = () => {
        setClassificacaoPVisivel({
            nomeColuna: 'Classificação',
            field: 'classificacao',
            visivel: !classificacaoPVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [kmPVisivel, setKmPVisivel] = useState(menusRedux.Pedido[6]);

    const handleOnChangeKmPVisivel = () => {
        setKmPVisivel({
            nomeColuna: 'Km',
            field: 'km',
            visivel: !kmPVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [clientePVisivel, setClientePVisivel] = useState(menusRedux.Pedido[7]);

    const handleOnChangeClientePVisivel = () => {
        setClientePVisivel({
            nomeColuna: 'Cliente',
            field: 'cliente',
            visivel: !clientePVisivel?.visivel
        })
    };

    const [embarcadorPVisivel, setEmbarcadorPVisivel] = useState(menusRedux.Pedido[8]);

    const handleOnChangeEmbarcadorPVisivel = () => {
        setEmbarcadorPVisivel({
            nomeColuna: 'Embarcador',
            field: 'embarcador',
            visivel: !embarcadorPVisivel?.visivel
        })
    };

    const [destinatarioPVisivel, setDestinatarioPVisivel] = useState(menusRedux.Pedido[10]);

    const handleOnChangeDestinatarioPVisivel = () => {
        setDestinatarioPVisivel({
            nomeColuna: 'Destinatario',
            field: 'destinatario',
            visivel: !destinatarioPVisivel?.visivel
        })
    };

    const [freteMotoristaPVisivel, setFreteMotoristaPVisivel] = useState(menusRedux.Pedido[11]);

    const handleOnChangeFreteMotoristaPVisivel = () => {
        setFreteMotoristaPVisivel({
            nomeColuna: 'Frete Motorista',
            field: 'freteMotorista',
            visivel: !freteMotoristaPVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [observacaoPVisivel, setObservacaoPVisivel] = useState(menusRedux.Pedido[12]);

    const handleOnChangeObservacaoPVisivel = () => {
        setObservacaoPVisivel({
            nomeColuna: 'Observação',
            field: 'observacao',
            visivel: !observacaoPVisivel?.visivel
        })
    };

    const [permiteAlterarFreteFiscalVisivel, setPermiteAlterarFreteFiscalVisivel] = useState(menusRedux.Pedido[13]);

    const handleOnChangePermiteAlterarFreteFiscalVisivel = () => {
        setPermiteAlterarFreteFiscalVisivel({
            nomeColuna: 'Permite Alterar Frete Fiscal',
            field: 'permiteAlterarFreteFiscal',
            visivel: !permiteAlterarFreteFiscalVisivel?.visivel
        })
    };

    const [valorEmbarquePedidoVisivel, setValorEmbarquePedidoVisivel] = useState(menusRedux.Pedido[14]);

    const handleOnChangeValorEmbarquePedidoVisivel = () => {
        setValorEmbarquePedidoVisivel({
            nomeColuna: 'Valor Embarque Pedido',
            field: 'valorEmbarque',
            visivel: !valorEmbarquePedidoVisivel?.visivel
        })
    };

    const [icmsEmbutidoVisivel, setIcmsEmbutidoVisivel] = useState(menusRedux.Pedido[14]);

    const handleOnChangeIcmsEmbutidoVisivel = () => {
        setIcmsEmbutidoVisivel({
            nomeColuna: 'ICMS Embutido',
            field: 'icmsEmbutido',
            visivel: !icmsEmbutidoVisivel?.visivel
        })
    };

    const [icmsEmbarcadorVisivel, setIcmsEmbarcadorVisivel] = useState(menusRedux.Pedido[15]);

    const handleOnChangeIcmsEmbarcadorVisivel = () => {
        setIcmsEmbarcadorVisivel({
            nomeColuna: 'ICMS Embarcador',
            field: 'icmsEmbarcador',
            visivel: !icmsEmbarcadorVisivel?.visivel
        })
    };

    const [freteNormalVisivel, setFreteNormalVisivel] = useState(menusRedux.Pedido[14]);

    const handleOnChangeFreteNormalVisivel = () => {
        setFreteNormalVisivel({
            nomeColuna: 'Frete Normal',
            field: 'freteNormal',
            visivel: !freteNormalVisivel?.visivel
        })
    };

    const [unidEmbarqueVisivel, setUnidEmbarqueVisivel] = useState(menusRedux.Embarque[1]);

    const handleOnChangeUnidEmbarqueVisivel = () => {
        setUnidEmbarqueVisivel({
            nomeColuna: 'Unidade de Embarque',
            field: 'unidEmbarque',
            visivel: !unidEmbarqueVisivel?.visivel
        })
    };

    const [empresaVisivel, setEmpresaVisivel] = useState(menusRedux.Embarque[2]);

    const handleOnChangeEmpresaVisivel = () => {
        setEmpresaVisivel({
            nomeColuna: 'Empresa',
            field: 'empresa',
            visivel: !empresaVisivel?.visivel
        })
    };

    const [filialVisivel, setFilialVisivel] = useState(menusRedux.Embarque[3]);

    const handleOnChangeFilialVisivel = () => {
        setFilialVisivel({
            nomeColuna: 'Filial',
            field: 'filial',
            visivel: !filialVisivel?.visivel
        })
    };

    const [rotaEmbarqueVisivel, setRotaEmbarqueVisivel] = useState(menusRedux.Embarque[4]);

    const handleOnChangeRotaEmbarqueVisivel = () => {
        setRotaEmbarqueVisivel({
            nomeColuna: 'RotaEmbarque',
            field: 'rota',
            visivel: !rotaEmbarqueVisivel?.visivel
        })
    };

    const [pedidoVisivel, setPedidoVisivel] = useState(menusRedux.Embarque[5]);

    const handleOnChangePedidoVisivel = () => {
        setPedidoVisivel({
            nomeColuna: 'Pedido',
            field: 'pedido',
            visivel: !pedidoVisivel?.visivel
        })
    };

    const [cteVisivel, setCteVisivel] = useState(menusRedux.Embarque[6]);

    const handleOnChangeCteVisivel = () => {
        setCteVisivel({
            nomeColuna: 'Cte',
            field: 'cte',
            visivel: !cteVisivel?.visivel
        })
    };

    const [previsaoEmbarqueVisivel, setPrevisaoEmbarqueVisivel] = useState(menusRedux.Embarque[7]);

    const handleOnChangePrevisaoEmbarqueVisivel = () => {
        setPrevisaoEmbarqueVisivel({
            nomeColuna: 'Previsão Embarque',
            field: 'previsaoEmbarque',
            visivel: !previsaoEmbarqueVisivel?.visivel
        })
    };

    const [previsaoEntregaVisivel, setPrevisaoEntregaVisivel] = useState(menusRedux.Embarque[8]);

    const handleOnChangePrevisaoEntregaVisivel = () => {
        setPrevisaoEntregaVisivel({
            nomeColuna: 'Previsão Entrega',
            field: 'previsaoEntrega',
            visivel: !previsaoEntregaVisivel?.visivel
        })
    };

    const [dataHoraChegadaEmbarqueVisivel, setDataHoraChegadaEmbarqueVisivel] = useState(menusRedux.Embarque[9]);

    const handleOnChangeDataHoraChegadaEmbarqueVisivel = () => {
        setDataHoraChegadaEmbarqueVisivel({
            nomeColuna: 'Data Hora Chegada Embarque',
            field: 'dataHoraChegadaEmbarque',
            visivel: !dataHoraChegadaEmbarqueVisivel?.visivel
        })
    };


    const [dataHoraEmbarcadoVisivel, setDataHoraEmbarcadoVisivel] = useState(menusRedux.Embarque[10]);

    const handleOnChangeDataHoraEmbarcadoVisivel = () => {
        setDataHoraEmbarcadoVisivel({
            nomeColuna: 'Data Hora Embarcado',
            field: 'dataHoraEmbarcado',
            visivel: !dataHoraEmbarcadoVisivel?.visivel
        })
    };

    const [dataHoraChegadaEntregaVisivel, setDataHoraChegadaEntregaVisivel] = useState(menusRedux.Embarque[11]);

    const handleOnChangeDataHoraChegadaEntregaVisivel = () => {
        setDataHoraChegadaEntregaVisivel({
            nomeColuna: 'Data Hora Chegada Entrega',
            field: 'dataHoraChegadaEntrega',
            visivel: !dataHoraChegadaEntregaVisivel?.visivel
        })
    };

    const [dataHoraEntregueVisivel, setDataHoraEntregueVisivel] = useState(menusRedux.Embarque[12]);

    const handleOnChangeDataHoraEntregueVisivel = () => {
        setDataHoraEntregueVisivel({
            nomeColuna: 'Data Hora Entregue',
            field: 'dataHoraEntregue',
            visivel: !dataHoraEntregueVisivel?.visivel
        })
    };

    const [aceiteVisivel, setAceiteVisivel] = useState(menusRedux.Embarque[13]);

    const handleOnChangeAceiteVisivel = () => {
        setAceiteVisivel({
            nomeColuna: 'Aceite',
            field: 'aceite',
            visivel: !aceiteVisivel?.visivel
        })
    };

    const [statusVisivel, setStatusVisivel] = useState(menusRedux.Embarque[14]);

    const handleOnChangeStatusVisivel = () => {
        setStatusVisivel({
            nomeColuna: 'Status',
            field: 'status',
            visivel: !statusVisivel?.visivel
        })
    };

    const [placaCavaloVisivel, setPlacaCavaloVisivel] = useState(menusRedux.Embarque[15]);

    const handleOnChangePlacaCavaloVisivel = () => {
        setPlacaCavaloVisivel({
            nomeColuna: 'Placa Cavalo',
            field: 'placaCavalo',
            visivel: !placaCavaloVisivel?.visivel
        })
    };

    const [placaCarretaVisivel, setPlacaCarretaVisivel] = useState(menusRedux.Embarque[16]);

    const handleOnChangePlacaCarretaVisivel = () => {
        setPlacaCarretaVisivel({
            nomeColuna: 'Placa Carreta',
            field: 'placaCarreta',
            visivel: !placaCarretaVisivel?.visivel
        })
    };

    const [placaCarreta2Visivel, setPlacaCarreta2Visivel] = useState(menusRedux.Embarque[17]);

    const handleOnChangePlacaCarreta2Visivel = () => {
        setPlacaCarreta2Visivel({
            nomeColuna: 'Placa Segunda Carreta',
            field: 'placaCarreta2',
            visivel: !placaCarreta2Visivel?.visivel
        })
    };

    const [proprietarioVisivel, setProprietarioVisivel] = useState(menusRedux.Embarque[18]);

    const handleOnChangeProprietarioVisivel = () => {
        setProprietarioVisivel({
            nomeColuna: 'Proprietario',
            field: 'proprietario',
            visivel: !proprietarioVisivel?.visivel
        })
    };

    const [motoristaVisivel, setMotoristaVisivel] = useState(menusRedux.Embarque[19]);

    const handleOnChangeMotoristaVisivel = () => {
        setMotoristaVisivel({
            nomeColuna: 'Motorista',
            field: 'motorista',
            visivel: !motoristaVisivel?.visivel
        })
    };

    const [freteFiscalVisivel, setFreteFiscalVisivel] = useState(menusRedux.Embarque[20]);

    const handleOnChangeFreteFiscalVisivel = () => {
        setFreteFiscalVisivel({
            nomeColuna: 'Frete Fiscal',
            field: 'freteFiscal',
            visivel: !freteFiscalVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [icmsVisivel, setIcmsVisivel] = useState(menusRedux.Embarque[21]);

    const handleOnChangeIcmsVisivel = () => {
        setIcmsVisivel({
            nomeColuna: 'ICMS',
            field: 'icms',
            visivel: !icmsVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [freteMotoristaVisivel, setFreteMotoristaVisivel] = useState(menusRedux.Embarque[22]);

    const handleOnChangeFreteMotoristaVisivel = () => {
        setFreteMotoristaVisivel({
            nomeColuna: 'Frete Motorista',
            field: 'freteMotorista',
            visivel: !freteMotoristaVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [adicionalEmpresaVisivel, setAdicionalEmpresaVisivel] = useState(menusRedux.Embarque[23]);

    const handleOnChangeAdicionalEmpresaVisivel = () => {
        setAdicionalEmpresaVisivel({
            nomeColuna: 'Adicional Empresa',
            field: 'adicionalEmpresa',
            visivel: !adicionalEmpresaVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [adicionalMotoristaVisivel, setAdicionalMotoristaVisivel] = useState(menusRedux.Embarque[24]);

    const handleOnChangeAdicionalMotoristaVisivel = () => {
        setAdicionalMotoristaVisivel({
            nomeColuna: 'Adicional Motorista',
            field: 'adicionalMotorista',
            visivel: !adicionalMotoristaVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [numeroEixosEmbarqueVisivel, setNumeroEixosEmbarqueVisivel] = useState(menusRedux.Embarque[25]);

    const handleOnChangeNumeroEixosEmbarqueVisivel = () => {
        setNumeroEixosEmbarqueVisivel({
            nomeColuna: 'Eixos',
            field: 'numeroEixos',
            visivel: !numeroEixosEmbarqueVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [pedagioVisivel, setPedagioVisivel] = useState(menusRedux.Embarque[26]);

    const handleOnChangePedagioVisivel = () => {
        setPedagioVisivel({
            nomeColuna: 'Pedagio',
            field: 'pedagio',
            visivel: !pedagioVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [classificacaoVisivel, setClassificacaoVisivel] = useState(menusRedux.Embarque[27]);

    const handleOnChangeClassificacaoVisivel = () => {
        setClassificacaoVisivel({
            nomeColuna: 'Classificação',
            field: 'classificacao',
            visivel: !classificacaoVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [margemVisivel, setMargemVisivel] = useState(menusRedux.Embarque[28]);

    const handleOnChangeMargemVisivel = () => {
        setMargemVisivel({
            nomeColuna: 'Margem',
            field: 'margem',
            visivel: !margemVisivel?.visivel
        })
    };

    const [adiantamentoVisivel, setAdiamentoVisivel] = useState(menusRedux.Embarque[29]);

    const handleOnChangeAdiamentoVisivel = () => {
        setAdiamentoVisivel({
            nomeColuna: 'Adiamento',
            field: 'adiantamento',
            visivel: !adiantamentoVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [saldoVisivel, setSaldoVisivel] = useState(menusRedux.Embarque[30]);

    const handleOnChangeSaldoVisivel = () => {
        setSaldoVisivel({
            nomeColuna: 'Saldo',
            field: 'saldo',
            visivel: !saldoVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [meioPagamentoVisivel, setMeioPagamentoVisivel] = useState(menusRedux.Embarque[31]);

    const handleOnChangeMeioPagamentoVisivel = () => {
        setMeioPagamentoVisivel({
            nomeColuna: 'Meio Pagamento',
            field: 'meioPagamento',
            visivel: !meioPagamentoVisivel?.visivel
        })
    };

    const [meioPagamento2Visivel, setMeioPagamento2Visivel] = useState(menusRedux.Embarque[32]);

    const handleOnChangeMeioPagamento2Visivel = () => {
        setMeioPagamento2Visivel({
            nomeColuna: 'Meio Pagamento Dois',
            field: 'meioPagamento2',
            visivel: !meioPagamento2Visivel?.visivel
        })
    };

    const [kmVisivel, setKmVisivel] = useState(menusRedux.Embarque[33]);

    const handleOnChangeKmVisivel = () => {
        setKmVisivel({
            nomeColuna: 'Km',
            field: 'km',
            visivel: !kmVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [clienteVisivel, setClienteVisivel] = useState(menusRedux.Embarque[34]);

    const handleOnChangeClienteVisivel = () => {
        setClienteVisivel({
            nomeColuna: 'Cliente',
            field: 'cliente',
            visivel: !clienteVisivel?.visivel
        })
    };

    const [embarcadorEmbVisivel, setEmbarcadorEmbVisivel] = useState(menusRedux.Embarque[35]);

    const handleOnChangeEmbarcadorEmbVisivel = () => {
        setEmbarcadorEmbVisivel({
            nomeColuna: 'Embarcador',
            field: 'embarcador',
            visivel: !embarcadorEmbVisivel?.visivel
        })
    };

    const [destinatarioEmbVisivel, setDestinatarioEmbVisivel] = useState(menusRedux.Embarque[36]);

    const handleOnChangeDestinatarioEmbVisivel = () => {
        setDestinatarioEmbVisivel({
            nomeColuna: 'Destinatario',
            field: 'destinatario',
            visivel: !destinatarioEmbVisivel?.visivel
        })
    };

    const [loadVisivel, setLoadVisivel] = useState(menusRedux.Embarque[37]);

    const handleOnChangeLoadVisivel = () => {
        setLoadVisivel({
            nomeColuna: 'Load',
            field: 'load',
            visivel: !loadVisivel?.visivel
        })
    };

    const [mercadoriaVisivel, setMercadoriaVisivel] = useState(menusRedux.Embarque[38]);

    const handleOnChangeMercadoriaVisivel = () => {
        setMercadoriaVisivel({
            nomeColuna: 'Mercadoria',
            field: 'mercadoria',
            visivel: !mercadoriaVisivel?.visivel
        })
    };

    const [pesoVisivel, setPesoVisivel] = useState(menusRedux.Embarque[39]);

    const handleOnChangePesoVisivel = () => {
        setPesoVisivel({
            nomeColuna: 'Peso',
            field: 'peso',
            visivel: !pesoVisivel?.visivel,
            tipo: 'number'
        })
    };

    const [embalagemEmbVisivel, setEmbalagemEmbVisivel] = useState(menusRedux.Embarque[40]);

    const handleOnChangeEmbalagemEmbVisivel = () => {
        setEmbalagemEmbVisivel({
            nomeColuna: 'Embalagem',
            field: 'embalagem',
            visivel: !embalagemEmbVisivel?.visivel
        })
    };

    const [observacaoVisivel, setObservacaoVisivel] = useState(menusRedux.Embarque[41]);

    const handleOnChangeObservacaoVisivel = () => {
        setObservacaoVisivel({
            nomeColuna: 'Observação',
            field: 'observacao',
            visivel: !observacaoVisivel?.visivel
        })
    };

    const [frotaVisivel, setFrotaVisivel] = useState(menusRedux.Embarque[42]);

    const handleOnChangeFrotaVisivel = () => {
        setFrotaVisivel({
            nomeColuna: 'Frota',
            field: 'frota',
            visivel: !frotaVisivel?.visivel
        })
    };

    const [canceladoVisivel, setCanceladoVisivel] = useState(menusRedux.Embarque[43]);

    const handleOnChangeCanceladoVisivel = () => {
        setCanceladoVisivel({
            nomeColuna: 'Cancelado',
            field: 'cancelado',
            visivel: !canceladoVisivel?.visivel
        })
    };

    const [motivoVisivel, setMotivoVisivel] = useState(menusRedux.Embarque[44]);

    const handleOnChangeMotivoVisivel = () => {
        setMotivoVisivel({
            nomeColuna: 'Motivo',
            field: 'motivo',
            visivel: !motivoVisivel?.visivel
        })
    };

    const [usuarioResponsavelVisivel, setUsuarioResponsavelVisivel] = useState(menusRedux.Embarque[45]);

    const handleOnChangeUsuarioResponsavelVisivel = () => {
        setUsuarioResponsavelVisivel({
            nomeColuna: 'Usuário Responsável',
            field: 'usuarioResponsavel',
            visivel: !usuarioResponsavelVisivel?.visivel
        })
    };


    const [nEntregasVisivel, setNEntregasVisivel] = useState(menusRedux.Embarque[46]);

    const handleOnChangeNEntregasVisivel = () => {
        setNEntregasVisivel({
            nomeColuna: 'Entregas',
            field: 'nEtregas',
            visivel: !nEntregasVisivel?.visivel
        })
    };

    const [comprovanteVisivel, setComprovanteVisivel] = useState(menusRedux.Embarque[47]);

    const handleOnChangeComprovanteVisivel = () => {
        setComprovanteVisivel({
            nomeColuna: 'Comprovante',
            field: 'comprovante',
            visivel: !comprovanteVisivel?.visivel
        })
    };

    const [checkinVisivel, setCheckinVisivel] = useState(menusRedux.Embarque[48]);

    const handleOnChangeCheckinVisivel = () => {
        setCheckinVisivel({
            nomeColuna: 'Check-in',
            field: 'checkInFeito',
            visivel: !checkinVisivel?.visivel
        })
    };

    const [configDataHoraVisivel, setConfigDataHoraVisivel] = useState(menusRedux.Embarque?.[50]);

    const handleOnChangeConfigDataHoraVisivel = () => {
        setConfigDataHoraVisivel({
            configDataHora: !configDataHoraVisivel?.configDataHora
        })
    };

    const [unidEmbarqueVisivelModelo, setUnidEmbarqueVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[1]);

    const handleOnChangeUnidEmbarqueVisivelModelo = () => {
        setUnidEmbarqueVisivelModelo({
            nomeColuna: 'Unidade de Embarque',
            field: 'unidEmbarque',
            visivel: !unidEmbarqueVisivelModelo?.visivel
        })
    };

    const [empresaVisivelModelo, setEmpresaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[2]);

    const handleOnChangeEmpresaVisivelModelo = () => {
        setEmpresaVisivelModelo({
            nomeColuna: 'Empresa',
            field: 'empresa',
            visivel: !empresaVisivelModelo?.visivel
        })
    };

    const [filialVisivelModelo, setFilialVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[3]);

    const handleOnChangeFilialVisivelModelo = () => {
        setFilialVisivelModelo({
            nomeColuna: 'Filial',
            field: 'filial',
            visivel: !filialVisivelModelo?.visivel
        })
    };

    const [rotaEmbarqueVisivelModelo, setRotaEmbarqueVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[4]);

    const handleOnChangeRotaEmbarqueVisivelModelo = () => {
        setRotaEmbarqueVisivelModelo({
            nomeColuna: 'RotaEmbarque',
            field: 'rota',
            visivel: !rotaEmbarqueVisivelModelo?.visivel
        })
    };

    const [pedidoVisivelModelo, setPedidoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[5]);

    const handleOnChangePedidoVisivelModelo = () => {
        setPedidoVisivelModelo({
            nomeColuna: 'Pedido',
            field: 'pedido',
            visivel: !pedidoVisivelModelo?.visivel
        })
    };

    const [cteVisivelModelo, setCteVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[6]);

    const handleOnChangeCteVisivelModelo = () => {
        setCteVisivelModelo({
            nomeColuna: 'Cte',
            field: 'cte',
            visivel: !cteVisivelModelo?.visivel
        })
    };

    const [previsaoEmbarqueVisivelModelo, setPrevisaoEmbarqueVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[7]);

    const handleOnChangePrevisaoEmbarqueVisivelModelo = () => {
        setPrevisaoEmbarqueVisivelModelo({
            nomeColuna: 'Previsão Embarque',
            field: 'previsaoEmbarque',
            visivel: !previsaoEmbarqueVisivelModelo?.visivel
        })
    };

    const [previsaoEntregaVisivelModelo, setPrevisaoEntregaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[8]);

    const handleOnChangePrevisaoEntregaVisivelModelo = () => {
        setPrevisaoEntregaVisivelModelo({
            nomeColuna: 'Previsão Entrega',
            field: 'previsaoEntrega',
            visivel: !previsaoEntregaVisivelModelo?.visivel
        })
    };

    const [dataHoraChegadaEmbarqueVisivelModelo, setDataHoraChegadaEmbarqueVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[9]);

    const handleOnChangeDataHoraChegadaEmbarqueVisivelModelo = () => {
        setDataHoraChegadaEmbarqueVisivelModelo({
            nomeColuna: 'Data Hora Chegada Embarque',
            field: 'dataHoraChegadaEmbarque',
            visivel: !dataHoraChegadaEmbarqueVisivelModelo?.visivel
        })
    };


    const [dataHoraEmbarcadoVisivelModelo, setDataHoraEmbarcadoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[10]);

    const handleOnChangeDataHoraEmbarcadoVisivelModelo = () => {
        setDataHoraEmbarcadoVisivelModelo({
            nomeColuna: 'Data Hora Embarcado',
            field: 'dataHoraEmbarcado',
            visivel: !dataHoraEmbarcadoVisivelModelo?.visivel
        })
    };

    const [dataHoraChegadaEntregaVisivelModelo, setDataHoraChegadaEntregaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[11]);

    const handleOnChangeDataHoraChegadaEntregaVisivelModelo = () => {
        setDataHoraChegadaEntregaVisivelModelo({
            nomeColuna: 'Data Hora Chegada Entrega',
            field: 'dataHoraChegadaEntrega',
            visivel: !dataHoraChegadaEntregaVisivelModelo?.visivel
        })
    };

    const [dataHoraEntregueVisivelModelo, setDataHoraEntregueVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[12]);

    const handleOnChangeDataHoraEntregueVisivelModelo = () => {
        setDataHoraEntregueVisivelModelo({
            nomeColuna: 'Data Hora Entregue',
            field: 'dataHoraEntregue',
            visivel: !dataHoraEntregueVisivelModelo?.visivel
        })
    };

    const [aceiteVisivelModelo, setAceiteVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[13]);

    const handleOnChangeAceiteVisivelModelo = () => {
        setAceiteVisivelModelo({
            nomeColuna: 'Aceite',
            field: 'aceite',
            visivel: !aceiteVisivelModelo?.visivel
        })
    };

    const [statusVisivelModelo, setStatusVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[14]);

    const handleOnChangeStatusVisivelModelo = () => {
        setStatusVisivelModelo({
            nomeColuna: 'Status',
            field: 'status',
            visivel: !statusVisivelModelo?.visivel
        })
    };

    const [placaCavaloVisivelModelo, setPlacaCavaloVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[15]);

    const handleOnChangePlacaCavaloVisivelModelo = () => {
        setPlacaCavaloVisivelModelo({
            nomeColuna: 'Placa Cavalo',
            field: 'placaCavalo',
            visivel: !placaCavaloVisivelModelo?.visivel
        })
    };

    const [placaCarretaVisivelModelo, setPlacaCarretaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[16]);

    const handleOnChangePlacaCarretaVisivelModelo = () => {
        setPlacaCarretaVisivelModelo({
            nomeColuna: 'Placa Carreta',
            field: 'placaCarreta',
            visivel: !placaCarretaVisivelModelo?.visivel
        })
    };

    const [placaCarreta2VisivelModelo, setPlacaCarreta2VisivelModelo] = useState(menusRedux.ModeloEmbarque?.[17]);

    const handleOnChangePlacaCarreta2VisivelModelo = () => {
        setPlacaCarreta2VisivelModelo({
            nomeColuna: 'Placa Segunda Carreta',
            field: 'placaCarreta2',
            visivel: !placaCarreta2VisivelModelo?.visivel
        })
    };

    const [proprietarioVisivelModelo, setProprietarioVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[18]);

    const handleOnChangeProprietarioVisivelModelo = () => {
        setProprietarioVisivelModelo({
            nomeColuna: 'Proprietario',
            field: 'proprietario',
            visivel: !proprietarioVisivelModelo?.visivel
        })
    };

    const [motoristaVisivelModelo, setMotoristaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[19]);

    const handleOnChangeMotoristaVisivelModelo = () => {
        setMotoristaVisivelModelo({
            nomeColuna: 'Motorista',
            field: 'motorista',
            visivel: !motoristaVisivelModelo?.visivel
        })
    };

    const [freteFiscalVisivelModelo, setFreteFiscalVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[20]);

    const handleOnChangeFreteFiscalVisivelModelo = () => {
        setFreteFiscalVisivelModelo({
            nomeColuna: 'Frete Fiscal',
            field: 'freteFiscal',
            visivel: !freteFiscalVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [icmsVisivelModelo, setIcmsVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[21]);

    const handleOnChangeIcmsVisivelModelo = () => {
        setIcmsVisivelModelo({
            nomeColuna: 'ICMS',
            field: 'icms',
            visivel: !icmsVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [freteMotoristaVisivelModelo, setFreteMotoristaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[22]);

    const handleOnChangeFreteMotoristaVisivelModelo = () => {
        setFreteMotoristaVisivelModelo({
            nomeColuna: 'Frete Motorista',
            field: 'freteMotorista',
            visivel: !freteMotoristaVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [adicionalEmpresaVisivelModelo, setAdicionalEmpresaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[23]);

    const handleOnChangeAdicionalEmpresaVisivelModelo = () => {
        setAdicionalEmpresaVisivelModelo({
            nomeColuna: 'Adicional Empresa',
            field: 'adicionalEmpresa',
            visivel: !adicionalEmpresaVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [adicionalMotoristaVisivelModelo, setAdicionalMotoristaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[24]);

    const handleOnChangeAdicionalMotoristaVisivelModelo = () => {
        setAdicionalMotoristaVisivelModelo({
            nomeColuna: 'Adicional Motorista',
            field: 'adicionalMotorista',
            visivel: !adicionalMotoristaVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [numeroEixosEmbarqueVisivelModelo, setNumeroEixosEmbarqueVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[25]);

    const handleOnChangeNumeroEixosEmbarqueVisivelModelo = () => {
        setNumeroEixosEmbarqueVisivelModelo({
            nomeColuna: 'Eixos',
            field: 'numeroEixos',
            visivel: !numeroEixosEmbarqueVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [pedagioVisivelModelo, setPedagioVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[26]);

    const handleOnChangePedagioVisivelModelo = () => {
        setPedagioVisivelModelo({
            nomeColuna: 'Pedagio',
            field: 'pedagio',
            visivel: !pedagioVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [classificacaoVisivelModelo, setClassificacaoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[27]);

    const handleOnChangeClassificacaoVisivelModelo = () => {
        setClassificacaoVisivelModelo({
            nomeColuna: 'Classificação',
            field: 'classificacao',
            visivel: !classificacaoVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [margemVisivelModelo, setMargemVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[28]);

    const handleOnChangeMargemVisivelModelo = () => {
        setMargemVisivelModelo({
            nomeColuna: 'Margem',
            field: 'margem',
            visivel: !margemVisivelModelo?.visivel
        })
    };

    const [adiantamentoVisivelModelo, setAdiamentoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[29]);

    const handleOnChangeAdiamentoVisivelModelo = () => {
        setAdiamentoVisivelModelo({
            nomeColuna: 'Adiamento',
            field: 'adiantamento',
            visivel: !adiantamentoVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [saldoVisivelModelo, setSaldoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[30]);

    const handleOnChangeSaldoVisivelModelo = () => {
        setSaldoVisivelModelo({
            nomeColuna: 'Saldo',
            field: 'saldo',
            visivel: !saldoVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [meioPagamentoVisivelModelo, setMeioPagamentoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[31]);

    const handleOnChangeMeioPagamentoVisivelModelo = () => {
        setMeioPagamentoVisivelModelo({
            nomeColuna: 'Meio Pagamento',
            field: 'meioPagamento',
            visivel: !meioPagamentoVisivelModelo?.visivel
        })
    };

    const [meioPagamento2VisivelModelo, setMeioPagamento2VisivelModelo] = useState(menusRedux.ModeloEmbarque?.[32]);

    const handleOnChangeMeioPagamento2VisivelModelo = () => {
        setMeioPagamento2VisivelModelo({
            nomeColuna: 'Meio Pagamento Dois',
            field: 'meioPagamento2',
            visivel: !meioPagamento2VisivelModelo?.visivel
        })
    };

    const [kmVisivelModelo, setKmVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[33]);

    const handleOnChangeKmVisivelModelo = () => {
        setKmVisivelModelo({
            nomeColuna: 'Km',
            field: 'km',
            visivel: !kmVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [clienteVisivelModelo, setClienteVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[34]);

    const handleOnChangeClienteVisivelModelo = () => {
        setClienteVisivelModelo({
            nomeColuna: 'Cliente',
            field: 'cliente',
            visivel: !clienteVisivelModelo?.visivel
        })
    };

    const [embarcadorEmbVisivelModelo, setEmbarcadorEmbVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[35]);

    const handleOnChangeEmbarcadorEmbVisivelModelo = () => {
        setEmbarcadorEmbVisivelModelo({
            nomeColuna: 'Embarcador',
            field: 'embarcador',
            visivel: !embarcadorEmbVisivelModelo?.visivel
        })
    };

    const [destinatarioEmbVisivelModelo, setDestinatarioEmbVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[36]);

    const handleOnChangeDestinatarioEmbVisivelModelo = () => {
        setDestinatarioEmbVisivelModelo({
            nomeColuna: 'Destinatario',
            field: 'destinatario',
            visivel: !destinatarioEmbVisivelModelo?.visivel
        })
    };

    const [loadVisivelModelo, setLoadVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[37]);

    const handleOnChangeLoadVisivelModelo = () => {
        setLoadVisivelModelo({
            nomeColuna: 'Load',
            field: 'load',
            visivel: !loadVisivelModelo?.visivel
        })
    };

    const [mercadoriaVisivelModelo, setMercadoriaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[38]);

    const handleOnChangeMercadoriaVisivelModelo = () => {
        setMercadoriaVisivelModelo({
            nomeColuna: 'Mercadoria',
            field: 'mercadoria',
            visivel: !mercadoriaVisivelModelo?.visivel
        })
    };

    const [pesoVisivelModelo, setPesoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[39]);

    const handleOnChangePesoVisivelModelo = () => {
        setPesoVisivelModelo({
            nomeColuna: 'Peso',
            field: 'peso',
            visivel: !pesoVisivelModelo?.visivel,
            tipo: 'number'
        })
    };

    const [embalagemEmbVisivelModelo, setEmbalagemEmbVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[40]);

    const handleOnChangeEmbalagemEmbVisivelModelo = () => {
        setEmbalagemEmbVisivelModelo({
            nomeColuna: 'Embalagem',
            field: 'embalagem',
            visivel: !embalagemEmbVisivelModelo?.visivel
        })
    };

    const [observacaoVisivelModelo, setObservacaoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[41]);

    const handleOnChangeObservacaoVisivelModelo = () => {
        setObservacaoVisivelModelo({
            nomeColuna: 'Observação',
            field: 'observacao',
            visivel: !observacaoVisivelModelo?.visivel
        })
    };

    const [frotaVisivelModelo, setFrotaVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[42]);

    const handleOnChangeFrotaVisivelModelo = () => {
        setFrotaVisivelModelo({
            nomeColuna: 'Frota',
            field: 'frota',
            visivel: !frotaVisivelModelo?.visivel
        })
    };

    const [canceladoVisivelModelo, setCanceladoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[43]);

    const handleOnChangeCanceladoVisivelModelo = () => {
        setCanceladoVisivelModelo({
            nomeColuna: 'Cancelado',
            field: 'cancelado',
            visivel: !canceladoVisivelModelo?.visivel
        })
    };

    const [motivoVisivelModelo, setMotivoVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[44]);

    const handleOnChangeMotivoVisivelModelo = () => {
        setMotivoVisivelModelo({
            nomeColuna: 'Motivo',
            field: 'motivo',
            visivel: !motivoVisivelModelo?.visivel
        })
    };

    const [usuarioResponsavelVisivelModelo, setUsuarioResponsavelVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[45]);

    const handleOnChangeUsuarioResponsavelVisivelModelo = () => {
        setUsuarioResponsavelVisivelModelo({
            nomeColuna: 'Usuário Responsável',
            field: 'usuarioResponsavel',
            visivel: !usuarioResponsavelVisivelModelo?.visivel
        })
    };


    const [nEntregasVisivelModelo, setNEntregasVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[46]);

    const handleOnChangeNEntregasVisivelModelo = () => {
        setNEntregasVisivelModelo({
            nomeColuna: 'Entregas',
            field: 'nEtregas',
            visivel: !nEntregasVisivelModelo?.visivel
        })
    };

    const [comprovanteVisivelModelo, setComprovanteVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[47]);

    const handleOnChangeComprovanteVisivelModelo = () => {
        setComprovanteVisivelModelo({
            nomeColuna: 'Comprovante',
            field: 'comprovante',
            visivel: !comprovanteVisivelModelo?.visivel
        })
    };

    const [checkinVisivelModelo, setCheckinVisivelModelo] = useState(menusRedux.ModeloEmbarque?.[48]);

    const handleOnChangeCheckinVisivelModelo = () => {
        setCheckinVisivelModelo({
            nomeColuna: 'Check-in',
            field: 'checkInFeito',
            visivel: !checkinVisivelModelo?.visivel
        })
    };

    const [configDataHoraVisivelModelo, setConfigDataHoraVisivelModelo] = useState(menusRedux.ModeloModeloEmbarque?.[50]);

    const handleOnChangeConfigDataHoraVisivelModelo = () => {
        setConfigDataHoraVisivelModelo({
            configDataHora: !configDataHoraVisivelModelo?.configDataHoraModelo
        })
    };

    const valorInicial = {
        'Embarque': [
            {
                'nomeColuna': 'Descrição',
                'field': 'descricao',
                'visivel': 'true'
            },
            {
                'nomeColuna': unidEmbarqueValue?.nomeColuna,
                'field': 'unidEmbarque',
                'visivel': unidEmbarqueVisivel?.visivel
            },
            {
                'nomeColuna': empresaValue?.nomeColuna,
                'field': 'empresa',
                'visivel': empresaVisivel?.visivel
            },
            {
                'nomeColuna': filialValue?.nomeColuna,
                'field': 'filial',
                'visivel': filialVisivel?.visivel
            },
            {
                'nomeColuna': rotaEmbarqueValue?.nomeColuna,
                'field': 'rota',
                'visivel': rotaEmbarqueVisivel?.visivel
            },
            {
                'nomeColuna': pedidoValue?.nomeColuna,
                'field': 'pedido',
                'visivel': pedidoVisivel?.visivel
            },
            {
                'nomeColuna': cteValue?.nomeColuna,
                'field': 'cte',
                'visivel': cteVisivel?.visivel
            },
            {
                'nomeColuna': previsaoEmbarqueValue?.nomeColuna,
                'field': 'previsaoEmbarque',
                'visivel': previsaoEmbarqueVisivel?.visivel
            },
            {
                'nomeColuna': previsaoEntregaValue?.nomeColuna,
                'field': 'previsaoEntrega',
                'visivel': previsaoEntregaVisivel?.visivel
            },
            {
                'nomeColuna': dataHoraChegadaEmbarqueValue?.nomeColuna,
                'field': 'dataHoraChegadaEmbarque',
                'visivel': dataHoraChegadaEmbarqueVisivel?.visivel
            },
            {
                'nomeColuna': dataHoraEmbarcadoValue?.nomeColuna,
                'field': 'dataHoraEmbarcado',
                'visivel': dataHoraEmbarcadoVisivel?.visivel
            },
            {
                'nomeColuna': dataHoraChegadaEntregaValue?.nomeColuna,
                'field': 'dataHoraChegadaEntrega',
                'visivel': dataHoraChegadaEntregaVisivel?.visivel
            },
            {
                'nomeColuna': dataHoraEntregueValue?.nomeColuna,
                'field': 'dataHoraEntregue',
                'visivel': dataHoraEntregueVisivel?.visivel
            },
            {
                'nomeColuna': aceiteValue?.nomeColuna,
                'field': 'aceite',
                'visivel': aceiteVisivel?.visivel
            },
            {
                'nomeColuna': statusValue?.nomeColuna,
                'field': 'status',
                'visivel': statusVisivel?.visivel
            },
            {
                'nomeColuna': placaCavaloValue?.nomeColuna,
                'field': 'placaCavalo',
                'visivel': placaCavaloVisivel?.visivel
            },
            {
                'nomeColuna': placaCarretaValue?.nomeColuna,
                'field': 'placaCarreta',
                'visivel': placaCarretaVisivel?.visivel
            },
            {
                'nomeColuna': placaCarreta2Value?.nomeColuna,
                'field': 'placaCarreta2',
                'visivel': placaCarreta2Visivel?.visivel
            },
            {
                'nomeColuna': proprietarioValue?.nomeColuna,
                'field': 'proprietario',
                'visivel': proprietarioVisivel?.visivel
            },
            {
                'nomeColuna': motoristaValue?.nomeColuna,
                'field': 'veiculoMotorista',
                'visivel': motoristaVisivel?.visivel
            },
            {
                'nomeColuna': freteFiscalValue?.nomeColuna,
                'field': 'freteFiscal',
                'visivel': freteFiscalVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': icmsValue?.nomeColuna,
                'field': 'icms',
                'visivel': icmsVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': freteMotoristaValue?.nomeColuna,
                'field': 'freteMotorista',
                'visivel': freteMotoristaVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalEmpresa?.nomeColuna,
                'field': 'adicionalEmpresa',
                'visivel': adicionalEmpresaVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalMotoristaValue?.nomeColuna,
                'field': 'adicionalMotorista',
                'visivel': adicionalMotoristaVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': numeroEixosEmbarqueValue?.nomeColuna,
                'field': 'numeroEixos',
                'visivel': numeroEixosEmbarqueVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': pedagioValue?.nomeColuna,
                'field': 'pedagio',
                'visivel': pedagioVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': classificacaoValue?.nomeColuna,
                'field': 'classificacao',
                'visivel': classificacaoVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': margemValue?.nomeColuna,
                'field': 'margem',
                'visivel': margemVisivel?.visivel
            },
            {
                'nomeColuna': adiantamentoValue?.nomeColuna,
                'field': 'adiantamento',
                'visivel': adiantamentoVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': saldoValue?.nomeColuna,
                'field': 'saldo',
                'visivel': saldoVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': meioPagamentoValue?.nomeColuna,
                'field': 'meioPagamento',
                'visivel': meioPagamentoVisivel?.visivel
            },
            {
                'nomeColuna': meioPagamento2Value?.nomeColuna,
                'field': 'meioPagamento2',
                'visivel': meioPagamento2Visivel?.visivel
            },
            {
                'nomeColuna': kmValue?.nomeColuna,
                'field': 'km',
                'visivel': kmVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': clienteValue?.nomeColuna,
                'field': 'cliente',
                'visivel': clienteVisivel?.visivel
            },
            {
                'nomeColuna': embarcadorEmbValue?.nomeColuna,
                'field': 'embarcador',
                'visivel': embarcadorEmbVisivel?.visivel
            },
            {
                'nomeColuna': destinatarioEmbValue?.nomeColuna,
                'field': 'destinatario',
                'visivel': destinatarioEmbVisivel?.visivel
            },
            {
                'nomeColuna': loadValue?.nomeColuna,
                'field': 'load',
                'visivel': loadVisivel?.visivel
            },
            {
                'nomeColuna': mercadoriaValue?.nomeColuna,
                'field': 'mercadoria',
                'visivel': mercadoriaVisivel?.visivel
            },
            {
                'nomeColuna': pesoValue?.nomeColuna,
                'field': 'peso',
                'visivel': pesoVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': embalagemEmbValue?.nomeColuna,
                'field': 'embalagem',
                'visivel': embalagemEmbVisivel?.visivel
            },
            {
                'nomeColuna': observacaoValue?.nomeColuna,
                'field': 'observacao',
                'visivel': observacaoVisivel?.visivel
            },
            {
                'nomeColuna': frotaValue?.nomeColuna,
                'field': 'frota',
                'visivel': frotaVisivel?.visivel
            },
            {
                'nomeColuna': canceladoValue?.nomeColuna,
                'field': 'cancelado',
                'visivel': canceladoVisivel?.visivel
            },
            {
                'nomeColuna': motivoEmbarqueValue?.nomeColuna,
                'field': 'motivo',
                'visivel': motivoVisivel?.visivel
            },
            {
                'nomeColuna': usuarioResponsavelValue?.nomeColuna,
                'field': 'usuarioResponsavel',
                'visivel': usuarioResponsavelVisivel?.visivel
            },
            {
                'nomeColuna': nEntregasValue?.nomeColuna,
                'field': 'nEntregas',
                'visivel': nEntregasVisivel?.visivel
            },
            {
                'nomeColuna': comprovanteValue?.nomeColuna,
                'field': 'comprovante',
                'visivel': comprovanteVisivel?.visivel
            },
            {
                'nomeColuna': checkinValue?.nomeColuna,
                'field': 'checkInFeito',
                'visivel': checkinVisivel?.visivel
            },
            {
                'quebraColunaEmbarque': quebraTabelaEmbarque?.quebraColunaEmbarque
            },
            {
                'configDataHora': configDataHoraVisivel?.configDataHora
            },
        ],
        'ModeloEmbarque': [
            {
                'nomeColuna': 'Descrição',
                'field': 'descricao',
                'visivel': 'true'
            },
            {
                'nomeColuna': unidEmbarqueValue?.nomeColuna,
                'field': 'unidEmbarque',
                'visivel': unidEmbarqueVisivelModelo?.visivel
            },
            {
                'nomeColuna': empresaValue?.nomeColuna,
                'field': 'empresa',
                'visivel': empresaVisivelModelo?.visivel
            },
            {
                'nomeColuna': filialValue?.nomeColuna,
                'field': 'filial',
                'visivel': filialVisivelModelo?.visivel
            },
            {
                'nomeColuna': rotaEmbarqueValue?.nomeColuna,
                'field': 'rota',
                'visivel': rotaEmbarqueVisivelModelo?.visivel
            },
            {
                'nomeColuna': pedidoValue?.nomeColuna,
                'field': 'pedido',
                'visivel': pedidoVisivelModelo?.visivel
            },
            {
                'nomeColuna': cteValue?.nomeColuna,
                'field': 'cte',
                'visivel': cteVisivelModelo?.visivel
            },
            {
                'nomeColuna': previsaoEmbarqueValue?.nomeColuna,
                'field': 'previsaoEmbarque',
                'visivel': previsaoEmbarqueVisivelModelo?.visivel
            },
            {
                'nomeColuna': previsaoEntregaValue?.nomeColuna,
                'field': 'previsaoEntrega',
                'visivel': previsaoEntregaVisivelModelo?.visivel
            },
            {
                'nomeColuna': dataHoraChegadaEmbarqueValue?.nomeColuna,
                'field': 'dataHoraChegadaEmbarque',
                'visivel': dataHoraChegadaEmbarqueVisivelModelo?.visivel
            },
            {
                'nomeColuna': dataHoraEmbarcadoValue?.nomeColuna,
                'field': 'dataHoraEmbarcado',
                'visivel': dataHoraEmbarcadoVisivelModelo?.visivel
            },
            {
                'nomeColuna': dataHoraChegadaEntregaValue?.nomeColuna,
                'field': 'dataHoraChegadaEntrega',
                'visivel': dataHoraChegadaEntregaVisivelModelo?.visivel
            },
            {
                'nomeColuna': dataHoraEntregueValue?.nomeColuna,
                'field': 'dataHoraEntregue',
                'visivel': dataHoraEntregueVisivelModelo?.visivel
            },
            {
                'nomeColuna': aceiteValue?.nomeColuna,
                'field': 'aceite',
                'visivel': aceiteVisivelModelo?.visivel
            },
            {
                'nomeColuna': statusValue?.nomeColuna,
                'field': 'status',
                'visivel': statusVisivelModelo?.visivel
            },
            {
                'nomeColuna': placaCavaloValue?.nomeColuna,
                'field': 'placaCavalo',
                'visivel': placaCavaloVisivelModelo?.visivel
            },
            {
                'nomeColuna': placaCarretaValue?.nomeColuna,
                'field': 'placaCarreta',
                'visivel': placaCarretaVisivelModelo?.visivel
            },
            {
                'nomeColuna': placaCarreta2Value?.nomeColuna,
                'field': 'placaCarreta2',
                'visivel': placaCarreta2VisivelModelo?.visivel
            },
            {
                'nomeColuna': proprietarioValue?.nomeColuna,
                'field': 'proprietario',
                'visivel': proprietarioVisivelModelo?.visivel
            },
            {
                'nomeColuna': motoristaValue?.nomeColuna,
                'field': 'veiculoMotorista',
                'visivel': motoristaVisivelModelo?.visivel
            },
            {
                'nomeColuna': freteFiscalValue?.nomeColuna,
                'field': 'freteFiscal',
                'visivel': freteFiscalVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': icmsValue?.nomeColuna,
                'field': 'icms',
                'visivel': icmsVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': freteMotoristaValue?.nomeColuna,
                'field': 'freteMotorista',
                'visivel': freteMotoristaVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalEmpresa?.nomeColuna,
                'field': 'adicionalEmpresa',
                'visivel': adicionalEmpresaVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': adicionalMotoristaValue?.nomeColuna,
                'field': 'adicionalMotorista',
                'visivel': adicionalMotoristaVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': numeroEixosEmbarqueValue?.nomeColuna,
                'field': 'numeroEixos',
                'visivel': numeroEixosEmbarqueVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': pedagioValue?.nomeColuna,
                'field': 'pedagio',
                'visivel': pedagioVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': classificacaoValue?.nomeColuna,
                'field': 'classificacao',
                'visivel': classificacaoVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': margemValue?.nomeColuna,
                'field': 'margem',
                'visivel': margemVisivelModelo?.visivel
            },
            {
                'nomeColuna': adiantamentoValue?.nomeColuna,
                'field': 'adiantamento',
                'visivel': adiantamentoVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': saldoValue?.nomeColuna,
                'field': 'saldo',
                'visivel': saldoVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': meioPagamentoValue?.nomeColuna,
                'field': 'meioPagamento',
                'visivel': meioPagamentoVisivelModelo?.visivel
            },
            {
                'nomeColuna': meioPagamento2Value?.nomeColuna,
                'field': 'meioPagamento2',
                'visivel': meioPagamento2VisivelModelo?.visivel
            },
            {
                'nomeColuna': kmValue?.nomeColuna,
                'field': 'km',
                'visivel': kmVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': clienteValue?.nomeColuna,
                'field': 'cliente',
                'visivel': clienteVisivelModelo?.visivel
            },
            {
                'nomeColuna': embarcadorEmbValue?.nomeColuna,
                'field': 'embarcador',
                'visivel': embarcadorEmbVisivelModelo?.visivel
            },
            {
                'nomeColuna': destinatarioEmbValue?.nomeColuna,
                'field': 'destinatario',
                'visivel': destinatarioEmbVisivelModelo?.visivel
            },
            {
                'nomeColuna': loadValue?.nomeColuna,
                'field': 'load',
                'visivel': loadVisivelModelo?.visivel
            },
            {
                'nomeColuna': mercadoriaValue?.nomeColuna,
                'field': 'mercadoria',
                'visivel': mercadoriaVisivelModelo?.visivel
            },
            {
                'nomeColuna': pesoValue?.nomeColuna,
                'field': 'peso',
                'visivel': pesoVisivelModelo?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': embalagemEmbValue?.nomeColuna,
                'field': 'embalagem',
                'visivel': embalagemEmbVisivelModelo?.visivel
            },
            {
                'nomeColuna': observacaoValue?.nomeColuna,
                'field': 'observacao',
                'visivel': observacaoVisivelModelo?.visivel
            },
            {
                'nomeColuna': frotaValue?.nomeColuna,
                'field': 'frota',
                'visivel': frotaVisivelModelo?.visivel
            },
            {
                'nomeColuna': canceladoValue?.nomeColuna,
                'field': 'cancelado',
                'visivel': canceladoVisivelModelo?.visivel
            },
            {
                'nomeColuna': motivoEmbarqueValue?.nomeColuna,
                'field': 'motivo',
                'visivel': motivoVisivelModelo?.visivel
            },
            {
                'nomeColuna': usuarioResponsavelValue?.nomeColuna,
                'field': 'usuarioResponsavel',
                'visivel': usuarioResponsavelVisivelModelo?.visivel
            },
            {
                'nomeColuna': nEntregasValue?.nomeColuna,
                'field': 'nEntregas',
                'visivel': nEntregasVisivelModelo?.visivel
            },
            {
                'nomeColuna': comprovanteValue?.nomeColuna,
                'field': 'comprovante',
                'visivel': comprovanteVisivelModelo?.visivel
            },
            {
                'nomeColuna': checkinValue?.nomeColuna,
                'field': 'checkInFeito',
                'visivel': checkinVisivelModelo?.visivel
            },
            {
                'quebraColunaEmbarque': quebraTabelaEmbarque?.quebraColunaEmbarque
            },
            {
                'configDataHora': configDataHoraVisivelModelo?.configDataHora
            },
        ],
        'Pedido': [
            {
                'nomeColuna': pedidoPValue?.nomeColuna,
                'field': 'pedido',
                'visivel': pedidoPVisivel?.visivel
            },
            {
                'nomeColuna': tipoRotaValue?.nomeColuna,
                'field': 'tipoRota',
                'visivel': tipoRotaVisivel?.visivel
            },
            {
                'nomeColuna': freteFiscalPValue?.nomeColuna,
                'field': 'freteFiscal',
                'visivel': freteFiscalPVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': icmsPValue?.nomeColuna,
                'field': 'icms',
                'visivel': icmsPVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': pedagioPValue?.nomeColuna,
                'field': 'pedagio',
                'visivel': pedagioPVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': classificacaoPValue?.nomeColuna,
                'field': 'classificacao',
                'visivel': classificacaoPVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': kmPValue?.nomeColuna,
                'field': 'km',
                'visivel': kmPVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': clientePValue?.nomeColuna,
                'field': 'cliente',
                'visivel': clientePVisivel?.visivel
            },
            {
                'nomeColuna': embarcadorPValue?.nomeColuna,
                'field': 'embarcador',
                'visivel': embarcadorPVisivel?.visivel
            },
            {
                'nomeColuna': destinatarioPValue?.nomeColuna,
                'field': 'destinatario',
                'visivel': destinatarioPVisivel?.visivel
            },
            {
                'nomeColuna': freteMotoristaPValue?.nomeColuna,
                'field': 'freteMotorista',
                'visivel': freteMotoristaPVisivel?.visivel,
                'tipo': 'number'
            },
            {
                'nomeColuna': observacaoPValue?.nomeColuna,
                'field': 'observacao',
                'visivel': observacaoPVisivel?.visivel
            },
            {
                'nomeColuna': permiteAlterarFreteFiscalValue?.nomeColuna,
                'field': 'permiteAlterarFreteFiscal',
                'visivel': permiteAlterarFreteFiscalVisivel?.visivel
            },
            {
                'nomeColuna': valorEmbarquePedidoValue?.nomeColuna,
                'field': 'valorEmbarque',
                'visivel': valorEmbarquePedidoVisivel?.visivel
            },
            {
                'nomeColuna': icmsEmbutidoValue?.nomeColuna,
                'field': 'icmsEmbutido',
                'visivel': icmsEmbutidoVisivel?.visivel
            },
            {
                'nomeColuna': icmsEmbarcadorValue?.nomeColuna,
                'field': 'icmsEmbarcador',
                'visivel': icmsEmbarcadorVisivel?.visivel
            },
            {
                'nomeColuna': freteNormalvalue?.nomeColuna,
                'field': 'freteNormal',
                'visivel': freteNormalVisivel?.visivel
            },
            {
                'quebraColunaPedido': quebraTabelaPedido?.quebraColunaPedido
            },
        ],
    };

    const handleClick = async () => {
        setLoading(true);
        const camposFormatados = JSON.stringify(valorInicial).replace(/"/g, `'`)
        localStorage.setItem('usuarioTabela', JSON.stringify(valorInicial));
        const usuarioTabela = {
            usuarioGerenId: dadosUsuarioRedux.usuario.idUsuario,
            campoVisiveis: camposFormatados
        }
        await editaUsuarioTabelaPorId(usuarioTabela, dadosUsuarioRedux.token).then((result) => {
            setLoading(false);
            onClose();
            setDadosMenusRedux(valorInicial);
            setDadosRequestControllerRedux("Sucesso");
        }).catch((error) => {
            onClose();
            if (error.response.status != 200) {
                setDadosRequestControllerRedux("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
            } else {
                setDadosRequestControllerRedux(error.response.data);
            }
            setLoading(false);
        });
    };

    if (props.nome === "pedido") {
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <>
                        {
                            !loading ? (
                                <div className='div-modal-usuario'>
                                    <DialogTitle id="alert-dialog-title">
                                        {props.titulo}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <div className='div-modal-usuario'>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pedidoPVisivel?.visivel}
                                                        onChange={handleOnChangePedidoPVisivel}
                                                    />} label={`Pedido(${pedidoPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={tipoRotaVisivel?.visivel}
                                                        onChange={handleOnChangeTipoRotaVisivel}
                                                    />} label={`Tipo Rota(${tipoRotaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={freteFiscalPVisivel?.visivel}
                                                        onChange={handleOnChangeFreteFiscalPVisivel}
                                                    />} label={`Frete Fiscal(${freteFiscalPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={icmsPVisivel?.visivel}
                                                        onChange={handleOnChangeIcmsPVisivel}
                                                    />} label={`ICMS(${icmsPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pedagioPVisivel?.visivel}
                                                        onChange={handleOnChangePedagioPVisivel}
                                                    />} label={`Pedágio(${pedagioPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={classificacaoPVisivel?.visivel}
                                                        onChange={handleOnChangeClassificacaoPVisivel}
                                                    />} label={`Classificação(${classificacaoPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={kmPVisivel?.visivel}
                                                        onChange={handleOnChangeKmPVisivel}
                                                    />} label={`KM(${kmPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={clientePVisivel?.visivel}
                                                        onChange={handleOnChangeClientePVisivel}
                                                    />} label={`Cliente(${clientePVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={embarcadorPVisivel?.visivel}
                                                        onChange={handleOnChangeEmbarcadorPVisivel}
                                                    />} label={`Embarcador(${embarcadorPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={destinatarioPVisivel?.visivel}
                                                        onChange={handleOnChangeDestinatarioPVisivel}
                                                    />} label={`Destinatário(${destinatarioPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={freteMotoristaPVisivel?.visivel}
                                                        onChange={handleOnChangeFreteMotoristaPVisivel}
                                                    />} label={`Frete Motorista(${freteMotoristaPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={observacaoPVisivel?.visivel}
                                                        onChange={handleOnChangeObservacaoPVisivel}
                                                    />} label={`Observação(${observacaoPVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={permiteAlterarFreteFiscalVisivel?.visivel}
                                                        onChange={handleOnChangePermiteAlterarFreteFiscalVisivel}
                                                    />} label={`Permite Alterar Frete Fiscal(${permiteAlterarFreteFiscalVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={valorEmbarquePedidoVisivel?.visivel}
                                                        onChange={handleOnChangeValorEmbarquePedidoVisivel}
                                                    />} label={`Valor Embarque(${valorEmbarquePedidoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={icmsEmbutidoVisivel?.visivel}
                                                        onChange={handleOnChangeIcmsEmbutidoVisivel}
                                                    />} label={`ICMS Embutido(${icmsEmbutidoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={icmsEmbarcadorVisivel?.visivel}
                                                        onChange={handleOnChangeIcmsEmbarcadorVisivel}
                                                    />} label={`ICMS Embarcador(${icmsEmbarcadorVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={freteNormalVisivel?.visivel}
                                                        onChange={handleOnChangeFreteNormalVisivel}
                                                    />} label={`Frete Normal(${freteNormalVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <div className="fixed">
                                            <CustomButton
                                                classCss="custom-button"
                                                titulo="OK"
                                                cor={dadosCoresRedux.botaoPadrao}
                                                corTexto={dadosCoresRedux.textoBotaoPadrao}
                                                onClick={handleClick}
                                            />
                                        </div>
                                    </DialogActions>
                                </div>
                            ) : (
                                <LoadingComponent />
                            )
                        }
                    </>
                </Dialog>
            </div>
        );
    }
    if (props.nome === "embarque") {
        return (
            <>
                {
                    !loading ? (
                        <div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                            >
                                <div className='div-modal-usuario'>
                                    <DialogTitle id="alert-dialog-title">
                                        {props.titulo}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <div className='div-modal-usuario'>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={configDataHoraVisivel?.configDataHora}
                                                        onChange={handleOnChangeConfigDataHoraVisivel}
                                                    />} label={`Mostrar Hora em Datas(${configDataHoraVisivel?.configDataHora ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={unidEmbarqueVisivel?.visivel}
                                                        onChange={handleOnChangeUnidEmbarqueVisivel}
                                                    />} label={`Unidade de Embarque(${unidEmbarqueVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={empresaVisivel?.visivel}
                                                        onChange={handleOnChangeEmpresaVisivel}
                                                    />} label={`Empresa(${empresaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={filialVisivel?.visivel}
                                                        onChange={handleOnChangeFilialVisivel}
                                                    />} label={`Filial(${filialVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={rotaEmbarqueVisivel?.visivel}
                                                        onChange={handleOnChangeRotaEmbarqueVisivel}
                                                    />} label={`Rota(${rotaEmbarqueVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pedidoVisivel?.visivel}
                                                        onChange={handleOnChangePedidoVisivel}
                                                    />} label={`Pedido(${pedidoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={cteVisivel?.visivel}
                                                        onChange={handleOnChangeCteVisivel}
                                                    />} label={`Cte(${cteVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={previsaoEmbarqueVisivel?.visivel}
                                                        onChange={handleOnChangePrevisaoEmbarqueVisivel}
                                                    />} label={`Previsão Viagem(${previsaoEmbarqueVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={previsaoEntregaVisivel?.visivel}
                                                        onChange={handleOnChangePrevisaoEntregaVisivel}
                                                    />} label={`Previsão Entrega(${previsaoEntregaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraChegadaEmbarqueVisivel?.visivel}
                                                        onChange={handleOnChangeDataHoraChegadaEmbarqueVisivel}
                                                    />} label={`Data Hora Chegada Viagem(${dataHoraChegadaEmbarqueVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraEmbarcadoVisivel?.visivel}
                                                        onChange={handleOnChangeDataHoraEmbarcadoVisivel}
                                                    />} label={`Data Hora Embarcado(${dataHoraEmbarcadoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraChegadaEntregaVisivel?.visivel}
                                                        onChange={handleOnChangeDataHoraChegadaEntregaVisivel}
                                                    />} label={`Data Hora Chegada Entrega Viagem(${dataHoraChegadaEntregaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraEntregueVisivel?.visivel}
                                                        onChange={handleOnChangeDataHoraEntregueVisivel}
                                                    />} label={`Data Hora Entregue(${dataHoraEntregueVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={aceiteVisivel?.visivel}
                                                        onChange={handleOnChangeAceiteVisivel}
                                                    />} label={`Aceite(${aceiteVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={statusVisivel?.visivel}
                                                        onChange={handleOnChangeStatusVisivel}
                                                    />} label={`Status(${statusVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={placaCavaloVisivel?.visivel}
                                                        onChange={handleOnChangePlacaCavaloVisivel}
                                                    />} label={`Placa Cavalo(${placaCavaloVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={placaCarretaVisivel?.visivel}
                                                        onChange={handleOnChangePlacaCarretaVisivel}
                                                    />} label={`Placa Carreta(${placaCarretaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={placaCarreta2Visivel?.visivel}
                                                        onChange={handleOnChangePlacaCarreta2Visivel}
                                                    />} label={`Placa Segunda Carreta(${placaCarreta2Visivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={proprietarioVisivel?.visivel}
                                                        onChange={handleOnChangeProprietarioVisivel}
                                                    />} label={`Proprietario(${proprietarioVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={motoristaVisivel?.visivel}
                                                        onChange={handleOnChangeMotoristaVisivel}
                                                    />} label={`Motorista(${motoristaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={freteFiscalVisivel?.visivel}
                                                        onChange={handleOnChangeFreteFiscalVisivel}
                                                    />} label={`Frete Fiscal(${freteFiscalVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={icmsVisivel?.visivel}
                                                        onChange={handleOnChangeIcmsVisivel}
                                                    />} label={`ICMS(${icmsVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={freteMotoristaVisivel?.visivel}
                                                        onChange={handleOnChangeFreteMotoristaVisivel}
                                                    />} label={`Frete Motorista(${freteMotoristaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={adicionalEmpresaVisivel?.visivel}
                                                        onChange={handleOnChangeAdicionalEmpresaVisivel}
                                                    />} label={`Adicional Empresa(${adicionalEmpresaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={adicionalMotoristaVisivel?.visivel}
                                                        onChange={handleOnChangeAdicionalMotoristaVisivel}
                                                    />} label={`Adicional Motorista(${adicionalMotoristaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={numeroEixosEmbarqueVisivel?.visivel}
                                                        onChange={handleOnChangeNumeroEixosEmbarqueVisivel}
                                                    />} label={`Eixos(${numeroEixosEmbarqueVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pedagioVisivel?.visivel}
                                                        onChange={handleOnChangePedagioVisivel}
                                                    />} label={`Pedagio(${pedagioVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={classificacaoVisivel?.visivel}
                                                        onChange={handleOnChangeClassificacaoVisivel}
                                                    />} label={`Classificação(${classificacaoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={margemVisivel?.visivel}
                                                        onChange={handleOnChangeMargemVisivel}
                                                    />} label={`Margem(${margemVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={adiantamentoVisivel?.visivel}
                                                        onChange={handleOnChangeAdiamentoVisivel}
                                                    />} label={`Adiantamento(${adiantamentoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={saldoVisivel?.visivel}
                                                        onChange={handleOnChangeSaldoVisivel}
                                                    />} label={`Saldo(${saldoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={meioPagamentoVisivel?.visivel}
                                                        onChange={handleOnChangeMeioPagamentoVisivel}
                                                    />} label={`Meio Pagamento(${meioPagamentoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={meioPagamento2Visivel?.visivel}
                                                        onChange={handleOnChangeMeioPagamento2Visivel}
                                                    />} label={`Meio Pagamento Dois(${meioPagamento2Visivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={kmVisivel?.visivel}
                                                        onChange={handleOnChangeKmVisivel}
                                                    />} label={`Km(${kmVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={clienteVisivel?.visivel}
                                                        onChange={handleOnChangeClienteVisivel}
                                                    />} label={`Cliente(${clienteVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={embarcadorEmbVisivel?.visivel}
                                                        onChange={handleOnChangeEmbarcadorEmbVisivel}
                                                    />} label={`Embarcador(${embarcadorEmbVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={destinatarioEmbVisivel?.visivel}
                                                        onChange={handleOnChangeDestinatarioEmbVisivel}
                                                    />} label={`Destinatario(${destinatarioEmbVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={loadVisivel?.visivel}
                                                        onChange={handleOnChangeLoadVisivel}
                                                    />} label={`LOAD(${loadVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={mercadoriaVisivel?.visivel}
                                                        onChange={handleOnChangeMercadoriaVisivel}
                                                    />} label={`Mercadoria(${mercadoriaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pesoVisivel?.visivel}
                                                        onChange={handleOnChangePesoVisivel}
                                                    />} label={`Peso(${pesoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={embalagemEmbVisivel?.visivel}
                                                        onChange={handleOnChangeEmbalagemEmbVisivel}
                                                    />} label={`Embalagem(${embalagemEmbVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={observacaoVisivel?.visivel}
                                                        onChange={handleOnChangeObservacaoVisivel}
                                                    />} label={`Observação(${observacaoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={frotaVisivel?.visivel}
                                                        onChange={handleOnChangeFrotaVisivel}
                                                    />} label={`Frota(${frotaVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={canceladoVisivel?.visivel}
                                                        onChange={handleOnChangeCanceladoVisivel}
                                                    />} label={`Cancelado(${canceladoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={motivoVisivel?.visivel}
                                                        onChange={handleOnChangeMotivoVisivel}
                                                    />} label={`Motivo(${motivoVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={usuarioResponsavelVisivel?.visivel}
                                                        onChange={handleOnChangeUsuarioResponsavelVisivel}
                                                    />} label={`Usuário Responsável(${usuarioResponsavelVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={nEntregasVisivel?.visivel}
                                                        onChange={handleOnChangeNEntregasVisivel}
                                                    />} label={`Entregas(${nEntregasVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={comprovanteVisivel?.visivel}
                                                        onChange={handleOnChangeComprovanteVisivel}
                                                    />} label={`Comprovante(${comprovanteVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={checkinVisivel?.visivel}
                                                        onChange={handleOnChangeCheckinVisivel}
                                                    />} label={`Check-in(${checkinVisivel?.visivel ? 'Sim' : 'Não'})`} />
                                                {/* <FormControlLabel control={
                                                    <Checkbox
                                                        checked={entregaVisivel?.visivel}
                                                        onChange={handleOnChangeEntregaVisivel}
                                                    />} label={`Entrega(${entregaVisivel?.visivel ? 'Sim' : 'Não'})`} /> */}
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <div className="fixed">
                                            <CustomButtonV2
                                                classCss="custom-button-cadastrar"
                                                classText="button-texto-cadastrar"
                                                titulo="OK"
                                                cor="#0946B8"
                                                onClick={handleClick}
                                            />
                                        </div>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        </div>
                    ) : (
                        <LoadingComponent />
                    )
                }
            </>
        );
    }
    if (props.nome === "modelo") {
        return (
            <>
                {
                    !loading ? (
                        <div>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                            >
                                <div className='div-modal-usuario'>
                                    <DialogTitle id="alert-dialog-title">
                                        {props.titulo}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <div className='div-modal-usuario'>
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={configDataHoraVisivelModelo?.configDataHora}
                                                        onChange={handleOnChangeConfigDataHoraVisivelModelo}
                                                    />} label={`Mostrar Hora em Datas(${configDataHoraVisivelModelo?.configDataHora ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={unidEmbarqueVisivelModelo?.visivel}
                                                        onChange={handleOnChangeUnidEmbarqueVisivelModelo}
                                                    />} label={`Unidade de Embarque(${unidEmbarqueVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={empresaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeEmpresaVisivelModelo}
                                                    />} label={`Empresa(${empresaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={filialVisivelModelo?.visivel}
                                                        onChange={handleOnChangeFilialVisivelModelo}
                                                    />} label={`Filial(${filialVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={rotaEmbarqueVisivelModelo?.visivel}
                                                        onChange={handleOnChangeRotaEmbarqueVisivelModelo}
                                                    />} label={`Rota(${rotaEmbarqueVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pedidoVisivelModelo?.visivel}
                                                        onChange={handleOnChangePedidoVisivelModelo}
                                                    />} label={`Pedido(${pedidoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={cteVisivelModelo?.visivel}
                                                        onChange={handleOnChangeCteVisivelModelo}
                                                    />} label={`Cte(${cteVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={previsaoEmbarqueVisivelModelo?.visivel}
                                                        onChange={handleOnChangePrevisaoEmbarqueVisivelModelo}
                                                    />} label={`Previsão Viagem(${previsaoEmbarqueVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={previsaoEntregaVisivelModelo?.visivel}
                                                        onChange={handleOnChangePrevisaoEntregaVisivelModelo}
                                                    />} label={`Previsão Entrega(${previsaoEntregaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraChegadaEmbarqueVisivelModelo?.visivel}
                                                        onChange={handleOnChangeDataHoraChegadaEmbarqueVisivelModelo}
                                                    />} label={`Data Hora Chegada Viagem(${dataHoraChegadaEmbarqueVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraEmbarcadoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeDataHoraEmbarcadoVisivelModelo}
                                                    />} label={`Data Hora Embarcado(${dataHoraEmbarcadoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraChegadaEntregaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeDataHoraChegadaEntregaVisivelModelo}
                                                    />} label={`Data Hora Chegada Entrega Viagem(${dataHoraChegadaEntregaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={dataHoraEntregueVisivelModelo?.visivel}
                                                        onChange={handleOnChangeDataHoraEntregueVisivelModelo}
                                                    />} label={`Data Hora Entregue(${dataHoraEntregueVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={aceiteVisivelModelo?.visivel}
                                                        onChange={handleOnChangeAceiteVisivelModelo}
                                                    />} label={`Aceite(${aceiteVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={statusVisivelModelo?.visivel}
                                                        onChange={handleOnChangeStatusVisivelModelo}
                                                    />} label={`Status(${statusVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={placaCavaloVisivelModelo?.visivel}
                                                        onChange={handleOnChangePlacaCavaloVisivelModelo}
                                                    />} label={`Placa Cavalo(${placaCavaloVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={placaCarretaVisivelModelo?.visivel}
                                                        onChange={handleOnChangePlacaCarretaVisivelModelo}
                                                    />} label={`Placa Carreta(${placaCarretaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={placaCarreta2VisivelModelo?.visivel}
                                                        onChange={handleOnChangePlacaCarreta2VisivelModelo}
                                                    />} label={`Placa Segunda Carreta(${placaCarreta2VisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={proprietarioVisivelModelo?.visivel}
                                                        onChange={handleOnChangeProprietarioVisivelModelo}
                                                    />} label={`Proprietario(${proprietarioVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={motoristaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeMotoristaVisivelModelo}
                                                    />} label={`Motorista(${motoristaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={freteFiscalVisivelModelo?.visivel}
                                                        onChange={handleOnChangeFreteFiscalVisivelModelo}
                                                    />} label={`Frete Fiscal(${freteFiscalVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={icmsVisivelModelo?.visivel}
                                                        onChange={handleOnChangeIcmsVisivelModelo}
                                                    />} label={`ICMS(${icmsVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={freteMotoristaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeFreteMotoristaVisivelModelo}
                                                    />} label={`Frete Motorista(${freteMotoristaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={adicionalEmpresaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeAdicionalEmpresaVisivelModelo}
                                                    />} label={`Adicional Empresa(${adicionalEmpresaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={adicionalMotoristaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeAdicionalMotoristaVisivelModelo}
                                                    />} label={`Adicional Motorista(${adicionalMotoristaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={numeroEixosEmbarqueVisivelModelo?.visivel}
                                                        onChange={handleOnChangeNumeroEixosEmbarqueVisivelModelo}
                                                    />} label={`Eixos(${numeroEixosEmbarqueVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pedagioVisivelModelo?.visivel}
                                                        onChange={handleOnChangePedagioVisivelModelo}
                                                    />} label={`Pedagio(${pedagioVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={classificacaoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeClassificacaoVisivelModelo}
                                                    />} label={`Classificação(${classificacaoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={margemVisivelModelo?.visivel}
                                                        onChange={handleOnChangeMargemVisivelModelo}
                                                    />} label={`Margem(${margemVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={adiantamentoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeAdiamentoVisivelModelo}
                                                    />} label={`Adiantamento(${adiantamentoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={saldoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeSaldoVisivelModelo}
                                                    />} label={`Saldo(${saldoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={meioPagamentoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeMeioPagamentoVisivelModelo}
                                                    />} label={`Meio Pagamento(${meioPagamentoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={meioPagamento2VisivelModelo?.visivel}
                                                        onChange={handleOnChangeMeioPagamento2VisivelModelo}
                                                    />} label={`Meio Pagamento Dois(${meioPagamento2VisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={kmVisivelModelo?.visivel}
                                                        onChange={handleOnChangeKmVisivelModelo}
                                                    />} label={`Km(${kmVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={clienteVisivelModelo?.visivel}
                                                        onChange={handleOnChangeClienteVisivelModelo}
                                                    />} label={`Cliente(${clienteVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={embarcadorEmbVisivelModelo?.visivel}
                                                        onChange={handleOnChangeEmbarcadorEmbVisivelModelo}
                                                    />} label={`Embarcador(${embarcadorEmbVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={destinatarioEmbVisivelModelo?.visivel}
                                                        onChange={handleOnChangeDestinatarioEmbVisivelModelo}
                                                    />} label={`Destinatario(${destinatarioEmbVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={loadVisivelModelo?.visivel}
                                                        onChange={handleOnChangeLoadVisivelModelo}
                                                    />} label={`LOAD(${loadVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={mercadoriaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeMercadoriaVisivelModelo}
                                                    />} label={`Mercadoria(${mercadoriaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={pesoVisivelModelo?.visivel}
                                                        onChange={handleOnChangePesoVisivelModelo}
                                                    />} label={`Peso(${pesoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={embalagemEmbVisivelModelo?.visivel}
                                                        onChange={handleOnChangeEmbalagemEmbVisivelModelo}
                                                    />} label={`Embalagem(${embalagemEmbVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={observacaoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeObservacaoVisivelModelo}
                                                    />} label={`Observação(${observacaoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={frotaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeFrotaVisivelModelo}
                                                    />} label={`Frota(${frotaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={canceladoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeCanceladoVisivelModelo}
                                                    />} label={`Cancelado(${canceladoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={motivoVisivelModelo?.visivel}
                                                        onChange={handleOnChangeMotivoVisivelModelo}
                                                    />} label={`Motivo(${motivoVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={usuarioResponsavelVisivelModelo?.visivel}
                                                        onChange={handleOnChangeUsuarioResponsavelVisivelModelo}
                                                    />} label={`Usuário Responsável(${usuarioResponsavelVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={nEntregasVisivelModelo?.visivel}
                                                        onChange={handleOnChangeNEntregasVisivelModelo}
                                                    />} label={`Entregas(${nEntregasVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={comprovanteVisivelModelo?.visivel}
                                                        onChange={handleOnChangeComprovanteVisivelModelo}
                                                    />} label={`Comprovante(${comprovanteVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                <FormControlLabel control={
                                                    <Checkbox
                                                        checked={checkinVisivelModelo?.visivel}
                                                        onChange={handleOnChangeCheckinVisivelModelo}
                                                    />} label={`Check-in(${checkinVisivelModelo?.visivel ? 'Sim' : 'Não'})`} />
                                                {/* <FormControlLabel control={
                                                    <Checkbox
                                                        checked={entregaVisivelModelo?.visivel}
                                                        onChange={handleOnChangeEntregaVisivelModelo}
                                                    />} label={`Entrega(${entregaVisivelModelo?.visivel ? 'Sim' : 'Não'})`} /> */}
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <div className="fixed">
                                            <CustomButtonV2
                                                classCss="custom-button-cadastrar"
                                                classText="button-texto-cadastrar"
                                                titulo="OK"
                                                cor="#0946B8"
                                                onClick={handleClick}
                                            />
                                        </div>
                                    </DialogActions>
                                </div>
                            </Dialog>
                        </div>
                    ) : (
                        <LoadingComponent />
                    )
                }
            </>
        );
    }
}

export default ModalConfigTabela;