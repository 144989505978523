import React, { useContext, useState, useEffect } from "react";
import NavbarV3 from "../../../components/NavbarV3/NavbarV3";
import InputLabel from "@mui/material/InputLabel";
import "./TipoCheckagem.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import CoresRedux from "../../../contexts/Cor";
import NomeTabelaRedux from "../../../contexts/NomeTabela";
import DadosUsuarioRedux from "../../../contexts/Usuario";
import Tabela from "../../../components/Tabela/Tabela";
import { useNavigate } from 'react-router-dom';
import ModalConfirmacao from "../../../components/ModalConfirmacao/ModalConfirmacao.tsx";
import ToastSucesso from "../../../components/Toast/ToastSucesso/ToastSucesso.tsx";
import ToastErro from "../../../components/Toast/ToastErro/ToastErro.tsx";
import ModalUsuario from "../../../components/ModalUsuario/ModalUsuario.tsx";
import TabelaRedux from "../../../contexts/Tabela";
import { verificaDadosUsuarioRedux } from "../../../services/ModuloGerenciador/Usuario";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import { getTipoCheckagem, cadastraTipoCheckagem, editaTipoCheckagemPorId, getTipoCheckagemPorId, deleteTipoCheckagemPorId } from "../../../services/ModuloChecklist/TipoCheckagem";
import Text from "../../../components/Text/Text";
import SemAutorizacao from "../../../components/SemAutorizacao/SemAutorizacao";
import { getRegras } from "../../../services/ModuloGerenciador/Geral";
import RequestControllerRedux from "../../../contexts/RequestController";
import { motion } from "framer-motion";
import { MenuItem, Select } from "@mui/material";
import TabelaV2 from "../../../components/TabelaV2/TabelaV2";
import CustomButtonV2 from "../../../components/CustomButton copy/CustomButtonV2";
import HeaderCadastro from "../../../components/HeaderCadastro/HeaderCadastro";
import CloseIcon from "../../../components/CloseIcon/CloseIcon";
import ClearIcon from '@mui/icons-material/Clear';

function TipoCheckagem(props) {

    const { dadosCoresRedux } = useContext(CoresRedux);

    const { dadosUsuarioRedux, setDadosUsuarioRedux } = useContext(DadosUsuarioRedux);

    const { dadosRequestControllerRedux, setDadosRequestControllerRedux } = useContext(RequestControllerRedux);

    const { dadosTabelaRedux } = useContext(NomeTabelaRedux);

    const { tabelaRedux, setTabelaRedux } = useContext(TabelaRedux);

    const [dadosTabela, setDadosTabela] = useState([]);

    const [telaCadastro, setTelaCadastro] = useState(false);

    const [editando, setEditando] = useState(false);

    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState([]);

    const [valuesData, setValuesData] = useState([]);

    const [validator, setValidator] = useState([]);

    const [open, setOpen] = useState(false);

    const [textoSucesso, setTextoSucesso] = useState("");

    const [textoErro, setTextoErro] = useState("");

    const [tipoNotificacao, setTipoNotificacao] = useState(['Por Data', 'Por KM']);

    const handleClose = (value) => {
        setOpen(false);
    };

    const [openToastSucesso, setOpenToastSucesso] = useState(false);

    const handleCloseToastSucesso = (value) => {
        setOpenToastSucesso(false);
    };

    const [openToastErro, setOpenToastErro] = useState(false);

    const handleCloseToastErro = (value) => {
        setOpenToastErro(false);
    };

    const [openUsuario, setOpenUsuario] = useState(false);

    const handleCloseUsuario = (value) => {
        setOpenUsuario(false);
    };

    const navigate = useNavigate();

    const buscarTipoCheckagem = async () => {
        setLoading(true);
        await getTipoCheckagem(dadosUsuarioRedux.token).then((result) => {
            setDadosTabela(result.data);
            setTabelaRedux({});
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const cadastrar = async () => {
        if ((values.nome !== "") && (validator.nome === "true")
            && (valuesData.dia !== "") && (validator.dia === "true")
            && (valuesData.mes !== "") && (validator.mes === "true")
            && (valuesData.ano !== "") && (validator.ano === "true")) {
            setLoading(true);
            await cadastraTipoCheckagem(values, dadosUsuarioRedux.token).then((result) => {
                if (result.status === 200) {
                    buscarTipoCheckagem();
                    setDadosRequestControllerRedux("Fechar Modal TipoCheckagem");
                    setTabelaRedux({});
                    setTextoSucesso("Cadastrado Com Sucesso");
                    setOpenToastSucesso(true);
                }
            }).catch((error) => {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            });
        } else {
            setTextoErro('Há campos sem preencher!');
            setOpenToastErro(true);
            setLoading(false);
        }
    }

    const editar = async () => {
        setLoading(true);
        await editaTipoCheckagemPorId(values, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarTipoCheckagem();
                setTabelaRedux({});
                setTextoSucesso("Cadastrado Com Sucesso");
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    const formatarData = async (data) => {
        const dia = data.split('d')[0].split(' ')[2];
        const mes = data.split('m')[0].split(' ')[1];
        const ano = data.split('a')[0];
        setValuesData(current => ({ ...current, ano: ano }));
        setValuesData(current => ({ ...current, mes: mes }));
        setValuesData(current => ({ ...current, dia: dia }));
    };

    const buscarTipoCheckagemPorId = async () => {
        setLoading(true);
        await getTipoCheckagemPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                if (editando) {
                    await formatarData(result.data.notificarACadaData);
                    setValues(result.data);
                    setLoading(false);
                }
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const buscarTipoCheckagemPorIdModal = async (id) => {
        setLoading(true);
        await getTipoCheckagemPorId(id, dadosUsuarioRedux.token).then(async (result) => {
            if (result.status === 200) {
                await formatarData(result.data.notificarACadaData);
                setValues(result.data);
            }
        }).catch((error) => {
            if (!editando) {
                if (typeof error.response.data === 'string') {
                    setTextoErro(error.response.data);
                } else {
                    setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                }
                setOpenToastErro(true);
                setLoading(false);
            }
        });
    }

    const deletarTipoCheckagemPorId = async () => {
        setLoading(true);
        await deleteTipoCheckagemPorId(tabelaRedux.colunaSelecionada, dadosUsuarioRedux.token).then((result) => {
            if (result.status === 200) {
                buscarTipoCheckagem();
                setTabelaRedux({});
                setOpenToastSucesso(true);
            }
        }).catch((error) => {
            if (typeof error.response.data === 'string') {
                setTextoErro(error.response.data);
                setOpenToastErro(true);
                setLoading(false);
            } else {
                setTextoErro("Ocorreu um erro inesperado, volte e tente novamente, caso o problema persista entre em contato com o suporte.");
                setOpenToastErro(true);
                setLoading(false);
            }
            setLoading(false);
        });
    }

    useEffect(() => {
        verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
        setValues(current => ({ ...current, usuario: dadosUsuarioRedux.usuario.usuario }));
        setValues(current => ({ ...current, notificarACadaData: "" }));
        if (!editando) {
            setValues(current => ({ ...current, notificarACadaKm: 0 }));
            setValues(current => ({ ...current, notificarAutomatico: false }));
        }
        setValidator(current => ({ ...current, nome: "false" }));
        setValidator(current => ({ ...current, ano: "false" }));
        setValidator(current => ({ ...current, mes: "false" }));
        setValidator(current => ({ ...current, dia: "false" }));
    }, [loading]);

    useEffect(() => {
        if (editando) {
            buscarTipoCheckagemPorId();
        }
    }, [editando]);

    useEffect(() => {
        buscarTipoCheckagem();
        if (props.idAtual) {
            buscarTipoCheckagemPorIdModal(props.idAtual);
        }
    }, []);

    useEffect(() => {
        if (props.telaCadastro === true) {
            setTelaCadastro(true);
        }
    }, []);

    useEffect(() => {
        setValues(current => ({ ...current, notificarACadaData: `${valuesData.ano}a ${valuesData.mes}m ${valuesData.dia}d` }));
    }, [valuesData]);

    const [semAutorizacao, setSemAutorizacao] = useState(true);

    useEffect(() => {
        if (dadosUsuarioRedux.regras !== undefined) {
            dadosUsuarioRedux.regras.forEach((element) => {
                if (element === 'Gerenciamento de Frota Configurações') {
                    setSemAutorizacao(false);
                }
            })
        } else {
            verificaDadosUsuarioRedux(dadosUsuarioRedux, setDadosUsuarioRedux);
            getRegras(dadosUsuarioRedux.token, dadosUsuarioRedux.usuario.idUsuario).then((result) => {
                let regras = [];
                result.data.forEach((dado) => {
                    regras.push(dado.regras.regra)
                    if (dado.regras.regra === 'Gerenciamento de Frota Configurações') {
                        setSemAutorizacao(false);
                    }
                });
                dadosUsuarioRedux.regras = regras;
                setDadosUsuarioRedux(dadosUsuarioRedux);
            })
        }
    })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {semAutorizacao ? (
                <>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Tipo de Checkagem" />

                        )
                    }
                    <SemAutorizacao></SemAutorizacao>
                </>
            ) : (
                <div>
                    {
                        props.navbar ? (
                            <>
                            </>
                        ) : (
                            <NavbarV3 selecionado="Tipo de Checkagem" />
                        )
                    }
                    {
                        !loading ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {
                                    !telaCadastro ? (
                                        <div className="embarques">
                                            <div className="header-cliente-v2">
                                                <div className="flex">
                                                    <p className="indicador-principal-embarque-v2">Tipo de Checkagem</p>
                                                </div>
                                            </div>
                                            <TabelaV2
                                                setTelaCadastro={setTelaCadastro}
                                                setEditando={setEditando}
                                                classeSelecionado="idTipoCheck"
                                                nomeClasse1="outer-wrapper-cidade"
                                                nomeClasse2="table-wrapper-cidade-v2"
                                                colunas={dadosTabelaRedux.TipoCheckagem}
                                                dados={dadosTabela} />
                                            <div className="container-botoes-cliente">
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-cadastrar"
                                                        classText="button-texto-cadastrar"
                                                        icone="iconeSave"
                                                        titulo="Cadastrar"
                                                        cor="#0946B8"
                                                        valor={values}
                                                        onClick={() => { setTelaCadastro(true); setEditando(false); }}
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-editar"
                                                        classText="button-texto-editar"
                                                        titulo="Editar"
                                                        icone="iconeEdit"
                                                        cor="#CDCDCD"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setTelaCadastro(true);
                                                                setEditando(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }
                                                        }
                                                    />
                                                    <CustomButtonV2
                                                        classCss="custom-button-deletar"
                                                        classText="button-texto-cadastrar"
                                                        titulo="Deletar"
                                                        icone="iconeDelete"
                                                        cor="#DC2D2D"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.colunaSelecionada) {
                                                                setOpen(true);
                                                                setTextoSucesso("Deletado Com Sucesso");
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <CustomButtonV2
                                                        classCss="custom-button-user"
                                                        classText="button-texto-user"
                                                        titulo="Obter Usuário"
                                                        cor="#FFFFFF"
                                                        valor={values}
                                                        onClick={() => {
                                                            if (tabelaRedux.usuarioAlteracao) {
                                                                setOpenUsuario(true);
                                                            } else {
                                                                setTextoErro("Selecione Uma Coluna");
                                                                setOpenToastErro(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {
                                                !props.desabilitar ? (
                                                    <div className="indicadores">
                                                        <p className="indicador-emb">{"Dados de Tipo de Checkagem /"}</p>
                                                        {
                                                            editando ? (
                                                                <p className="indicador-emb">{"Detalhes de Checkagem"}</p>
                                                            ) : (
                                                                <p className="indicador-emb">{"Cadastro de Checkagem"}</p>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )
                                            }
                                            <div className="flex">
                                                {
                                                    !props.botaoVoltar ? (
                                                        <div className="flex-emb">
                                                            {
                                                                editando ? (
                                                                    <HeaderCadastro texto="Detalhes de Checkagem" />
                                                                ) : (
                                                                    <HeaderCadastro texto="Cadastro de Checkagem" />
                                                                )
                                                            }
                                                            <CloseIcon onClick={() => {
                                                                setValues({}); setValuesData({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({});
                                                            }} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div className="flex-column">
                                                <div className="container-select-emb">
                                                    <div className="rota-div-2">
                                                        <div className="coluna-emb">
                                                            <div>
                                                                <p className="input-label-emb" htmlFor="descricao">Nome:</p>
                                                                <input
                                                                    type="text"
                                                                    className="input-emb"
                                                                    style={{ width: '250px' }}
                                                                    onChange={(e) => {
                                                                        setValues(current => ({ ...current, nome: e.target.value }));
                                                                        setValidator(current => ({ ...current, nome: "true" }));
                                                                    }}
                                                                    placeholder="Ex: Troca de Óleo"
                                                                    value={values.nome}
                                                                    disabled={props.desabilitar} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {values.notificarAutomatico === true && (
                                                        <div className="rota-div-2">
                                                            <div className="coluna-emb">
                                                                <div>
                                                                    <p className="input-label-emb" htmlFor="descricao">Tipo Notificação:</p>
                                                                    <Select
                                                                        className='input-emb'
                                                                        variant="standard"
                                                                        id="tipoNotificacao"
                                                                        placeholder="Selecione"
                                                                        style={{ width: 250 }}
                                                                        value={values.tipoNotificacao}
                                                                        disabled={props.desabilitar}
                                                                    >
                                                                        {tipoNotificacao.map((data) => (
                                                                            <MenuItem
                                                                                key={data}
                                                                                value={data}
                                                                                onClick={(e) => {
                                                                                    if (data === 'Por KM') {
                                                                                        setValues(current => ({ ...current, tipoNotificacao: 'Por KM' }));
                                                                                        setValidator(current => ({ ...current, tipoNotificacao: "true" }));
                                                                                        setValidator(current => ({ ...current, ano: "true" }));
                                                                                        setValidator(current => ({ ...current, dia: "true" }));
                                                                                        setValidator(current => ({ ...current, mes: "true" }));
                                                                                    } else {
                                                                                        setValues(current => ({ ...current, tipoNotificacao: 'Por Data' }));
                                                                                        setValidator(current => ({ ...current, tipoNotificacao: "true" }));
                                                                                        setValidator(current => ({ ...current, ano: "true" }));
                                                                                        setValidator(current => ({ ...current, dia: "true" }));
                                                                                        setValidator(current => ({ ...current, mes: "true" }));
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {data}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {values.tipoNotificacao === 'Por KM' && (
                                                        <div className="rota-div-2">
                                                            <div className="coluna-emb">
                                                                <div>
                                                                    <p className="input-label-emb" htmlFor="descricao">KM:</p>
                                                                    <input
                                                                        type="number"
                                                                        className="input-emb"
                                                                        style={{ width: '250px' }}
                                                                        onChange={(e) => {
                                                                            setValues(current => ({ ...current, notificarACadaKm: e.target.value }));
                                                                            setValidator(current => ({ ...current, notificarACadaKm: "true" }));
                                                                        }}
                                                                        placeholder="Ex: 100000"
                                                                        value={values.notificarACadaKm}
                                                                        disabled={props.desabilitar} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {values.tipoNotificacao === 'Por Data' && (
                                                        <>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Ano:</p>
                                                                        <Select
                                                                            className='input-emb'
                                                                            variant="standard"
                                                                            id="ano"
                                                                            placeholder="Selecione"
                                                                            style={{ width: 250 }}
                                                                            value={valuesData.ano}
                                                                            disabled={props.desabilitar}
                                                                            onChange={(e) => {
                                                                                setValuesData(current => ({ ...current, ano: e.target.value }));
                                                                                setValidator(current => ({ ...current, ano: "true" }));
                                                                            }}
                                                                        >
                                                                            {[...Array(11)].map((e, i) => (
                                                                                <MenuItem
                                                                                    key={i}
                                                                                    value={i}
                                                                                >
                                                                                    {i}
                                                                                    {
                                                                                        i === valuesData.ano &&
                                                                                        <ClearIcon className="info-icon"
                                                                                            onClick={(e) => {
                                                                                                setValuesData(current => ({ ...current, ano: '' }));
                                                                                                setValidator(current => ({ ...current, ano: "false" }));
                                                                                            }} />
                                                                                    }
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Mês:</p>
                                                                        <Select
                                                                            className='input-emb'
                                                                            variant="standard"
                                                                            id="mes"
                                                                            placeholder="Selecione"
                                                                            style={{ width: 250 }}
                                                                            value={valuesData.mes}
                                                                            disabled={props.desabilitar}
                                                                            onChange={(e) => {
                                                                                setValuesData(current => ({ ...current, mes: e.target.value }));
                                                                                setValidator(current => ({ ...current, mes: "true" }));
                                                                            }}
                                                                        >
                                                                            {[...Array(13)].map((e, i) => (
                                                                                <MenuItem
                                                                                    key={i}
                                                                                    value={i}
                                                                                >
                                                                                    {i}
                                                                                    {
                                                                                        i === valuesData.mes &&
                                                                                        <ClearIcon className="info-icon"
                                                                                            onClick={(e) => {
                                                                                                setValuesData(current => ({ ...current, mes: '' }));
                                                                                                setValidator(current => ({ ...current, mes: "false" }));
                                                                                            }} />
                                                                                    }
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="rota-div-2">
                                                                <div className="coluna-emb">
                                                                    <div>
                                                                        <p className="input-label-emb" htmlFor="descricao">Dia:</p>
                                                                        <Select
                                                                            className='input-emb'
                                                                            variant="standard"
                                                                            id="dia"
                                                                            placeholder="Selecione"
                                                                            style={{ width: 250 }}
                                                                            value={valuesData.dia}
                                                                            disabled={props.desabilitar}
                                                                            onChange={(e) => {
                                                                                setValuesData(current => ({ ...current, dia: e.target.value }));
                                                                                setValidator(current => ({ ...current, dia: "true" }));
                                                                            }}
                                                                        >
                                                                            {[...Array(32)].map((e, i) => (
                                                                                <MenuItem
                                                                                    key={i}
                                                                                    value={i}
                                                                                >
                                                                                    {i}
                                                                                    {
                                                                                        i === valuesData.dia &&
                                                                                        <ClearIcon className="info-icon"
                                                                                            onClick={(e) => {
                                                                                                setValuesData(current => ({ ...current, dia: '' }));
                                                                                                setValidator(current => ({ ...current, dia: "false" }));
                                                                                            }} />
                                                                                    }
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="rota-div-2">
                                                    <div className="coluna-emb">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                checked={values.notificarAutomatico}
                                                                onChange={(e) => {
                                                                    setValues(current => ({ ...current, notificarAutomatico: e.target.checked }));
                                                                }}
                                                                disabled={props.desabilitar}
                                                            ></input>Notificar Automático
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="botoes-embalagem">
                                                {
                                                    editando ? (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cadastrar"
                                                            classText="button-texto-cadastrar"
                                                            icone="iconeSave"
                                                            titulo="Salvar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { editar(); setTelaCadastro(false); setEditando(false); setValues({}); setValuesData({}); }}
                                                        />
                                                    ) : (
                                                        <>
                                                            {
                                                                !props.desabilitar ? (
                                                                    props.botaoCadastrar ? (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "") && (validator.nome === "true")
                                                                                    && (valuesData.dia !== "") && (validator.dia === "true")
                                                                                    && (valuesData.mes !== "") && (validator.mes === "true")
                                                                                    && (valuesData.ano !== "") && (validator.ano === "true")) {
                                                                                    cadastrar();
                                                                                    setValues({});
                                                                                    setValuesData({});
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CustomButtonV2
                                                                            classCss="custom-button-cadastrar"
                                                                            classText="button-texto-cadastrar"
                                                                            icone="iconeSave"
                                                                            titulo="Salvar"
                                                                            cor="#0946B8"
                                                                            valor={values}
                                                                            onClick={() => {
                                                                                if ((values.nome !== "") && (validator.nome === "true")
                                                                                    && (valuesData.dia !== "") && (validator.dia === "true")
                                                                                    && (valuesData.mes !== "") && (validator.mes === "true")
                                                                                    && (valuesData.ano !== "") && (validator.ano === "true")) {
                                                                                    cadastrar();
                                                                                    setValuesData({});
                                                                                    setValues({});
                                                                                    setTelaCadastro(false);
                                                                                } else {
                                                                                    setTextoErro('Há campos sem preencher!');
                                                                                    setOpenToastErro(true);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <></>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                                                {
                                                    props.botaoVoltar ? (
                                                        <>
                                                        </>
                                                    ) : (
                                                        <CustomButtonV2
                                                            classCss="custom-button-cancelar"
                                                            classText="button-texto-cancelar"
                                                            titulo="Cancelar"
                                                            cor="#0946B8"
                                                            valor={values}
                                                            onClick={() => { setValues({}); setValuesData({}); setTelaCadastro(false); setEditando(false); setTabelaRedux({}); }}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                                <div>
                                    <ModalConfirmacao
                                        titulo="Deletar"
                                        texto="Deseja Deletar?"
                                        tituloConfirmar="Confirmar"
                                        tituloCancelar="Cancelar"
                                        open={open}
                                        onClose={handleClose}
                                        onClick={deletarTipoCheckagemPorId}
                                    />
                                    <ModalUsuario
                                        titulo="Usuário"
                                        tituloConfirmar="OK"
                                        open={openUsuario}
                                        onClose={handleCloseUsuario}
                                    />
                                </div>
                                <div>
                                    <ToastSucesso
                                        texto={textoSucesso}
                                        open={openToastSucesso}
                                        onClose={handleCloseToastSucesso}
                                    />
                                    <ToastErro
                                        texto={textoErro}
                                        open={openToastErro}
                                        onClose={handleCloseToastErro}
                                    />
                                </div>
                            </motion.div>
                        ) : (
                            <LoadingComponent />
                        )
                    }
                </div >
            )
            }
        </motion.div >
    );
}

export default TipoCheckagem;