import { api } from "./UrlPadrao"

export const cadastraEstadia = async (estadia, token) => {
  return api.post("estadia",
    estadia
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const cadastraEstadiaGoogleStorage = async (estadia, id, token) => {
  return api.post("estadiaGoogleStorage/" + id,
    estadia
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getEstadia = async (token) => {
  return api.get("estadia",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getEstadiaPorId = async (id, token) => {
  return api.get("estadia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getEstadiaGoogleStoragePorId = async (id, token) => {
  return api.get("estadiaGoogleStorage/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteEstadiaPorId = async (id, token) => {
  return api.delete("estadia/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteEstadiaStoragePorId = async (id, token) => {
  return api.delete("estadiaGoogleStorage/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaEstadiaPorId = async (estadia, token) => {
  return api.put("estadia",
    estadia,
  {
    headers: {
      'Authorization': token
    }
  })
}