import { api } from "./UrlPadrao"

export const cadastraTipoObservacao = async (tipoObservacao, token) => {
  return api.post("TipoObservacao",
    tipoObservacao
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoObservacao = async (token) => {
  return api.get("TipoObservacao",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoObservacaoPorId = async (id, token) => {
  return api.get("TipoObservacao/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoObservacaoPorProprietarioId = async (id, token) => {
  return api.get("TipoObservacao/Motorista/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTipoObservacaoPorId = async (id, token) => {
  return api.delete("TipoObservacao/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTipoObservacaoPorId = async (tipoObservacao, token) => {
  return api.put("TipoObservacao",
    tipoObservacao,
  {
    headers: {
      'Authorization': token
    }
  })
}