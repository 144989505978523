import { api } from "./UrlPadrao"

export const cadastraTipoContato = async (tipoContato, token) => {
  return api.post("TipoContato",
    tipoContato
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoContato = async (token) => {
  return api.get("TipoContato",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoContatoPorId = async (id, token) => {
  return api.get("TipoContato/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoContatoPorProprietarioId = async (id, token) => {
  return api.get("TipoContato/Proprietario/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getTipoContatoPorMotoristaId = async (id, token) => {
  return api.get("TipoContato/Motorista/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteTipoContatoPorId = async (id, token) => {
  return api.delete("TipoContato/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaTipoContatoPorId = async (tipoContato, token) => {
  return api.put("TipoContato",
    tipoContato,
  {
    headers: {
      'Authorization': token
    }
  })
}