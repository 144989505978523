import { api } from "../ModuloGerenciador/UrlPadrao"

export const cadastraVeiculoDocumento = async (VeiculoDocumento, token) => {
  return api.post("VeiculoDocumento",
    VeiculoDocumento
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getVeiculoDocumento = async (token) => {
  return api.get("VeiculoDocumento",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getVeiculoDocumentoPorId = async (id, token) => {
  return api.get("VeiculoDocumento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteVeiculoDocumentoPorId = async (id, token) => {
  return api.delete("VeiculoDocumento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const editaVeiculoDocumentoPorId = async (VeiculoDocumento, token) => {
  return api.put("VeiculoDocumento",
    VeiculoDocumento,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const cadastraCarretaDocumento = async (CarretaDocumento, token) => {
  return api.post("CarretaDocumento",
    CarretaDocumento
  , {
    headers: {
      'Authorization': token
    }
  })
}

export const getCarretaDocumento = async (token) => {
  return api.get("CarretaDocumento",
  {
    headers: {
      'Authorization': token
    }
  })
}

export const getCarretaDocumentoPorId = async (id, token) => {
  return api.get("CarretaDocumento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const deleteCarretaDocumentoPorId = async (id, token) => {
  return api.delete("CarretaDocumento/" + id,
  {
    headers: {
      'Authorization': token
    }
  })
}

export const salvaDocsCarreta = async (tipoDocumento, doc, id, dataValidade, token) => {
  return api.post("CarretaDocumento/" + tipoDocumento + "/" + id + "/" + dataValidade,
    doc
  , {
    headers: {
      'Authorization': token
    }
  })
}